import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useReducer, useState } from 'react'
import { MdWarning } from 'react-icons/md'
import { modelIsImporting } from '../../util/models'
import { useModels } from '../../providers/ModelProvider'
import NextbrainSelect, { categoryToColor, Option } from './NextbrainSelect'
import Loading from '../loading/LoadingSmall'
import HelpTooltip from './HelpTooltip'
import {
  trainflowReduce,
  TRAINFLOW_ACTIONS,
  TARGET_STEP,
} from './train-flow/trainflowReducer'
import TrainButton from './train-flow/TrainButton'
import DataTypeIcon from '../data-types/DataTypeIcon'
import { TextareaAutosize } from '@material-ui/core'
import Pdf from '../knowledge-base/file-icons/pdf'
import { downloadReport, downloadSummaryReport } from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'

function columnValidation(model, column) {
  const type = model?.dataset?.final_column_status?.[column]
  if (
    type === 'Categorical' &&
    (model?.dataset?.statistics?.[column]?.nunique ?? 0) > 99
  ) {
    return 'Invalid, too many categories'
  } else if (type === 'Text' || type === 'ID') {
    return `${type} columns are not supported`
  }
}

export default function InsightsSixSigma({
  model,
  updateModel = () => {},
  ...props
}) {
  const { token, signout } = useAuth()
  const { t } = useTranslation()
  const isImporting = model?.status !== 'error' && modelIsImporting(model)
  const { stopTraining, enoughRowsToTrain, sixSigmaTrain } = useModels()
  const [pdfUrl, setPdfUrl] = useState('')

  const [
    {
      target,
      step,
      warning,
      columnsIgnored,
      treatmentColumns,
      confounderColumns,
      additionalContext,
    },
    _dispatch,
  ] = useReducer(trainflowReduce, {
    isLoading: true,
    columnsIgnored: [], // model?.six_sigma_details?.columns_to_ignore ?? [],
    columnsToIgnore: [],
    treatmentColumns: [],
    confounderColumns: [],
  })

  const dispatch = useCallback(
    (...params) => {
      if (model.status === 'training') return
      _dispatch(...params)
    },
    [model?.status],
  )

  const PrefixColumn = ({ column }) => {
    const type = (model?.dataset?.final_column_status ?? {})[column]
    return (
      <DataTypeIcon
        style={{
          borderColor: categoryToColor[type],
        }}
        color={categoryToColor[type]}
        size={28}
        type={type}
      />
    )
  }

  const LabelTargetType = ({ column, invalid }) => {
    const { t } = useTranslation()
    return (
      <div
        className={`d-flex align-items-center justify-content-between ${
          invalid ? 'invalid-option-target' : ''
        }`}
      >
        <div
          title={column}
          style={{ maxWidth: 'calc(100% - 28px)' }}
          className="d-inline-block text-truncate"
        >
          {column}
          <br />
          {invalid ? (
            <strong
              className="small ms-2 color-red"
              style={{ color: 'var(--nextbrain-error-color)' }}
            >
              {t(invalid)}
            </strong>
          ) : (
            <></>
          )}
        </div>
        <PrefixColumn column={column} />
      </div>
    )
  }

  const updateColumnsToIgnore = useCallback(
    (v) => {
      dispatch({ type: TRAINFLOW_ACTIONS.UPDATE_COLUMNS_IGNORED, payload: v })
    },
    [dispatch],
  )

  const updateTreatmentColumns = useCallback(
    (v) => {
      dispatch({ type: TRAINFLOW_ACTIONS.UPDATE_TREATMENT_COLUMNS, payload: v })
    },
    [dispatch],
  )

  const updateConfounderColumns = useCallback(
    (v) => {
      dispatch({
        type: TRAINFLOW_ACTIONS.UPDATE_CONFOUNDER_COLUMNS,
        payload: v,
      })
    },
    [dispatch],
  )

  const updateAdditionalContext = useCallback(
    (v) => {
      dispatch({
        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
        payload: {
          param: 'additionalContext',
          value: v,
        },
      })
    },
    [dispatch],
  )

  const updateTarget = useCallback(
    (v) => {
      if (v?.invalid) return
      dispatch({
        type: TRAINFLOW_ACTIONS.UPDATE_TARGET,
        payload: {
          target: v.value,
          model,
        },
      })
    },
    [dispatch, model],
  )

  useEffect(() => {
    if (!model?.six_sigma_details) return
    if (model?.six_sigma_details?.columns_to_ignore) {
      updateColumnsToIgnore(
        model.six_sigma_details.columns_to_ignore.map((e) => ({
          value: e,
          label: e,
          type: (model?.dataset?.final_column_status ?? {})?.[e],
        })),
      )
    }
    if (model?.six_sigma_details?.treatment_columns) {
      updateTreatmentColumns(
        model.six_sigma_details.treatment_columns.map((e) => ({
          value: e,
          label: e,
          type: (model?.dataset?.final_column_status ?? {})?.[e],
        })),
      )
    }
    if (model?.six_sigma_details?.confounder_columns) {
      updateConfounderColumns(
        model.six_sigma_details.confounder_columns.map((e) => ({
          value: e,
          label: e,
          type: (model?.dataset?.final_column_status ?? {})?.[e],
        })),
      )
    }
    if (model?.six_sigma_details?.context) {
      updateAdditionalContext(model.six_sigma_details.context)
    }
    if (model?.target) {
      updateTarget({
        value: model.target,
        label: (
          <LabelTargetType
            column={model.target}
            invalid={columnValidation(model, model.target)}
          />
        ),
        type: (model?.dataset?.final_column_status ?? {})[model.target],
      })
    }
    // eslint-disable-next-line
  }, [model])

  useEffect(() => {
    if (model?.status !== 'trained') return
    downloadSummaryReport({
      modelId: model.id,
      token,
      signout,
    }).then(async (response) => {
      const url = window.URL.createObjectURL(await response.blob())
      console.log('url', url)
      setPdfUrl(url)
    })
  }, [model, token, signout])

  const options = (model?.dataset?.columns_order ?? [])
    .map((element) => ({
      value: element,
      label: (
        <LabelTargetType
          column={element}
          invalid={columnValidation(model, element)}
        />
      ),
      type: (model?.dataset?.final_column_status ?? {})[element],
      invalid: columnValidation(model, element),
    }))
    .filter((e) => e)

  const columnsIgnoredSet = new Set(columnsIgnored.map((v) => v.value))

  const downloadFile = async (defaultName, response) => {
    try {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const contentDisposition = response.headers.get('Content-Disposition')
      let filename = defaultName ?? 'downloaded_file.pdf'

      if (contentDisposition && contentDisposition.includes('filename=')) {
        filename = contentDisposition
          .split('filename=')[1]
          .split(';')[0]
          .replace(/"/g, '')
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  }

  return (
    <div {...props} className={'view-data-component'}>
      {model?.id && (
        <>
          <Row
            className={`w-100 ${
              isImporting ? 'disabled-blur' : ''
            } position-relative mt-4`}
          >
            {isImporting && (
              <Col
                className="not-blur position-absolute d-flex align-items-center justify-content-center h-100"
                xs={12}
              >
                <Loading message={'Importing'} />
              </Col>
            )}
            <Tabs>
              <Row className={`alt-tabsdiv chatbot-explore-data d-none`}>
                <Col xs={12}>
                  <TabList className="ps-0">
                    <Tab>
                      <span className="ms-3 h6 navigate-explore-header">
                        {t('Explore')}
                      </span>
                      <HelpTooltip
                        className="ms-1 help-select-icon"
                        message={t('Explore your dataset with the assistant')}
                        helpLink={'chat-interaction-2tt9io'}
                      />
                    </Tab>
                  </TabList>
                </Col>
              </Row>
              <TabPanel>
                {model?.dataset?.status === 'imported' && (
                  <Row className="w-100 pl-3">
                    <Col className="position-relative" xs={12}>
                      <Row
                        {...props}
                        className={`${
                          props.className ?? ''
                        } train-flow-component mt-5`}
                      >
                        <Col className="ps-0" xs={12}>
                          <Row>
                            <Col
                              xs={12}
                              md={6}
                              lg={4}
                              xl={3}
                              className={`target-select-container mb-2`}
                              onClick={() => {
                                if (step !== TARGET_STEP) {
                                  //same as target=null problemType=null forecastingDate=null
                                  dispatch({
                                    type: TRAINFLOW_ACTIONS.UPDATE_TARGET,
                                    payload: { model },
                                  })
                                }
                              }}
                            >
                              <Row>
                                <Col className="position-relative" xs={12}>
                                  <span>{t('Target')}</span>
                                  <HelpTooltip
                                    className="help-select-icon"
                                    message={'column to predict'}
                                    helpLink={'column-to-predict-18qxfb2'}
                                  />
                                  {warning && (
                                    <OverlayTrigger
                                      rootClose={true}
                                      trigger={['hover', 'focus']}
                                      placement="auto"
                                      delay={{ show: 100, hide: 100 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>
                                          {t(warning)}
                                        </Tooltip>
                                      )}
                                    >
                                      <div className="trainflow-column-warning">
                                        <MdWarning size={25} />
                                      </div>
                                    </OverlayTrigger>
                                  )}
                                </Col>
                              </Row>
                              <NextbrainSelect
                                value={
                                  target
                                    ? {
                                        value: target,
                                        label: (
                                          <LabelTargetType column={target} />
                                        ),
                                        type: (model?.dataset
                                          ?.final_column_status ?? {})[target],
                                      }
                                    : null
                                }
                                onChange={updateTarget}
                                className="basic-single mt-2"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                name="Column to analyze"
                                options={options}
                              />
                            </Col>
                            <Col xs={12} md={6} lg={4} xl={3}>
                              <Row>
                                <Col className="position-relative" xs={12}>
                                  <span>{t('Columns to ignore')}</span>
                                </Col>
                              </Row>
                              <NextbrainSelect
                                value={columnsIgnored.filter(
                                  (c) => c.value !== target,
                                )}
                                onChange={updateColumnsToIgnore}
                                className="mt-2"
                                options={options
                                  .filter((e) => e.value !== target)
                                  .map((e) => ({
                                    ...e,
                                    label: e.value,
                                  }))}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                  Option,
                                }}
                                allowSelectAll={true}
                              />
                            </Col>
                            <Col xs={12} md={6} lg={4} xl={3}>
                              <Row>
                                <Col className="position-relative" xs={12}>
                                  <span>{t('Input changable columns')}</span>
                                </Col>
                              </Row>
                              <NextbrainSelect
                                value={treatmentColumns.filter(
                                  (c) =>
                                    c.value !== target &&
                                    !columnsIgnoredSet.has(c.value),
                                )}
                                onChange={updateTreatmentColumns}
                                className="mt-2"
                                options={options
                                  .filter(
                                    (e) =>
                                      e.value !== target &&
                                      !columnsIgnoredSet.has(e.value),
                                  )
                                  .map((e) => ({
                                    ...e,
                                    label: e.value,
                                  }))}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                  Option,
                                }}
                                allowSelectAll={true}
                              />
                            </Col>
                            <Col xs={12} md={6} lg={4} xl={3}>
                              <Row>
                                <Col className="position-relative" xs={12}>
                                  <span>{t('Confounders')}</span>
                                </Col>
                              </Row>
                              <NextbrainSelect
                                value={confounderColumns.filter(
                                  (c) =>
                                    c.value !== target &&
                                    !columnsIgnoredSet.has(c.value),
                                )}
                                onChange={updateConfounderColumns}
                                className="mt-2"
                                options={options
                                  .filter(
                                    (e) =>
                                      e.value !== target &&
                                      !columnsIgnoredSet.has(e.value),
                                  )
                                  .map((e) => ({
                                    ...e,
                                    label: e.value,
                                  }))}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                  Option,
                                }}
                                allowSelectAll={true}
                              />
                            </Col>
                            <Col xs={12} md={12} lg={8} xl={9} className="mt-2">
                              <Row>
                                <Col className="position-relative" xs={12}>
                                  <span>
                                    {t('Additional context (optional)')}
                                  </span>
                                </Col>
                              </Row>
                              <TextareaAutosize
                                rowsMin={1}
                                maxLength={4096}
                                className="nb-input-soft  match-select-height w-100 p-2"
                                value={additionalContext}
                                onChange={(e) =>
                                  updateAdditionalContext(e.target.value)
                                }
                                placeholder={t(
                                  'The goal of this dataset is to optimize and find actionable insigths to improve efficiency considering...',
                                )}
                              />
                            </Col>
                            <Col xs={12} md={12} lg={8} xl={6} className="mt-2">
                              <Row className="mt-4">
                                <Col
                                  xl={6}
                                  lg={6}
                                  md={6}
                                  xs={12}
                                  className="mt-2"
                                >
                                  <TrainButton
                                    model={model}
                                    trainProps={{
                                      onClick: () => {
                                        const data = {
                                          target,
                                          columns_to_ignore: columnsIgnored.map(
                                            (e) => e.value,
                                          ),
                                          treatment_columns:
                                            treatmentColumns.map(
                                              (e) => e.value,
                                            ),
                                          confounder_columns:
                                            confounderColumns.map(
                                              (e) => e.value,
                                            ),
                                          context: additionalContext,
                                        }
                                        sixSigmaTrain(model, data)
                                      },
                                      className: 'action-button w-100',
                                      disabled:
                                        model.status === 'readyToTrain' ||
                                        model.status === 'training' ||
                                        model.dataset.status === 'importing' ||
                                        model.dataset.status ===
                                          'readyToImport' ||
                                        target === null ||
                                        target === undefined,
                                    }}
                                    stopProps={{
                                      onClick: stopTraining,
                                      className: `original stop-training-button  w-100 bold`,
                                      variant: 'danger',
                                    }}
                                    enoughRows={enoughRowsToTrain}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            {model.status === 'trained' ? (
                              <>
                                {pdfUrl ? (
                                  <Row className="justify-content-center">
                                    <Col xs={8} className="mt-5">
                                      <embed
                                        src={pdfUrl}
                                        type="application/pdf"
                                        width="100%"
                                        style={{
                                          height: '85vh',
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                ) : (
                                  <></>
                                )}
                                <Col xs={12} className="mt-5">
                                  <Row className="justify-content-center">
                                    <Col
                                      xs={3}
                                      className="mx-3 text-center py-5 cursor-pointer bot-suggestions text-white"
                                      onClick={() => {
                                        downloadReport({
                                          modelId: model.id,
                                          token,
                                          signout,
                                        }).then((response) => {
                                          downloadFile(
                                            `Report ${
                                              model?.dataset?.name ?? 'model'
                                            }.pdf`,
                                            response,
                                          )
                                        })
                                      }}
                                    >
                                      <Pdf
                                        width={40}
                                        height={40}
                                        className="custom-svg me-3"
                                      />
                                      {t('Technical report')}
                                    </Col>
                                    <Col
                                      xs={3}
                                      className="mx-3 text-center py-5 cursor-pointer bot-suggestions text-white"
                                      onClick={() => {
                                        downloadSummaryReport({
                                          modelId: model.id,
                                          token,
                                          signout,
                                        }).then((response) => {
                                          downloadFile(
                                            `Summary Report ${
                                              model?.dataset?.name ?? 'model'
                                            }.pdf`,
                                            response,
                                          )
                                        })
                                      }}
                                    >
                                      <Pdf
                                        width={40}
                                        height={40}
                                        className="custom-svg me-3"
                                      />
                                      {t('Summary report')}
                                    </Col>
                                  </Row>
                                </Col>
                              </>
                            ) : (
                              <></>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </TabPanel>
            </Tabs>
          </Row>
        </>
      )}
    </div>
  )
}

import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useCallback,
  useMemo,
} from 'react'
import TargetSelect from './TargetSelect'
import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Button,
  Form,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import TrainButton from './TrainButton'

import { getDefaultAlgorithms } from '../../../util/models'
import { getDateTypeColumns } from '../../../util/models'
import NextbrainSelect, { Option } from '../NextbrainSelect'
import './TrainFlow.css'
import HelpTooltip from '../HelpTooltip'
import TimeColumn from './mmm/TimeColumn'
import ColumnsToIgnore from './ColumnsToIgnore'
import { useModels } from '../../../providers/ModelProvider'
import { MdWarning } from 'react-icons/md'
import { RiAlarmWarningFill } from 'react-icons/ri'
import {
  trainflowReduce,
  TRAINFLOW_ACTIONS,
  TARGET_STEP,
  TUNNING_STEP,
} from './trainflowReducer'
import { useAuth } from '../../../providers/AuthProvider'
import Periodicity, {
  options as periodOptions,
} from './anomalyDetection/Periodicity'
import Slider from '../../slider/Slider'
import FineTunning from './FineTunning'
import ForecastHorizon from './ForecastHorizon'
import { useNav } from '../../../providers/NavProvider'
import ProblemButton from './ProblemButton'
import { useQuery } from 'react-query'
import { getModelHighCorrelation } from '../../../services/model'
import { round } from '../../utils/formating'
import CrashFallback from '../../crash-fallback/CrashFallback'
import RoleDisable from '../../utils/RoleDisable'

const problemToClass = {
  Classification: 'classification',
  Regression: 'regression',
  Forecast: 'forecast',
  'Marketing Mix Modeling': 'marketing_mix_modeling',
  'Anomaly Detection': 'anomaly_detection',
}

export default function TrainFlow({
  onTrain = () => {},
  onConfigChanged = () => {},
  syntheticEnabled,
  balanceDataEnabled,
  removeOutliersEnabled,
  switchSynthetic,
  switchBalanceData,
  switchRemoveOutliers,
  advancedOpen,
  setAdvancedOpen,
  expand,
  setExpand = () => {},
  ...props
}) {
  const { MMMEnabled, token, signout } = useAuth()
  const { activeModel, stopTraining, enoughRowsToTrain } = useModels()
  const model = activeModel
  const { t } = useTranslation()
  const forecastingCapRef = useRef()
  const suggestionIgnoreRef = useRef()
  const { mode } = useNav()

  const [
    {
      isLoading,
      step,
      target,
      columnsIgnored,
      columnsToIgnore,
      problemType,
      forecastingDate,
      useSynthetic,
      removeOutliers,
      optimizationMetrics,
      trainSplit,
      selectedTime,
      extraFeatures,
      mmmTimeColumn,
      mmmTimeUnits,
      warning,
      periodicity,
      maxAnomalies,
      algorithm,
      newModel,
      columnTypes,
      horizonMagnitude,
      horizonUnit,
      forecastPeriodicity,
      forecastQuality,
      forecast_limit,
      enableColumns,
      ...parameters
    },
    _dispatch,
  ] = useReducer(trainflowReduce, {
    isLoading: true,
    columnsIgnored: [],
    columnsToIgnore: [],
  })
  const dispatch = useCallback(
    (...params) => {
      if (model.status === 'training') return
      _dispatch(...params)
    },
    [model?.status],
  )
  const [dismissedSuggestions, setDismissedSuggestions] = useState({})
  const { data: suggestionsIgnore, refetch } = useQuery(
    ['model-target-ignore-columns', model?.id, target],
    async () => {
      if (
        model?.status === 'trained' ||
        !target ||
        !['Double', 'Integer'].includes(
          model?.dataset?.final_column_status?.[target],
        )
      )
        return null
      const items = await getModelHighCorrelation({
        modelId: model?.id,
        target,
        token,
        signout,
      })
      if (!items) {
        setTimeout(refetch, 3000)
        return null
      }
      return items
    },
    { staleTime: Infinity },
  )

  const doingMMM = problemType === 'Marketing Mix Modeling'

  useEffect(() => {
    if (isLoading) {
      _dispatch({ type: TRAINFLOW_ACTIONS.LOAD, payload: { model, t, mode } })
    }
    // eslint-disable-next-line
  }, [model, isLoading, dispatch])

  const setStep = () => {}

  const dateTypes = getDateTypeColumns(model)
  const selectOptions = useMemo(() => {
    return (
      model?.dataset?.columns_order?.map((element) => ({
        value: element,
        label: element,
        type: (model?.dataset?.final_column_status ?? {})[element],
      })) ?? []
    )
  }, [model])
  let mmmTimeColumnOptions = []
  if (doingMMM) {
    if (dateTypes.length === 0) {
      mmmTimeColumnOptions = selectOptions.filter((c) => {
        return c.value !== target && c.type === 'Integer'
      })
    } else if (dateTypes.length > 1) {
      mmmTimeColumnOptions = dateTypes
    }
  }

  const forecastQualityOptions = useMemo(() => {
    const options = {
      Fast: 200,
      Performance: 400,
      Accurate: 600,
    }
    return Object.entries(options).map(([label, value]) => ({
      label: t(label),
      value,
    }))
  }, [t])

  const showIgnoreColumns = () =>
    problemType !== 'Forecast' && model.dataset.cols > 2

  const shouldUseSynthetic = (model?.dataset?.rows ?? 0) < 5000

  const trainCallback = () => {
    const mmm = {}
    if (problemType === 'Marketing Mix Modeling') {
      let timeColumn
      let timeColumnUnit = mmmTimeUnits?.value
      if (dateTypes.length === 1) {
        timeColumn = dateTypes[0]
        timeColumnUnit = 'D'
      } else if (dateTypes.length > 1) {
        timeColumnUnit = 'D'
      } else {
        timeColumn = mmmTimeColumn.value
      }

      mmm.datetime_col = timeColumn
      mmm.frequency = timeColumnUnit
      mmm.extra_features_cols = Array.isArray(extraFeatures)
        ? extraFeatures.map((v) => v.value)
        : []
    }
    const anomalies = {}
    if (problemType === 'Anomaly Detection') {
      anomalies.periodicity = periodOptions?.[periodicity] ?? 4
      anomalies.max_anomalies = maxAnomalies
    }

    onTrain(
      { target, problemType, forecastingDate },
      (columnsIgnored ?? []).map((v) => v.value),
      selectedTime.value,
      selectedTime.value === 1, // is_lightning ?
      useSynthetic || syntheticEnabled,
      removeOutliers || removeOutliersEnabled,
      getDefaultAlgorithms(model)
        ?.map((v) => v.value)
        .filter((v) => v !== 'Auto'),
      optimizationMetrics?.map((v) => v.value).filter((v) => v !== 'Auto'),
      Number.parseFloat(forecastingCapRef?.current?.value),
      trainSplit / 100,
      mmm,
      anomalies,
      {
        horizonMagnitude,
        horizonUnit,
        periodicity: forecastPeriodicity?.value,
        forecastQuality: forecastQuality,
        forecast_limit,
      },

      Object.entries(parameters).reduce((acc, [k, v]) => {
        if (typeof v === 'boolean') acc[k] = v
        return acc
      }, {}),
    )
  }

  const updateSynthetic = useCallback(
    (v) => {
      dispatch({
        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
        payload: {
          param: 'useSynthetic',
          value: v,
        },
      })
    },
    [dispatch],
  )

  const updateRemoveOutliers = useCallback(
    (v) => {
      dispatch({
        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
        payload: {
          param: 'removeOutliers',
          value: v,
        },
      })
    },
    [dispatch],
  )

  const updateOptimizationMextrics = useCallback(
    (v) => {
      dispatch({
        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
        payload: {
          param: 'optimizationMetrics',
          value: v,
        },
      })
    },
    [dispatch],
  )

  const updateColumnsToIgnore = useCallback(
    (v) => {
      dispatch({ type: TRAINFLOW_ACTIONS.UPDATE_COLUMNS_IGNORED, payload: v })
    },
    [dispatch],
  )
  const updateSelectedTime = useCallback(
    (v) => {
      dispatch({
        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
        payload: {
          param: 'selectedTime',
          value: v,
        },
      })
    },
    [dispatch],
  )

  const updateTargetConfig = useCallback((v) => {
    dispatch({
      type: TRAINFLOW_ACTIONS.UPDATE_TARGET,
      payload: { ...(v ?? {}), model },
    })
    // eslint-disable-next-line
  }, [])

  const updateExtraFeatures = useCallback((v) => {
    dispatch({
      type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
      payload: {
        param: 'extraFeatures',
        value: v,
      },
    })
    // eslint-disable-next-line
  }, [])

  const keyParams = JSON.stringify(parameters)
  useEffect(
    () => {
      onConfigChanged({
        targetConfig: { target, problemType, forecastingDate },
        setTargetConfig: updateTargetConfig,
        columnsToIgnore: columnsIgnored,
        setColumnsToIgnore: updateColumnsToIgnore,
        selectedTime,
        setSelectedTime: updateSelectedTime,
        useSynthetic,
        setUseSynthethic: updateSynthetic,
        removeOutliers,
        setRemoveOutliers: updateRemoveOutliers,
        optimizationMetrics,
        setOptimizationMetrics: updateOptimizationMextrics,
        extraFeatures,
        setExtraFeatures: updateExtraFeatures,
        parameters,
      })
    },
    // eslint-disable-next-line
    [
      target,
      problemType,
      forecastingDate,
      columnsIgnored,
      selectedTime,
      useSynthetic,
      removeOutliers,
      optimizationMetrics,
      extraFeatures,
      keyParams,
      dispatch,
    ],
  )

  const startTunning = () =>
    step !== TUNNING_STEP ? setStep(TUNNING_STEP) : null

  const hideIf = (b) => (b ? 'd-none' : '')

  useEffect(() => {
    dispatch({
      type: TRAINFLOW_ACTIONS.UPDATE_MODEL_TYPES,
      payload: model,
    })
  }, [model, dispatch])

  const MMMTunning = () => {
    if (problemType !== 'Marketing Mix Modeling') return <></>

    switch (dateTypes.length) {
      case 0:
      case 1:
        if (!mmmTimeColumnOptions.length) return <></>
        return (
          <>
            <Col
              onClick={() => {}}
              className="hide-before-tunning mb-2"
              xs={12}
              md={6}
              lg={4}
              xl={3}
            >
              <Row className={`align-items-center`}>
                <Col className="mb-2" xs={12}>
                  <TimeColumn
                    onChange={(value) => {
                      dispatch({
                        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                        payload: {
                          param: 'mmmTimeColumn',
                          value,
                        },
                      })
                    }}
                    mmmTimeColumn={mmmTimeColumn}
                    mmmTimeColumnOptions={mmmTimeColumnOptions}
                    selectProps={{
                      isDisabled: mmmTimeColumnOptions.length === 1,
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              className="hide-before-tunning mb-2"
              xs={12}
              md={6}
              lg={4}
              xl={3}
            >
              <Row>
                <Col xs={12}>
                  {t('Time column units')}{' '}
                  <HelpTooltip
                    className="help-select-icon"
                    message={'time column units'}
                    helpLink={'columns-to-ignore-14wj24d'}
                  />
                </Col>
                <Col className="mb-2" xs={12}>
                  <NextbrainSelect
                    value={mmmTimeUnits}
                    onChange={(value) => {
                      dispatch({
                        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                        payload: {
                          param: 'mmmTimeUnits',
                          value,
                        },
                      })
                    }}
                    className="basic-single mt-2"
                    classNamePrefix="select"
                    name={t('Time column units')}
                    options={[
                      { value: 'D', label: t('Day') },
                      { value: 'W', label: t('Week') },
                      { value: 'M', label: t('Month') },
                    ]}
                    defaultValue={{ value: 'W', label: t('Week') }}
                  />
                </Col>
              </Row>
            </Col>
          </>
        )
      default:
        return (
          <Col
            className="hide-before-tunning mb-2"
            xs={12}
            md={6}
            lg={4}
            xl={3}
          >
            <TimeColumn
              onChange={(value) => {
                dispatch({
                  type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                  payload: {
                    param: 'mmmTimeColumn',
                    value: {
                      value: mmmTimeColumnOptions[0]?.value,
                      label: mmmTimeColumnOptions[0]?.label,
                    },
                  },
                })
              }}
              mmmTimeColumn={mmmTimeColumn}
              mmmTimeColumnOptions={mmmTimeColumnOptions}
            />
          </Col>
        )
    }
  }

  const trainClass =
    `train-flow-${model?.status}` +
    ` ${step === TUNNING_STEP ? 'train-ready' : ''}` +
    ` problem-${problemToClass[problemType] ?? 'empty'}` +
    `${doingMMM ? ' market-mix-modeling-problem' : ''}`
  const isMMMWithoutPlugin =
    problemType === 'Marketing Mix Modeling' && !MMMEnabled()

  const anyValidSuggestion = useMemo(() => {
    const suggestions = Object.keys(suggestionsIgnore ?? {})
    const ignoredSet = new Set(columnsIgnored?.map((v) => v.value) ?? [])
    const trainingOrTrained = ['trained', 'readyToTrain', 'training'].includes(
      model?.status,
    )
    return (
      problemType &&
      problemType !== 'Anomaly Detection' &&
      !trainingOrTrained &&
      !dismissedSuggestions?.[target] &&
      suggestions.some((v) => !ignoredSet.has(v))
    )
  }, [
    target,
    problemType,
    suggestionsIgnore,
    columnsIgnored,
    dismissedSuggestions,
    model,
  ])

  const columnsIgnoredSet = new Set(columnsIgnored?.map((v) => v?.value) ?? [])

  return model && !isLoading ? (
    <>
      <RoleDisable screen={true}>
        <Row
          {...props}
          className={`${
            props.className ?? ''
          } train-flow-component ${trainClass} mt-5`}
        >
          <Col className="ps-0" xs={12}>
            <Row>
              <Col
                xs={12}
                md={6}
                lg={4}
                xl={3}
                className={`target-select-container mb-2`}
                onClick={() => {
                  if (step !== TARGET_STEP) {
                    //same as target=null problemType=null forecastingDate=null
                    dispatch({
                      type: TRAINFLOW_ACTIONS.UPDATE_TARGET,
                      payload: { model },
                    })
                  }
                }}
              >
                <Row>
                  <Col className="position-relative" xs={12}>
                    <span>
                      {mode === 'mmm'
                        ? t('KPI column')
                        : t('Column to predict')}
                    </span>
                    <HelpTooltip
                      className="help-select-icon"
                      message={'column to predict'}
                      helpLink={'column-to-predict-18qxfb2'}
                    />
                    {warning && (
                      <OverlayTrigger
                        rootClose={true}
                        trigger={['hover', 'focus']}
                        placement="auto"
                        delay={{ show: 100, hide: 100 }}
                        overlay={(props) => (
                          <Tooltip {...props}>{t(warning)}</Tooltip>
                        )}
                      >
                        <div className="trainflow-column-warning">
                          <MdWarning size={25} />
                        </div>
                      </OverlayTrigger>
                    )}
                  </Col>
                </Row>
                <TargetSelect
                  className="mt-2"
                  target={target}
                  problemType={problemType}
                  forecastingDate={forecastingDate}
                  onChange={(target, problemType, forecastingDate) => {
                    dispatch({
                      type: TRAINFLOW_ACTIONS.UPDATE_TARGET,
                      payload: { target, problemType, forecastingDate, model },
                    })
                  }}
                  model={model}
                />
              </Col>
              <Col className="show-forecast mb-2" xs={12} md={6} lg={4} xl={3}>
                <Row>
                  <Col className="show-forecast mb-2" xs={12}>
                    <strong>{t('Forecast quality')}</strong>
                  </Col>
                </Row>
                <NextbrainSelect
                  value={forecastQualityOptions.find(
                    (v) => v.value === forecastQuality,
                  )}
                  onChange={(value) => {
                    dispatch({
                      type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                      payload: {
                        forecastQuality: value?.value ?? 200,
                      },
                    })
                  }}
                  options={forecastQualityOptions}
                  hideSelectedOptions={false}
                  isClearable={false}
                  placeholder={'Units'}
                />
              </Col>
              <Col className="show-forecast mb-2" xs={12} md={6} lg={4} xl={3}>
                <strong>{t('Forecast horizon')} </strong>
                <HelpTooltip
                  className="help-select-icon"
                  message={t(
                    'How far in the future to predict, as we go further into the future, the predictions become less accurate',
                  )}
                />
                <Row>
                  <Col className="show-forecast mt-2" xs={12}>
                    <ForecastHorizon
                      model={model}
                      forecastingDate={forecastingDate}
                      magnitude={horizonMagnitude}
                      units={horizonUnit}
                      onChange={(magnitude, units) => {
                        dispatch({
                          type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                          payload: {
                            horizonMagnitude: magnitude,
                            horizonUnit: units,
                          },
                        })
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="visible-mmm mb-2" xs={12} md={6} lg={4} xl={3}>
                <strong>{t('Extra features')} </strong>
                <HelpTooltip
                  className="help-select-icon"
                  message={'Extra features'}
                  helpLink={'extra-features-t01vry'}
                />
                <Row>
                  <Col className="visible-mmm mb-2" xs={12}>
                    <NextbrainSelect
                      value={extraFeatures}
                      onChange={(value) =>
                        dispatch({
                          type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                          payload: {
                            param: 'extraFeatures',
                            value: value,
                          },
                        })
                      }
                      className="mt-2"
                      options={selectOptions.filter(
                        (c) =>
                          c.value !== target &&
                          c.value !== mmmTimeColumn?.value &&
                          !columnsIgnoredSet.has(c.value),
                      )}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,
                      }}
                      allowSelectAll={true}
                    />
                  </Col>
                </Row>
              </Col>
              {MMMTunning()}
              <Col
                xs={12}
                md={6}
                lg={4}
                xl={3}
                onClick={startTunning}
                className={`columns-to-ignore-container hide-anomalies hide-forecast hide-before-tunning mb-2 ${hideIf(
                  !showIgnoreColumns(),
                )}`}
              >
                <Row
                  className={`align-items-center columns-to-ignore-target-flow`}
                >
                  <ColumnsToIgnore
                    columnsIgnored={columnsIgnored}
                    columnsToIgnore={columnsToIgnore}
                    setColumnsIdnored={updateColumnsToIgnore}
                    targetConfig={{ target, problemType, forecastingDate }}
                    mmmTimeColumn={mmmTimeColumn}
                    selectOptions={selectOptions}
                    extraFeatures={extraFeatures}
                    columnsEnabled={enableColumns}
                    setColumnsEnabled={(v) => {
                      dispatch({
                        type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                        payload: {
                          param: 'enableColumns',
                          value: v,
                        },
                      })
                    }}
                  />
                </Row>
              </Col>
              <Col
                xs={12}
                md={6}
                lg={4}
                xl={3}
                onClick={startTunning}
                className={`tunning-container hide-before-tunning hide-anomalies hide-forecast mb-2`}
              >
                <Row className={`align-items-center  tunning-target-flow`}>
                  <Col xs={12}>
                    <span>{t('Training quality')} </span>
                    <HelpTooltip
                      className="help-select-icon"
                      message={'train quality'}
                      helpLink={'train-quality-iv58rp'}
                    />
                  </Col>
                  <Col className={`mb-2`} xs={12}>
                    <Row>
                      <Col xs={12}>
                        <NextbrainSelect
                          value={
                            selectedTime?.value
                              ? {
                                  label: t(selectedTime.label),
                                  value: selectedTime.value,
                                }
                              : null
                          }
                          onChange={(value) => updateSelectedTime(value)}
                          className="basic-single mt-2"
                          classNamePrefix="select"
                          name={t('Train quality')}
                          options={[
                            { value: 1, label: t('Fast') },
                            { value: 2, label: t('Performance (≈ 3-5 min)') },
                            { value: 5, label: t('Accurate (≈ 6-10 min)') },
                          ]}
                          defaultValue={{ value: 1, label: t('Fast') }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col
                className="show-anomaly-detection mb-2"
                xs={12}
                md={6}
                lg={4}
                xl={3}
              >
                <Row>
                  <Col xs={12}>
                    <strong>{t('Periodicity')} </strong>
                    <HelpTooltip
                      className="help-select-icon"
                      message={t(
                        'The time period to split the dataset and compare for anomaly detection',
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Periodicity
                      model={model}
                      problemType={problemType}
                      periodicity={periodicity}
                      onChange={(v) => {
                        dispatch({
                          type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                          payload: {
                            param: 'periodicity',
                            value: v,
                          },
                        })
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                className="show-anomaly-detection mb-2"
                xs={12}
                md={6}
                lg={4}
                xl={3}
              >
                <Row>
                  <Col xs={12}>
                    <strong>{t('Maximum Sample Anomaly Rate')} </strong>
                    <HelpTooltip
                      className="help-select-icon"
                      message={t(
                        'Limit the percentage of samples that can be categorized as anomalies',
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Slider
                      min={-2}
                      max={20}
                      value={maxAnomalies}
                      updateRight={false}
                      onChange={(v) =>
                        dispatch({
                          type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                          payload: {
                            param: 'maxAnomalies',
                            value: Math.max(Math.min(v, 20), 2),
                          },
                        })
                      }
                      leftText={t('Min (2%)')}
                      rightText={t('Max (20%)')}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12}></Col>
              <Col
                className="hide-before-tunning hide-anomalies advanced-group mb-2"
                // style={{ maxHeight: "30px", zIndex: 10 }}
                xs={12}
                md={6}
                lg={4}
                xl={3}
              >
                <Row className="align-items-center ms-1">
                  <Col xs={12}>
                    <FineTunning
                      target={target}
                      problemType={problemType}
                      dispatch={dispatch}
                      advancedOpen={advancedOpen}
                      setAdvancedOpen={setAdvancedOpen}
                      shouldUseSynthetic={shouldUseSynthetic}
                      useSynthetic={useSynthetic}
                      syntheticEnabled={syntheticEnabled}
                      balanceDataEnabled={balanceDataEnabled}
                      updateSynthetic={updateSynthetic}
                      switchSynthetic={switchSynthetic}
                      switchBalanceData={switchBalanceData}
                      removeOutliers={removeOutliers}
                      removeOutliersEnabled={removeOutliersEnabled}
                      updateRemoveOutliers={updateRemoveOutliers}
                      switchRemoveOutliers={switchRemoveOutliers}
                      step={step}
                      model={model}
                      parameters={parameters}
                      periodicicty={forecastPeriodicity}
                      forecast_limit={forecast_limit}
                      forecastingDate={forecastingDate}
                      setPeriodicity={(v) =>
                        dispatch({
                          type: TRAINFLOW_ACTIONS.UPDATE_PARAM_VALUE,
                          payload: {
                            param: 'forecastPeriodicity',
                            value: v,
                          },
                        })
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={12} lg={8} xl={6}>
                <Row>
                  {problemType && mode !== 'mmm' && (
                    <Col
                      className="mb-2 mb-sm-2 mb-md-0 px-2"
                      xl={6}
                      lg={6}
                      md={6}
                      xs={12}
                    >
                      <Row className="mx-1">
                        <Col
                          className="dflex-center looks-like-train-input py-1 position-relative"
                          xs={12}
                        >
                          <ProblemButton
                            className="w-100"
                            problemType={problemType}
                            active={true}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col xl={6} lg={6} md={6} xs={12}>
                    <TrainButton
                      needsPlugin={isMMMWithoutPlugin}
                      model={model}
                      trainProps={{
                        onClick: trainCallback,
                        className: `action-button w-100 ${hideIf(
                          step !== TUNNING_STEP,
                        )}`,
                        disabled:
                          model.status === 'readyToTrain' ||
                          model.status === 'training' ||
                          model.dataset.status === 'importing' ||
                          model.dataset.status === 'readyToImport',
                      }}
                      stopProps={{
                        onClick: stopTraining,
                        className: `original stop-training-button  w-100 bold`,
                        variant: 'danger',
                      }}
                      enoughRows={enoughRowsToTrain}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {anyValidSuggestion && (
          <CrashFallback message={<></>} t={t}>
            <Row>
              <Col className="dflex-center mt-4" xs={12}>
                <Row
                  className="container-suggestions-correlation p-3"
                  ref={suggestionIgnoreRef}
                >
                  <Col className="pe-0" xs={1}>
                    <RiAlarmWarningFill
                      size={40}
                      color={'var(--nextbrain-warning-color)'}
                    />
                  </Col>
                  <Col className="text-center h5 mb-4 ps-0" xl={11}>
                    {target}{' '}
                    {t(
                      'exhibits strong correlation with the columns listed below, and we believe it would be advantageous to exclude them in order to enhance the accuracy of your model.',
                    )}
                  </Col>
                  <Col xs={12} className="text-left">
                    <div className="ms-5">{t('Columns to ignore')}</div>
                  </Col>
                  {Object.entries(suggestionsIgnore)
                    .sort((a, b) => b[1] - a[1])
                    .map(([k, v]) => (
                      <Col key={k} xs={12}>
                        <Row className="justify-content-between mx-xl-5 mx-md-2 pb-1">
                          <Col
                            xs={'auto'}
                            className="d-inline-block text-truncate ps-0"
                            style={{ maxWidth: '65%' }}
                          >
                            <span className="d-inline-block">
                              <Form.Check
                                type="checkbox"
                                className={`ps-0 mb-0 d-inline-block`}
                                style={{ maxWidth: '30px' }}
                                defaultChecked={true}
                                name={k}
                              />
                            </span>
                            <strong>{k}</strong>
                          </Col>
                          <Col xs={'auto'} style={{ maxWidth: '35%' }}>
                            <strong>{round(v * 100, 1)}%</strong>{' '}
                            {t('correlated')}
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  <Col xs={12}>
                    <Row className="justify-content-center mt-5">
                      <Col xs={'auto'}>
                        <Button
                          className="px-3 py-2 original"
                          variant="secondary"
                          onClick={() => {
                            setDismissedSuggestions({
                              ...dismissedSuggestions,
                              [target]: true,
                            })
                          }}
                        >
                          <span>{t('Cancel')}</span>
                        </Button>
                      </Col>
                      <Col xs={'auto'}>
                        <Button
                          className="px-3 py-2"
                          onClick={() => {
                            const currentlyIgnored = new Set(
                              columnsIgnored.map((v) => v.value),
                            )
                            const items = [
                              ...suggestionIgnoreRef?.current?.querySelectorAll(
                                'input[type="checkbox"]:checked',
                              ),
                            ].map((v) => v.name)

                            updateColumnsToIgnore([
                              ...columnsIgnored,
                              ...items
                                .filter((v) => !currentlyIgnored.has(v))
                                .map((v) => ({
                                  value: v,
                                  label: v,
                                })),
                            ])
                            setDismissedSuggestions({
                              ...dismissedSuggestions,
                              [target]: true,
                            })
                          }}
                        >
                          <span>{t('Continue')}</span>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CrashFallback>
        )}
      </RoleDisable>
    </>
  ) : (
    <>{t('No model')}</>
  )
}

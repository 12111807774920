import React, { useEffect, useMemo, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import {
  createWorkspace,
  deleteWorkspace as deleteWorkspaceApi,
  duplicateProject,
  editProjectById,
  getAllWorkSpace,
} from '../../services/project'
import { TiPlus } from 'react-icons/ti'
import './homepage.css'
import { Link } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import { Row, Col, Modal, Breadcrumb, Button, Container } from 'react-bootstrap'
import Loading from '../loading/LoadingSmall'
import { useTranslation } from 'react-i18next'

import ProjectCard from './ProjectCard'
import EditOverlay from './EditOverlay'
import NewProject from './NewProject'
import useStoreState from '../../hooks/UseStoreState'

import { useAuth } from '../../providers/AuthProvider'
import IntroScreen from './IntroScreen'
import { useNav } from '../../providers/NavProvider'
import NBInput from '../form/NBInput'
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa'

export default function Home({ setTitle }) {
  const { t } = useTranslation()
  const [workSpaceData, setWorkSpaceData] = useState([])
  const [newProject, setNewProject] = useState(false)
  const [justShare, setJustShare] = useState(false)
  const [order, setOrder] = useState(0)
  const { signout, token, user } = useAuth()
  const [userHelp, setUserHelp] = useStoreState({ key: 'userHelp' })
  const [filter, setFilter] = useState('')
  const { mode } = useNav()
  const projectsKey = `workspace-${token}-${mode}`
  const queryClient = useQueryClient()

  useEffect(() => setWorkSpaceData([]), [mode])
  useEffect(() => {
    setTitle(t('NextBrain'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: projects } = useQuery(
    projectsKey,
    async () => {
      if (mode === 'rag') return []
      const ws = await getAllWorkSpace({
        only_projects: true,
        product_type: mode,
        token,
        signout,
      })
      if (!Array.isArray(ws)) throw new Error('Failed to fetch workspaces')
      return ws
    },
    { staleTime: Infinity, retryDelay: 1500, retry: true },
  )
  const [ordering, setOrdering] = useState(null)

  const data = useMemo(() => {
    let data = projects
    console.log(data)
    if (!Array.isArray(data)) return data
    data = [...data]
    switch (ordering) {
      case 'desc':
        data.sort((a, b) =>
          a?.name?.toLowerCase() > b?.name?.toLowerCase() ? -1 : 1,
        )
        break
      case 'asc':
        data.sort((a, b) =>
          a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1,
        )
        break
      default:
        break
    }
    return data
  }, [ordering, projects])

  const isLoading = !Array.isArray(data)
  useEffect(() => {
    if (Array.isArray(data)) {
      setWorkSpaceData(data)
    }
    // eslint-disable-next-line
  }, [isLoading, data])

  const CreateProject = () => (
    <Col
      xs={12}
      className="my-3 d-flex justify-content-center"
      onClick={() => {
        setJustShare(false)
        setNewProject({
          owner: { id: user.id, email: user.email },
          users: Object.keys(users),
        })
      }}
    >
      <Row className="project-new-template p-2 w-auto  justify-content-center">
        <Col className="col-auto d-flex align-items-center">
          <TiPlus className="me-1" size={25} />
          {t('Create a new workspace')}
        </Col>
      </Row>
    </Col>
  )

  const editWSmenu = async (action, project) => {
    let deleteQuestion = `${t('Are you sure you want to delete')} ${
      project.name
    }?`
    let failedDelete = t('Failed to delete project')
    let deletedProject = `${t('Deleted project')} ${project.name}`
    switch (action) {
      case 'edit':
        setJustShare(false)
        setNewProject(project)
        break
      case 'leave':
        deleteQuestion = `${t('Are you sure you want to leave')} ${
          project.name
        }?`
        failedDelete = t('Failed to leave project')
        deletedProject = t('Left project', { projectName: project.name })
      // eslint-disable-next-line
      case 'delete':
        NotificationManager.info(
          <Row>
            <Col title={deleteQuestion} xs={12}>
              {deleteQuestion}
            </Col>
            <Col className="my-2" xs={12}>
              <Button
                className="original w-100"
                variant="danger"
                onClick={() => {
                  console.log('deleting project', project.id)
                  setWorkSpaceData(
                    workSpaceData.filter((e) => e.id !== project.id),
                  )
                  const index = data.findIndex((e) => e.id === project.id)
                  if (index >= 0) data.splice(index, 1)
                  deleteWorkspaceApi(project.id, token, signout).then((res) => {
                    if (!res?.ok) {
                      NotificationManager.error(failedDelete)
                    } else {
                      NotificationManager.info(deletedProject)
                    }
                  })
                }}
              >
                {t('Confirm')}
              </Button>
            </Col>
          </Row>,
        )
        break
      case 'duplicate':
        NotificationManager.info(t('Duplicating project'))
        document.querySelector('body')?.click()
        duplicateProject({
          projectId: project.id,
          token,
          signout,
        }).then((r) => {
          if (r?.id) {
            NotificationManager.info(
              t('Duplicated project, copied models will be available shortly'),
            )
            queryClient.invalidateQueries(projectsKey)
          } else {
            NotificationManager.error(t('Failed to duplicate project'))
          }
        })
        break
      case 'shareMenu':
        setJustShare(true)
        setNewProject(project)
        break
      case 'favourite':
        await onFavourite(project.id, !project.favourite)
        project.favourite = !project.favourite
        setWorkSpaceData([...workSpaceData])
        break
      default:
        NotificationManager.error(`${t('Invalid action')} ${action}`)
        break
    }
  }

  const onFavourite = async (workspaceId, is_favourite) => {
    setWorkSpaceData(workSpaceData.filter((e) => !e.delete))
    await editProjectById(workspaceId, { is_favourite }, token, signout)
  }

  const linkModel = (workspace) => {
    return `${
      workspace.id.startsWith('pending')
        ? '#'
        : `model/${workspace.id}?wp-name=${encodeURIComponent(workspace.name)}`
    }`
  }

  const myWorkspaces = () =>
    !user ? [] : workSpaceData.filter((w) => w.owner.id === user.id)
  const sharedWorkspaces = () =>
    !user
      ? []
      : workSpaceData.filter((w) =>
          w.invited_users.find((w) => w.id === user.id),
        )
  const favouriteWorkspaces = () =>
    !user ? [] : workSpaceData.filter((w) => w.favourite)

  const users = workSpaceData.reduce((dict, project) => {
    dict[project.owner.email] = dict[project.owner.email] ?? new Set()
    dict[project.owner.email].add(project.id)
    project.invited_users.forEach((u) => {
      dict[u.email] = dict[u.email] ?? new Set()
      dict[u.email].add(project.id)
    })
    return dict
  }, {})

  const SortItem = ordering === 'desc' ? FaSortAlphaUp : FaSortAlphaDown

  return (
    <>
      <Container className="justify-content-center">
        <Row className="newContainer">
          <Col xs={12} className="my-2 ps-0">
            <Row className="mx-xl-4 mx-md-2 mx-1">
              <Row className="d-lg-none d-flex w-100 justify-content-center pt-3">
                <Col xs={'auto'}>
                  <Breadcrumb>
                    <Breadcrumb.Item
                      className={`breadcrumb-opt ${
                        0 === order ? 'active' : ''
                      }`}
                      onClick={() => {
                        setOrder(0)
                      }}
                    >
                      {t('MY WORKSPACES')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                      className={`breadcrumb-opt ${
                        1 === order ? 'active' : ''
                      }`}
                      onClick={() => {
                        setOrder(1)
                      }}
                    >
                      {t('SHARED')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                      className={`breadcrumb-opt ${
                        2 === order ? 'active' : ''
                      }`}
                      onClick={() => {
                        setOrder(2)
                      }}
                    >
                      {t('FAVORITES')}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>
              <Row className="justify-content-between w-100 pe-0">
                <Col
                  style={{ minWidth: '100px' }}
                  className="pe-none d-xl-block d-sm-none"
                  xl="auto"
                ></Col>
                <Col
                  className="dflex-center"
                  style={{ minWidth: '200px' }}
                  xl={'auto'}
                  sm={12}
                >
                  <CreateProject key={'create'} />
                </Col>
                <Col
                  className="d-flex align-items-center justify-content-xl-end justify-content-sm-start pe-0"
                  style={{ minWidth: '140px', maxWidth: '140px' }}
                  xl="auto"
                  sm={12}
                >
                  <SortItem
                    size={22}
                    color={ordering ? 'white' : '#A8A8A8'}
                    className="icon-btn me-2"
                    onClick={() => {
                      switch (ordering) {
                        case 'asc':
                          setOrdering('desc')
                          break
                        case 'desc':
                          setOrdering(null)
                          break
                        default:
                          setOrdering('asc')
                      }
                    }}
                  />
                  <div>
                    <NBInput
                      borderRadius="8px"
                      style={{
                        borderRadius: 8,
                        paddingLeft: '50px',
                        maxWidth: '300px',
                        minWidth: '300px',
                      }}
                      useWrapper={false}
                      className="mt-4 expand-search-item"
                      placeholder={t('Search a workspace...')}
                      onFocus={(e) => {
                        e.currentTarget.classList.add('focused-search')
                      }}
                      onBlur={(e) => {
                        e.currentTarget.classList.remove('focused-search')
                      }}
                      onChange={(e) => setFilter(e.target.value)}
                    />
                    <i
                      style={{ float: 'left' }}
                      className="fas fa-search icon"
                    ></i>
                  </div>
                </Col>
              </Row>
              <Col xs={12}>
                {[
                  <Row key="mine">
                    <Row className="h4 mx-2  mt-2">{t('My workspaces')}</Row>
                    {[
                      isLoading ? (
                        <Row key={'loading'}>
                          <Col xs={12} style={{ minHeight: '50vh' }}>
                            <Loading />
                          </Col>
                        </Row>
                      ) : (
                        <React.Fragment key={'loading'} />
                      ),
                      ...myWorkspaces()
                        .filter((d) =>
                          d.name.toLowerCase().includes(filter.toLowerCase()),
                        )
                        .map((d, index) => (
                          <Col
                            xl={4}
                            md={6}
                            xs={12}
                            key={d.id}
                            projectname={d.name.toLowerCase()}
                            className="private-ws  my-2"
                          >
                            <Link to={linkModel(d)}>
                              <ProjectCard
                                id={`privatews_${d.id}`}
                                className="p-3 mx-1 mt-3 mb-2 project-card"
                                onFavourite={onFavourite}
                                menuTriggerElement={EditOverlay(
                                  d,
                                  editWSmenu,
                                  {
                                    shareMenu: true,
                                  },
                                  t,
                                )}
                                key={index}
                                project={d}
                                onShare={() => {
                                  setJustShare(true)
                                  setNewProject(d)
                                }}
                              />
                            </Link>
                          </Col>
                        )),
                    ]}
                  </Row>,
                  <Row key="shared" hidden={sharedWorkspaces().length === 0}>
                    <Row className="h4 mx-2  my-3">
                      <Col>{t('Shared with me')}</Col>
                    </Row>
                    {sharedWorkspaces()
                      .filter((d) =>
                        d.name.toLowerCase().includes(filter.toLowerCase()),
                      )
                      .map((d, index) => (
                        <Col
                          xl={4}
                          md={6}
                          xs={12}
                          key={d.id}
                          projectname={d.name.toLowerCase()}
                          className="private-ws my-2"
                        >
                          <Link to={linkModel(d)}>
                            <ProjectCard
                              id={`privatews_${d.id}`}
                              className="p-3 mx-1 project-card"
                              onFavourite={onFavourite}
                              menuTriggerElement={EditOverlay(
                                d,
                                (action, d) => {
                                  if (action === 'leave') {
                                    editWSmenu('leave', d)
                                  } else editWSmenu(action, d)
                                },
                                {
                                  rename: true,
                                  delete: true,
                                  share: true,
                                  color: true,
                                  leave: true,
                                },
                                t,
                              )}
                              key={index}
                              type={t('Shared workspace')}
                              project={d}
                            />
                          </Link>
                        </Col>
                      ))}
                  </Row>,
                  <Row key="favs" hidden={favouriteWorkspaces().length === 0}>
                    <Row className="h4 mx-2 my-3">
                      <Col>{t('Favorites')}</Col>
                    </Row>
                    {favouriteWorkspaces()
                      .filter((d) =>
                        d.name.toLowerCase().includes(filter.toLowerCase()),
                      )
                      .map((d, index) => (
                        <Col
                          xl={4}
                          md={6}
                          xs={12}
                          key={d.id}
                          projectname={d.name.toLowerCase()}
                          className="private-ws my-2"
                        >
                          <Link to={linkModel(d)}>
                            <ProjectCard
                              id={`privatews_${d.id}`}
                              className="p-3 mx-1 project-card"
                              onFavourite={onFavourite}
                              menuTriggerElement={EditOverlay(
                                d,
                                (action, d) => {
                                  editWSmenu(action, d)
                                },
                                {
                                  rename: true,
                                  delete: true,
                                  share: true,
                                  color: true,
                                },
                                t,
                              )}
                              key={index}
                              type={t('Favorite')}
                              project={d}
                            />
                          </Link>
                        </Col>
                      ))}
                  </Row>,
                ].map((el, dex, arr) => arr[(dex + order) % 3])}
              </Col>
            </Row>
          </Col>
          <Modal size={'lg'} show={newProject !== false}>
            <Modal.Header>
              <Row>
                <Col className="h2 mb-0 project-modal-title">
                  <strong>
                    {newProject?.id
                      ? justShare
                        ? t('Share workspace')
                        : t('Edit workspace')
                      : t('Add workspace')}
                  </strong>
                </Col>
              </Row>
            </Modal.Header>
            <Modal.Body>
              <NewProject
                project={newProject}
                allUsers={users}
                onCancel={() => {
                  setNewProject(false)
                }}
                justShare={justShare}
                onSave={async (newProject) => {
                  if (newProject.id) {
                    editProjectById(
                      newProject.id,
                      {
                        project_name: newProject.name,
                        invited_emails: newProject.invited_emails.map(
                          (u) => u.name,
                        ),
                        invited_roles: newProject.invited_emails.map(
                          (u) => u.role,
                        ),
                        color: newProject.color,
                      },
                      token,
                      signout,
                    )
                      .then((result) => {
                        if (!result.id) {
                          let msg = ''
                          try {
                            msg = result?.detail?.[0]?.msg
                          } catch (e) {}
                          NotificationManager.error(
                            `${t(msg || 'Failed to edit workspace')} ${
                              newProject.name ?? ''
                            }.`,
                          )
                          return
                        }
                        setNewProject(false)
                        if (result) {
                          workSpaceData.splice(
                            workSpaceData.findIndex((p) => p.id === result.id),
                            1,
                            result,
                          )
                          setWorkSpaceData([...workSpaceData])
                        } else {
                          NotificationManager.error(
                            `${t('Failed to edit workspace')} ${
                              newProject.name ?? ''
                            }.`,
                          )
                        }
                      })
                      .catch(() => {
                        NotificationManager.error(
                          `${t('Failed to edit workspace')} ${
                            newProject.name ?? ''
                          }.`,
                        )
                        setNewProject(false)
                      })
                  } else {
                    const result = await createWorkspace({
                      project: {
                        project_name: newProject.name,
                        invited_emails: newProject.invited_emails.map(
                          (u) => u.name,
                        ),
                        invited_roles: newProject.invited_emails.map(
                          (u) => u.role,
                        ),
                        color: newProject.color,
                      },
                      product_type: mode,
                      token,
                      signout,
                    })
                    if (result) {
                      setWorkSpaceData([result, ...workSpaceData])
                      data?.unshift(result)
                      setNewProject(false)
                      queryClient.invalidateQueries(`workspace-${token}`)
                    } else {
                      NotificationManager.error(
                        `${t('Failed to create workspace')} ${
                          newProject.name ?? ''
                        }`,
                      )
                      setNewProject(false)
                    }
                  }
                }}
              />
            </Modal.Body>
          </Modal>
          <Modal size={'lg'} show={!userHelp.workspace}>
            <IntroScreen
              onFinish={() => {
                setUserHelp({ ...userHelp, workspace: true })
              }}
            />
          </Modal>
        </Row>
      </Container>
    </>
  )
}

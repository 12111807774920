import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { OverlayTrigger, Form, Tooltip, Image } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { FaShareSquare, FaStar } from 'react-icons/fa'
import { GoAlert } from 'react-icons/go'
import { BsFillChatFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'

import HelpTooltip from '../model-content/HelpTooltip'
import ModelNotFound from '../model/model-not-found'
import LoadingModel from '../model/loading-model'
import CustomSankey, { getColorsByTarget } from '../model-content/CustomSankey'
import CustomScatter from '../model-content/CustomScatter'
import { ForecastPredict } from '../predict-form/predict-form'
import { HorizontalWaffle } from '../model-content/CustomWaffle'
import CustomBar from '../model-content/CustomBar'
import CustomPie from '../model-content/CustomPie'
import { editPrivacy, getModelById, getHead } from '../../services/model'
import { isForecastingModel } from '../../util/models'
import AccuracyGauge from '../model-content/AccuracyGauge'

import { round } from '../utils/formating'
import { useAuth } from '../../providers/AuthProvider'
import {
  getAccuracy,
  getAccuracyTraining,
  testAccuracy,
  trainAccuracy,
  hasBaseline,
  getModelCorrelation,
  getPredictionAccuracy,
  getTopFeatureImportance,
} from '../../util/models'

import { downloadOutliersCsv } from '../../services/model'

import './insights.css'
import Avatar from '../../assets/images/avatar.png'
import InsightHeading from '../model-content/InsightHeading'
import { useNav } from '../../providers/NavProvider'
import RoleDisable from '../utils/RoleDisable'

const TARGET_CLASS_LIMITS = 3

const DUMMY_DATA = [
  {
    id: 'Survived 0',
    data: [
      {
        x: 0,
        y: 22,
      },
      {
        x: 0,
        y: 27,
      },
      {
        x: 0,
        y: 66,
      },
    ],
  },
  {
    id: 'Survived 1',
    data: [
      {
        x: 1,
        y: 68,
      },
      {
        x: 1,
        y: 85,
      },
      {
        x: 1,
        y: 100,
      },
    ],
  },
]

export function AccInsight({ model, style = { minHeight: '250px' } }) {
  const { t } = useTranslation()
  return (
    <Row style={style} className="insights-card py-2 px-2">
      <Col md={hasBaseline(model) ? 6 : 12}>
        <span className="pl-2 card-title">{t('Model accuracy:')}</span>
      </Col>

      {hasBaseline(model) ? (
        <Col md={6}>
          <span className="pl-2 card-title">{t('Baseline:')}</span>
        </Col>
      ) : (
        <></>
      )}
      <Col
        align="center"
        md={hasBaseline(model) ? 6 : 12}
        className="my-4 text-secondary big-text"
      >
        <strong>{testAccuracy(model)}%</strong>
      </Col>
      <Col
        md={hasBaseline(model) ? 6 : 12}
        align="center"
        className="mb-4 text-secondary"
        style={
          hasBaseline(model)
            ? {
                marginTop: '2.4rem',
              }
            : {}
        }
      >
        {hasBaseline(model) ? (
          <span title={t('Baseline Explanation')}>
            <strong className="medium-text">
              {round(testAccuracy(model) / parseInt(model.baseline * 100), 1)}x
            </strong>{' '}
            {t('better than baseline')}
          </span>
        ) : (
          <></>
        )}
      </Col>
      <Col className="mt-2" md={12}>
        <span className="pl-2 card-title">
          {t('Accuracy during training the model:')}{' '}
          <span className="text-secondary">{trainAccuracy(model)}%</span>
        </span>
      </Col>
    </Row>
  )
}

export function TextAccuracy({ model }) {
  const { t } = useTranslation()
  const testAcc = testAccuracy(model)
  const colorTest = testAcc > 40 ? '#3ec73e' : '#DA5B0C'
  const acc = Number.parseInt(testAcc)
  const err = 100 - testAcc
  return (
    <Row className="h5">
      <div className="col-auto d-inline-block me-4">
        <i>{t('Accuracy')}</i>: <span style={{ color: colorTest }}>{acc}%</span>
      </div>
      <div className="col-auto d-inline-block">
        <i>{t('Margin error')}</i>: <span>{err}%</span>
      </div>
    </Row>
  )
}

export function AccInsightV2({
  model,
  style = { minHeight: '250px' },
  isInView = true,
  ...props
}) {
  const { t } = useTranslation()
  const testAcc = testAccuracy(model)
  const [shownAcc, setShownAcc] = useState(0)
  const colorTest = testAcc > 40 ? '#3ec73e' : '#DA5B0C'

  useEffect(() => {
    if (isInView && shownAcc < testAcc) {
      setShownAcc(testAcc)
    }
  }, [shownAcc, testAcc, isInView])

  const syntheticSimilarity = model?.dataset?.synthetic_similarity
  const title =
    model?.problem_type === 'time_series_regression' ? (
      <Row>
        <div className="col-auto">{t('Model accuracy')}</div>
        <div className="col-auto ms-2">
          <span className="smallp">{t('With 95% confidence')}</span>
        </div>
      </Row>
    ) : (
      t('Model accuracy')
    )

  return (
    <Row
      style={style}
      className={`insights-card accuracy-gauge flex-column flex-nowrap px-2 ${
        props?.className ?? ''
      }`}
    >
      <Col xs={12}>
        <Row>
          <InsightHeading title={title} className="my-3" />
          <Col xs={12} className="mb-3">
            <AccuracyGauge
              testAcc={shownAcc}
              height={190 - (hasBaseline(model) ? 20 : 0)}
              marginLeft={20}
              colorTest={colorTest}
              labelsTextColor={'white'}
              theme={{
                fontSize: 20,
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col align="center" className="mt-2 display-inline-block" xs={12}>
        {hasBaseline(model) ? (
          <>
            <span className="text-secondary">
              {round(testAcc / parseInt(model.baseline * 100), 1)}x
            </span>{' '}
            {t('better than baseline')}
          </>
        ) : (
          <></>
        )}
        {hasBaseline(model) && syntheticSimilarity ? <br /> : <></>}
        {syntheticSimilarity ? (
          <span title={t('Synthetic Similarity Explanation')}>
            <strong className="medium-text">
              {round(syntheticSimilarity, 1)}%
            </strong>{' '}
            {t('synthetic similarity to original data')}
          </span>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  )
}

function andJoin(data) {
  if (data.length === 0) return ''
  if (data.length === 1) return data[0]
  return data.reduce((prev, curr, index) => [
    prev,
    <span key={`separator-${index}`}>
      {index === data.length - 1 ? ' and ' : ', '}
    </span>,
    curr,
  ])
}

function CustomOverlay({
  overlay = null,
  children,
  onClick = () => console.log('Custom overlay clicked'),
}) {
  const { t } = useTranslation()
  overlay = overlay ?? t('Click to ignore column')
  return (
    <RoleDisable opacity={100}>
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement="bottom"
        delay={{ show: 100, hide: 100 }}
        overlay={(props) => <Tooltip {...props}>{overlay}</Tooltip>}
      >
        <span onClick={onClick}>{children}</span>
      </OverlayTrigger>
    </RoleDisable>
  )
}

export function HowToImprovePerformance({
  model,
  trainingConfiguration,
  style = { minHeight: '250px' },
  viewData = () => {},
  viewBot = () => {},
  switchSynthetic,
  switchRemoveOutliers,
  switchBalanceData = () => {},
  syntheticEnabled,
  balanceDataEnabled,
  removeOutliersEnabled,
  Wrapper,
  wrapperProps = {},
  ...props
}) {
  const { t } = useTranslation()
  let { signout, token } = useAuth()

  const columnsAlreadyIgnored = trainingConfiguration?.columnsToIgnore
    ? trainingConfiguration?.columnsToIgnore.map((v) => v.value)
    : []
  const ignoreColumn = trainingConfiguration?.setColumnsToIgnore
    ? (c) =>
        trainingConfiguration.setColumnsToIgnore((cols) => [
          ...cols,
          { label: c, value: c },
        ])
    : () => {}

  const {
    ignore_id_columns,
    ignore_high_correlated_with_target,
    ignore_too_many_nulls,
    balance_target,
  } =
    trainingConfiguration?.parameters &&
    typeof trainingConfiguration?.parameters === 'object'
      ? trainingConfiguration.parameters
      : {}

  const validFeatures = (
    model && model.status === 'trained' && model.details.feature_importance
      ? model.details.feature_importance
      : []
  ).filter((c) => model.columns_active[c.feature] && c.feature !== model.target)

  const sumFeatureImportance =
    validFeatures.reduce((acc, c) => acc + c.importance_std, 0) || 100
  const featureImportance = validFeatures
    .map((item) => ({
      column: item.feature,
      Importance: (100 * item.importance_std) / sumFeatureImportance,
    }))
    .filter((a) => a.Importance < 5)
    .sort((a, b) => a.Importance - b.Importance)

  const validColumns = model?.dataset?.columns_that_should_be_ignored ?? {}

  const columnsThatShouldBeIgnored = Object.keys(validColumns).reduce(
    (dict, col) => {
      if (
        model.columns_active[col] &&
        model.target !== col &&
        !columnsAlreadyIgnored.includes(col)
      )
        dict[col] = validColumns[col]
      return dict
    },
    {},
  )

  const notRelevantColumns = featureImportance
    .filter((item) => !columnsAlreadyIgnored.includes(item.column))
    .map((item) => {
      let column = item.column
      if (column.startsWith('ln_') || column.startsWith('sq_')) {
        column = column.substring(3)
      }
      return (
        <CustomOverlay onClick={() => ignoreColumn(column)} key={column}>
          <span className="link">{column}</span>{' '}
          <span>({round(item.Importance, 1)}%)</span>
        </CustomOverlay>
      )
    })
  const allNonRelevantColumns = featureImportance
    .filter((item) => !columnsAlreadyIgnored.includes(item.column))
    .map((item) => {
      let column = item.column
      if (column.startsWith('ln_') || column.startsWith('sq_')) {
        column = column.substring(3)
      }
      return column
    })

  const nonRelevantColumnsStr =
    allNonRelevantColumns.slice(0, -1).join(', ') +
    ` ${t('and')} ${allNonRelevantColumns[allNonRelevantColumns.length - 1]}`
  const ignoreAllNonRelevantColumns = (
    <CustomOverlay
      overlay={t(
        'Click to ignore the following columns ({{numColumns}}): {{columns}}',
        {
          numColumns: allNonRelevantColumns.length,
          columns:
            nonRelevantColumnsStr.length > 400
              ? nonRelevantColumnsStr.substring(0, 400) + '...'
              : nonRelevantColumnsStr,
        },
      )}
      onClick={() => allNonRelevantColumns.map(ignoreColumn)}
    >
      <span className="link">{t('here')}</span>
    </CustomOverlay>
  )

  const idColumns = Object.keys(columnsThatShouldBeIgnored)
    .filter((column) => columnsThatShouldBeIgnored[column] === 'id')
    .map((column) => (
      <CustomOverlay onClick={() => ignoreColumn(column)} key={column}>
        <span className="link">{column}</span>
      </CustomOverlay>
    ))

  const allHighNansColumns = Object.keys(columnsThatShouldBeIgnored).filter(
    (column) => columnsThatShouldBeIgnored[column] === 'too_many_nans',
  )

  const highNans = allHighNansColumns.map((column) => (
    <CustomOverlay onClick={() => ignoreColumn(column)} key={column}>
      <span className="link">{column}</span>{' '}
      <span>
        (
        {round(
          (100 * (model.dataset.statistics?.[column]?.nan_count ?? 0)) /
            model.dataset.rows,
          1,
        )}
        %)
      </span>
    </CustomOverlay>
  ))

  const highNansColumnsStr =
    allHighNansColumns.slice(0, -1).join(', ') +
    ` ${t('and')} ${allHighNansColumns[allHighNansColumns.length - 1]}`

  const ignoreAllHighNansColumns = (
    <CustomOverlay
      overlay={t(
        'Click to ignore the following columns ({{numColumns}}): {{columns}}',
        {
          numColumns: allHighNansColumns.length,
          columns:
            highNansColumnsStr.length > 400
              ? highNansColumnsStr.substring(0, 400) + '...'
              : highNansColumnsStr,
        },
      )}
      onClick={() => allHighNansColumns.map(ignoreColumn)}
    >
      <span className="link">{t('here')}</span>
    </CustomOverlay>
  )

  const allMulticollinearityColumns = Object.keys(
    columnsThatShouldBeIgnored,
  ).filter(
    (column) => columnsThatShouldBeIgnored[column] === 'multicollinearity',
  )

  const multicollinearity = allMulticollinearityColumns.map((column) => (
    <CustomOverlay onClick={() => ignoreColumn(column)} key={column}>
      <span className="link">{column}</span>
    </CustomOverlay>
  ))

  const multicollinearityColumnsStr =
    allMulticollinearityColumns.slice(0, -1).join(', ') +
    ` ${t('and')} ${
      allMulticollinearityColumns[allMulticollinearityColumns.length - 1]
    }`

  const ignoreAllMulticollinearityColumns = (
    <CustomOverlay
      overlay={t(
        'Click to ignore the following columns ({{numColumns}}): {{columns}}',
        {
          numColumns: allMulticollinearityColumns.length,
          columns:
            multicollinearityColumnsStr.length > 400
              ? multicollinearityColumnsStr.substring(0, 400) + '...'
              : multicollinearityColumnsStr,
        },
      )}
      onClick={() => allMulticollinearityColumns.map(ignoreColumn)}
    >
      <span className="link">{t('here')}</span>
    </CustomOverlay>
  )

  const lowTargetCorrelation = Object.keys(columnsThatShouldBeIgnored)
    .filter((column) =>
      columnsThatShouldBeIgnored[column].startsWith('low_correlated_'),
    )
    .map((column) => (
      <CustomOverlay onClick={() => ignoreColumn(column)} key={column}>
        <span className="link">{column}</span>{' '}
        <span>({columnsThatShouldBeIgnored[column].split('_')[2]}%)</span>
      </CustomOverlay>
    ))

  const highTargetCorrelation = Object.keys(columnsThatShouldBeIgnored)
    .filter((column) =>
      columnsThatShouldBeIgnored[column].startsWith('high_correlated_'),
    )
    .map((column) => (
      <CustomOverlay onClick={() => ignoreColumn(column)} key={column}>
        <span className="link">{column}</span>{' '}
        <span>({columnsThatShouldBeIgnored[column].split('_')[2]}%)</span>
      </CustomOverlay>
    ))

  if (
    model?.status !== 'trained' &&
    idColumns.length === 0 &&
    highNans.length === 0
  )
    return null

  function checkModel(model) {
    const LIMIT_RATIO = 0.5
    const validProblemTypes = [
      'binary',
      'multiclass',
      'time_series_binary',
      'time_series_multiclass',
    ]
    if (!validProblemTypes.includes(model.problem_type)) {
      return false
    }
    const values = Object.values(model.details.target_distribution)
    const ratio = Math.min(...values) / Math.max(...values)
    return ratio < LIMIT_RATIO
  }

  const activeTooltip = {
    ignoreColumn:
      notRelevantColumns &&
      notRelevantColumns.length > 0 &&
      !isForecastingModel(model),
    ignoreId: !ignore_id_columns && idColumns && idColumns.length > 0,
    ignoreEmpty: !ignore_too_many_nulls && highNans && highNans.length > 0,
    ignoreCorrelated: multicollinearity && multicollinearity.length > 0,
    ignoreLowTargetCorrelation:
      lowTargetCorrelation && lowTargetCorrelation.length > 0,
    ignoreHighTargetCorrelation:
      !ignore_high_correlated_with_target &&
      highTargetCorrelation &&
      highTargetCorrelation.length > 0,
    largeDataset:
      model?.status === 'trained' &&
      (!notRelevantColumns || notRelevantColumns.length === 0) &&
      (!idColumns || idColumns.length === 0) &&
      (!highNans || highNans.length === 0) &&
      (!multicollinearity || multicollinearity.length === 0) &&
      model.dataset.columns_order.length * 20 > model.dataset.rows,
    lowAccuracy: testAccuracy(model) < 50,
    useSynthetic:
      !trainingConfiguration?.useSynthetic &&
      (model?.dataset?.rows ?? 0) < 5000 &&
      !syntheticEnabled,
    balanceData:
      model?.status === 'trained' &&
      !balance_target &&
      checkModel(model) &&
      !balanceDataEnabled,
    removeOutliers: removeOutliersEnabled,
    suggestBot: model?.status === 'trained' && testAccuracy(model) < 20,
  }

  const MAX_COLUMNS_IN_SUGGESTION = 15
  const anySuggestions = Object.values(activeTooltip).some((a) => a)
  const suggestions = anySuggestions ? (
    <Row
      style={style}
      {...props}
      className={`insights-card insights-card-alt py-2 pe-1 ${
        props?.className ?? ''
      }`}
    >
      <Col md={12}>
        <Image src={Avatar} width={64} />
        <strong className="mx-2" style={{ fontSize: 16 }}>
          {t("These are my suggestions for enhancing your model's accuracy.")}
        </strong>
      </Col>

      <Col className="mt-2 display-inline-block" md={12}>
        <ul>
          {activeTooltip.ignoreColumn && (
            <li>
              {notRelevantColumns.length > MAX_COLUMNS_IN_SUGGESTION ? (
                <>
                  {t('Suggest ignore columns')}: {ignoreAllNonRelevantColumns}
                </>
              ) : (
                <>
                  {t('Suggest ignore columns')}:{' '}
                  <i>{andJoin(notRelevantColumns)}</i>
                </>
              )}
            </li>
          )}
          {activeTooltip.ignoreId && (
            <li>
              <GoAlert className="mx-1 icon-up" style={{ color: '#f27d0f' }} />
              {t('Suggest ignore id columns')} <i>{andJoin(idColumns)}</i>
            </li>
          )}
          {activeTooltip.ignoreEmpty && (
            <li>
              {allHighNansColumns.length > MAX_COLUMNS_IN_SUGGESTION ? (
                <>
                  {t('Suggest ignore columns with empty values')}{' '}
                  {ignoreAllHighNansColumns}
                </>
              ) : (
                <>
                  {t('Suggest ignore columns with empty values')}{' '}
                  <i>{andJoin(highNans)}</i>
                </>
              )}
            </li>
          )}
          {activeTooltip.ignoreCorrelated && (
            <li>
              <HelpTooltip
                className="help-select-icon mx-2"
                message={
                  'Multicolinearity occurs when, in a model trying to predict something using several columns of data, one column can be almost guessed from the others. This is a problem because it makes it hard for the model to understand which column is really important, leading to less reliable predictions.'
                }
              />
              {t('Suggest ignore correlated columns')}{' '}
              {allMulticollinearityColumns.length >
              MAX_COLUMNS_IN_SUGGESTION ? (
                <i>{ignoreAllMulticollinearityColumns}</i>
              ) : (
                <i>{andJoin(multicollinearity)}</i>
              )}
            </li>
          )}
          {activeTooltip.ignoreLowTargetCorrelation && (
            <li>
              {t(
                'Try to ignore the following columns because they are not related at all with the target',
              )}{' '}
              <i>{andJoin(lowTargetCorrelation)}</i>
            </li>
          )}
          {activeTooltip.ignoreHighTargetCorrelation && (
            <li>
              {t(
                "Try to ignore the following columns because they have a really high correlation with the target. This may lead to overfitting and reduce the model's ability to generalize to new data",
              )}{' '}
              <i>{andJoin(highTargetCorrelation)}</i>
            </li>
          )}
          {activeTooltip.largeDataset && (
            <li>{t('Generating large sample dataset')}</li>
          )}
          {activeTooltip.removeOutliers ? (
            <li>
              {t(
                'We have found some outliers in the data. You can download and check them',
              )}{' '}
              <span
                className="link"
                onClick={() => {
                  downloadOutliersCsv({
                    modelId: model.id,
                    token,
                    signout,
                  }).then((outliers) => {
                    const download = (filename, text) => {
                      var pom = document.createElement('a')
                      pom.setAttribute(
                        'href',
                        'data:text/csv;charset=utf-8,' +
                          encodeURIComponent(text),
                      )
                      pom.setAttribute('download', filename)

                      if (document.createEvent) {
                        var event = document.createEvent('MouseEvents')
                        event.initEvent('click', true, true)
                        pom.dispatchEvent(event)
                      } else {
                        pom.click()
                      }
                    }
                    download(`Outliers - ${model.dataset.name}.csv`, outliers)
                  })
                }}
              >
                <i>{t('here')}</i>
              </span>
            </li>
          ) : (
            <li>
              <span className="link" onClick={() => switchRemoveOutliers()}>
                <i>{t('Remove outliers')}</i>
              </span>{' '}
              {t('to improve performance')}
            </li>
          )}
          {50 <
          parseFloat((model?.acc?.Precission ?? '0').replace('%', '')) <
          87 ? (
            <li>
              {t(
                'Please consider creating new features based on existing ones (in',
              )}{' '}
              <span
                className="link"
                onClick={() => {
                  document
                    .getElementsByClassName('step-name-Explore')[0]
                    .click()
                }}
              >
                {t('Explore')}
              </span>{' '}
              {t('section and Import wizard)')}
            </li>
          ) : (
            <></>
          )}
          {activeTooltip.useSynthetic && (
            <li>
              <FaStar className="text-warning mx-1 icon-up" />
              {t('Synthetic data tooltip')}{' '}
              <RoleDisable opacity={100}>
                <span className="link" onClick={() => switchSynthetic()}>
                  <i>{t('enabled')}</i>
                </span>
              </RoleDisable>
              .
            </li>
          )}
          {activeTooltip.balanceData && (
            <li>
              <FaStar className="text-warning mx-1 icon-up" />
              {t('Balance data tooltip')}{' '}
              <span className="link" onClick={() => switchBalanceData()}>
                <i>{t('enabled')}</i>
              </span>
              .
            </li>
          )}
          {activeTooltip.lowAccuracy && (
            <li>
              <GoAlert className="mx-1 icon-up" style={{ color: '#f27d0f' }} />
              {t('The model accuracy is low,')}
              <span className="link" onClick={viewData}>
                <i> {t('choosing adequate data types')}</i>
              </span>{' '}
              {t('for the columns may help improving the model')}
            </li>
          )}
          {activeTooltip.suggestBot && (
            <li>
              <BsFillChatFill className="mx-1 icon-up" />
              {t(
                'The model accuracy is low, consider improving, trimming or changing the types of your dataset with the ',
              )}
              <span className="link" onClick={viewBot}>
                <i> {t('Assistant Bot')}</i>
              </span>
            </li>
          )}
        </ul>
      </Col>
    </Row>
  ) : (
    <></>
  )
  if (Wrapper) return <Wrapper {...wrapperProps}>{suggestions}</Wrapper>
  else return suggestions
}

function MostImportantColumnsScatter({
  data,
  leftLegend = 'Age',
  bottomLegend = 'Survived',
  colors_by_target,
}) {
  return (
    <CustomScatter
      data={data ? data : DUMMY_DATA}
      addLineLayer={false}
      yFormat={null}
      xFormat={null}
      nodeSize={9}
      colorById={colors_by_target}
      axisBottomProps={{
        tickValues: 1,
        tickSize: 0,
      }}
      axisLeftProps={{
        tickPadding: 10,
        tickSize: 0,
      }}
      leftLegend={leftLegend}
      bottomLegend={bottomLegend}
      height={185}
      theme={{
        fontSize: '9px',
        textColor: '#6c757d',
      }}
    />
  )
}

function AccuracyWaffle({
  value,
  label = 'Accuracy',
  color = '#468df3',
  ...props
}) {
  return (
    <HorizontalWaffle
      height={58}
      data={[
        {
          id: label,
          label: label,
          value: value,
          color: color,
        },
      ]}
      {...props}
    />
  )
}

function PredictAccCard({ model, colorsByTarget }) {
  const { t } = useTranslation()
  const testAcc = getPredictionAccuracy(model)
  const trainAcc = getPredictionAccuracy(model, false)

  const BinaryContent = () => (
    <>
      <Row className="mt-1" align="center">
        <Col align="center" md={4}>
          <span className="size11 text-secondary">{testAcc[0].title}</span>
        </Col>
        <Col align="center" md={4}>
          <span>{t('Test Accuracy')}</span>
        </Col>
        <Col align="center" md={4}>
          <span className="size11 text-secondary">{testAcc[1].title}</span>
        </Col>
        <Col md={4}>
          <AccuracyWaffle
            value={testAcc[0].value}
            color={colorsByTarget[testAcc[0].title]}
            label={testAcc[0].label}
          />
        </Col>
        <Col align="center" md={4}>
          <Row align="center">
            <Col md={4}>
              <span style={{ color: colorsByTarget[testAcc[0].title] }}>
                {testAcc[0].correct}/{testAcc[0].total}
              </span>
            </Col>
            <Col align="center" md={4}>
              <strong className="card-title medium-text">
                {getAccuracy(model)}%
              </strong>
            </Col>
            <Col align="right" md={4}>
              <span style={{ color: colorsByTarget[testAcc[1].title] }}>
                {testAcc[1].correct}/{testAcc[1].total}
              </span>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <AccuracyWaffle
            value={testAcc[1].value}
            color={colorsByTarget[testAcc[1].title]}
            label={testAcc[1].label}
          />
        </Col>
        <Col md={12}>
          <hr />
        </Col>
      </Row>
      <Row align="center">
        <Col align="center" md={12}>
          <span>{t('Train Accuracy')}</span>
          <br />
        </Col>
        <Col md={4}>
          <AccuracyWaffle
            value={trainAcc[0].value}
            color={colorsByTarget[trainAcc[0].title]}
            label={trainAcc[0].label}
          />
        </Col>
        <Col align="center" md={4}>
          <Row align="center">
            <Col md={4}>
              <span style={{ color: colorsByTarget[trainAcc[0].title] }}>
                {trainAcc[0].correct}/{trainAcc[0].total}
              </span>
            </Col>
            <Col md={4}>
              <span className="card-title">{getAccuracyTraining(model)}%</span>
            </Col>
            <Col align="right" md={4}>
              <span style={{ color: colorsByTarget[trainAcc[1].title] }}>
                {trainAcc[1].correct}/{trainAcc[1].total}
              </span>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <AccuracyWaffle
            value={trainAcc[1].value}
            color={colorsByTarget[trainAcc[1].title]}
            label={trainAcc[1].label}
          />
        </Col>
      </Row>
    </>
  )

  const MulticlassContent = () => (
    <>
      <Row className="mt-2" align="center">
        <Col align="center" md={3}>
          <span>{t('Test Accuracy')}</span>
          <br />
        </Col>
        {testAcc.map((pred) => (
          <Col key={pred.key} align="center" md={3}>
            <span>{pred.title}</span>
            <br />
            <span style={{ color: colorsByTarget[pred.title] }}>
              {pred.correct}/{pred.total}
            </span>
          </Col>
        ))}
      </Row>
      <Row align="center">
        <Col align="center" md={3}>
          <strong className="medium-text">{getAccuracy(model)}%</strong>
        </Col>
        {testAcc.map((pred) => (
          <Col key={pred.key} align="center" md={3}>
            <Row>
              <Col md={12}>
                <AccuracyWaffle
                  value={pred.value}
                  color={colorsByTarget[pred.title]}
                  label={pred.label}
                />
              </Col>
            </Row>
          </Col>
        ))}
      </Row>

      <Row className="mt-2" align="center">
        <Col align="center" md={3}>
          <span>{t('Train Accuracy')}</span>
          <br />
        </Col>
        {trainAcc.map((pred) => (
          <Col key={pred.key} align="center" md={3}>
            <span style={{ color: colorsByTarget[pred.title] }}>
              {pred.correct}/{pred.total}
            </span>
          </Col>
        ))}
      </Row>
      <Row align="center">
        <Col align="center" md={3}>
          <span>{getAccuracyTraining(model)}%</span>
        </Col>
        {trainAcc.map((pred) => (
          <Col key={pred.key} align="center" md={3}>
            <Row>
              <Col md={12}>
                <AccuracyWaffle
                  value={pred.value}
                  color={colorsByTarget[pred.title]}
                  label={pred.label}
                />
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </>
  )

  return (
    <div className="insights-card py-2 px-2">
      <span className="px-2 card-title">{t('Prediction accuracy:')}</span>
      {testAcc.length === 2 ? <BinaryContent /> : <MulticlassContent />}
    </div>
  )
}

function ImportanceScatters({ model, sample, limit = 6, colors_by_target }) {
  if (!sample) return <></>

  const topFeaturesImportance = getTopFeatureImportance(model, limit)
  const addedFeatures = new Set(
    topFeaturesImportance.map((item) => item.column),
  )

  if (topFeaturesImportance.length < limit) {
    for (const feature of Object.keys(model.columns_active)) {
      if (addedFeatures.has(feature) || feature === model.target) continue

      // Skip categorical features with too many possible values
      if (
        feature in model.dataset.categorical_to_unique &&
        model.dataset.categorical_to_unique[feature].length > 20
      )
        continue

      addedFeatures.add(feature)
      topFeaturesImportance.push({
        column: feature,
        importance: 0,
      })

      if (topFeaturesImportance.length >= limit) break
    }
  }

  const listOfScatters = []
  for (let [i, { column }] of topFeaturesImportance.entries()) {
    if (column.startsWith('ln_') || column.startsWith('sq_')) {
      column = column.substring(3)
    }
    const columnsCorrelation = sample.head[column]
      .map((v, j) => [v, sample.head[model.target][j]])
      .filter((v) => v[0] !== null && v[1] !== null)

    const data = []
    for (let uniqueValue of model.dataset.categorical_to_unique[model.target] ??
      []) {
      const dataForValue = columnsCorrelation.filter(
        (v) => v[1].toString() === uniqueValue.toString(),
      )
      if (dataForValue.length === 0) continue
      data.push({
        id: `${model.target} ${uniqueValue}`,
        data: dataForValue.map((v) => ({
          x: uniqueValue,
          y: v[0],
        })),
      })
    }

    const scatterProps = {
      data,
      key: column,
      leftLegend: column,
      bottomLegend: model.target,
      colors_by_target,
    }

    listOfScatters.push({
      scatterProps,
      importance: round(topFeaturesImportance[i].importance, 1),
    })
  }

  return listOfScatters.map(({ scatterProps, importance }) => (
    <Col key={scatterProps.key} align="center" md={2}>
      <span className="px-2">
        <span className="text-secondary size11">{scatterProps.leftLegend}</span>
        <br />
        <span className="medium-text" style={{ marginLeft: '20px' }}>
          {importance}%
        </span>
      </span>
      <MostImportantColumnsScatter {...scatterProps} />
    </Col>
  ))
}

export function Insights({ setTitle, modelId, showHead = true }) {
  const { t } = useTranslation()
  let { signout, token } = useAuth()
  const [model, setModel] = useState(null)
  const [sample, setSample] = useState(null)
  const [isPrivateReport, setIsPrivateReport] = useState(true)
  const param = useParams()
  const navigate = useNavigate()
  const { setShowNav } = useNav()
  useEffect(() => {
    setShowNav(false)
    return () => setShowNav(true)
    // eslint-disable-next-line
  }, [])
  const COLORS_BY_TARGET =
    model && model.details
      ? getColorsByTarget(model.details.sankey_feature_importance, model.target)
          .targetToColor
      : null

  const { isLoading, data } = useQuery(
    `model-${modelId ?? param.id}`,
    () => getModelById(modelId ?? param.id, token, signout),
    { staleTime: 60 * 1000 },
  )

  if (!setTitle) setTitle = () => {}

  useEffect(() => {
    setTitle(`${t('Insights')} | ${t('NextBrain')}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLoading) return
    setModel(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (!model || !model.dataset) return
    setTitle(`${model.dataset.name} ${t('Insights')} | ${t('NextBrain')}`)

    setIsPrivateReport(model.is_private_report)
    getHead({
      modelId: model.id,
      pageSize: 2000,
      pageNumber: 1,
      token,
      signout,
    }).then((response) => {
      setSample(response)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model])

  if (isLoading) return <LoadingModel />

  if (!model) return <ModelNotFound />

  let targetDistribution =
    model && model.details && model.details.target_distribution
      ? Object.keys(model.details.target_distribution)
          .map((k) => ({
            id: `${model.target} ${k}`,
            label: `${model.target} ${k}`,
            value: model.details.target_distribution[k],
            color: COLORS_BY_TARGET[`${model.target} ${k}`],
          }))
          .sort((a, b) => b.value - a.value)
      : null

  if (targetDistribution && targetDistribution.length > TARGET_CLASS_LIMITS) {
    const topTargetDistribution = targetDistribution.slice(
      0,
      TARGET_CLASS_LIMITS - 1,
    )
    topTargetDistribution.unshift(
      targetDistribution.slice(TARGET_CLASS_LIMITS - 1).reduce(
        (accumulator, a) => ({
          id: `${model.target} Other`,
          label: `${model.target} Other`,
          value: accumulator.value + a.value,
          color: COLORS_BY_TARGET[`${model.target} Other`],
        }),
        { value: 0 },
      ),
    )
    targetDistribution = topTargetDistribution
  }

  const isForecasting = (model) =>
    model &&
    model.columns_active &&
    Object.keys(model.columns_active).length === 2 &&
    model.problem_type === 'time_series_regression'

  const isSomeKindOfRegresion = (model) =>
    model &&
    (model.problem_type === 'regression' ||
      model.problem_type === 'time_series_regression')

  const isCorrelation = (model) =>
    model &&
    model.columns_active &&
    Object.keys(model.columns_active).length === 2 &&
    model.problem_type !== 'time_series_regression'

  const { modelCorrelation, std } = isCorrelation(model)
    ? getModelCorrelation(model)
    : { modelCorrelation: [], std: 0 }

  return (
    <Container style={{ marginTop: 25 }}>
      <Row className="mb-2">
        <Col md={12}>
          <button
            target="_blank"
            className={`linkshare link ${
              model.status !== 'trained' ? 'disabled' : ''
            }`}
            disabled={model.status !== 'trained'}
            rel="noopener noreferrer"
            onClick={() => navigate(`/model/widget/${model.id}`)}
          >
            {t('Access Dashboard')}
            <FaShareSquare />
          </button>
        </Col>
      </Row>
      {showHead ? (
        <Row className="mt-2">
          <Col md={12}>
            <h5>
              {model && model.status === 'trained'
                ? model.dataset.name + ' | '
                : ''}{' '}
              {t('Model insights')}
            </h5>
          </Col>
          {model?.user_has_access_to_model ? (
            <Col align="right" md={12} style={{ marginTop: -30 }}>
              <Form className="form-inline">
                <Form.Group className="mb-3">
                  <Form.Check
                    type="switch"
                    label={t('Share report')}
                    className="form-switch-share"
                    defaultChecked={!isPrivateReport}
                    onChange={async (e) => {
                      const newIsPrivateReport = !e.target.checked
                      setIsPrivateReport(newIsPrivateReport)
                      // In order to predict a model the report should be also publicly accessible
                      // We can change it in the future, it is just because it call the same /model/get_model/:model-id
                      const options = { private_report: newIsPrivateReport }
                      if (newIsPrivateReport) {
                        options.private_predict = true
                      } else {
                        if (navigator?.clipboard?.writeText) {
                          navigator.clipboard.writeText(window.location.href)
                        } else
                          NotificationManager.error('Clipboard not supported')
                      }

                      NotificationManager.info(
                        newIsPrivateReport
                          ? t('Report is now private')
                          : t('Report is now public'),
                      )
                      await editPrivacy(model.id, options, token, signout)
                    }}
                  />
                </Form.Group>
              </Form>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      ) : (
        <Row>
          <Col md={12}>
            <button
              href={`/insights/${model.id}`}
              target="_blank"
              className={`linkshare link ${
                model.status !== 'trained' ? 'disabled' : ''
              }`}
              disabled={model.status !== 'trained'}
              rel="noopener noreferrer"
              onClick={() => window.open(`/insights/${model.id}`, '_blank')}
            >
              {t('Share report')}
              <FaShareSquare />
            </button>
          </Col>
        </Row>
      )}
      <Row className="mt-3">
        {!isSomeKindOfRegresion(model) ? (
          <Col md={4}>
            <div className="insights-card py-2 px-2">
              <span className="px-2 card-title">{t('Data distribution:')}</span>
              <Row align="center">
                <Col md={6}>
                  {model && model.details ? (
                    <CustomPie data={targetDistribution} />
                  ) : (
                    <></>
                  )}
                </Col>
                <Col md={6}>
                  <span>{t('Number of columns')}</span>
                  <br />
                  <strong className="medium-text">
                    {model && model.columns_active
                      ? Object.keys(model.columns_active).length
                      : 0}
                  </strong>
                  <br />
                  <br />
                  <span>{t('Number of rows')}</span>
                  <br />
                  <strong className="medium-text">
                    {model && model.dataset ? model.dataset.rows : 0}
                  </strong>
                </Col>
              </Row>
            </div>
          </Col>
        ) : !isForecasting(model) ? (
          <Col md={2}>
            <div className="px-3">
              <AccInsight model={model} />
            </div>
          </Col>
        ) : (
          <></>
        )}
        {
          // If it is not correlation nor forecasting
          model &&
          model.columns_active &&
          Object.keys(model.columns_active).length > 2 ? (
            <>
              {model.problem_type !== 'regression' ? (
                <Col md={5}>
                  <PredictAccCard
                    model={model}
                    colorsByTarget={COLORS_BY_TARGET}
                  />
                </Col>
              ) : (
                <Col md={10}>
                  <CustomScatter
                    data={{
                      id: model.target,
                      data: model.true_vs_predict,
                    }}
                    header={
                      <span className="px-2 card-title">
                        {t('Predicted vs Actual values:')}
                      </span>
                    }
                    className="insights-card py-2 px-2"
                    height={204}
                  />
                </Col>
              )}
            </>
          ) : (
            <></>
          )
        }
        {
          // If it is correlation
          model &&
          model.columns_active &&
          Object.keys(model.columns_active).length === 2 &&
          model.problem_type !== 'time_series_regression' ? (
            <>
              <Col md={5}>
                <CustomScatter
                  data={{
                    id: model.target,
                    data: model.true_vs_predict,
                  }}
                  header={
                    <span className="px-2 card-title">
                      {t('Predicted vs Actual values:')}
                    </span>
                  }
                  className="insights-card py-2"
                  height={204}
                />
              </Col>
              <Col md={5}>
                <CustomScatter
                  data={modelCorrelation}
                  header={
                    <span className="px-2 card-title">{t('Residuals:')}</span>
                  }
                  leftLegend={t('Prediction minus True value')}
                  bottomLegend={`${t('Predicted')} ${model.target}`}
                  addLineLayer={false}
                  constantExtraLines={[std, -std]}
                  showLegend={true}
                  className="insights-card py-2"
                  height={204}
                />
              </Col>
            </>
          ) : (
            <></>
          )
        }
        {!isSomeKindOfRegresion(model) ? (
          <Col md={3}>
            <div className="insights-card py-2 px-2">
              <span className="px-2 card-title">{t('Performance:')}</span>
              <Row>
                <Col className="mt-2" md={12}>
                  {model &&
                  model.status === 'trained' &&
                  model.baseline !== null ? (
                    getAccuracy(model) < parseInt(model.baseline * 100) ? (
                      <div className="mb-2">
                        <span className="px-2">
                          {t('There is not that much predictive power')}
                        </span>
                      </div>
                    ) : (
                      <span className="px-2">
                        <strong className="medium-text">
                          {round(
                            model && model.status === 'trained'
                              ? getAccuracy(model) /
                                  parseInt(model.baseline * 100)
                              : 0,
                            1,
                          )}
                          x
                        </strong>{' '}
                        {t('better than a simpler model')}
                      </span>
                    )
                  ) : (
                    <></>
                  )}
                </Col>
                <Col md={12}>
                  <CustomBar
                    data={[
                      {
                        column: `${t('NextBrain')} ${'model'}`,
                        Accuracy:
                          model && model.status === 'trained'
                            ? round(getAccuracy(model), 1)
                            : 0,
                        color: 'rgba(6, 1, 249, 0.9)',
                      },
                      {
                        column: t('Simpler model'),
                        Accuracy:
                          model && model.status === 'trained'
                            ? round(model.baseline * 100, 1)
                            : 0,
                        color: 'rgba(108, 117, 125, 0.5)',
                      },
                    ]}
                    layout="vertical"
                    barKey={t('Accuracy')}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legendPosition: 'middle',
                      legendOffset: 32,
                    }}
                    axisLeft={null}
                    height={166}
                    mt={10}
                    mr={50}
                    mb={30}
                    ml={50}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      {
        // If it is nor correlation nor forecasting (2nd row)
        model &&
        model.columns_active &&
        Object.keys(model.columns_active).length > 2 ? (
          <>
            {model.problem_type !== 'regression' && sample ? (
              <Row className="mt-3">
                <Col md={12}>
                  <div className="insights-card py-2 px-2">
                    <span className="px-2 card-title">
                      {t('Columns importance:')}
                    </span>
                    <Row className="mt-1">
                      <ImportanceScatters
                        model={model}
                        sample={sample}
                        colors_by_target={COLORS_BY_TARGET}
                      />
                    </Row>
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row className="mt-3">
              <Col md={12}>
                <Row justify="center">
                  <Col md={12}>
                    <CustomSankey
                      height={400}
                      header={
                        <span className="px-2 card-title">
                          {t(
                            'How most importants features affect to predict your target?',
                          )}
                        </span>
                      }
                      className="insights-card py-2 px-2"
                      data={
                        model && model.details
                          ? model.details.sankey_feature_importance
                          : null
                      }
                      target={model ? model.target : null}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )
      }
      {
        // If it is forecasting (2nd row)
        model &&
        model.columns_active &&
        Object.keys(model.columns_active).length === 2 &&
        model.problem_type === 'time_series_regression' ? (
          <Row className="mt-3">
            <Col md={12}>
              <div className="insights-card py-2 px-2">
                <div className="mx-3">
                  <ForecastPredict model={model} />
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <></>
        )
      }
      {
        // If it is correlation
        model &&
        model.columns_active &&
        Object.keys(model.columns_active).length === 2 &&
        model.problem_type !== 'time_series_regression' ? (
          <Row className="mt-3">
            <Col md={12}>
              <CustomScatter
                data={[
                  {
                    id: 'True',
                    data: model.correlation,
                  },
                  {
                    id: 'Predicted',
                    data: model.correlation.map((d) => ({
                      x: d.x,
                      y: d.y_pred,
                    })),
                  },
                ]}
                header={
                  <span className="px-2 card-title">{t('Correlation:')}</span>
                }
                leftLegend={model.target}
                bottomLegend={
                  Object.keys(model.columns_active).filter(
                    (c) => c !== model.target,
                  )[0]
                }
                addLineLayer={false}
                addDynamicLines={[
                  {
                    serie: model.correlation
                      .map((d) => ({ x: d.x, y: d.y_pred }))
                      .sort((d1, d2) =>
                        d1.x - d2.x !== 0 ? d1.x - d2.x : d1.y - d2.y,
                      ),
                    stroke: '#B32318',
                    style: {
                      opacity: 0.25,
                    },
                  },
                ]}
                showLegend={true}
                className="insights-card py-2 px-2"
                height={400}
              />
            </Col>
          </Row>
        ) : (
          <></>
        )
      }
    </Container>
  )
}

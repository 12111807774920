import React, { useRef, useState } from 'react'
import { Row, Col } from 'react-grid-system'
import { FaInfoCircle } from 'react-icons/fa'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { JSONEditor } from '../../editor/editor'
import { uploadApi } from '../../../services/csv'
import BouncyButton from '../../bouncy-button/BouncyButton'
import { useAuth } from '../../../providers/AuthProvider'
import { useModels } from '../../../providers/ModelProvider'
import { NotificationManager } from 'react-notifications'
import { checkApi } from '../../../services/csv'
import { awaitTask } from '../../../services/base'
import { GridTable } from '../../grid-table/GridTable'
import { AssistantCheck } from '../AssistantCheck'
import RoleDisable from '../../utils/RoleDisable'

export default function ApiSource({
  actionLabel = 'Create new model',
  configuration,
  onFinish = null,
  sampleData = null,
  single,
  onNameChange = () => {},
  onAskAi = false,
}) {
  const { t } = useTranslation()
  const { addModel } = useModels()
  const { token, signout } = useAuth()
  const [isCreating, setIsCreating] = useState(false)
  const [sample, setSample] = useState(sampleData)
  const [isSampling, setIsSampling] = useState(null)
  const [headersValue, setHeadersValue] = useState(() => {
    if (configuration?.headers) {
      try {
        return JSON.stringify(configuration.headers)
      } catch (e) {}
    }
    return `{
  
}`
  })
  const apiLinkRef = useRef()
  const apiTypeRef = useRef()
  const apiUsernameRef = useRef()
  const apiPasswordRef = useRef()

  if (!onFinish)
    onFinish = () => {
      let apiData
      setIsCreating(true)
      try {
        apiData = {
          link: apiLinkRef.current.value,
          data_type: apiTypeRef.current.value,
          headers: JSON.parse(headersValue),
          username_auth: apiUsernameRef.current.value,
          password_auth: apiPasswordRef.current.value,
        }
      } catch (e) {
        return
      }
      uploadApi(apiData, token, signout)
        .then((response) => {
          setIsCreating(false)
          if (!response || response.length === 0) return
          addModel(response[0])
        })
        .catch((e) => setIsCreating(false))
    }

  const getSample = async () => {
    const params = {
      link: apiLinkRef.current.value,
      data_type: apiTypeRef.current.value,
      headers: JSON.parse(headersValue),
      username_auth: apiUsernameRef.current.value,
      password_auth: apiPasswordRef.current.value,
    }
    if (!params.link) {
      NotificationManager.error('Link is required')
      return
    }
    setIsSampling(true)
    const { task_id } = await checkApi({ ...params, token, signout })
    awaitTask({ taskUuid: task_id })
      .then((result) => {
        setSample({
          columns: result.columns,
          sample: result.data.slice(0, 30),
        })
      })
      .catch((e) => {
        NotificationManager.error(
          e?.error ?? t('Error while fetching sample data'),
        )
      })
      .finally(() => {
        setIsSampling(false)
      })
  }

  return (
    <Row justify="between">
      <Col xs={12} className="nopaddingright mt-3">
        <p>{t('Create new model from API Connection')}</p>
      </Col>
      <Col xs={12}>
        <Form>
          <Row>
            <Col xs={6}>
              <Form.Group className="mb-3" controlId="formAPIURL">
                <Form.Label>
                  Link<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="nb-input-soft"
                  ref={apiLinkRef}
                  defaultValue={configuration?.link ?? ''}
                  type="text"
                  placeholder={t('Enter your API Link')}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formAPIURL">
                <Form.Label>{t('Data type')}</Form.Label>
                <select
                  ref={apiTypeRef}
                  defaultValue={configuration?.data_type ?? 'json'}
                  className="form-select mt-0 nb-input-soft"
                >
                  <option value="json">JSON</option>
                  <option value="csv">CSV</option>
                  <option value="xml">XML</option>
                </select>
              </Form.Group>
              <Row>
                <Col xs={12}>
                  <p className="mt-2">
                    {t('Basic authentication (if needed)')}
                  </p>
                </Col>
                <Col xs={6}>
                  <Form.Group className="mb-3" controlId="formAPIBasicAuthName">
                    <Form.Label>{t('Username')}</Form.Label>
                    <Form.Control
                      ref={apiUsernameRef}
                      defaultValue={configuration?.username_auth ?? ''}
                      className="nb-input-soft"
                      type="text"
                      placeholder={t('Enter your username')}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className="mb-3" controlId="formAPIBasicAuthPass">
                    <Form.Label>
                      {t('Password')}
                      <OverlayTrigger
                        rootClose={true}
                        trigger={['hover', 'focus']}
                        delay={{ show: 100, hide: 100 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            {t(`We encrypt all sensitive data`)}.
                          </Tooltip>
                        )}
                      >
                        <span className="mx-2">
                          <FaInfoCircle
                            style={{ marginTop: '-10px' }}
                            size={15}
                          />
                        </span>
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      ref={apiPasswordRef}
                      defaultValue={configuration?.password_auth ?? ''}
                      className="nb-input-soft"
                      type="password"
                      placeholder={t('Enter your password')}
                    />
                    {/* <Form.Text className="text-muted">
                      {t('We will never store your password.')}
                    </Form.Text> */}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Form.Group className="mb-3" controlId="formAPIHeaders">
                <Form.Label>{t('HTTP Headers')}</Form.Label>
                <JSONEditor value={headersValue} setValue={setHeadersValue} />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Col>

      <Col style={{ minWidth: '250px' }} xs={2}>
        <Row>
          <Col xs={12}>
            <RoleDisable className="w-100">
              <BouncyButton
                alternative={true}
                loading={isSampling}
                onClick={getSample}
                className="config-button w-100"
                style={{
                  backgroundColor: isCreating ? '#DCDCDC' : '#4240B5',
                }}
                disabled={isSampling}
              >
                {t('Test')}
              </BouncyButton>
            </RoleDisable>
          </Col>
          <Col className="mt-2" xs={12}>
            <RoleDisable className="w-100">
              <BouncyButton
                alternative={true}
                loading={isCreating}
                disabled={!sample}
                onClick={() => {
                  onFinish(sample, {
                    link: apiLinkRef.current.value,
                    data_type: apiTypeRef.current.value,
                    headers: JSON.parse(headersValue),
                    username_auth: apiUsernameRef.current.value,
                    password_auth: apiPasswordRef.current.value,
                  })
                }}
                className="config-button w-100"
                style={{
                  backgroundColor: isCreating ? '#DCDCDC' : '#4240B5',
                }}
              >
                {t(actionLabel)}
              </BouncyButton>
            </RoleDisable>
          </Col>
          <Col className="mt-1" xs={12}>
            {onAskAi && (
              <AssistantCheck
                className="w-100 ms-0 mb-3"
                onChange={onAskAi}
                enable={() => {
                  const cols = sample?.columns?.length
                  if (cols && cols > 500) return false
                  return true
                }}
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col style={{ maxWidth: 'calc(100% - 250px)' }} xs={10}>
        {sample && (
          <GridTable
            rows={sample.sample}
            rowsPerPage={30}
            header={[sample.columns]}
            index={(i) => (
              <div className={`grid-table-cell index-cell ${i ? '' : 'first'}`}>
                {i ? i : ''}
              </div>
            )}
            className="w-100 table-view-data"
            defaultColumnWidth={(str) => Math.max(200, str.length * 12)}
            style={{ maxHeight: '300px' }}
            pagerLast={true}
          />
        )}
      </Col>
    </Row>
  )
}

import { useState, Fragment, useMemo, useRef, useEffect } from 'react'
import {
  Row,
  Col,
  Modal,
  Form,
  Button,
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../../providers/AuthProvider'
import {
  deleteRecipeById,
  getCustomApps,
  getMarketplaceApps,
  getMarketplaceTags,
  installApp,
} from '../../services/recipe'
import { useDebouncedCallback } from 'use-debounce'
import { formatDate } from '../../util/other'
import { FaRegCheckCircle, FaTag } from 'react-icons/fa'
import { FaArrowLeft } from 'react-icons/fa6'
import CodeMirror from '@uiw/react-codemirror'
import { python } from '@codemirror/lang-python'
import { solarizedDark } from '@uiw/codemirror-theme-solarized'
import { NotificationManager } from 'react-notifications'
import { Tag } from '../knowledge-base/TagManager'
import { useSearchParams } from 'react-router-dom'
import Loading from '../loading/LoadingSmall'

function AppDetails({ app, model, onInstall, onUninstall }) {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: customApps } = useQuery(
    ['custom-apps', model?.id ?? null, token],
    async () => {
      const apps = await getCustomApps({ token, signout, model })
      if (Array.isArray(apps)) return apps.reverse()
      return []
    },
    { staleTime: Infinity },
  )

  const installed = customApps?.find(
    (installedApp) => installedApp.marketplace_id === app.marketplace_id,
  )
  const isAlreadyInstalled = !!installed

  const createApp = async (scope) => {
    const result = await installApp({
      recipeId: app.marketplace_id,
      modelId: model?.id,
      scope,
      token,
      signout,
    })
    if (result?.recipe_id) {
      NotificationManager.success(t('App installed successfully'))
      queryClient.invalidateQueries(['custom-apps', model?.id ?? null, token])
      searchParams.delete('appMarketplace')
      setSearchParams(searchParams)
      if (onInstall) onInstall(result)
    } else NotificationManager.error(t('Error installing app'))
  }

  return (
    <Row>
      <Col className="mt-4 mb-1" xs={12}>
        <Row className="justify-content-between">
          <Col xs="auto">
            <Row>
              <Col xs={12}>
                <h3>{app.name}</h3>
              </Col>
              <Col xs={12}>
                <Row>
                  {app.tags?.map((tag, i) => (
                    <Tag
                      tag={tag}
                      className={`pe-none mt-1 ${i === 0 ? 'ms-4' : ''}`}
                      key={tag}
                    />
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs="auto">
            {isAlreadyInstalled ? (
              <Button
                variant="danger"
                className="original"
                size={'lg'}
                onClick={() =>
                  deleteRecipeById({
                    modelId: model?.id,
                    recipeId: installed.recipe_id,
                    token,
                    signout,
                  }).then((r) => {
                    if (r?.ok) {
                      NotificationManager.success(
                        t('App uninstalled successfully'),
                      )
                      queryClient.invalidateQueries([
                        'custom-apps',
                        model?.id ?? null,
                        token,
                      ])
                      if (onUninstall) onUninstall(installed)
                    } else {
                      NotificationManager.error(t('Error uninstalling app'))
                    }
                  })
                }
              >
                {t('Uninstall')}
              </Button>
            ) : (
              <DropdownButton size={'lg'} title={t('Install')}>
                {model && (
                  <>
                    <Dropdown.Item
                      eventKey="1"
                      className="color-white"
                      onClick={() => createApp('model')}
                    >
                      {t('Install at model level')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      className="color-white"
                      onClick={() => createApp('workspace')}
                    >
                      {t('Install at workspace level')}
                    </Dropdown.Item>
                  </>
                )}
                <Dropdown.Item
                  eventKey="3"
                  className="color-white"
                  onClick={() => createApp('user')}
                >
                  {t('Install at user level')}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="4"
                  className="color-white"
                  onClick={() => createApp('company')}
                >
                  {t('Install at company level')}
                </Dropdown.Item>
              </DropdownButton>
            )}
          </Col>
        </Row>
      </Col>
      <Col className="mt-2" xs={12}>
        <Row>
          <Col className="mb-5" xs={8}>
            {app.description}
          </Col>
          <Col xs={12}>
            <CodeMirror
              value={app.script}
              editable={false}
              extensions={[python({})].filter((e) => e)}
              onChange={() => {}}
              theme={solarizedDark}
              indentWithTab
              height="50vh"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

function AppItem({ app, tagFilters, onActivate, installed }) {
  const { t } = useTranslation()
  return (
    <Row className=" mx-2 p-2">
      <Col xs={10}>
        <Row>
          <Col
            className="d-inline-flex flex-nowrap align-items-end justify-content-between"
            xs={12}
          >
            <h5
              className="mb-0 color-white clickable-text d-inline-flex align-items-center"
              onClick={() => onActivate(app)}
            >
              <FaRegCheckCircle
                className="me-1"
                color={'var(--nextbrain-card-color)'}
              />
              {app.name}
            </h5>
            <span className="smallp ms-3 d-inline-flex align-items-center flex-nowrap">
              {formatDate(new Date(app.created_at * 1000))}
              {!!app.tags?.length && (
                <OverlayTrigger
                  rootClose={true}
                  trigger={['hover', 'focus']}
                  placement={'top'}
                  delay={{ show: 100, hide: 200 }}
                  overlay={(props) => (
                    <Popover
                      {...props}
                      style={{
                        ...props.style,
                        transform: `${
                          props?.style?.transform ?? ''
                        } scale(0.75)`,
                        zIndex: 10000,
                      }}
                    >
                      <Row
                        className="p-1 text-center smallp mx-0 justify-content-center"
                        style={{
                          backgroundColor: 'var(--nextbrain-body-75)',
                          border: '2px solid var(--nextbrain-background)',
                          borderRadius: '5px',
                        }}
                      >
                        {app?.tags?.map((tag) => (
                          <Tag tag={tag} key={tag} />
                        ))}
                      </Row>
                    </Popover>
                  )}
                >
                  <div
                    className="d-flex align-items-center smallp ms-3"
                    style={{ fontSize: '14px' }}
                  >
                    <FaTag />{' '}
                    <strong className="ms-1">{app?.tags?.length}</strong>
                  </div>
                </OverlayTrigger>
              )}
            </span>
          </Col>
          <OverlayTrigger
            rootClose={true}
            trigger={['hover', 'focus']}
            placement="bottom"
            delay={{ show: 200, hide: 0 }}
            overlay={(props) => (
              <Popover
                {...props}
                style={{ ...props.style, minWidth: '400px', zIndex: 9999 }}
              >
                <Row className="p-4">
                  <Col xs={12}>{app.description}</Col>
                </Row>
              </Popover>
            )}
          >
            <Col xs={12} className="mt-2">
              <span className="multiline-truncate-2 overflow-hidden smallp max-w-full ms-4">
                {app.description}
              </span>
            </Col>
          </OverlayTrigger>
        </Row>
      </Col>
      <Col className="dflex-center flex-column" xs={2}>
        <Button
          className="original empty-secondary px-3"
          style={{ borderRadius: '30px' }}
          onClick={() => onActivate(app)}
        >
          {t('View')}
        </Button>
        {installed ? (
          <p
            className="smallp mt-1"
            style={{ color: 'var(--nextbrain-tables-graph-bar-color)' }}
          >
            {t('Installed')}
          </p>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  )
}

const PAGE_SIZE = 20

export default function Marketplace({
  model,
  onInstall = () => {},
  onUninstall = () => {},
}) {
  const { token, signout } = useAuth()
  const [search, setSearch] = useState('')
  const [tagFilters, setTagFilters] = useState([])
  const [activeApp, setActiveApp] = useState(null)
  const { t } = useTranslation()

  const { data: tags } = useQuery(
    ['app-market-tags'],
    async () => {
      const tags = await getMarketplaceTags({
        token,
        signout,
      })
      if (!Array.isArray(tags)) throw new Error('Error fetching tags')
      return tags || []
    },
    { staleTime: Infinity },
  )

  const {
    data: apps,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery(
    ['app-market-infinite', search, tagFilters],
    async ({ pageParam = 0 }) => {
      const apps = await getMarketplaceApps({
        query: search,
        tags: tagFilters,
        limit: PAGE_SIZE,
        token,
        signout,
        page: pageParam,
      })
      if (!Array.isArray(apps)) throw new Error('Error fetching apps')

      return apps
    },
    {
      getNextPageParam: (_, items) => {
        return items.length
      },
      staleTime: Infinity,
    },
  )

  const { data: customApps } = useQuery(
    ['custom-apps', model?.id ?? null, token],
    async () => {
      const apps = await getCustomApps({ token, signout, model })
      if (Array.isArray(apps)) return apps.reverse()
      return []
    },
    { staleTime: Infinity },
  )

  const installed = useMemo(() => {
    if (customApps) {
      return new Set(customApps.map((e) => e.marketplace_id))
    }
    return new Set()
  }, [customApps])

  const updateFilter = useDebouncedCallback((v) => setSearch(v), [1000])

  const footerRef = useRef()
  const containerRef = useRef()

  useEffect(() => {
    if (footerRef.current && containerRef.current) {
      const viewItem = footerRef.current
      let options = {
        root: containerRef?.current,
        rootMargin: '200px',
        threshold: 1,
      }
      //const observer = new IntersectionObserver(fetchNextPage, options)
      const observer = new IntersectionObserver(([{ isIntersecting }]) => {
        if (apps?.pages?.[apps?.pages?.length - 1]?.length === 0) return
        if (isIntersecting) fetchNextPage()
      }, options)
      observer.observe(viewItem)
      return () => observer.disconnect()
    }
    // eslint-disable-next-line
  }, [activeApp, apps])

  if (activeApp)
    return (
      <Row>
        <Col className="cursor-pointer user-select-none" xs={12}>
          <h5
            className="d-flex align-items-center clickable-text"
            onClick={() => setActiveApp(null)}
          >
            <FaArrowLeft className="me-1" /> {t('Back to marketplace')}
          </h5>
        </Col>
        <Col xs={12} className="my-2">
          <AppDetails
            app={activeApp}
            model={model}
            onInstall={onInstall}
            onUninstall={onUninstall}
          />
        </Col>
      </Row>
    )

  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col xs={12} style={{ maxWidth: 'calc(100% - 300px)' }}>
            <Row className="p-2 mx-3">
              <Col className="mt-3 px-0 d-flex align-items-center" xs={12}>
                <h5 className="me-3" style={{ display: 'inline-block' }}>
                  {t('Tags')}:
                </h5>
                {tags?.map((tag, i) => (
                  <Tag
                    key={tag}
                    tag={tag}
                    active={tagFilters?.includes(tag)}
                    onClick={() => {
                      let newTags = tagFilters || []
                      if (tagFilters.includes(tag))
                        newTags = tagFilters.filter((t) => t !== tag)
                      else newTags.push(tag)
                      newTags.sort((a, b) => a.localeCompare(b))
                      setTagFilters([...newTags])
                    }}
                  />
                ))}
              </Col>
            </Row>
          </Col>
          <Col
            style={{ maxWidth: '300px' }}
            xs={12}
            className="d-flex justify-content-end mt-3 mb-1"
          >
            <div
              style={{
                marginRight: 35,
              }}
            >
              <Form.Control
                className="nb-input with-icon"
                style={{ maxWidth: '500px' }}
                placeholder={t('Search apps..')}
                defaultValue={search}
                onChange={(e) => updateFilter(e.target.value)}
                autoFocus
              />
              <i className="fas fa-search icon"></i>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row
          className="mt-3"
          style={{
            minHeight: '70vh',
            maxHeight: '70vh',
            overflow: 'auto',
          }}
        >
          <Col xs={12}>
            <Row ref={containerRef}>
              {(apps?.pages ?? []).map((page, i) => (
                <Fragment key={i}>
                  {(Array.isArray(page) ? page : []).map((app) => (
                    <Col
                      xl={6}
                      md={6}
                      xs={12}
                      key={app.marketplace_id}
                      className="mb-3"
                    >
                      <AppItem
                        app={app}
                        onActivate={() => setActiveApp(app)}
                        tagFilters={tagFilters}
                        installed={installed.has(app.marketplace_id)}
                      />
                    </Col>
                  ))}
                </Fragment>
              ))}
              <div ref={footerRef}>
                {isLoading && <Loading message="Loading" />}
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export function MarketplaceModel({ onHide = () => {}, ...props }) {
  const { t } = useTranslation()

  return (
    <Modal
      show={true}
      onHide={() => onHide()}
      size="xl"
      className="modal-xl-nb"
    >
      <Modal.Header closeButton className="mb-0">
        <h4 className="d-flex align-items-center input-grow-full mb-0 ">
          {t('App marketplace')}
        </h4>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Marketplace {...props} />
      </Modal.Body>
    </Modal>
  )
}

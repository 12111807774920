import { request } from './base'

export async function getIntegrations({ token, signout }) {
  return request({
    endpoint: `/integration/list`,
    method: 'GET',
    token,
    signout,
  })
}

export async function createIntegration({ data, token, signout }) {
  return request({
    endpoint: `/integration/create`,
    method: 'POST',
    body: data,
    contentType: 'json',
    token,
    signout,
  })
}

export async function getIntegration({ integrationId, token, signout }) {
  return request({
    endpoint: `/integration/integration/${integrationId}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function updateIntegration({
  integrationId,
  data,
  token,
  signout,
}) {
  return request({
    endpoint: `/integration/integration/${integrationId}`,
    method: 'POST',
    body: data,
    contentType: 'json',
    token,
    signout,
  })
}

export async function deleteIntegration({ integrationId, token, signout }) {
  return request({
    endpoint: `/integration/integration/${integrationId}`,
    method: 'DELETE',
    token,
    signout,
  })
}

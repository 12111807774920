import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useReducer,
  useRef,
  useMemo,
} from 'react'
import { NotificationManager } from 'react-notifications'
import {
  getModelTrainingDate,
  isMMMModel,
  modelIsActive,
  modelIsImporting,
  modelIsTraining,
} from '../util/models'
import { useAuth } from './AuthProvider'
import { modelIsOptimizing } from '../util/models'
import {
  getModelsFromList,
  trainModel,
  optimizeMMM,
  stopOptimizeMMM,
  modelsFromList,
  stopTraining as stopTrainingAPI,
  trainCluster as trainClusterAPI,
  kmeansTrain,
  getModelById,
  sixSigmaGenerateReport,
} from '../services/model'
import { getProjectById, setProjectActive } from '../services/project'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { useNav } from './NavProvider'
import { isValidDate } from '../util/other'

const ModelContext = createContext({
  loading: false,
  workspaceId: null,
  workspacename: null,
  lightModels: [],
  models: [],
  modelsById: {},
  modelPlaceholders: [],
  addPlaceholder: () => {},
  activeModel: null,
  numModels: 0,
  setActiveModel: () => {},
  updateModel: () => {},
  deleteModel: () => {},
  addModel: () => {},
  requestUpdate: () => {},
  train: () => {},
  stopTraining: () => {},
  optimize: () => {},
  trainCluster: () => {},
  trainKmean: () => {},
  isTraining: false,
  isOptimizing: false,
  onTransition: () => {},
  offTransition: () => {},
  enoughRowsToTrain: false,
  onImportUpdate: () => {},
  offImportUpdate: () => {},
  onParameterUpdate: () => {},
  offParameterUpdate: () => {},
  searchParams: [],
  setSearchParams: () => {},
})

const ACTIONS = {
  LOAD: 'LOAD',
  MODEL_UPDATE: 'MODEL_UPDATE',
  MODEL_DELETE: 'MODEL_DELETE',
  UPDATE_MODELS_FROM_LIST: 'UPDATE_MODELS_FROM_LIST',
  ADD_NEW_MODEL: 'ADD_NEW_MODEL',
  ADD_COMPLETE_MODEL: 'ADD_COMPLETE_MODEL',
  UPDATE_PARAMETERS: 'UPDATE_PARAMETERS',
  UPDATE_JUST_PARAMETERS: 'UPDATE_JUST_PARAMETERS',
}

/*
Problem1: Have to provide current project's data strcture consisting of a set of
models and an optinal active model or template for it to trigger newModelContent

Solution: React Context with provides models and functions to update the structure

Problem 2:
Active models require constant polling until they become inactive. 
"Active" refers to models that are either in training, ready to train, importing, or ready to import status.

Solution:
Periodically check the models that might require updates, request updates from the 
back end through pooling, and receive lightweight responses that can be used to 
request the whole model once we detect it has reached a non-active status.

Problem 3:
Additional logic may be necessary when models switch from one state to another. For example, in AttoW, 
we need to track the transition of models from training to trained to track the results of each iteration.

Solution:
Allow descendants to register callbacks that fire on such transitions, receiving a model just before the transition and the new version.
*/

function invalidate_queries(queryClient, model) {
  setTimeout(() => {
    queryClient.invalidateQueries(['forecast-model', model.id])
    queryClient.invalidateQueries(['synthetic-summary', model.id])
    queryClient.invalidateQueries(['forecast_trend', model.id])
    queryClient.invalidateQueries(['statistical-significance', model.id])
    queryClient.invalidateQueries(['forecast-model-original', model.id])
  }, 3000)

  if (isMMMModel(model))
    setTimeout(() => {
      queryClient.invalidateQueries(['mmm-optimized-table-spend', model.id])
      queryClient.invalidateQueries(['lag-carryover', model.id])
      queryClient.invalidateQueries(['saturation-curves', model.id])
      queryClient.invalidateQueries(['CustomOptimizedTable', model?.id])
      queryClient.invalidateQueries(['MMMInfluence', model.id])
      queryClient.invalidateQueries(['mediaContribution', model.id])
      queryClient.invalidateQueries(['mmm-model-statistics', model.id])
      queryClient.invalidateQueries(['mmm-model-seasonal-data', model.id])
      queryClient.invalidateQueries(['MMMDynamicSpend', model.id])
      queryClient.invalidateQueries(['mmm-funnel-effect', model?.id])
      queryClient.invalidateQueries(['mmm-total-funnel-effect', model?.id])
    }, 5000)
}

function mergeModel(state, model, updateCompleteModels = true) {
  try {
    const lightId = state.lightModels.findIndex((l) => l.id === model.id)

    if (lightId !== -1) state.lightModels[lightId] = model
    else state.lightModels.unshift(model)
    state.lightModels = [...state.lightModels]
    if (!updateCompleteModels) return

    const modelId = state.models.findIndex((m) => m.id === model.id)
    if (modelId !== -1) state.models[modelId] = model
    else state.models.unshift(model)
  } catch (e) {
    console.error('Failed merging model', state, model, e)
  }
}

export function ModelProvider({
  setTitle = () => {},
  workspaceId,
  defaultActiveModelId,
  timeout = 4000,
  children,
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    token,
    signout,
    user,
    reloadUser,
    updateuserData,
    unlimitedTraining,
    MMMEnabled,
    hasAnomalyPlugin,
  } = useAuth()
  const queryClient = useQueryClient()
  const { setMode } = useNav()
  const [isChangingDataActiveModel, setIsChangingDataActiveModel] =
    useState(false)

  const { data: project, isLoading: projectIsLoading } = useQuery(
    ['project', workspaceId],
    async () => {
      return await getProjectById(workspaceId, token, signout)
    },
    { staleTime: Infinity },
  )

  const role = useMemo(() => {
    return (
      project?.invited_users?.find((u) => u.id === user?.id)?.role ?? 'editor'
    )
  }, [project, user])

  const workspacename = project?.name
  const activeModelId = searchParams.get('model-id')

  useEffect(() => {
    if (workspacename) {
      setMode(project.product_type)
      searchParams.set('wp-name', workspacename)
    }
    // eslint-disable-next-line
  }, [workspacename])

  //from -> to
  const modelTransitions = useRef({
    importing: {
      imported: new Set([
        ({ model }) => {
          setTimeout(() => {
            queryClient.invalidateQueries([
              'viewData-infinite',
              model?.id,
              'imported',
            ])
            queryClient.invalidateQueries([
              'model-summary',
              model.id,
              'imported',
            ])
            queryClient.invalidateQueries(['columnsDeleted', model?.id])
          }, 2000)
        },
      ]),
    },
    readyToTrain: {
      trained: new Set([
        ({ updatedModel }) => invalidate_queries(queryClient, updatedModel),
      ]),
    },
    training: {
      trained: new Set([
        ({ updatedModel }) => invalidate_queries(queryClient, updatedModel),
      ]),
    },
  })
  const anySource = useRef({})
  const anyTarget = useRef({})
  const transitionCallbacks = useRef({})
  const parameterUpdateCallbacks = useRef({}) //Only called with UPDATE_JUST_PARAMETERS {modelId:{'parameter':Set(callbacks)}

  const [modelPlaceholders, setModelPlaceholders] = useState([])
  const { t } = useTranslation()

  const fireModelTranstionCallbacks = (model, updatedModel) => {
    let from = null,
      to = null
    if (model?.status !== updatedModel?.status) {
      from = model?.status
      to = updatedModel?.status
    } else if (model?.dataset?.status !== updatedModel?.dataset?.status) {
      from = model?.dataset?.status
      to = updatedModel?.dataset?.status
    }

    if (!from || !to) return

    const updates = modelTransitions.current?.[from]?.[to]
    const updatesFromAny = anySource.current?.[to]
    const updatesToAny = anyTarget.current?.[from]

    if (updates)
      for (const callback of updates) callback({ model, updatedModel })

    if (updatesFromAny)
      for (const callback of [...updatesFromAny]) {
        callback({ model, updatedModel })
      }

    if (updatesToAny)
      for (const callback of updatesToAny) callback({ model, updatedModel })
  }

  function reduce(state, action) {
    const { payload } = action
    switch (action.type) {
      case ACTIONS.LOAD:
        let id = payload.models.find((m) => m.id === defaultActiveModelId)?.id

        if (!id && defaultActiveModelId === 'new') id = 'new'
        payload.models.forEach((m) => {
          m.dataset.name = m.name
        })
        payload.models
          .filter((m) => modelIsActive(m))
          .forEach((m) => state.modelsToQueue.add(m.id))
        return {
          ...state,
          lightModels: payload.models,
          modelsById: {},
          models: [],
          loading: false,
          id: payload.id,
          name: payload.name,
        }
      case ACTIONS.UPDATE_MODELS_FROM_LIST:
        const newModels = payload
        if (!newModels?.length) return
        newModels.forEach((m) => {
          state.modelsToUpdate.delete(m.id)
          mergeModel(state, m, false)
        })

        state.modelsById = state.models.reduce(
          (acc, m) => ({ ...acc, [m.id]: m }),
          {},
        )
        return { ...state }

      case ACTIONS.ADD_NEW_MODEL:
        mergeModel(state, payload)
        state.modelsToUpdate.add(payload.id)
        state.modelsById[payload.id] = payload
        setSearchParams({ 'model-id': payload.id })
        return { ...state }

      case ACTIONS.ADD_COMPLETE_MODEL:
        mergeModel(state, payload)
        state.modelsById[payload.id] = payload
        return { ...state }

      case ACTIONS.UPDATE_PARAMETERS: {
        const index = state.models.findIndex((m) => m.id === payload.id)
        const lightIndex = state.lightModels.findIndex(
          (m) => m.id === payload.id,
        )
        let change = false

        if (index !== -1) {
          Object.assign(state.models[index], payload.data)
          state.models[index] = { ...state.models[index] }
          state.models = [...state.models]
          state.modelsById = state.models.reduce(
            (acc, m) => ({ ...acc, [m.id]: m }),
            {},
          )
          change = true
        }

        if (lightIndex !== -1) {
          Object.assign(state.lightModels[lightIndex], payload.data)
          state.lightModels[lightIndex] = { ...state.lightModels[lightIndex] }
          change = true
        }

        return change ? { ...state } : state
      }

      case ACTIONS.UPDATE_JUST_PARAMETERS: {
        const index = state.models.findIndex((m) => m.id === payload.id)
        const lightIndex = state.lightModels.findIndex(
          (m) => m.id === payload.id,
        )
        if (index !== -1) {
          Object.assign(state.models[index], payload.data)
          const modelCallbacks = parameterUpdateCallbacks.current?.[payload.id]
          if (modelCallbacks) {
            Object.entries(payload.data).forEach(([k, v]) => {
              const callbacks = modelCallbacks[k]
              if (callbacks) for (const callback of callbacks) callback(v)
            })
          }
        }
        if (lightIndex !== -1)
          Object.assign(state.lightModels[lightIndex], payload.data)
        return state
      }

      case ACTIONS.MODEL_DELETE:
        if (payload in state.modelsById) {
          state.modelsToQueue.delete(payload)
          state.modelsToUpdate.delete(payload)
          let index = state.models.findIndex((m) => m.id === payload)
          if (index !== -1) {
            state.models = state.models.filter((_, i) => i !== index)
            if (activeModelId === payload) {
              setSearchParams({ 'model-id': state.models[0]?.id })
            }
            delete state.modelsById[payload]
          }
          state.lightModels = state.lightModels.filter((m) => m.id !== payload)
          return { ...state }
        }
        state.lightModels = state.lightModels.filter((m) => m.id !== payload)
        return { ...state }

      case ACTIONS.MODEL_UPDATE:
        let items = payload
        if (!Array.isArray(items)) items = [items]

        for (const payload of items) {
          const index = state.models.findIndex((m) => m.id === payload.id)
          if (index !== -1) {
            const model = state.modelsById[payload.id]
            fireModelTranstionCallbacks(model, payload)
          }
          mergeModel(state, payload)
        }

        state.modelsById = state.models.reduce(
          (acc, m) => ({ ...acc, [m.id]: m }),
          {},
        )
        return { ...state }
      default:
        break
    }
    return state
  }

  const [values, dispatch] = useReducer(reduce, {
    loading: true,
    lightModels: [],
    modelsToQueue: new Set(),
    modelsToUpdate: new Set(),
  })

  const {
    models,
    lightModels,
    modelsById,
    modelsToQueue,
    modelsToUpdate,
    loading,
  } =
    values && typeof values === 'object'
      ? values
      : {
          loading: true,
          lightModels: [],
          modelsToQueue: new Set(),
          modelsToUpdate: new Set(),
        }

  useEffect(() => {
    if (
      !loading &&
      activeModelId &&
      activeModelId !== 'new' &&
      !modelsById?.[activeModelId]
    ) {
      getModelById(activeModelId, token, signout).then((model) => {
        if (model?.id) {
          dispatch({ type: ACTIONS.ADD_COMPLETE_MODEL, payload: model })
        }
      })
    }
    // eslint-disable-next-line
  }, [activeModelId, loading])

  const activeModel = modelsById?.[activeModelId]

  const [checkUpdates, setCheckUpdates] = useState({})
  useEffect(() => {
    if (checkUpdates[activeModel?.id]) {
      const iv = setInterval(async () => {
        const model = await getModelById(activeModel?.id, token, signout)
        if (model && model?.updated !== activeModel?.updated) {
          dispatch({ type: ACTIONS.MODEL_UPDATE, payload: model })
          setCheckUpdates((cu) => ({ ...cu, [activeModel?.id]: false }))
          clearInterval(iv)
        }
      }, 5000)
      return () => clearInterval(iv)
    }
    // eslint-disable-next-line
  }, [checkUpdates, activeModel])

  const updatePbar = async () => {
    if (activeModel?.id && activeModel?.id !== 'new') {
      const model = await getModelById(activeModel?.id, token, signout)
      if (!modelIsImporting(activeModel) && activeModel?.pbar_data) {
        dispatch({
          type: ACTIONS.MODEL_UPDATE,
          payload: {
            ...activeModel,
            pbar_data: {
              ...model?.pbar_data,
              n: activeModel?.pbar_data?.total,
            },
          },
        })
        setTimeout(() => {
          dispatch({
            type: ACTIONS.MODEL_UPDATE,
            payload: model,
          })
        }, 1500)
      } else if (model?.id) {
        dispatch({
          type: ACTIONS.MODEL_UPDATE,
          payload: model,
        })
      }
    }
  }

  const programUpdate = async () => {
    ;[...modelsToQueue].forEach((id) => {
      const model = modelsById?.[id] ?? lightModels.find((m) => m.id === id)

      if (!model || !modelIsActive(model)) {
        modelsToQueue.delete(id)
        modelsToUpdate.delete(id)
        return
      }

      let currentTime = new Date()
      let trainingDate = getModelTrainingDate(model)
      let tzOffset = currentTime.getTimezoneOffset() * 60 * 1000
      // This works because we assume the users can't train a hour or more
      if (Math.abs(currentTime - trainingDate) / 36e5 <= 1) {
        tzOffset = 0
      }

      const elapsedSeconds =
        (currentTime.getTime() + tzOffset - trainingDate.getTime()) / 1000
      const timeToWait = model.minutes * 60 - elapsedSeconds

      if (timeToWait <= 0) {
        modelsToQueue.delete(id)
        modelsToUpdate.add(id)
      }
    })

    for (const id of [...modelsToUpdate]) {
      if (
        !modelIsActive(modelsById[id] ?? lightModels.find((m) => m.id === id))
      )
        modelsToUpdate.delete(id)
    }

    if (modelsToUpdate.size) {
      const newModels = await modelsFromList(
        [...modelsToUpdate],
        token,
        signout,
      )
      if (!Array.isArray(newModels) || !newModels.length) return

      const modelsNotActive = newModels
        .map((newModel) => {
          const oldModel = modelsById[newModel.id]
          if (modelIsActive(oldModel) && !modelIsActive(newModel))
            return newModel.id
          return null
        })
        .filter((e) => e)

      if (modelsNotActive.length) {
        const models = await getModelsFromList(modelsNotActive, token, signout)
        dispatch({ type: ACTIONS.UPDATE_MODELS_FROM_LIST, payload: models })
        Promise.all(
          modelsNotActive.map((id) => getModelById(id, token, signout)),
        ).then((models) => {
          models = models.filter((m) => m?.id)
          if (models.length)
            dispatch({ type: ACTIONS.MODEL_UPDATE, payload: models })
        })
      }
    }
  }

  const updateModel = (_, updatedModel) => {
    dispatch({ type: ACTIONS.MODEL_UPDATE, payload: updatedModel })
  }

  const requestUpdate = (model) => {
    const id = model?.id ?? model
    if (typeof id !== 'string') return

    getModelById(id, token, signout).then((model) => {
      if (model?.id) {
        dispatch({ type: ACTIONS.ADD_COMPLETE_MODEL, payload: model })
      }
    })
  }

  const train = (
    model,
    targetConfig,
    columnsToIgnore,
    quality,
    isLightning,
    useSynthetic,
    removeOutliers,
    algorithm,
    metrics,
    cap,
    trainSplit = 0.8,
    mmm = {},
    anomalies = {},
    forecastConfig = {},
    parameters = {},
  ) => {
    if (!targetConfig.target) {
      NotificationManager.error(t('Select a column to predict'))
      return
    }

    if (
      !unlimitedTraining &&
      user?.monthly_limits?.training_rows < activeModel?.dataset?.rows
    ) {
      NotificationManager.error(t('Plan limit reached'))
      return
    }

    columnsToIgnore = columnsToIgnore?.filter((c) => c !== targetConfig.target)

    let finalColumnsToIgnore = columnsToIgnore
    if (targetConfig.problemType === 'Forecast') {
      const finalColumnStatus = model?.dataset?.final_column_status
      if (finalColumnStatus) {
        const columnsToUse = new Set()
        columnsToUse.add(targetConfig.target)
        for (const [k, v] of Object.entries(finalColumnStatus)) {
          if (v === 'Datetime') {
            columnsToUse.add(k)
            break
          }
        }
        finalColumnsToIgnore = Object.keys(finalColumnStatus).filter(
          (c) => !columnsToUse.has(c),
        )
      }
    }

    const trainModelOptions = {
      target: targetConfig.target,
      minutes: quality,
      columns_to_ignore: finalColumnsToIgnore,
      generate_synthetic: useSynthetic,
      remove_outliers: removeOutliers,
      cap: Number.isNaN(cap) ? null : cap,
      train_percentage: trainSplit,
      is_lightning: isLightning,
      extra_configuration: parameters ?? {},
    }
    if (trainModelOptions.extra_configuration?.sample)
      delete trainModelOptions.extra_configuration.sample
    else trainModelOptions.extra_configuration.sample = null

    if (Object.keys(mmm).length > 0) {
      if (!MMMEnabled()) {
        NotificationManager.error(t('MMM is not available'))
        return
      }
      trainModelOptions.train_mode = 'mmm'
      trainModelOptions.mmm_params = mmm
      trainModelOptions.columns_to_ignore =
        trainModelOptions.columns_to_ignore?.filter(
          (v) => v !== mmm.datetime_col,
        )
      model.special_model_type = 'mmm'
    }

    if (targetConfig.problemType === 'Forecast') {
      if (
        forecastConfig?.horizonMagnitude &&
        forecastConfig?.horizonUnit &&
        forecastConfig?.horizonUnit?.toLowerCase() !== 'auto'
      ) {
        trainModelOptions.horizon_units = forecastConfig.horizonUnit[0]
        trainModelOptions.horizon_magnitude = forecastConfig.horizonMagnitude
      }

      if (
        forecastConfig?.periodicity &&
        forecastConfig?.periodicity?.toLowerCase() !== 'auto' &&
        !Number.isNaN(Number.parseInt(forecastConfig?.periodicity))
      )
        trainModelOptions.forecast_peridicity = Number.parseInt(
          forecastConfig.periodicity,
        )
      if (!Number.isNaN(forecastConfig.forecastQuality))
        trainModelOptions.forecast_quality = Number.parseInt(
          forecastConfig.forecastQuality,
        )
      if (isValidDate(forecastConfig?.forecast_limit)) {
        trainModelOptions.forecast_limit = forecastConfig?.forecast_limit
      }
    }

    if (targetConfig.problemType === 'Anomaly Detection') {
      if (!hasAnomalyPlugin) {
        NotificationManager.error(t('Anomaly Detection is not available'))
        return
      }
      trainModelOptions.anomaly_params = {}
      trainModelOptions.anomaly_params.periodicity = Math.floor(
        anomalies.periodicity,
      )
      trainModelOptions.anomaly_params.max_anomalies =
        anomalies.max_anomalies / 100
      trainModelOptions.train_mode = 'anomaly'
      model.special_model_type = 'anomaly'
    }

    if (metrics?.length) {
      trainModelOptions.objectives = [metrics[0]]
      model.objectives = trainModelOptions.objectives
    }

    if (algorithm?.length) {
      trainModelOptions.algorithms = algorithm
      model.algorithms = trainModelOptions.algorithms
    }
    NotificationManager.info(
      t('Waiting for servers availability'),
      null,
      null,
      null,
      true,
    )
    const prevState = modelsById?.[model?.id]?.status ?? 'error'
    dispatch({
      type: ACTIONS.UPDATE_PARAMETERS,
      payload: {
        id: model.id,
        data: {
          status: 'training',
          percent_train: 0,
          last_train_socket: {
            message: 'Starting',
            model_id: model.id,
            name: model?.dataset?.name ?? '',
            level: 'INFO',
            percent: 0,
            percent_step: 0,
            finished: false,
          },
        },
      },
    })

    const modelId = model.id
    trainModel(model.id, trainModelOptions, token, signout)
      .then(async (model) => {
        if (!model || !model.id) {
          let result = {}
          try {
            result = await model.response.json()
          } catch (e) {}
          NotificationManager.error(result?.detail ?? t(`Plan limit reached`))
          reloadUser()
          dispatch({
            type: ACTIONS.UPDATE_PARAMETERS,
            payload: { id: modelId, data: { status: prevState } },
          })
          return
        }
        model.percent_train = 0
        if (!unlimitedTraining)
          updateuserData((session) => {
            session.data.user.monthly_limits.training_rows -=
              activeModel.dataset.rows
            session.data.user = { ...session.data.user }
            return { ...session }
          })
        updateModel(model.id, model)
        modelsToQueue.add(model.id)
      })
      .catch((error) => {
        NotificationManager.error(
          `Failed to train model ${model?.dataset?.name ?? `#${model.id}`}`,
        )
        dispatch({
          type: ACTIONS.UPDATE_PARAMETERS,
          payload: { id: model.id, data: { status: 'error' } },
        })
      })
  }

  const sixSigmaTrain = (model, data) => {
    if (
      !unlimitedTraining &&
      user?.monthly_limits?.training_rows < activeModel?.dataset?.rows
    ) {
      NotificationManager.error(t('Plan limit reached'))
      return
    }

    NotificationManager.info(
      t('Waiting for servers availability'),
      null,
      null,
      null,
      true,
    )
    const prevState = modelsById?.[model?.id]?.status ?? 'error'
    dispatch({
      type: ACTIONS.UPDATE_PARAMETERS,
      payload: {
        id: model.id,
        data: {
          status: 'training',
          percent_train: 0,
          last_train_socket: {
            message: 'Starting',
            model_id: model.id,
            name: model?.dataset?.name ?? '',
            level: 'INFO',
            percent: 0,
            percent_step: 0,
            finished: false,
          },
        },
      },
    })

    const modelId = model.id
    sixSigmaGenerateReport(model.id, data, token, signout)
      .then(async (model) => {
        if (!model || !model.id) {
          let result = {}
          try {
            result = await model.response.json()
          } catch (e) {}
          NotificationManager.error(result?.detail ?? t(`Plan limit reached`))
          reloadUser()
          dispatch({
            type: ACTIONS.UPDATE_PARAMETERS,
            payload: { id: modelId, data: { status: prevState } },
          })
          return
        }
        model.percent_train = 0
        if (!unlimitedTraining)
          updateuserData((session) => {
            session.data.user.monthly_limits.training_rows -=
              activeModel.dataset.rows
            session.data.user = { ...session.data.user }
            return { ...session }
          })
        updateModel(model.id, model)
        modelsToQueue.add(model.id)
      })
      .catch((error) => {
        NotificationManager.error(
          `Failed to train model ${model?.dataset?.name ?? `#${model.id}`}`,
        )
        dispatch({
          type: ACTIONS.UPDATE_PARAMETERS,
          payload: { id: model.id, data: { status: 'error' } },
        })
      })
  }

  const trainCluster = async ({ modelId, ...params }) => {
    dispatch({
      type: ACTIONS.UPDATE_PARAMETERS,
      payload: {
        id: modelId,
        data: {
          last_clustering_socket: {
            message: 'Starting clustering',
            model_id: modelId,
            name: activeModel?.dataset?.name ?? '',
            level: 'INFO',
            percent: 0,
            percent_step: 0,
            finished: false,
          },
        },
      },
    })
    return await trainClusterAPI({
      modelId,
      ...params,
      token,
      signout,
    })
      .then((result) => {
        if (!result?.ok) {
          NotificationManager.error(`Failed cluster train`)
          return
        }
      })
      .catch((error) => {
        NotificationManager.error(`Failed cluster train`)
        console.error(error)
      })
      .finally(() => {
        setTimeout(() => {
          queryClient.invalidateQueries(['model-clusters', modelId])
        }, 2000)
      })
  }

  const trainKmean = async ({ modelId, ...params }) => {
    dispatch({
      type: ACTIONS.UPDATE_PARAMETERS,
      payload: {
        id: modelId,
        data: {
          last_kmean_socket: {
            message: 'Starting clustering',
            model_id: modelId,
            name: activeModel?.dataset?.name ?? '',
            level: 'INFO',
            percent: 0,
            percent_step: 0,
            finished: false,
          },
        },
      },
    })
    return await kmeansTrain({
      modelId,
      ...params,
      token,
      signout,
    })
      .then((result) => {
        if (!result?.ok) {
          NotificationManager.error(`Failed cluster train`)
          return
        }
        return { ok: true }
      })
      .catch((error) => {
        NotificationManager.error(`Failed cluster train`)
        console.error(error)
      })
  }

  const stopTraining = () => {
    if (activeModelId) {
      stopTrainingAPI({ modelId: activeModelId, token, signout })
        .then(() => {
          NotificationManager.info(t('The model will stop training shortly'))
          modelsToUpdate.add(activeModelId)
        })
        .catch((error) => {
          console.error(error)
          NotificationManager.error('Failed to stop model training')
        })
    }
  }

  const optimize = ({
    modelId,
    channelToMinSpend,
    channelToMaxSpend,
    outcomeTarget,
    budgetTarget,
    weeks,
    time,
  }) => {
    optimizeMMM({
      modelId: modelId,
      token,
      signout,
      channelToMinSpend: channelToMinSpend,
      channelToMaxSpend: channelToMaxSpend,
      outcomeTarget: outcomeTarget,
      budgetTarget: budgetTarget,
      weeks: weeks,
      time,
    })
      .then((model) => {
        if (!model || !model.id) {
          NotificationManager.error(
            `Failed to optimize model, didn't receive updated model`,
          )
          return
        }
        if (model?.mmm) model.mmm.percent_optimize = 0
        updateModel(model.id, model)
      })
      .catch((error) => {
        console.error(`Error calling optimize for model ${modelId}`, error)
        NotificationManager.error(
          `Failed to optimize model ${
            modelsById?.[modelId]?.dataset?.name ?? `#${modelId}`
          }`,
        )
      })
  }

  const stopOptimize = ({ modelId }) => {
    stopOptimizeMMM({
      modelId: modelId,
      token,
      signout,
    })
      .then((r) => {
        if (!r?.ok) {
          NotificationManager.error(`Failed to stop optimization`)
          return
        }
        const model = modelsById?.[modelId]
        if (model?.mmm) {
          model.mmm.stopping_optimize = Date.now()
          updateModel(model.id, model)
        }
      })
      .catch((error) => {
        console.error(`Error stopping optimization`)
        NotificationManager.error(`Failed to stop optimization`)
      })
  }

  const addPlaceholder = async (name, getModel) => {
    setModelPlaceholders((names) => [name, ...names])
    try {
      const model = await getModel().catch((e) => null)
      if (model) dispatch({ type: ACTIONS.ADD_NEW_MODEL, payload: model })
      else NotificationManager.error(t('Error loading model'))
    } catch (e) {
      NotificationManager.error(t('Error loading model'))
      console.error(`error loading model with placeholder ${name}`, e)
    }
    setModelPlaceholders((names) => names.filter((n) => n !== name))
  }

  useEffect(() => {
    setProjectActive(workspaceId, token, signout).then((response) => {
      if (!response || !response?.models?.length) {
        if (!Array.isArray(response?.models)) {
          NotificationManager.error(t('Error loading project'))
          response = { models: [] }
        }
      }
      const data = {
        models: response.models,
        role: response?.role ?? 'editor',
      }
      dispatch({
        type: ACTIONS.LOAD,
        payload: data,
      })
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!loading) {
      const interval = setInterval(programUpdate, timeout)
      return () => clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [models, lightModels, activeModel, loading])

  useEffect(() => {
    if (!loading && modelIsImporting(activeModel)) {
      const interval = setInterval(updatePbar, 3000)
      return () => clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [activeModel, loading])

  window.model = activeModel
  useEffect(() => {
    if (activeModelId === 'new') {
      setTitle(`New model | ${t('Nextbrain')}`)
    } else if (activeModel?.dataset?.name) {
      setTitle(`${activeModel?.dataset?.name} | ${t('Nextbrain')}`)
    } else {
      setTitle(`${workspacename ?? t('Loading workspace')} | ${t('Nextbrain')}`)
    }
    // eslint-disable-next-line
  }, [activeModel, activeModelId, workspacename])

  const modelVariables = {
    loading,
    loadingCompleteModel:
      (activeModelId &&
        activeModelId !== 'new' &&
        !modelsById?.[activeModelId]) ||
      loading,
    role,
    project,
    projectIsLoading,
    workspaceId,
    workspacename,
    lightModels,
    models: models || [],
    modelsById: modelsById || {},
    modelPlaceholders,
    addPlaceholder,
    lightActiveModel: lightModels?.find((m) => m.id === activeModelId),
    activeModel:
      activeModelId === 'new'
        ? { id: 'new', dataset: { name: 'new' } }
        : activeModel,
    numModels: models?.length ?? 0,
    setActiveModel: (model) => {
      searchParams.set('model-id', model?.id ?? model ?? '')
      setSearchParams(searchParams)
    },
    updateModel,
    updateModelParameters: (modelId, parameters) =>
      dispatch({
        type: ACTIONS.UPDATE_PARAMETERS,
        payload: { id: modelId, data: parameters },
      }),
    deleteModel: (modelId) => {
      localStorage.removeItem(`history-${modelId}`)
      dispatch({ type: ACTIONS.MODEL_DELETE, payload: modelId })
    },
    addModel: (model) => {
      dispatch({ type: ACTIONS.ADD_NEW_MODEL, payload: model })
    },
    requestUpdate,
    poolForUpdate: (modelId) => {
      setCheckUpdates((cu) => ({ ...cu, [modelId]: true }))
    },
    train,
    sixSigmaTrain,
    stopTraining,
    optimize,
    stopOptimize,
    trainCluster,
    trainKmean,
    isTraining: modelIsTraining(modelsById?.[activeModelId]),
    isOptimizing: modelIsOptimizing(modelsById?.[activeModelId]),
    onTransition: (from, to, callback) => {
      if (from === '*') {
        anySource.current[to] = anySource.current[to] ?? new Set()
        anySource.current[to].add(callback)
      } else if (to === '*') {
        anyTarget.current[from] = anyTarget.current[from] ?? new Set()
        anyTarget.current[from].add(callback)
      } else {
        modelTransitions.current[from] = modelTransitions.current[from] ?? {}
        modelTransitions.current[from][to] =
          modelTransitions.current[from][to] ?? new Set()
        modelTransitions.current[from][to].add(callback)
      }
    },
    offTransition: (from, to, callback) => {
      if (from === '*') anySource.current?.[to]?.delete(callback)
      else if (to === '*') anyTarget.current[from]?.delete(callback)
      else modelTransitions.current?.[from]?.[to]?.add(callback)
    },
    enoughRowsToTrain:
      unlimitedTraining ||
      user?.monthly_limits?.training_rows >= activeModel?.dataset?.rows,
    onImportUpdate: (modelId, callback) => {
      transitionCallbacks.current[modelId] =
        transitionCallbacks.current[modelId] ?? new Set()
      transitionCallbacks.current[modelId].add(callback)
    },
    offImportUpdate: (modelId, callback) => {
      transitionCallbacks.current[modelId]?.delete(callback)
    },
    onParameterUpdate: (modelId, parameter, callback) => {
      parameterUpdateCallbacks.current[modelId] =
        parameterUpdateCallbacks.current[modelId] ?? {}
      parameterUpdateCallbacks.current[modelId][parameter] =
        parameterUpdateCallbacks.current[modelId][parameter] ?? new Set()
      parameterUpdateCallbacks.current[modelId][parameter].add(callback)
    },
    offParameterUpdate: (modelId, parameter, callback) => {
      parameterUpdateCallbacks.current[modelId]?.[parameter]?.delete(callback)
    },
    searchParams,
    setSearchParams,
    isChangingDataActiveModel,
    setIsChangingDataActiveModel,
  }
  return (
    <ModelContext.Provider value={modelVariables}>
      {children}
    </ModelContext.Provider>
  )
}

export function useModels() {
  return useContext(ModelContext)
}

import React, { useState, useRef, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useAuth } from '../../providers/AuthProvider'
import {
  createDocument,
  updateDocument as updateDocumentAPI,
  deleteDocument,
  getDocuments,
  getRepositories,
} from '../../services/document'
import {
  Col,
  Row,
  Modal,
  Button,
  Container,
  OverlayTrigger,
  Popover,
  Form,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { GrDocumentText } from 'react-icons/gr'
import { TiPlus } from 'react-icons/ti'
import { NotificationManager } from 'react-notifications'
import { Link, useParams } from 'react-router-dom'
import { FaRocketchat, FaTrash, FaUpload, FaLink } from 'react-icons/fa'
import { BsArrowLeft } from 'react-icons/bs'
import { Status as UploadStatus } from '../model-content/datasource-flow/Status'
import Loading from '../loading/LoadingSmall'
import Document from './Document'
import { useDebouncedCallback } from 'use-debounce'
import '../homepage/homepage.css'
import './Documents.css'
import TagManager from './TagManager'
import BreadcrumbNav from '../header/BreadcrumbNav'

function AddDocumentNav({ onClick, ...props }) {
  const { t } = useTranslation()

  return (
    <Row className="px-4 py-4 position-relative">
      <Col
        xs={12}
        className="d-inline-flex justify-content-center icon-btn"
        onClick={() => onClick('file')}
      >
        <h5 className="d-inline-flex justify-content-center align-items-center">
          <FaUpload
            className="me-2"
            style={{ color: '#EC812F', fontSize: 20 }}
          />
          <span>{t('Local file')}</span>{' '}
        </h5>
      </Col>
      <Col
        className="d-inline-flex justify-content-center cursor-ponter icon-btn mt-4"
        xs={12}
        onClick={() => onClick('web')}
      >
        <h5 className="d-inline-flex justify-content-center align-items-center">
          <FaLink className="me-2" style={{ color: '#2CF1FA', fontSize: 18 }} />
          {t('Website')}{' '}
        </h5>
      </Col>
    </Row>
  )
}

export default function Documents() {
  const { repositoryId } = useParams()
  const queryClient = useQueryClient()
  const { token, signout } = useAuth()

  const inputRef = useRef()
  const [newDocuments, setNewDocuments] = useState([])

  const { t } = useTranslation()

  const [showMenu, setShowMenu] = useState(false)
  const [showWebImport, setShowWebImport] = useState(false)
  const [updateDocument, setUpdateDocument] = useState(null)
  const [showOverlay, _setShowOverlay] = useState(false)
  const [filters, setFilters] = useState([])
  const setShowOverlay = useDebouncedCallback((show) => {
    _setShowOverlay(show)
  }, 500)

  useEffect(() => {
    if (!showMenu) {
      setNewDocuments([])
      setUpdateDocument(null)
    }
  }, [showMenu])

  const { data: repository } = useQuery(
    ['document-repository', repositoryId, token],
    async () => {
      const repos = await getRepositories({ repositoryId, token, signout })
      return repos?.[0]
    },
    { staleTime: Infinity },
  )

  const { data: documents, isLoading: isLoadingDocuments } = useQuery(
    ['documents', repositoryId, token],
    async () => {
      return await getDocuments({ repositoryId, token, signout })
    },
    { staleTime: Infinity },
  )

  useEffect(() => {
    if (documents?.some((d) => d?.status === 'loading')) {
      const iv = setInterval(() => {
        queryClient.invalidateQueries(['documents', repositoryId, token])
      }, 4000)
      return () => clearInterval(iv)
    }
    // eslint-disable-next-line
  }, [documents])

  const [uploadState, setUploadState] = useState(null)

  const createDocuments = async (baseDocuments = null) => {
    const documents = baseDocuments ?? newDocuments
    if (documents.length === 0) {
      NotificationManager.error('Please select a document to upload')
      return
    }
    setUploadState({})
    const promises = updateDocument
      ? [
          updateDocumentAPI({
            documentId: updateDocument.id,
            file: documents[0],
            token,
            signout,
            onProgress: (progress, name) => {
              setUploadState((s) => ({ ...s, [name]: progress }))
            },
          }).catch(() => null),
        ]
      : documents.map(async (d) => {
          return createDocument({
            repositoryId,
            file: d,
            token,
            signout,
            onProgress: (progress, name) => {
              setUploadState((s) => ({ ...s, [name]: progress }))
            },
          }).catch(() => null)
        })

    setNewDocuments([])
    const results = (await Promise.all(promises).catch((e) => {})) || []
    const errors = documents
      .map((d, i) => {
        if (!results[i]) {
          return `Error uploading ${d.name}`
        }
        return null
      })
      .filter((e) => e)
    if (errors?.length) {
      NotificationManager.error(errors.join('\n'))
    }
    setUploadState(null)
    setUpdateDocument(false)
    queryClient.invalidateQueries(['documents', repositoryId, token])
  }

  const createWebsite = async () => {
    const element = document.querySelector('#input-website')
    const website = element?.value
    const control = document.querySelector('#import-website')
    if (control) control.disabled = true
    if (!website) {
      NotificationManager.error('Please input a valid website')
      element?.focus()
      return false
    }
    const result = await (updateDocument
      ? updateDocumentAPI({
          documentId: updateDocument.id,
          alternative: {
            url: website,
          },
          token,
          signout,
          onProgress: (progress, name) => {
            setUploadState((s) => ({ ...s, [name]: progress }))
          },
        })
          .catch((e) => {})
          .finally(() => {
            if (control) control.disabled = false
          })
      : await createDocument({
          repositoryId,
          alternative: {
            url: website,
          },
          token,
          signout,
        })
          .catch((e) => {})
          .finally(() => {
            if (control) control.disabled = false
          }))

    if (result?.document_id || result?.ok) {
      queryClient.invalidateQueries(['documents', repositoryId, token])
      setShowWebImport(false)
      if (updateDocument) NotificationManager.success(t('Data updated'))
    } else {
      NotificationManager.error('Error creating document from website')
    }
  }

  const events = {
    onDragOver: (e) => {
      if (e?.dataTransfer?.items?.length) {
        _setShowOverlay(true)
      }
      e.preventDefault()
      e.stopPropagation()
    },

    onDrop: (e) => {
      e.preventDefault()
      e.stopPropagation()
      _setShowOverlay(false)
      if (e?.dataTransfer?.files?.length) {
        createDocuments([...e?.dataTransfer?.files])
      }
    },
    onDragEnd: () => _setShowOverlay(false),
    onDragLeave: () => setShowOverlay(false),
  }

  return (
    <>
      <BreadcrumbNav
        repositoryId={repository?.id}
        repositoryName={repository?.name}
      />
      <Row
        style={{ height: 'calc(100% - 60px)' }}
        className={`px-4 flex-column position-relative `}
        {...events}
      >
        <div
          className={`overlay-upload-documents d-inline-flex align-items-center ${
            showOverlay ? 'overlay-visible' : ''
          }`}
        >
          <GrDocumentText
            className="me-2 pe-none"
            size={40}
            style={{ filter: 'invert(1)' }}
          />
          {t('Drop your files to create new documents')}
        </div>
        <div>
          <Container>
            <Row>
              <Col
                className="d-flex align-items-center justify-content-between mt-4"
                style={{ minWidth: '150px' }}
                xl={12}
              >
                <span style={{ minWidth: '5vw' }}>
                  <Link to="/knowledge-base">
                    <span
                      className="breadcrumbs-nav-report p-2 looks-like-a-2"
                      style={{
                        fontWeight: 'bold',
                        border: '1px solid var(--nextbrain-main-color)',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        userSelect: 'none',
                      }}
                    >
                      <BsArrowLeft size={25} />
                    </span>
                  </Link>
                </span>
                <OverlayTrigger
                  rootClose={true}
                  trigger={'click'}
                  placement="auto"
                  delay={{ show: 200, hide: 0 }}
                  overlay={(props) => (
                    <Popover
                      {...props}
                      className={`popover-nextbrain-body ${
                        props?.className ?? ''
                      }`}
                      style={{
                        ...props.style,
                        minWidth: 'max(25vw, 300px)',
                      }}
                    >
                      <div style={{ width: 'max(25vw, 300px)' }}>
                        <AddDocumentNav
                          onClick={(type) => {
                            switch (type) {
                              case 'file':
                                setShowMenu(true)
                                break
                              case 'web':
                                setShowWebImport(true)
                                break
                              default:
                                NotificationManager.error('Unknown import')
                                break
                            }
                          }}
                        />
                      </div>
                    </Popover>
                  )}
                >
                  <Row className="project-new-template p-2 w-auto align-items-center justify-content-center">
                    <Col className="col-auto d-flex align-items-center">
                      <TiPlus className="me-1" size={25} />
                      {t('Add documents')}
                    </Col>
                  </Row>
                </OverlayTrigger>

                <span className="ms-3 action-button-2">
                  <Link to={`/knowledge-base/chat/${repositoryId}`}>
                    <Row
                      className="p-2 w-auto justify-content-center breadcrumbs-nav-report"
                      onClick={() => setShowMenu(true)}
                    >
                      <Col className="col-auto d-flex align-items-center">
                        <FaRocketchat className="me-2" size={25} />
                        {t('Chat with your docs')}
                      </Col>
                    </Row>
                  </Link>
                </span>
              </Col>
              <Col xs={12}>
                <TagManager
                  filters={filters}
                  repositoryId={repositoryId}
                  className="mt-5"
                  setFilters={setFilters}
                />
              </Col>
              <Col
                xs={12}
                className="my-3 d-flex justify-content-center"
                onClick={() => {}}
              >
                <Modal
                  size="lg"
                  show={showMenu}
                  onHide={() => setShowMenu(false)}
                >
                  <Modal.Header closeButton>
                    <span className="h3">
                      {t(updateDocument ? 'Update document' : 'Add documents')}
                    </span>
                  </Modal.Header>
                  <Modal.Body>
                    <Row
                      onDragOver={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onDrop={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                    >
                      <Col xs={12}>
                        <form
                          id="form-file-upload"
                          style={{ pointerEvents: 'all' }}
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <input
                            id="build_model_response"
                            accept=".pdf"
                            ref={inputRef}
                            type="file"
                            multiple={!updateDocument}
                            onChange={() => {
                              setNewDocuments((d) =>
                                updateDocument
                                  ? [...inputRef?.current?.files]
                                  : [...d, ...(inputRef?.current?.files ?? [])],
                              )
                            }}
                          />
                          <label
                            id="label-file-upload"
                            htmlFor="build_model_response"
                            className={`cursor-pointer p-3 `}
                            onDrop={(e) => {
                              if (e?.dataTransfer?.files?.length)
                                setNewDocuments((d) =>
                                  updateDocument
                                    ? [e?.dataTransfer?.files?.[0]]
                                    : [...d, ...(e?.dataTransfer?.files ?? [])],
                                )
                            }}
                          >
                            <div>
                              <p>
                                {t(
                                  updateDocument
                                    ? t(
                                        'Drag and drop your document for update or',
                                      )
                                    : t(
                                        'Drag and drop your documents files here or',
                                      ),
                                )}
                              </p>
                              <button
                                className="upload-button"
                                onClick={() => inputRef?.current?.click()}
                              >
                                {t('Upload a file')}
                              </button>
                              <p className="small text-secondary mt-3 ms-auto me-auto">
                                {t('Allowed formats:')}
                                <br />
                                {t('pdf')}, {t('xls')}, {t('doc')}, {t('csv')},{' '}
                                {t('json')}, {t('xml')}, ...
                              </p>
                            </div>
                          </label>
                        </form>
                      </Col>
                      <Col xs={12}>
                        <Row>
                          {newDocuments.map((d, i) => (
                            <Col key={i} xs={12}>
                              <Row className="d-flex justify-content-between align-items-center mt-2">
                                <Col
                                  className="d-inline-block text-truncate"
                                  xs={10}
                                >
                                  <span className="d-inline-flex flex-nowrap">
                                    <span className="d-inline-block text-truncate">
                                      {d.name}
                                    </span>
                                    <span className="ms-2 d-inline-block text-truncate">
                                      {Math.round(d.size / 1024, 0)}kb
                                    </span>
                                  </span>
                                </Col>
                                <Col
                                  className="d-flex justify-content-end "
                                  xs={2}
                                >
                                  <FaTrash
                                    className="icon-btn"
                                    size={25}
                                    onClick={() => {
                                      setNewDocuments((d) =>
                                        d.filter((_, index) => index !== i),
                                      )
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Row className="w-100">
                      <Col
                        className="d-inline-flex justify-content-end"
                        xs={12}
                      >
                        <Button
                          className="me-3 original empty-secondary"
                          variant="secondary"
                          onClick={() => setShowMenu(false)}
                        >
                          {t('Cancel')}
                        </Button>
                        <Button
                          onClick={() => {
                            createDocuments()
                            setShowMenu(false)
                          }}
                          disabled={newDocuments.length === 0}
                        >
                          {t(
                            updateDocument
                              ? 'Update document'
                              : 'Add documents',
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Footer>
                </Modal>
                <Modal
                  size="lg"
                  show={showWebImport}
                  onHide={() => setShowWebImport(false)}
                >
                  <Modal.Header closeButton>
                    <span className="h3">
                      {t(updateDocument ? 'Update website' : 'Add website')}
                    </span>
                  </Modal.Header>
                  <Modal.Body>
                    <Row
                      onDragOver={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onDrop={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                    >
                      <Col xs={12}>
                        <form
                          style={{ pointerEvents: 'all' }}
                          onSubmit={(e) => e.preventDefault()}
                          className="d-flex flex-column"
                        >
                          <label
                            htmlFor="build_model_response"
                            className={`cursor-pointer`}
                          >
                            {t('Website URL')}
                          </label>
                          {showWebImport && (
                            <Form.Control
                              id="input-website"
                              type="text"
                              className="nb-input w-100 mt-1"
                              placeholder="https://example.com.."
                              defaultValue={updateDocument?.metadata?.url}
                            />
                          )}
                        </form>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Row className="w-100">
                      <Col
                        className="d-inline-flex justify-content-end"
                        xs={12}
                      >
                        <Button
                          className="me-3 original empty-secondary"
                          variant="secondary"
                          onClick={() => setShowWebImport(false)}
                        >
                          {t('Cancel')}
                        </Button>
                        <Button
                          id="import-website"
                          onClick={async () => {
                            if (await createWebsite()) setShowWebImport(false)
                          }}
                        >
                          {t(updateDocument ? 'Update website' : 'Add website')}
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Footer>
                </Modal>
              </Col>
              <Col xs={12} className="mt-3">
                <Row className="mx-xl-5 mx-md-2 mx-0">
                  {isLoadingDocuments || !Array.isArray(documents) ? (
                    <Loading message={t('Loading documents')} />
                  ) : (
                    documents.map((d) => (
                      <Col
                        xl={2}
                        lg={3}
                        md={4}
                        xs={6}
                        key={d.id}
                        className={`${
                          filters?.length &&
                          !filters?.some((f) => d?.tags?.includes(f))
                            ? 'hidden-tag'
                            : ''
                        }`}
                      >
                        <div>
                          <Document
                            className="pt-1 mt-2 "
                            doc={d}
                            onDelete={async () => {
                              const r = await deleteDocument({
                                documentId: d.id,
                                token,
                                signout,
                              })
                              if (!r?.ok)
                                NotificationManager.error(
                                  'Error deleting document',
                                )
                              else {
                                queryClient.invalidateQueries([
                                  'documents',
                                  repositoryId,
                                  token,
                                ])
                              }
                            }}
                            onUpdate={() => {
                              setUpdateDocument(d)
                              if (d.type === 'web') setShowWebImport(true)
                              else setShowMenu(true)
                            }}
                          />
                        </div>
                      </Col>
                    ))
                  )}
                </Row>
              </Col>
              <Modal size="lg" show={!!uploadState} onHide={() => null}>
                <Modal.Header>
                  <span className="h3 loading-tooltip">
                    {t('Uploading documents')}
                  </span>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <UploadStatus status={uploadState} message={' '} />
                  </Row>
                </Modal.Body>
              </Modal>
            </Row>
          </Container>
        </div>
      </Row>
    </>
  )
}

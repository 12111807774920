import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Image,
  Modal,
  Tooltip,
  OverlayTrigger,
  Button,
  Form,
  Popover,
} from 'react-bootstrap'
import Connector from './Connector'
import { useTranslation } from 'react-i18next'
import { SiZapier } from 'react-icons/si'
import { MdHttp, MdPlayCircle } from 'react-icons/md'
import { FaRegCheckCircle } from 'react-icons/fa'
import { FcQuestions, FcComboChart } from 'react-icons/fc'
import { BsThreeDotsVertical, BsTrash, BsPencil } from 'react-icons/bs'
import PredictForDevelopers from '../predict-form/PredictForDevelopers'
import ZapierPrediction from '../predict-form/ZapierPrediction'
import { useAuth } from '../../providers/AuthProvider'
import { useQuery, useQueryClient } from 'react-query'
import { deleteRecipeById, getCustomApps } from '../../services/recipe'
import { useSearchParams } from 'react-router-dom'
import {
  EditModalRecipeStatus,
  ExecuteModalRecipeStatus,
} from '../recipe/RecipeStatus'
import { NotificationManager } from 'react-notifications'
import HelpTooltip from './HelpTooltip'
import { FaPlus } from 'react-icons/fa'
import { FaRocketchat, FaShop } from 'react-icons/fa6'
import { MarketplaceModel } from '../recipe/Marketplace'

const DISABLED_ZAPIER = process.env?.['REACT_APP_DISABLEZAPPIER'] === '1'

export default function Deploy({ model, setTabIndex = () => {}, ...props }) {
  const { token, signout } = useAuth()
  const { t } = useTranslation()
  const [showPredictModal, setShowPredictModal] = useState(0)
  const queryClient = useQueryClient()

  const { data: customApps } = useQuery(
    ['custom-apps', model?.id, token],
    async () => {
      const apps = await getCustomApps({ token, signout, model })
      if (Array.isArray(apps)) return apps.reverse()
      return []
    },
    { staleTime: Infinity },
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        Array.isArray(customApps) &&
        customApps.some((e) => e.status === 'generating-data')
      )
        queryClient.invalidateQueries(['custom-apps', model?.id, token])
    }, 5000)
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [customApps])

  const [searchParams, setSearchParams] = useSearchParams()
  const [filterApps, setFilterApps] = useState('')

  return (
    <>
      <Container>
        <Row className="my-2">
          <Col xs={12}>
            <h5 className="mt-5 text-white">{t('System apps')}</h5>
          </Col>
        </Row>
        <Row>
          <Connector
            xs={12}
            md={6}
            xl={4}
            name={t('Web predict')}
            description={t('Predict using a web interface')}
            icon=<Image src={'/LogoBlue.png'} width={65} />
            onClick={() => window.open(`/predict/${model.id}`, '_blank')}
            disabled={model?.status !== 'trained'}
            disabledMessage={t('Train your model first')}
          />
          <Connector
            xs={12}
            md={6}
            xl={4}
            name={t('Visual Web predict')}
            description={t('Visualize your prediction in a web interface')}
            icon=<Image src={'/what-if.svg'} width={35} />
            onClick={() =>
              window.open(`/predict-dynamic/${model.id}`, '_blank')
            }
            disabled={model?.status !== 'trained'}
            disabledMessage={t('Train your model first')}
          />
          <Connector
            xs={12}
            md={6}
            xl={4}
            name={t('Counterfactual analysis')}
            description={t('What do I need to change to get the result I want')}
            icon=<FcQuestions style={{ color: '#0F9D58', fontSize: 35 }} />
            onClick={() => window.open(`/what-if-tool/${model.id}`, '_blank')}
            disabled={model?.status !== 'trained'}
            disabledMessage={t('Train your model first')}
          />
          <Connector
            xs={12}
            md={6}
            xl={4}
            name={t('Causal inference')}
            description={t('Measure the impact of a change')}
            disabled={
              (model?.dataset?.cols ?? 0) <= 2 ||
              (model?.dataset?.cols ?? 0) >= 200
            }
            disabledMessage={t(
              'The dataset must have between 3 and 200 columns',
            )}
            icon=<Image src={'/CauseEffect.webp'} width={50} />
            onClick={() =>
              window.open(`/causal-inference/${model.id}`, '_blank')
            }
          />
          <Connector
            xs={12}
            md={6}
            xl={4}
            name={t('Multivariable forecast')}
            description={t('Forecast based on multiple columns')}
            icon=<FcComboChart style={{ color: '#0F9D58', fontSize: 35 }} />
            onClick={() => window.open(`/multiforecast/${model.id}`, '_blank')}
            disabled={
              !Object.entries(model?.dataset?.final_column_status ?? {}).some(
                ([, v], _, a) => v === 'Datetime' && a?.length > 2,
              )
            }
            disabledMessage={t(
              'This model does not have a datetime column and at least 2 other columns',
            )}
          />
          {/*<Connector
              xs={12}
              md={6}
              xl={4}
              name={t('Spreadsheet')}
              description={t('Predict data in bulk using an embeed spreadsheet')}
              icon=<SiGooglesheets style={{ color: '#0F9D58', fontSize: 35 }} />
              onClick={() =>
                window.open(`/predict-spreadsheet/${model.id}`, '_blank')
              }
            />*/}
          <Connector
            xs={12}
            md={6}
            xl={4}
            name={t('API predict')}
            description={t('Deploy your model in your code')}
            icon=<MdHttp style={{ color: '#0F9D58', fontSize: 35 }} />
            onClick={() => setShowPredictModal(1)}
            disabled={model?.status !== 'trained'}
            disabledMessage={t('Train your model first')}
          />
          {!DISABLED_ZAPIER ||
            (false && (
              <Connector
                xs={12}
                md={6}
                xl={4}
                name={t('Connect with zapier')}
                description={t('Create a connection with zapier')}
                icon=<SiZapier style={{ color: '#FF4A00', fontSize: 35 }} />
                onClick={() => setShowPredictModal(2)}
                disabled={model?.status !== 'trained'}
                disabledMessage={t('Train your model first')}
              />
            ))}
        </Row>
        <Row className="my-2 justify-content-between">
          <Col xs={'auto'}>
            <h5 className="mt-5 text-white d-inline-flex align-items-center">
              {t('User apps')}
            </h5>
            <HelpTooltip
              className="help-select-icon ms-2"
              message={t('explanation_custom_apps')}
            />
          </Col>
          <Col xs="auto" className="d-flex align-items-end">
            <span className="position-relative" style={{ maxHeight: '45px' }}>
              <Form.Control
                style={{
                  borderRadius: 8,
                  paddingLeft: '50px',
                }}
                className="nb-input py-2"
                placeholder={t('Search a custom app...')}
                autoFocus
                onChange={(e) => {
                  setFilterApps(e.target.value)
                }}
              />
              <i style={{ float: 'left' }} className="fas fa-search icon"></i>
            </span>
          </Col>
        </Row>
        <Row className="my-2">
          <Col className="my-3" xs={12} md={6} xl={4}>
            <OverlayTrigger
              rootClose={true}
              trigger={'click'}
              placement="top"
              delay={{ show: 200, hide: 0 }}
              overlay={(props) => (
                <Popover
                  {...props}
                  className={`popover-nextbrain-body popover-nextbrain-body-bottom  ${
                    props?.className ?? ''
                  }`}
                  style={{
                    ...props.style,
                    minWidth: 350,
                  }}
                >
                  <div style={{ width: 350 }}>
                    <Row className="px-4 py-4 position-relative">
                      <Col
                        xs={12}
                        className="d-inline-flex icon-btn"
                        onClick={() => {
                          searchParams.set('appMarketplace', 'show')
                          setSearchParams(searchParams)
                        }}
                      >
                        <h5 className="d-inline-flex">
                          <FaShop
                            className="me-2"
                            style={{ color: '#EC812F', fontSize: 28 }}
                          />
                          <span>{t('Import from marketplace')}</span>
                        </h5>
                      </Col>
                      <Col
                        className="d-inline-flex cursor-ponter icon-btn mt-4"
                        xs={12}
                        onClick={() => setTabIndex(4)}
                      >
                        <h5 className="d-inline-flex">
                          <FaRocketchat
                            className="me-2"
                            style={{ color: '#2CF1FA', fontSize: 28 }}
                          />
                          <span>{t('Create from chat')}</span>
                        </h5>
                      </Col>
                    </Row>
                  </div>
                </Popover>
              )}
            >
              <Row
                className="min-h-full connector mx-1"
                style={{
                  border: '4px dashed white',
                  minHeight: 118,
                }}
              >
                <Col className="dflex-center" xs={4}>
                  <FaPlus size={55} />
                </Col>
                <Col
                  className="d-flex align-items-center "
                  style={{ fontSize: '24px' }}
                  xs={8}
                >
                  <span className="ms-4">{t('Add new')}</span>
                </Col>
              </Row>
            </OverlayTrigger>
          </Col>
          <>
            {(Array.isArray(customApps) ? customApps : [])
              .filter(
                (a) =>
                  !filterApps ||
                  a.name.toLowerCase().includes(filterApps.toLowerCase()),
              )
              .map((app) => (
                <Connector
                  key={app.recipe_id}
                  dualLayout={true}
                  xs={12}
                  md={6}
                  xl={4}
                  name={app.name}
                  description={app.description}
                  icon={
                    <>
                      <div
                        className={`${
                          app.status === 'configured'
                            ? ''
                            : 'pe-none opacity-50'
                        }`}
                        onClick={(e) => {
                          e.stopPropagation()
                          searchParams.set('execute_appId', app.recipe_id)
                          setSearchParams(searchParams)
                        }}
                      >
                        <MdPlayCircle
                          color={
                            app.status === 'configured'
                              ? 'var(--nextbrain-decision-tree-green)'
                              : '#999'
                          }
                          size={50}
                          className="on-hover-scale on-active-scale"
                        />
                      </div>
                    </>
                  }
                  onClick={() => {
                    if (app.status === 'configured')
                      searchParams.set('execute_appId', app.recipe_id)
                    else searchParams.set('appId', app.recipe_id)
                    setSearchParams(searchParams)
                  }}
                  ContextMenu={
                    <OverlayTrigger
                      rootClose={true}
                      trigger={'click'}
                      placement="auto"
                      delay={{ show: 100, hide: 100 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          <Row
                            style={{ maxWidth: '150px' }}
                            className="p-2"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {app.status === 'configured' && (
                              <Col
                                className="d-flex justify-content-start align-items-center icon-btn mb-1"
                                xs={12}
                                onClick={() => {
                                  document.querySelector('body')?.click()
                                  searchParams.set(
                                    'execute_appId',
                                    app.recipe_id,
                                  )
                                  setSearchParams(searchParams)
                                }}
                              >
                                <MdPlayCircle style={{}} />{' '}
                                <span
                                  style={{
                                    marginLeft: '5px',
                                    fontSize: '.875rem',
                                  }}
                                >
                                  {t('Execute')}
                                </span>
                              </Col>
                            )}
                            <Col
                              className="d-flex justify-content-start align-items-center icon-btn my-2"
                              xs={12}
                              onClick={() => {
                                document.querySelector('body')?.click()
                                searchParams.set('appId', app.recipe_id)
                                setSearchParams(searchParams)
                              }}
                            >
                              <BsPencil style={{}} />{' '}
                              <span
                                style={{
                                  marginLeft: '5px',
                                  fontSize: '.875rem',
                                }}
                              >
                                {t('Edit')}
                              </span>
                            </Col>
                            <Col
                              className="d-flex justify-content-start align-items-center icon-btn mt-1"
                              xs={12}
                              onClick={() => {
                                document.querySelector('body')?.click()
                                NotificationManager.warning(
                                  <Row>
                                    <Col className="smallp" xs={12}>
                                      <span>
                                        {t(
                                          'Are you sure you want to delete {{name}}?',
                                          { name: app.name },
                                        )}
                                      </span>
                                    </Col>
                                    <Col xs={12} className="mt-2 d-flex">
                                      <Button
                                        className="original"
                                        variant="danger"
                                        onClick={() => {
                                          queryClient.setQueryData(
                                            ['custom-apps', model?.id, token],
                                            customApps.filter(
                                              (a) =>
                                                a.recipe_id !== app.recipe_id,
                                            ),
                                          )
                                          deleteRecipeById({
                                            modelId: model.id,
                                            recipeId: app.recipe_id,
                                            token,
                                            signout,
                                          }).then((r) => {
                                            if (!r?.ok) {
                                              NotificationManager.error(
                                                t('Error deleting app'),
                                              )
                                              queryClient.invalidateQueries([
                                                'custom-apps',
                                                model?.id,
                                                token,
                                              ])
                                            }
                                          })
                                        }}
                                      >
                                        {t('Confirm')}
                                      </Button>
                                    </Col>
                                  </Row>,
                                  null,
                                  10000,
                                )
                              }}
                            >
                              <BsTrash />
                              <span
                                style={{
                                  marginLeft: '5px',
                                  fontSize: '.875rem',
                                }}
                              >
                                {t('Delete app')}
                              </span>
                            </Col>
                          </Row>
                        </Tooltip>
                      )}
                    >
                      <span
                        onClick={(e) => {
                          document.querySelector('body')?.click()
                          e.stopPropagation()
                        }}
                        style={{
                          position: 'absolute',
                          top: '-10px',
                          right: '0px',
                          zIndex: '10',
                        }}
                        className="icon-btn"
                      >
                        <BsThreeDotsVertical size={28} />
                      </span>
                    </OverlayTrigger>
                  }
                >
                  {app?.scope && (
                    <Row className="align-items-center text-center">
                      <Col className="smallp" xs={12}>
                        {app.marketplace_id && (
                          <OverlayTrigger
                            rootClose={true}
                            trigger={['hover', 'focus']}
                            placement={'auto'}
                            delay={{ show: 100, hide: 200 }}
                            overlay={(props) => (
                              <Tooltip {...props}>
                                <span className="">
                                  {t('App installed from the marketplace')}
                                </span>
                              </Tooltip>
                            )}
                          >
                            <span>
                              <FaRegCheckCircle
                                size={20}
                                color={'var(--nextbrain-card-color)'}
                                className="me-2"
                              />
                            </span>
                          </OverlayTrigger>
                        )}
                        <u className="me-2">
                          {app.scope} {t('scope')}
                        </u>
                      </Col>
                    </Row>
                  )}
                </Connector>
              ))}
          </>
        </Row>
      </Container>
      <Modal
        show={showPredictModal}
        onHide={() => setShowPredictModal(0)}
        size={'xl'}
      >
        <Modal.Header closeButton>
          <h3>
            {showPredictModal === 1 ? t('API predict') : t('Zapier predict')}
          </h3>
        </Modal.Header>
        <Modal.Body>
          {showPredictModal === 1 && <PredictForDevelopers model={model} />}
          {showPredictModal === 2 && <ZapierPrediction model={model} />}
        </Modal.Body>
      </Modal>
      {searchParams.get('appId') && <EditModalRecipeStatus model={model} />}
      {searchParams.get('execute_appId') && (
        <ExecuteModalRecipeStatus model={model} />
      )}
      {searchParams.get('appMarketplace') && (
        <MarketplaceModel
          model={model}
          onHide={() => {
            searchParams.delete('appMarketplace')
            setSearchParams(searchParams)
          }}
        />
      )}
    </>
  )
}

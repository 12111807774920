import { request } from './base'

export async function getRecipeById({ token, modelId, signout, recipeId }) {
  let searchparams = []
  if (modelId) searchparams.push(`model_id=${modelId}`)
  const searchString = (searchparams.length ? '?' : '') + searchparams.join('&')

  return request({
    endpoint: `/recipe/recipe/${recipeId}${searchString}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function updateRecipeById({
  modelId,
  update,
  recipeId,
  token,
  signout,
}) {
  let searchparams = []
  if (modelId) searchparams.push(`model_id=${modelId}`)
  const searchString = (searchparams.length ? '?' : '') + searchparams.join('&')

  return request({
    endpoint: `/recipe/recipe/${recipeId}${searchString}`,
    method: 'POST',
    contentType: 'json',
    body: update,
    token,
    signout,
  })
}

export async function deleteRecipeById({ modelId, recipeId, token, signout }) {
  let searchparams = []
  if (modelId) searchparams.push(`model_id=${modelId}`)
  const searchString = (searchparams.length ? '?' : '') + searchparams.join('&')

  return request({
    endpoint: `/recipe/recipe/${recipeId}${searchString}`,
    method: 'DELETE',
    token,
    signout,
  })
}

export async function getCustomApps({ token, signout, model }) {
  let search = ''
  if (model)
    search = `?model_id=${model.id}&project_id=${model?.dataset?.project_id}`

  return request({
    endpoint: `/recipe/list${search}`,
    method: 'GET',
    contentType: 'json',
    token,
    signout,
  })
}

export async function executeCustomApp({
  token,
  signout,
  recipeId,
  modelId,
  keepResponse = true,
  generateInterpretation = true,
  sample = null,
  test = false,
}) {
  let sampleParam = sample !== null ? `&sample=${sample}` : ''
  let testParam = test ? `&test=true` : ''
  return request({
    endpoint: `/recipe/execute/${recipeId}?model_id=${encodeURIComponent(
      modelId,
    )}&keep_response=${keepResponse}&generate_interpretation=${generateInterpretation}${sampleParam}${testParam}`,
    method: 'POST',
    contentType: 'json',
    token,
    signout,
  })
}

export async function getRecipeExecutionFile({
  recipeId,
  timestamp,
  filename,
  token,
  signout,
}) {
  return request({
    endpoint: `/recipe/file/${recipeId}/${timestamp}?filename=${encodeURIComponent(
      filename,
    )}`,
    method: 'GET',
    rawResponse: true,
    token,
    signout,
  })
}

export async function getRecipeExecution({
  recipeId,
  timestamp,
  token,
  signout,
}) {
  return request({
    endpoint: `/recipe/executions/${recipeId}?timestamp=${timestamp}`,
    method: 'GET',
    token,
    signout,
  })
}

export async function getMarketplaceApps({
  query = '',
  limit = 100,
  page = 0,
  tags,
  token,
  signout,
}) {
  return request({
    endpoint: `/recipe/marketplace/list?query=${encodeURIComponent(
      query,
    )}&limit=${limit}&page=${page}`,
    body: {
      tags,
    },
    contentType: 'json',
    method: 'POST',
    token,
    signout,
  })
}

export async function getMarketplaceTags({ token, signout }) {
  return request({
    endpoint: `/recipe/marketplace/get-all-tags`,
    method: 'GET',
    token,
    signout,
  })
}

export async function installApp({ recipeId, modelId, scope, token, signout }) {
  return request({
    endpoint: `/recipe/marketplace/install/${recipeId}`,
    method: 'POST',
    body: {
      model_id: modelId,
      scope,
    },
    contentType: 'json',
    token,
    signout,
  })
}

export async function addRecipeParameters({
  recipeId,
  prompt,
  token,
  signout,
}) {
  return request({
    endpoint: `/recipe/add-parameters/${recipeId}?prompt=${encodeURIComponent(
      prompt,
    )}`,
    method: 'POST',
    token,
    signout,
  })
}

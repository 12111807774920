import React, { useEffect, useRef, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useQuery } from 'react-query'
import { Row, Col } from 'react-grid-system'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MdSend, MdOutlineWarning } from 'react-icons/md'
import { RiDashboardFill } from 'react-icons/ri'
import { BsFillChatRightDotsFill } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'

import CustomEditor from './CustomEditor'
import {
  enrichBotAnswer,
  generateModelContext,
  secureQueryNlp,
} from '../../services/nlp'

import GenericPredictForm from '../predict-form/predict-form'
import { useAuth } from '../../providers/AuthProvider'
import useConfetti from '../../hooks/UseConfetti'
import PredictForDevelopers from '../predict-form/PredictForDevelopers'
import ZapierPrediction from '../predict-form/ZapierPrediction'
import { round } from '../utils/formating'
import { modelIsLoading, modelIsTraining } from '../../util/models'
import TrainFlow from './train-flow/TrainFlow'
import StatusMessage from './StatusMessage'
import NBSpreadsheet from '../predict-form/Spreadsheet'
import { useModels } from '../../providers/ModelProvider'
import ExploreData from './ExploreData'
import ModelSummary from './model-summary/ModelSummary'
import TrainProgress from './TrainProgress'
import './model-content.css'
import { gptSpecificTask } from '../../services/model'
import OptimizeTab from '../mmm/OptimizeTab'
import MMMInsights from './model-summary/MMMInsights'
import { MMMProvideExport } from './model-summary/MMMProvideExport'
import { NotificationManager } from 'react-notifications'
import ProblemSuggestions from './new-model-assistant/train/ProblemSuggestions'
import ProgressTabs from './progress-tabs/ProgressTabs'
import { useNav } from '../../providers/NavProvider'
import Deploy from './Deploy'
import InsightsAutoml from './InsightsAutoml'
import InsightsSixSigma from './InsightsSixSigma'

const MAX_TAB_UNTRAINED_MODELS = 4
const DISABLED_ZAPIER = process.env?.['REACT_APP_DISABLEZAPPIER'] === '1'

const STEP_MAPPING = {
  //Which nav section to highlight in different tabs, defaults to tab number
  automl: {
    9: 2,
    10: 2,
  },
  mmm: {},
  inventory_management: {},
}

const STEPS_PER_MODE = {
  automl: [
    {
      name: 'My data',
      tab: 3,
    },
    {
      name: 'Explore',
      tab: 4,
    },
    {
      name: 'Train',
      tab: 5,
    },
    {
      name: 'Apps',
      tab: 8,
    },
    {
      name: 'Dashboard2',
      tab: 'dashboard',
    },
  ],
  mmm: [
    {
      name: 'My data',
      tab: 3,
    },
    {
      name: 'Explore',
      tab: 4,
    },
    {
      name: 'Insights',
      tab: 5,
    },
    {
      name: 'Optimization2',
      tab: 6,
    },
    {
      name: 'Planning',
      tab: 7,
    },
    {
      name: 'Dashboard2',
      tab: 'dashboard',
    },
  ],
  inventory_management: [],
  sixsigma: [
    {
      name: 'My data',
      tab: 3,
    },
    {
      name: 'Explore',
      tab: 4,
    },
    {
      name: 'Analyze',
      tab: 14,
    },
  ],
}

function ModelContent() {
  const { t } = useTranslation()
  let { signout, freeUser, token, llmDisabled } = useAuth()
  const { mode } = useNav()
  const steps = STEPS_PER_MODE[mode] ?? []
  const navigate = useNavigate()

  const {
    activeModel,
    train,
    searchParams,
    setSearchParams,
    updateModel,
    role,
  } = useModels()
  const readOnlyDashboard = role !== 'editor'
  const model = activeModel
  const modelInError = model.dataset.status === 'error'
  const modelInLoading = modelIsLoading(model)
  const modelInTraining = modelIsTraining(model)
  const modelInTrained = model.status === 'trained'

  const [tabIndex, setTabIndex] = useState(() => {
    const tab = Number.parseInt(searchParams.get('tab'))
    if (Number.isNaN(tab)) return 3
    const limit =
      model?.status === 'trained' || tab === 8 ? 9999 : MAX_TAB_UNTRAINED_MODELS
    return Math.min(limit, tab, 13)
  })

  useEffect(() => {
    searchParams.set('tab', tabIndex)
    if (Number.parseInt(tabIndex) === 3)
      searchParams.set('modelContentWidth', 'full')
    else if (Number.parseInt(tabIndex) === 4)
      searchParams.set('modelContentWidth', 'full')
    else searchParams.set('modelContentWidth', 'adjust')
    setSearchParams(searchParams)
    // eslint-disable-next-line
  }, [tabIndex])

  const [contextHidden, setContextHidden] = useState(true)
  const [contextValue, setContextValue] = useState('')
  const [askModal, setAskModal] = useState(null)
  const [currentTrainingConfig, setCurrentTrainingConfig] = useState(null)
  const [dashboardAnimate, setDashboardAnimate] = useState(null)
  const { getConfetti, confetti } = useConfetti()

  const [botMsgs, setBotMsgs] = useState([])
  const questionRef = useRef()
  const contextRef = useRef()
  const [syntheticEnabled, setSyntheticEnabled] = useState(false)
  const [balanceDataEnabled, setBalanceDataEnabled] = useState(false)
  const [removeOutliersEnabled, setRemoveOutliersEnabled] = useState(true)
  const [advancedOpen, setAdvancedOpen] = useState(false)
  const [expand, setExpand] = useState(false)

  const { data: problems, isLoading: problemsIsLoading } = useQuery(
    ['gpt-specific-task-problems_selected', activeModel?.id],
    async () => {
      if (!activeModel) return []

      const res = await gptSpecificTask({
        modelId: activeModel?.id,
        taskType: 'selected_problems_to_solve',
        token,
        signout,
      })
      return res
    },
    {
      staleTime: Infinity,
    },
  )

  const isTraining = modelIsTraining(model)
  const showOptimizeMMMTab =
    model?.status === 'trained' && model?.special_model_type === 'mmm'

  useEffect(() => {
    if (model && isTraining && tabIndex > 5 && tabIndex !== 14) setTabIndex(5)

    try {
      setContextValue(generateModelContext(model))
    } catch (e) {
      console.error('Cannot set model context', e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model])

  useEffect(() => {
    if (model && tabIndex === 3 && model.showConfetti) {
      getConfetti(3000, () => (model.showConfetti = false), {
        style: { width: '100%', height: '1000px' },
      })
      return () => {
        model.showConfetti = false
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, tabIndex])

  useEffect(() => {
    if (dashboardAnimate) setTimeout(() => setDashboardAnimate(false), 2000)
  }, [dashboardAnimate])

  if (!model) {
    return <></>
  }

  const validColumns =
    model &&
    model.status === 'trained' &&
    model.dataset &&
    model.dataset.columns_order
      ? model.dataset.columns_order.filter(
          (column) =>
            column in model.columns_active &&
            column in model.dataset.statistics &&
            column !== model.target,
        )
      : []

  const Confetti = () => {
    return model.showConfetti ? confetti : <></>
  }

  const askAssistantBot = () => {
    const value = questionRef.current.value
    if (value.length === 0) return
    questionRef.current.value = ''
    setBotMsgs((prev) => {
      return prev.concat({
        owner: t('You'),
        text: value,
      })
    })
    secureQueryNlp(
      {
        inputs: {
          question: value,
          context: contextRef.current.value,
        },
      },
      token,
      signout,
    ).then((response) => {
      setBotMsgs((prev) => {
        return prev.concat({
          owner: 'ChatGPT',
          text: enrichBotAnswer(value, response.answer, model),
          score: response.score,
        })
      })
    })
  }

  const dashboardFlair = () => {
    window.scroll(0, 0)
    setDashboardAnimate(true)
  }
  const switchSynthetic = () => {
    if (!syntheticEnabled) setAdvancedOpen(true)
    setSyntheticEnabled(!syntheticEnabled)
  }

  const switchBalanceData = () => {
    if (!balanceDataEnabled) setAdvancedOpen(true)
    setBalanceDataEnabled((x) => !x)
  }

  const switchRemoveOutliers = () => {
    if (!removeOutliersEnabled) setAdvancedOpen(true)
    setRemoveOutliersEnabled((x) => !x)
  }

  const tabStatus = [
    false, //Matilde
    false, //Problem description
    true, // My data
    false, //Train
    false,
    false, // Insighhts mmm
    !showOptimizeMMMTab, //Optimization
    !showOptimizeMMMTab,
    false,
    model.status !== 'trained' ||
      (validColumns.length <= 2 &&
        model.problem_type === 'time_series_regression'),
    model.status !== 'trained',
    model.status !== 'trained',
    model.status !== 'trained',
    model.status !== 'trained',
    false, // Analyze six sigma
  ]

  const currentStep = steps.findIndex(({ tab }) => tab === tabIndex)

  return (
    <>
      <Row
        className="progress-tabs-container me-0"
        style={{
          marginLeft:
            searchParams.get('modelContentWidth') === 'full' ? '10px' : 0,
        }}
      >
        <Col
          className={`py-1 mb-2 tabs-model-status-${model?.status} ${
            model?.dirty ? 'model-dirty' : ''
          }`}
          xs={12}
        >
          <ProgressTabs
            steps={steps.map(({ name }) => name)}
            currentStep={STEP_MAPPING[mode]?.[tabIndex] ?? currentStep}
            disabled={steps.map(({ tab }) => {
              if (tab === 'dashboard') return model.status !== 'trained'
              return tabStatus[tab]
            })}
            setStep={(i) => {
              const tab = steps[i]?.tab
              if (tab === 'dashboard') {
                model.status === 'trained' &&
                  navigate(
                    `/model/widget/${model?.id}?readOnly=${readOnlyDashboard}`,
                  )
              } else if (!tabStatus[tab]) setTabIndex(tab ?? 0)
            }}
            onClick={() =>
              document.querySelector('.model-content')?.scrollTo(0, 0)
            }
          />
        </Col>
      </Row>
      <Confetti />
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index) => {
          return tabIndex <= 6 || index < 6 ? setTabIndex(index) : null
        }}
      >
        <Row className="tabsdiv justify-content-center w-100">
          <Col className="d-none" style={{ maxWidth: 'calc(100% - 200px)' }}>
            <TabList className="ps-0">
              <Tab disabled={tabStatus[0]} hidden>
                <BsFillChatRightDotsFill /> ChatGPT
              </Tab>
              <Tab disabled={tabStatus[1]} style={{ marginLeft: '-1px' }}>
                {t('Problem description')}
              </Tab>
              <Tab disabled={tabStatus[2]}>{t('Import data')}</Tab>
              <Tab disabled={tabStatus[3]}>{t('My data')}</Tab>
              <Tab disabled={tabStatus[4]}>
                <span className="tab-mmm">{t('Insights')}</span>
              </Tab>
              <Tab disabled={tabStatus[5]}>{t('Train model')}</Tab>
              <Tab
                hidden={model?.special_model_type !== 'mmm'}
                disabled={tabStatus[6]}
              >
                <span className="tab-mmm">{t('Insights')}</span>
              </Tab>
              <Tab
                hidden={model?.special_model_type !== 'mmm'}
                disabled={tabStatus[7]}
              >
                <span className="tab-mmm">{t('Planning')}</span>
              </Tab>
              <Tab
                hidden={model?.special_model_type === 'mmm'}
                disabled={tabStatus[8]}
                className={` react-tabs__tab ${
                  tabIndex >= 6 ? 'react-tabs__tab--selected' : ''
                }`}
              >
                Predict
              </Tab>
              <Tab className="d-none" disabled={tabStatus[9]}>
                <div id="tabIdHelp3">{t('Predict Batch')}</div>
              </Tab>
              <Tab className="d-none" disabled={tabStatus[1]}>
                {t('Spreadsheet prediction')}
              </Tab>
              <Tab className="d-none" disabled={tabStatus[11]}>
                {t('Predict with API')}
              </Tab>
              <Tab className="d-none" disabled={tabStatus[12]}>
                {t('Ask me')}
              </Tab>
              <Tab className="d-none" disabled={tabStatus[13]}></Tab>
              <Tab disabled={tabStatus[14]}>{t('Analyze')}</Tab>
            </TabList>
          </Col>
          <Col
            className="d-flex d-none align-items-end"
            style={{ maxWidth: '200px' }}
            md={3}
          >
            {freeUser ? (
              <span style={{ marginBottom: '20px', marginLeft: '60px' }}>
                <Button
                  className={`button-dash original ${
                    dashboardAnimate ? 'animated-dash-button' : ''
                  }`}
                  variant="warning"
                  disabled={model?.status !== 'trained'}
                  onClick={() =>
                    NotificationManager.warning(
                      t('Upgrade your plan to get access to this feature'),
                    )
                  }
                >
                  <span className="d-flex align-items-center">
                    {t('DashboardStd')}{' '}
                    <RiDashboardFill size={25} className="ms-3" />
                  </span>
                </Button>
              </span>
            ) : (
              <Link
                style={{ marginBottom: '20px', marginLeft: '60px' }}
                className={`hover-link report-link ${
                  model?.status === 'trained' ? '' : 'disabled'
                }`}
                to={`/model/widget/${model?.id}?readOnly=${readOnlyDashboard}`}
              >
                <Button
                  className={`button-dash ${
                    dashboardAnimate ? 'animated-dash-button' : ''
                  }`}
                  disabled={model?.status !== 'trained'}
                >
                  <span className="d-flex align-items-center">
                    {t('DashboardStd')}{' '}
                    <RiDashboardFill size={25} className="ms-3" />
                  </span>
                </Button>
              </Link>
            )}
          </Col>
        </Row>
        <div>
          <div className="model-content-children w-100">
            <Row id="tabpaneldiv" className="tabpaneldiv model-content-tabs">
              <Col md={12} className="nopaddingright">
                <TabPanel></TabPanel>
                <TabPanel>
                  <CustomEditor
                    model={model}
                    token={token}
                    signout={signout}
                    enabledLLMFeatures={!llmDisabled}
                  />
                </TabPanel>
                <TabPanel>
                  <></>
                </TabPanel>
                <TabPanel>
                  <ExploreData />
                </TabPanel>
                <TabPanel>
                  <InsightsAutoml model={model} updateModel={updateModel} />
                </TabPanel>
                <TabPanel>
                  <Row>
                    {!problemsIsLoading && Array.isArray(problems) && (
                      <Col className="mb-3 px-0" xs={12}>
                        <ProblemSuggestions
                          defaultOpen={model?.status !== 'trained'}
                          currentTrainingConfig={currentTrainingConfig}
                          className="w-100 py-2 mx-0"
                        />
                      </Col>
                    )}
                    <Col xs={12} className="mb-4">
                      {modelInError && (
                        <StatusMessage
                          className="ms-3"
                          showLoad={false}
                          history={[{ message: t('Model dataset is corrupt') }]}
                        />
                      )}
                      {!modelInError && modelInLoading && (
                        <StatusMessage
                          className="ms-3"
                          history={[{ message: t('Loading model') }]}
                        />
                      )}
                      {!modelInError && !modelInLoading && (
                        <>
                          <TrainFlow
                            className="my-2"
                            onConfigChanged={(v) => setCurrentTrainingConfig(v)}
                            onTrain={(...props) => train(activeModel, ...props)}
                            syntheticEnabled={syntheticEnabled}
                            balanceDataEnabled={balanceDataEnabled}
                            removeOutliersEnabled={removeOutliersEnabled}
                            switchSynthetic={switchSynthetic}
                            switchBalanceData={switchBalanceData}
                            switchRemoveOutliers={switchRemoveOutliers}
                            advancedOpen={advancedOpen}
                            setAdvancedOpen={setAdvancedOpen}
                            expand={expand}
                            setExpand={() => {
                              setExpand((e) => !e)
                              setTimeout(() => {
                                window.dispatchEvent(new Event('resize'))
                              }, 500)
                            }}
                          />
                        </>
                      )}
                    </Col>
                    <Col xs={12}>
                      <Row>
                        {modelInTraining && (
                          <>
                            <TrainProgress />
                            {model?.problem_type ===
                              'time_series_regression' && (
                              <Col className="dflex-center mt-2" xs={12}>
                                <MdOutlineWarning
                                  size={30}
                                  className="me-1"
                                  color="var(--nextbrain-warning-color)"
                                />
                                {t(
                                  'Training a forecast model might take a few minutes.',
                                )}
                              </Col>
                            )}
                          </>
                        )}
                        {modelInTrained && (
                          <ModelSummary
                            currentTrainingConfig={currentTrainingConfig}
                            switchTabs={setTabIndex}
                            expand={expand}
                            switchSynthetic={switchSynthetic}
                            switchBalanceData={switchBalanceData}
                            syntheticEnabled={syntheticEnabled}
                            balanceDataEnabled={balanceDataEnabled}
                            switchRemoveOutliers={switchRemoveOutliers}
                            removeOutliersEnabled={removeOutliersEnabled}
                            dashboardFlair={dashboardFlair}
                            className={`mt-md-5 mt-xl-0`}
                            xs={12}
                          ></ModelSummary>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Modal
                    size={'lg'}
                    show={askModal}
                    onHide={() => setAskModal(null)}
                  >
                    {askModal && askModal.header ? (
                      <Modal.Header closeButton>{askModal.header}</Modal.Header>
                    ) : (
                      <></>
                    )}
                    {askModal && askModal.body ? (
                      <Modal.Body>{askModal.body}</Modal.Body>
                    ) : (
                      <></>
                    )}
                    {askModal && askModal.footer ? (
                      <Modal.Footer>{askModal.footer}</Modal.Footer>
                    ) : (
                      <></>
                    )}
                  </Modal>
                </TabPanel>
                <TabPanel>
                  <MMMProvideExport model={model}>
                    <MMMInsights
                      activeModel={model}
                      currentTrainingConfig={currentTrainingConfig}
                    />
                  </MMMProvideExport>
                </TabPanel>
                <TabPanel>
                  <OptimizeTab model={model} className="mt-2 mx-2" />
                </TabPanel>
                <TabPanel>
                  <Deploy model={model} setTabIndex={setTabIndex} />
                </TabPanel>
                <TabPanel>
                  <GenericPredictForm model={model} batchPredict={true} />
                </TabPanel>
                <TabPanel>
                  <Row>
                    <Col md={12}>
                      <NBSpreadsheet model={model} />
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel>
                  <PredictForDevelopers model={model} />
                </TabPanel>
                <TabPanel>{!DISABLED_ZAPIER && <ZapierPrediction />}</TabPanel>
                <TabPanel>
                  <Row
                    className="chat mx-3"
                    style={{
                      marginTop: -30,
                      maxHeight: 300,
                      overflow: 'auto',
                    }}
                  >
                    {botMsgs.length ? (
                      botMsgs.map((msg, i) => (
                        <Col key={i} md={12} className="chat-msg mt-1">
                          <span>
                            <strong>{msg.owner}</strong>
                            {msg.score
                              ? ` (score: ${round(msg.score, 3)})`
                              : ''}
                          </span>
                          <br />
                          <span>{msg.text}</span>
                        </Col>
                      ))
                    ) : (
                      <Col align="center" md={12}>
                        <p className="secondary py-3">
                          {t(
                            'There is no message yet, ask whatever you want about your model...',
                          )}
                        </p>
                      </Col>
                    )}
                  </Row>
                  <Row className="mt-3 mx-3">
                    <Col md={12}>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault()
                          askAssistantBot()
                        }}
                      >
                        <Form.Control
                          ref={questionRef}
                          type="text"
                          placeholder={t(
                            'Ask the assistant bot anything about your model ...',
                          )}
                          className="rounded-border no-border input-chat py-2"
                        />
                        <MdSend
                          className="btn-send-chat"
                          onClick={askAssistantBot}
                        />
                      </Form>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <button
                        className="linkshare link"
                        onClick={() => setContextHidden((prev) => !prev)}
                      >
                        {t('Context (just to debug)')}
                      </button>
                    </Col>
                    <Col md={12} hidden={contextHidden}>
                      <textarea
                        ref={contextRef}
                        onChange={() =>
                          setContextValue(contextRef.current.value)
                        }
                        className="form-control"
                        rows="10"
                        value={contextValue}
                      />
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel>
                  <Row>
                    <Col md={12}>
                      <InsightsSixSigma
                        model={model}
                        updateModel={updateModel}
                      />
                    </Col>
                    {modelInTraining ? (
                      <Col xs={12}>
                        <Row>
                          <TrainProgress />
                        </Row>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                </TabPanel>
              </Col>
            </Row>
          </div>
        </div>
      </Tabs>
    </>
  )
}

export default ModelContent

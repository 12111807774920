import React, { useState, useEffect } from 'react'
import { Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'
import packageJson from '../package.json'
import moment from 'moment'
import { useQuery } from 'react-query'
import { config } from './Constants'
import { formatDate } from './util/other'
import { defaultFormat } from './component/utils/formating'
import { useTranslation } from 'react-i18next'
import { VscDebugDisconnect } from 'react-icons/vsc'
import { useAuth } from './providers/AuthProvider'

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate)
  const momCurrentDateTime = moment(currentDate)

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true
  } else {
    return false
  }
}

function EmbLine({ values, width, height }) {
  if (values?.length === 1) values.push(values[0])
  const wbase = width / (values.length || 1)
  const hmin = values?.length ? Math.min(...values) : 0
  const hmax = values?.length ? Math.max(...values) : 1
  const path =
    values?.reduce((acc, v, i) => {
      const x = Number.parseInt(i * wbase)
      const y = Number.parseInt(height - ((v - hmin) / (hmax || 1)) * height)
      return acc + (i ? ` L ${x} ${y}` : `M ${0} ${y}`)
    }, '') ?? ''

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      role="img"
    >
      {
        <path
          d={path}
          stroke="var(--nextbrain-tables-graph-bar-color)"
          fill="transparent"
        />
      }
    </svg>
  )
}

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const { t } = useTranslation()
    const { user } = useAuth()
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [build, setBuild] = useState({})
    const [connected, setConnected] = useState(true)

    useEffect(() => {
      const iv = setInterval(() => setRefresh((r) => !r), 20000)
      return () => clearInterval(iv)
    }, [])

    const { data } = useQuery(
      'version',
      async () => {
        const response = await fetch(config.API_URL, { cache: 'no-store' })
        const data = await response.json()
        return data
      },
      {
        refetchInterval: 10000,
      },
    )

    const [usage, setUsage] = useState({
      cpu: [],
      memory: [],
    })

    useEffect(() => {
      if (data) {
        const cpu = Math.min(1, data?.usage?.cpu_usage ?? 0)
        const memory = Math.min(1, data?.usage?.memory_usage ?? 0)
        setUsage((u) => ({
          cpu: [...u.cpu, cpu].slice(-5),
          memory: [...u.memory, memory].slice(-5),
        }))
      }
    }, [data])

    useEffect(() => {
      fetch('/meta.json', { cache: 'no-store' })
        .then((response) => {
          try {
            return response.json()
          } catch (e) {
            return { buildDate: Date.now() }
          }
        })
        .then((meta) => {
          const latestVersionDate = meta.buildDate
          const currentVersionDate = packageJson.buildDate
          setBuild((b) => ({ ...b, date: new Date(latestVersionDate) }))

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate,
          )
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false)
            refreshCacheAndReload()
          } else {
            setIsLatestBuildDate(true)
          }
          setConnected(true)
        })
        .catch((e) => setConnected(false))
    }, [refresh])

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name)
          }
        })
      }
      // delete browser cache and hard reload
      window.location.reload(true)
    }

    let isTrial = false

    for (let company of user?.company ? [user?.company] : []) {
      if (company?.subscription?.payment_type === 'trial') {
        isTrial = true
      } else if (company?.subscription?.payment_status === 'paid') {
        isTrial = false
        break
      }
    }

    const cpu = Math.min(100, (data?.usage?.cpu_usage ?? 0) * 100)
    const memory = Math.min(100, (data?.usage?.memory_usage ?? 0) * 100)
    return (
      <React.Fragment>
        <div
          className={`build-release ${isTrial ? 'trial-build-release' : ''}`}
        >
          <Row className="position-relative">
            <Col
              className="ps-0 d-flex justify-content-end"
              style={{ minWidth: '50px', maxWidth: '50px' }}
              xs={12}
            >
              {connected ? (
                <Row className="d-inline-flex flex-nowrap">
                  <Col className="ps-0" xs={6}>
                    <Row>
                      <Col
                        className="ps-0 d-flex justify-content-end text-nowrap"
                        xs={12}
                      >
                        CPU: {defaultFormat({ num: cpu })}%
                      </Col>
                      <Col
                        className="ps-0 d-flex justify-content-end mt-1 text-nowrap"
                        xs={12}
                      >
                        RAM: {defaultFormat({ num: memory })}%
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={4}>
                    <Row>
                      <Col
                        className="box-usage px-0"
                        style={{ minHeight: 15 }}
                        xs={12}
                      >
                        <EmbLine values={usage.cpu} width={30} height={15} />
                      </Col>
                      <Col
                        className="box-usage px-0 mt-1"
                        style={{ minHeight: 15 }}
                        xs={12}
                      >
                        <EmbLine values={usage.memory} width={30} height={15} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <OverlayTrigger
                  rootClose={true}
                  trigger={['hover', 'focus']}
                  placement="left"
                  delay={{ show: 200, hide: 0 }}
                  overlay={(props) => (
                    <Tooltip
                      {...props}
                      className={`context-menu-column-type big-menu pe-none ${
                        props?.className ?? ''
                      }`}
                    >
                      {t(
                        'Connection to the server was lost, attempting to reconnect....',
                      )}
                    </Tooltip>
                  )}
                >
                  <div className="connection-loss-autogen-chat">
                    <VscDebugDisconnect
                      size={30}
                      color={'var(--nextbrain-tables-negative-graph-bar-color)'}
                    />
                  </div>
                </OverlayTrigger>
              )}
              <Row>
                <Col className="px-0 text-center" xs={6}></Col>
                <Col className="px-0 text-center" xs={6}></Col>
              </Row>
            </Col>
            <Col
              className="pe-none"
              xs={7}
              style={{
                minWidth: 'calc(100% - 50px)',
                maxWidth: 'calc(100% - 50px)',
              }}
            >
              <Row>
                <Col className="text-center" xs={12}>
                  {data?.version}
                </Col>
                <Col className="text-center" xs={12}>
                  {build?.date ? formatDate(build?.date) : ''}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {isLatestBuildDate ? <Component {...props} /> : null}
      </React.Fragment>
    )
  }

  return ClearCacheComponent
}

export default withClearCache

import React, { useState, useEffect, useMemo } from 'react'
import {
  Col,
  Row,
  Modal,
  Form,
  Container,
  OverlayTrigger,
  Tooltip,
  Popover,
} from 'react-bootstrap'
import { useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { TiPlus } from 'react-icons/ti'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { NotificationManager } from 'react-notifications'

import { useAuth } from '../../providers/AuthProvider'
import {
  deleteIntegration,
  getIntegrations,
  updateIntegration,
} from '../../services/integration'

import Loading from '../loading/LoadingSmall'
import DataTable from 'react-data-table-component'
import { InlineEdit } from '../inline-edit/InlineEdit'
import { FaBolt, FaCopy, FaEdit, FaTrash } from 'react-icons/fa'
import ConfigIntegration from './ConfigIntegration'
import { MdOutlineMenuBook } from 'react-icons/md'
import './Integrations.css'
import BreadcrumbNav from '../header/BreadcrumbNav'
import { useNav } from '../../providers/NavProvider'

const customStyles = {
  responsiveWrapper: {
    style: {
      borderRadius: '20px',
      border: 'solid var(--nextbrain-secondary-border-color) 1px !important',
    },
  },
  tableWrapper: {
    style: {
      borderRadius: '20px',
    },
  },
  table: {
    style: {
      padding: '0px', // override the cell padding for head cells
      backgroundColor: 'var(--nextbrain-background)',
      color: 'var(--nextbrain-white-font)',
      borderRadius: '20px',
    },
  },
  noData: {
    style: {
      color: 'var(--nextbrain-white-font)',
    },
  },
  pagination: {
    style: {
      backgroundColor: 'var(--nextbrain-body)',
      color: 'var(--nextbrain-white-font)',
      fill: 'white',
    },
  },
  rows: {
    style: {
      minHeight: '30px',
      backgroundColor: 'var(--nextbrain-body)',
      color: 'var(--nextbrain-white-font)',
      borderBottom:
        'solid var(--nextbrain-secondary-border-color) 1px !important',
    },
    stripedStyle: {
      backgroundColor: 'var(--nextbrain-body)',
      color: 'var(--nextbrain-white-font)',
      borderBottom:
        'solid var(--nextbrain-secondary-border-color) 1px !important',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      width: 'max-content',
      //fontWeight: '600',
      fontSize: '16px',
      //border: 'solid var(--nextbrain-secondary-border-color) 1px',
      color: 'var(--nextbrain-white-font)',
      backgroundColor: 'var(--nextbrain-secondary-color)',
    },
  },

  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      fontSize: '14px',
      fontWeight: '500',
      //border: 'solid var(--nextbrain-secondary-border-color) 1px',
      marginBottom: '0',
      width: 'max-content',
    },
  },
}

export default function Integrations() {
  const { token, signout, user } = useAuth()
  const { t } = useTranslation()
  const [menu, setMenu] = useState(null)
  const queryClient = useQueryClient()
  const { mode } = useNav()

  const { data: _integrations, isError } = useQuery(
    ['integrations', token],
    async () => {
      const integrations = await getIntegrations({
        token,
        signout,
      })
      return integrations
    },
    { staleTime: Infinity },
  )

  const integrations = useMemo(() => {
    if (mode === 'rag') {
      return _integrations?.filter((i) => i.type === 'rag') ?? []
    } else if (mode) {
      return _integrations?.filter((i) => i.type === 'app') ?? []
    }
    return _integrations
  }, [mode, _integrations])

  useEffect(() => {
    if (isError) NotificationManager.error('Error fetching integrations')
  }, [isError])

  const tableConfig = [
    {
      name: t('Enabled'),
      selector: (integration) => integration.active,
      cell: (integration) => {
        return (
          <Form.Check
            type="switch"
            className="form-switch-share"
            checked={integration.active}
            onChange={(e) => {
              updateIntegration({
                integrationId: integration.integration_id,
                data: { active: e.target.checked },
                token,
                signout,
              })
                .then((r) => {
                  if (!r?.integration_id)
                    NotificationManager.error(t('Failed to update integration'))
                })
                .finally((f) => {
                  queryClient.invalidateQueries(['integrations', token])
                })
            }}
          />
        )
      },
      sortable: true,
      width: '100px',
      conditionalCellStyles: [
        {
          when: (row) => true,
          style: {
            textAlign: 'center',
          },
        },
      ],
    },
    {
      name: t('Name'),
      selector: (integration) => integration.name,
      sortable: true,
      cell: (integration) => (
        <>
          <InlineEdit
            text={integration.name}
            onEdit={(text) => {
              updateIntegration({
                integrationId: integration.integration_id,
                data: { name: text },
                token,
                signout,
              })
                .then((r) => {
                  if (!r?.integration_id)
                    NotificationManager.error(t('Failed to update integration'))
                })
                .finally((f) => {
                  queryClient.invalidateQueries(['integrations', token])
                })
            }}
          />
        </>
      ),
    },
    {
      name: t('Scope'),
      cell: (integration) => (
        <div style={{ minWidth: '130px' }} className="py-1">
          <Form.Select
            defaultValue={integration.scope}
            className="nb-input"
            onChange={(e) => {
              const newScope = e.target.value
              updateIntegration({
                integrationId: integration.integration_id,
                data: { scope: newScope },
                token,
                signout,
              })
                .then((r) => {
                  if (!r?.integration_id)
                    NotificationManager.error(t('Failed to update integration'))
                })
                .finally((f) => {
                  queryClient.invalidateQueries(['integrations', token])
                })
            }}
          >
            <option value="user">User</option>
            <option value="company">Company</option>
          </Form.Select>
        </div>
      ),
      sortable: false,
      width: '150px',
    },
    mode
      ? null
      : {
          name: t('Type'),
          selector: (integration) => integration?.type ?? 'App',
          cell: (integration) => integration?.type ?? 'App',
          sortable: true,
          width: '100px',
          conditionalCellStyles: [
            {
              when: (row) => true,
              style: {
                textAlign: 'center',
              },
            },
          ],
        },
    {
      name: t('Created'),
      selector: (integration) => integration.created_at,
      cell: (integration) => {
        const created = new Date(integration.created_at * 1000)
        return `${created.getDate()} ${created.toLocaleString('default', {
          month: 'short',
        })} ${created.getFullYear()}`
      },
      sortable: true,
      width: '100px',
      conditionalCellStyles: [
        {
          when: (row) => true,
          style: {
            textAlign: 'center',
          },
        },
      ],
    },
    {
      id: 'Updated',
      name: t('Updated'),
      selector: (integration) => integration.updated_at,
      cell: (integration) => {
        const updated = new Date(integration.updated_at * 1000)
        return `${updated.getDate()} ${updated.toLocaleString('default', {
          month: 'short',
        })} ${updated.getFullYear()}`
      },
      sortable: true,
      width: '100px',
      conditionalCellStyles: [
        {
          when: (row) => true,
          style: {
            textAlign: 'center',
          },
        },
      ],
    },
    {
      name: t('Executions'),
      selector: (row) => row?.executions ?? 0,
      sortable: true,
      width: '120px',
    },
    {
      name: '',
      selector: (integration) => integration.scope,
      sortable: true,
      width: '40px',
      cell: (integration) => (
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="left"
          delay={{ show: 250, hide: 100 }}
          overlay={(props) => (
            <Tooltip
              {...props}
              className={`context-menu-column-type  ${props?.className ?? ''}`}
            >
              <Row className="p-2">
                <Col
                  className="d-flex justify-content-start align-items-center cursor-pointer icon-btn"
                  xs={12}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    addMenu(null, integration)
                    document.querySelector('body').click()
                  }}
                >
                  <FaEdit size={20} className="me-2" /> {t('Edit')}
                </Col>
                <Col
                  className="d-flex justify-content-start align-items-center cursor-pointer icon-btn mt-3"
                  xs={12}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    NotificationManager.warning('Comming soon')
                    document.querySelector('body').click()
                  }}
                >
                  <FaCopy size={20} className="me-2" /> {t('Duplicate')}
                </Col>
                <Col
                  className="d-flex justify-content-start align-items-center mt-3 cursor-pointer icon-btn"
                  xs={12}
                  onClick={() => {
                    if (integration.user_id !== user?.id) {
                      NotificationManager.error(
                        t('You are not allowed to delete this integration'),
                      )
                      return
                    }
                    document.querySelector('body')?.click()
                    deleteIntegration({
                      integrationId: integration.integration_id,
                      token,
                      signout,
                    })
                      .then((r) => {
                        if (!r?.ok)
                          NotificationManager.error(
                            t('Failed to delete integration'),
                          )
                      })
                      .finally(() => {
                        queryClient.invalidateQueries(['integrations', token])
                      })
                  }}
                >
                  <FaTrash size={20} className="me-2" /> {t('Delete')}
                </Col>
              </Row>
            </Tooltip>
          )}
        >
          <span className="icon-btn">
            <BsThreeDotsVertical size={20} />
          </span>
        </OverlayTrigger>
      ),
    },
  ].filter((v) => v)

  const addMenu = (mode, integration) => {
    setMenu(
      <Modal show={true} size={'xl'} onHide={() => setMenu(null)}>
        <Modal.Header closeButton>
          <span className="h3">
            {integration
              ? `${t('Edit integration')} ${integration?.name}`
              : t('New integration from') +
                ` ${integration?.pipeline?.[0]?.type ?? mode}`}
          </span>
        </Modal.Header>
        <Modal.Body>
          <ConfigIntegration
            mode={mode}
            integration={integration}
            onFinish={() => {
              setMenu(null)
            }}
          />
        </Modal.Body>
      </Modal>,
    )
  }

  return (
    <>
      <BreadcrumbNav integrations={true} />
      <Container>
        <Row></Row>
        <Row className="px-4 mt-4">
          {menu}
          <Col
            xs={12}
            className="mb-2 d-flex justify-content-center"
            onClick={() => {}}
          >
            <OverlayTrigger
              rootClose={true}
              trigger={'click'}
              placement="bottom"
              delay={{ show: 200, hide: 0 }}
              overlay={(props) =>
                mode ? (
                  <Row></Row>
                ) : (
                  <Popover
                    {...props}
                    className={`popover-nextbrain-body ${
                      props?.className ?? ''
                    }`}
                    style={{
                      ...props.style,
                      minWidth: 'max(8vw, 100px)',
                    }}
                  >
                    <div style={{ width: 'max(8vw, 100px)' }}>
                      <Row className="px-4 py-4 position-relative">
                        <Col
                          xs={12}
                          className="d-inline-flex justify-content-center icon-btn"
                          onClick={() => addMenu('app')}
                        >
                          <h5 className="d-inline-flex justify-content-center align-items-center">
                            <FaBolt
                              className="me-2"
                              style={{ color: 'yellow', fontSize: 20 }}
                            />
                            <span>{t('App')}</span>{' '}
                          </h5>
                        </Col>
                        <Col
                          className="d-inline-flex justify-content-center cursor-ponter icon-btn mt-4"
                          xs={12}
                          onClick={() => addMenu('rag')}
                        >
                          <h5 className="d-inline-flex justify-content-center align-items-center">
                            <MdOutlineMenuBook
                              className="me-2"
                              color="var(--nextbrain-white-font)"
                            />
                            {t('RAG')}{' '}
                          </h5>
                        </Col>
                      </Row>
                    </div>
                  </Popover>
                )
              }
            >
              <Row
                className="project-new-template p-2 w-auto  justify-content-center"
                onClick={(e) => {
                  if (mode) {
                    e.stopPropagation()
                    e.preventDefault()
                    if (mode === 'rag') {
                      addMenu('rag')
                    } else {
                      addMenu('app')
                    }
                  }
                }}
              >
                <Col className="col-auto d-flex align-items-center">
                  <TiPlus className="me-1" size={25} />
                  {t('Create a new integration')}
                </Col>
              </Row>
            </OverlayTrigger>
          </Col>
          <Col className="mt-4" xs={12}>
            <h3>{t('Integrations')}</h3>
          </Col>
          <Col className="mb-3" xs={12}>
            {t('Connect external applications to NextBrain Apps & RAG')}
          </Col>
          <Col xs={12}>
            <Row>
              {Array.isArray(integrations) ? (
                integrations.length > 0 ? (
                  <Col className="table-integrations-container" xs={12}>
                    <DataTable
                      key={integrations?.length ?? 0}
                      customStyles={customStyles}
                      columns={tableConfig}
                      data={integrations}
                      defaultSortFieldId={'Updated'}
                      defaultSortAsc={false}
                    />
                  </Col>
                ) : (
                  <Col xs={12} className="text-center">
                    <p>{t("You don't have any integrations yet")}</p>
                  </Col>
                )
              ) : (
                <Col xs={12}>
                  <Loading />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

import { useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { config } from '../../Constants'
import { useAuth } from '../../providers/AuthProvider'
import { splitByCodeSections } from '../../util/other'
import { editPrivacy } from '../../services/model'
import { v4 as uuidv4 } from 'uuid'
import {
  FaBolt,
  FaRegArrowAltCircleDown,
  FaChartPie,
  FaLightbulb,
  FaTable,
} from 'react-icons/fa'
import { ImStatsDots } from 'react-icons/im'
import { IoMdAnalytics } from 'react-icons/io'
import { MdOutlineBusinessCenter } from 'react-icons/md'
import { prompts, workflows } from './autogen_constants'
import { editRepositoryPrivacy } from '../../services/document'

const crypto = {
  randomUUID: uuidv4,
}
// eslint-disable-next-line
export const matchCommunication = /([^\(]+) \(to (.+)\):/

export const iconMap = {
  Visualizations: (
    <FaChartPie
      size={25}
      className="me-1 opacity-75"
      color="var(--nextbrain-tables-negative-graph-bar-color)"
    />
  ),
  'Descriptive statistics': (
    <ImStatsDots size={25} className="me-1 opacity-75" color="#76d0eb" />
  ),
  'Data Distribution': (
    <ImStatsDots size={25} className="me-1 opacity-75" color="#76d0eb" />
  ),
  'Summary Statistics': (
    <IoMdAnalytics size={25} className="me-1 opacity-75" color="#cb8bd0" />
  ),
  'Descriptive Statistics': (
    <IoMdAnalytics size={25} className="me-1 opacity-75" color="#cb8bd0" />
  ),
  'Distribution analaysis': (
    <IoMdAnalytics size={25} className="me-1 opacity-75" color="#cb8bd0" />
  ),
  'Business cases': (
    <MdOutlineBusinessCenter
      size={25}
      className="me-1 opacity-75"
      color="#7170d3"
    />
  ),
  'Dataset check': (
    <FaTable
      size={25}
      className="me-1 opacity-75"
      color="var(--nextbrain-tables-graph-bar-color)"
    />
  ),
  'Data enrichment': (
    <FaTable
      size={25}
      className="me-1 opacity-75"
      color="var(--nextbrain-tables-graph-bar-color)"
    />
  ),
  'Dataset preprocessing': (
    <FaTable
      size={25}
      className="me-1 opacity-75"
      color="var(--nextbrain-tables-graph-bar-color)"
    />
  ),
  'Causality analysis': (
    <FaBolt size={25} className="me-1 opacity-75" color="#e2c541" />
  ),
  Other: <FaLightbulb size={25} className="me-1 opacity-75" color="#e2c541" />,
}

async function errorString(response) {
  if (!response) return 'No response'

  let message = ''
  try {
    message = await response.text()
  } catch (e) {}
  return `code: ${response.status} error: ${message}`
}

class AutogenState {
  constructor({
    model,
    token,
    onChange = () => console.log('new change'),
    shareSession,
    sessions,
    currentSession,
    appMode = 'automl',
    workflow = 'default',
  }) {
    if ((!model || !token) && !shareSession)
      throw new Error('Missing model or token')

    this.appMode = appMode
    this.model = model
    this.shareSession = shareSession
    this.state = 'off'
    this.error = null
    this.token = token
    this.currentSession = currentSession ?? null
    this.sessions = sessions ?? []
    this.messages = []
    this.onChange = () => this.state !== 'off' && onChange()
    this.onNewContent = () => console.log('new content')
    this.finishedNewContent = false
    this.workflow = workflow
    this.defaultPrompt = prompts.defaultPrompt
    this.plannerPrompt = prompts.plannerPrompt
    this.coderPrompt = prompts.coderPrompt
    this.responderPrompt = prompts.responderPrompt
    this.researcherPrompt = prompts.researcherPrompt
    this.ragResponderPrompt = prompts.ragResponderPrompt
    this.flowConfig = workflows[workflow] ?? workflow['default']

    if (model?.id?.startsWith('repository_')) {
      const repositoryId = this.model.id.split('_')[1]
      this.flowConfig.repository_id = repositoryId
    }
  }

  async waitBusy(resolve, wait_all = false) {
    if (this.state === 'off') return
    const status = await fetch(
      `${config.AUTOGEN2_URL}/api/pending-tasks?session_id=${encodeURIComponent(
        this?.currentSession?.session_id,
      )}`,
      this.fetchParams(),
    ).catch((e) =>
      console.error('Error waiting for autogen chat to stop working', e),
    )
    if (status?.status === 200) {
      const result = await status.json()
      const now = new Date().getTime()
      result.data = result.data.filter((t) => {
        const d = new Date(t.timestamp + 'Z')
        return now - d.getTime() < 1000 * 60 * 10
      })
      if (result?.data?.length > 1 || (wait_all && result?.data?.length === 1))
        if (resolve) setTimeout(() => this.waitBusy(resolve, wait_all), 1000)
        else
          return await new Promise((resolve) =>
            setTimeout(() => this.waitBusy(resolve, wait_all), 1000),
          )
      else return resolve ? resolve(result?.data?.[0]) : result.data[0]
    } else {
      const error = `Error fetching pending tasks ${await errorString(
        status,
      )})}`
      return resolve ? resolve(error) : error
    }
  }

  async fetchSessions({ wait = 0 }) {
    const sessions = await fetch(
      `${config.AUTOGEN2_URL}/api/sessions?model_id=${this.model.id}`,
      this.fetchParams(),
    ).catch((e) => {})
    if (wait) await new Promise((resolve) => setTimeout(resolve, wait))
    if (sessions?.status === 200) {
      const response = await sessions.json()
      this.sessions = Array.isArray(response?.data) ? response?.data : []
      if (!this.sessions?.length) {
        if (wait > 5000) return 'No chats available'

        await this.create_session()
        return this.fetchSessions({ wait: wait + 500 })
      } else this.currentSession = this.sessions[0]
    } else {
      return `Error fetching chats ${await errorString(sessions)}`
    }
  }

  async create_session() {
    const response = await fetch(`${config.AUTOGEN2_URL}/api/sessions`, {
      ...this.fetchParams('POST', { 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        session: {
          session_id: `${this.model.id}`,
          timestamp: new Date(),
          flow_config: this.flowConfig,
        },
      }),
    }).catch((e) => console.error('Error creating session', e))
    console.log('Create session', response?.status)
    if (response?.status === 200) {
      const result = await response.json()
      return result?.data
    } else {
      console.error('Error creating session', await response.text())
    }
    return null
  }

  async loadSession() {
    this.messages = []
    let error = await this.fetchMessages()
    this.onChange()
    this.onNewContent()
    if (error) {
      this.addError(error)
    } else {
      if (this.state === 'starting') {
        const waitError = await this.waitBusy()
        if (waitError) {
          if (typeof waitError === 'string') this.addError(waitError)
          else {
            const task = waitError
            const task_id = task?.task_id
            const lastMessage = this.messages[this.messages.length - 1]
            if (task_id === lastMessage?.msg_id) {
              this.state = 'working'
              await this.processMessageResponse({ status: true, task_id })
            } else {
              await this.waitBusy(null, true)
            }
          }
        }
        this.state = 'on'
        this.onChange()
        this.onNewContent()
      }
    }
  }

  async start() {
    console.log('starting autogen')
    this.state = 'starting'
    this.onChange()

    if (this.shareSession) {
      this.currentSession = {
        session_id: this.shareSession,
      }
      await this.loadSession()
      return
    }

    if (this.sessions && this.currentSession) {
      await this.loadSession()
      return
    }

    let sessionError = await this.fetchSessions({ wait: 0 })
    while (sessionError && this.state !== 'off') {
      this.error = sessionError
      this.onChange()
      await new Promise((resolve) => setTimeout(resolve, 5000))
      sessionError = await this.fetchSessions({ wait: 0 })
    }
    await this.loadSession()
  }

  fetchParams(method = 'GET', headers = {}) {
    return {
      method,
      headers: {
        Authorization: this.shareSession
          ? `Session ${this.shareSession}`
          : `Bearer ${this.token}`,
        ...headers,
      },
    }
  }

  prepareLogs(logs) {
    const KEY_WORD = '**Question:**\n'
    for (let i = 0; i < logs.length; i++) {
      let index = logs[i].lastIndexOf(KEY_WORD)
      if (index !== -1) logs[i] = logs[i].slice(index + KEY_WORD.length)
    }
  }

  prepareMetadata(metadata) {
    if (Array.isArray(metadata?.files)) {
      metadata.files.forEach((f) => {
        f.path = `${config.AUTOGEN2_URL}/api/${f.path}`
      })
    }
  }

  mapExitCodes(el) {
    try {
      el.forEach(
        (c, i) =>
          (el[i] = el[i].replaceAll(
            'exitcode: 1 (execution failed)',
            '<ErrorExit />',
          )),
      )
      el.forEach(
        (c, i) => (el[i] = el[i].replaceAll('exitcode: 1', '<ErrorExit />')),
      )
      el.forEach(
        (c, i) =>
          (el[i] = el[i].replaceAll(
            'exitcode: 0 (execution succeeded)',
            '<SuccessExit />',
          )),
      )
      el.forEach(
        (c, i) => (el[i] = el[i].replaceAll('exitcode: 0', '<SuccessExit />')),
      )
    } catch (e) {}
  }

  processCitations(data) {
    if (data)
      for (let i = 0; i < data.length; i++) {
        const index = data[i].indexOf('<Citation file="')
        if (index !== -1) {
          const citations = data[i].substring(index)
          const noCitations = data[i].substring(0, index)
          data[i] = noCitations
          return citations
        }
      }
  }

  prepareContent(message) {
    message.content = splitByCodeSections(message.content)
    message.content.forEach((c) => (c.text = c.text.replace('TERMINATE', '\n')))
    message.content.forEach(
      (
        c, //Fix bad table headers
      ) =>
        (c.text = c.text
          .replace('\n| |', '\n| index |')
          .replace('|-|', '|------|')),
    )
  }

  async applyTransform(ignore = false) {
    // /apply-transform
    const response = await fetch(
      `${config.AUTOGEN2_URL}/api/${
        ignore ? 'ignore-transform' : 'apply-transform'
      }?session_id=${encodeURIComponent(this?.currentSession?.session_id)}`,
      this.fetchParams('POST', { 'Content-Type': 'application/json' }),
    ).catch((e) => {
      if (!ignore) {
        console.error('Error applying transform', e)
      }
    })

    switch (response?.status) {
      case 200:
        return await response.json()
      case 403:
      case 401:
        return 'Unauthorized'
      default:
        return await response.text()
    }
  }

  async fetchMessages() {
    this.onChange()
    const messages = await fetch(
      `${config.AUTOGEN2_URL}/api/messages?session_id=${encodeURIComponent(
        this?.currentSession?.session_id,
      )}`,
      this.fetchParams(),
    ).catch((e) => console.error('Error fetching messages', e))
    switch (messages?.status) {
      case 200:
        this.messages = (await messages.json())?.data ?? []
        this.messages.forEach((m, i) => {
          if (m.logs) {
            this.prepareLogs(m.logs)
          }
          if (
            m.script &&
            i &&
            this.messages[i - 1]?.content?.startsWith('Execute app')
          )
            m.script = null
          if (m.metadata) {
            try {
              m.metadata = JSON.parse(m.metadata)
              this.prepareMetadata(m.metadata)
              this.prepareContent(m)
            } catch (e) {}
          }
        })
        try {
          for (let i = 0; i < this.messages.length; i++) {
            const message = this.messages[i]
            if (message.role === 'user' && Array.isArray(message?.logs)) {
              const citations = this.processCitations(message.logs)
              if (citations && Array.isArray(this.messages?.[i + 1]?.content)) {
                this.messages[i + 1].content.push({
                  type: 'text',
                  text: citations,
                })
              }
            }
          }
        } catch (e) {
          console.error('Error seeking citations', e)
        }
        this.addWelcome()
        return
      case 403:
      case 401:
        return 'Unauthorized'
      default:
        return errorString(messages)
    }
  }

  async sendReport() {
    const response = await fetch(
      `${config.AUTOGEN2_URL}/api/send-report?session_id=${encodeURIComponent(
        this?.currentSession?.session_id,
      )}`,
      this.fetchParams('POST', { 'Content-Type': 'application/json' }),
    ).catch((e) => console.error('Error generating report', e))
    switch (response?.status) {
      case 200:
        return await response.json()
      case 403:
      case 401:
        return 'Unauthorized'
      default:
        return await response.text
    }
  }

  tailMessage() {
    return '0'
  }

  createErrorMessage(error) {
    switch (error.toLowerCase()) {
      case 'timeout': {
        const source = this.messages.findLast((m) => m.role !== 'assistant')
        this.messages.push({
          role: 'assistant',
          content: 'Timeout error. the server failed to respond in time',
          className: 'error-message-autogen',
          source: source,
          extraContent: source ? (
            <div>
              <Button
                onClick={() => {
                  this.sendMessage(source.content)
                }}
              >
                Retry
              </Button>
            </div>
          ) : null,
          session_id: this?.currentSession?.session_id,
          root_msg_id: '0',
          msg_id: crypto.randomUUID(),
        })
        break
      }
      default:
        break
    }
  }

  async processMessageResponse(response) {
    // Equivalent to 10 minutes
    const MAX_MILISECONDS = 60 * 10 * 1000
    const DELAY_PER_MESSAGE = 1000
    const MAX_MESSAGES = MAX_MILISECONDS / DELAY_PER_MESSAGE

    if (!response?.status || !response?.task_id) {
      this.error = JSON.stringify(response)
      return
    }

    const task_id = response.task_id
    let attempts = MAX_MESSAGES
    let citations = ''
    //console.log('Processing task_id: ', task_id)
    const waitTask = async (resolve) => {
      if (--attempts > 0 && this.state !== 'off') {
        let response = await fetch(
          `${
            config.AUTOGEN2_URL
          }/api/task/${task_id}?session_id=${encodeURIComponent(
            this?.currentSession?.session_id,
          )}`,
          this.fetchParams(),
        ).catch((e) => {
          return {
            status: 200,
            json: async () => ({
              restart: true,
            }),
          }
        })
        if (response?.status !== 200) {
          resolve({
            error: `Error generating answer ${await errorString(response)}`,
          })
          return
        }

        response = await response.json()
        if (response.restart) {
          return resolve(
            await new Promise((resolve) =>
              setTimeout(() => waitTask(resolve), DELAY_PER_MESSAGE),
            ),
          )
        } else if (!response?.status) {
          resolve({
            error: JSON.stringify(response),
          })
        } else if (
          Date.now() - new Date(response.data.timestamp + 'Z') <
          1000 * 60 * 10
        ) {
          if (response?.data?.pending) {
            try {
              if (response?.data?.logs) {
                this.prepareLogs(response.data.logs)
              }
              const logs = response.data.logs
              if (
                Array.isArray(this.currentLogs) &&
                logs.length === this.currentLogs.length
              )
                return resolve(
                  await new Promise((resolve) =>
                    setTimeout(() => waitTask(resolve), DELAY_PER_MESSAGE),
                  ),
                )
              this.mapExitCodes(logs)
              const localCitations = this.processCitations(logs)
              if (localCitations) citations = localCitations
              this.currentLogs = logs
              if (this.messages[this.messages.length - 1])
                this.messages[this.messages.length - 1].logs = logs
              this.onChange()
            } catch (e) {
              console.error('Error parsing content', e)
            }
            return resolve(
              await new Promise((resolve) =>
                setTimeout(() => waitTask(resolve), DELAY_PER_MESSAGE),
              ),
            )
          }
          resolve(response?.data)
        }
      } else
        resolve({
          error: 'timeout',
        })
    }
    await new Promise((r) => setTimeout(r, 1500))
    if (this.state === 'off') return
    const task_result = await new Promise(waitTask)
    this.currentLogs = null
    if (task_result.error || !task_result?.content) {
      this.error = task_result.error
      this.createErrorMessage(task_result.error)
      this.onChange()
    } else {
      const data = task_result.content
      let content = splitByCodeSections(data?.message ?? '')
      content.forEach((c) => (c.text = c.text.replace('TERMINATE', '')))

      if (
        !citations &&
        !this.shareSession &&
        Array.isArray(task_result?.logs)
      ) {
        const localCitations = this.processCitations(task_result.logs)
        if (localCitations) citations = localCitations
      }

      if (citations && !this.shareSession) {
        if (Array.isArray(content))
          content.push({ type: 'text', text: citations })
        else content = content + citations
      }

      if (data?.logs) {
        this.prepareLogs(data.logs)
      }
      this.prepareMetadata(data.metadata)

      if (this.messages[this.messages.length - 1])
        this.messages[this.messages.length - 1].logs =
          task_result?.logs ?? data.logs

      let message = {
        role: 'assistant',
        content: content,
        logs: task_result?.logs ?? data.logs,
        metadata: data.metadata,
        session_id: this?.currentSession?.session_id,
        root_msg_id: this.tailMessage(),
        msg_id: data?.msg_id ?? crypto.randomUUID(),
      }

      if (
        Object.keys(data).includes('script') &&
        !this.messages?.[this.messages.length - 1]?.content?.startsWith(
          'Execute app',
        )
      ) {
        message.script = data.script
      }

      if (message?.logs) {
        this.prepareLogs(message.logs)
      }

      this.messages.push(message)
      this.finishedNewContent = true
      this.onChange()
      setTimeout(() => {
        this.finishedNewContent = false
        this.onChange()
      }, 6000)
    }
  }

  async sendMessage(
    message,
    regenerate = false,
    replace_msg_id = null,
    options = {},
  ) {
    const rewrite = this?.rewrite ?? {}

    if (this.state !== 'on') {
      console.log('Tried to send a messag when the bot was not ready', message)
      return
    }
    this.state = 'working'
    const id = crypto.randomUUID()

    const messageObject = {
      role: 'user',
      content: message,
      session_id: this?.currentSession?.session_id,
      root_msg_id: this.tailMessage(),
      msg_id: id,
    }
    // Why?
    // this.flowConfig.receiver.config.system_message = this.defaultPrompt
    Object.keys(this.flowConfig.agents).forEach((k) => {
      if (rewrite?.[k])
        this.flowConfig.agents[k].config.system_message = rewrite[k]
    })
    const data = {
      message: messageObject,
      flow_config: this.flowConfig,
      replace_msg_id: replace_msg_id,
      ...options,
    }
    if (this?.selectedTags?.length) {
      const tags = this.selectedTags
        .filter((t) => t.type === 'tag')
        .map((t) => t.name)
      const documents = this.selectedTags
        .filter((t) => t.type === 'document')
        .map((t) => t.id)
      const all_documents = this.selectedTags.findIndex(
        (t) => t.name === 'All documents',
      )
      if (tags.length) data.tags = tags
      if (documents.length) {
        data.document_ids = documents
        if (all_documents !== -1) {
          data.get_all_documents = true
          data.document_ids.splice(all_documents, 1)
        }
      }
    } else if (this.workflow === 'rag') data.get_all_documents = true

    if (!this.currentSession?.short_content)
      this.currentSession.short_content = message.substring(0, 55)

    if (regenerate) {
      this.messages.pop()
      const lastQuestion = this.messages.pop()
      messageObject.content = lastQuestion.content
    }

    if (replace_msg_id) {
      const index = this.messages.findIndex((m) => m.msg_id === replace_msg_id)
      this.messages = this.messages.slice(0, index)
    }

    if (messageObject?.logs) {
      this.prepareLogs(messageObject.logs)
    }

    this.messages.push(messageObject)
    this.onChange()
    if (!regenerate) this.onNewContent()

    const response = await fetch(
      `${config.AUTOGEN2_URL}/api/messages?session_id=${encodeURIComponent(
        this?.currentSession?.session_id,
      )}&regenerate=${regenerate}`,
      {
        ...this.fetchParams('POST', { 'Content-Type': 'application/json' }),
        body: JSON.stringify(data),
      },
    ).catch((e) => console.error('Error sending message', e))
    switch (response?.status) {
      case 200: {
        const result = await response.json()
        await this.processMessageResponse(result)
        break
      }
      case 403:
      case 401:
        this.addError('Unauthorized')
        break
      default:
        this.addError(await errorString(response))
        break
    }
    this.state = 'on'
    this.onChange()
    this.onNewContent()
  }

  async convertIntoApp(message) {
    const response = await fetch(
      `${
        config.AUTOGEN2_URL
      }/api/convert-to-recipe?session_id=${encodeURIComponent(
        message.session_id,
      )}&msg_id=${encodeURIComponent(message.msg_id)}`,
      {
        ...this.fetchParams('POST', { 'Content-Type': 'application/json' }),
      },
    ).catch((e) => console.error('Error converting ', e))
    switch (response?.status) {
      case 200: {
        return await response.json()
      }
      case 403:
      case 401:
        this.addError('Unauthorized')
        break
      default:
        this.addError(await errorString(response))
        break
    }
  }

  createWelcome() {
    const SUGGESTED_QUESTIONS =
      this.workflow === 'rag'
        ? []
        : this.appMode === 'mmm'
        ? [
            [
              'Insights',
              'Check the time period to be at least 12 months, identify the target (KPI), marketing channels and fields with high correlation to the target',
            ],
            [
              'Machine learning',
              'What external data will enrich the dataset in order to create a Marketing Mix Model?',
            ],
            [
              'Visualizations',
              'Can you plot me the most interesting visualizations of the data that I have provided? (at least 5)',
            ],
            [
              'Dataset check',
              'Ensure that all data is formatted correctly and that there are no inconsistencies within the dataset',
            ],
          ]
        : [
            [
              'Initial analysis',
              'Could you conduct an exploratory data analysis (EDA) on the dataset and highlight the most significant insights? Identify key patterns, trends and anomalies',
            ],
            [
              'Dataset preprocessing',
              'Ensure the dataset is properly cleaned and formatted by handling missing values, removing duplicates, correcting inconsistencies, and detecting and managing outliers.',
            ],
            [
              'Visualizations',
              'Can you plot me the most interesting visualizations of the data that I have provided? (at least 5)',
            ],
            [
              'Data enrichment',
              'What external data will enrich the dataset in order to create a machine learning model? Taking into account the provided dataset',
            ],
            [
              'Business cases',
              'Find the most interesting business cases and machine learning problems in my data',
            ],
            [
              'Causality analysis',
              'Explore causality analysis using the DoWhy library and explain it in a simple and visual way, including a causal graph and additional relevant visualizations (at least 5)',
            ],
          ]

    return (
      <span>
        <Row className="justify-content-center">
          {SUGGESTED_QUESTIONS.map((q, i) => (
            <Col
              md={3}
              xs={12}
              key={i}
              className="mx-3 px-2 py-3 my-3 cursor-pointer bot-suggestions text-white"
              onClick={() => {
                this.sendMessage(q[1])
              }}
            >
              <span className="px-3 bot-suggestions-title remark-color h5">
                {iconMap[q[0]] ?? iconMap['Other']} {q[0]}
              </span>
              <Row className="p-3">
                <Col md={10}>
                  <i>{q[1]}</i>
                </Col>
                <Col md={2}>
                  <FaRegArrowAltCircleDown className="arrow" size={35} />
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </span>
    )
  }

  addWelcome() {
    const welcomes = {
      default:
        'Hello! I can help you to explore and transform your data. What do you need from me?',
      rag: 'Hello! I can help you to explore and query your knowledge base?',
    }

    this.messages.unshift({
      role: 'assistant',
      content: welcomes[this?.workflow] ?? welcomes['default'],
      className: 'welcome-message-autogen',
      extraContent: this.customWelcome ?? this.createWelcome(),
      metadata: null,
      session_id: this?.currentSession?.session_id,
      root_msg_id: '0',
      msg_id: crypto.randomUUID(),
    })
  }

  setCustomWelcome(welcome, overwrite = false) {
    if (this.customWelcome && !overwrite) return
    this.customWelcome = welcome.content
    let index = this.messages.findIndex(
      (m) => m.className === 'welcome-message-autogen',
    )
    if (index !== -1) {
      this.messages[index] = welcome
      this.onChange()
    }
  }

  showSkills() {
    const SUGGESTED_QUESTIONS = ['Show me all the generated files']

    this.messages = this.messages.filter((m) => m.msg_id !== 'skills')

    this.messages.push({
      role: 'assistant',
      content: 'You have some pre-built skills that you can use.',
      className: 'welcome-message-autogen',
      extraContent: (
        <span>
          <Row className="justify-content-center">
            {SUGGESTED_QUESTIONS.map((q, i) => (
              <Col
                md={3}
                key={i}
                className="mx-3 px-2 py-2 my-3 cursor-pointer chat-looks-like-a bot-suggestions text-white"
                onClick={() => {
                  this.sendMessage(q)
                }}
              >
                <div className="p-3">
                  <span>{q}</span>
                </div>
              </Col>
            ))}
          </Row>
        </span>
      ),
      metadata: null,
      session_id: this?.currentSession?.session_id,
      root_msg_id: '0',
      msg_id: 'skills',
    })
  }

  async clearMessages() {
    this.state = 'working'
    this.onChange()
    const response = await fetch(`${config.AUTOGEN2_URL}/api/session`, {
      ...this.fetchParams('DELETE', { 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        session: {
          session_id: this?.currentSession?.session_id,
        },
      }),
    }).catch((e) => console.error('Error clearing messages', e))
    if (response?.status !== 200) {
      this.addError(await errorString(response))
      return
    }
    if (this?.currentSession?.short_content)
      this.currentSession.short_content = null
    this.messages = []
    this.state = 'on'
    this.addWelcome()
    this.onChange()
  }

  async deleteSession(sessionName) {
    sessionName = sessionName || this?.currentSession?.session_id
    const response = await fetch(`${config.AUTOGEN2_URL}/api/cleardb`, {
      ...this.fetchParams('POST', { 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        session: {
          session_id: sessionName.trim(),
        },
      }),
    }).catch((e) => console.error('Error deleting session', e))
    if (response?.status !== 200) {
      this.addError(await errorString(response))
      return
    }
    this.sessions = this.sessions.filter((s) => s.session_id !== sessionName)
    if (this.currentSession?.session_id === sessionName) {
      this.currentSession = this.sessions[0]
      if (!this.currentSession) {
        const error = await this.fetchSessions({ wait: 0 })
        if (error) {
          this.error = error
          this.onChange()
          return
        }
      }
    }
    this.state = 'on'
  }

  async addDummy(replay = false, message = null) {
    let bmessage = {
      role: 'assistant',
      content:
        message ??
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      logs: [],
      metadata: null,
      session_id: this?.currentSession?.session_id,
      root_msg_id: this.tailMessage(),
      msg_id: crypto.randomUUID(),
    }
    if (replay) {
      bmessage = this.messages[this.messages.length - 1]
      if (!bmessage) return
      bmessage = { ...bmessage, msg_id: crypto.randomUUID() }
    }

    this.messages.push(bmessage)
    this.finishedNewContent = true
    setTimeout(() => {
      this.finishedNewContent = false
      this.onChange()
    }, 6000)
    this.onChange()
    this.onNewContent()
  }

  async setSessionPrivacy(sessionName, isPublic) {
    const prevState = this.state
    this.state = 'working'
    this.onChange()
    const result = await fetch(
      `${config.AUTOGEN2_URL}/api/share_session?session_id=${
        encodeURIComponent(sessionName) + '&is_public=' + isPublic
      }`,
      this.fetchParams('POST'),
    ).catch((e) => console.error('Error setting session privacy', e))

    if (result?.status) {
      if (isPublic) {
        if (sessionName.startsWith('repository_'))
          await editRepositoryPrivacy({
            repositoryId: sessionName.split('_')[1],
            isPublic: true,
            token: this.token,
            signout: () => {},
          })
        else
          await editPrivacy(
            this.model.id,
            { private_report: false },
            this.token,
            () => {},
          )
      } else if (sessionName.startsWith('repository_')) {
        await editRepositoryPrivacy({
          repositoryId: sessionName.split('_')[1],
          isPublic: false,
          token: this.token,
          signout: () => {},
        })
      }
      this.sessions
        .filter((s) => s.session_id === sessionName)
        .forEach((s) => (s.is_public = isPublic))
      if (this.state === 'working') {
        this.state = prevState
        this.onChange()
      }
    } else {
      this.addError(await errorString(result))
    }
  }

  addError(message) {
    this.error = message
    this.onChange()
  }

  clearError(state = 'on') {
    this.error = null
    this.onChange()
  }

  async createNewPublicSession() {
    return fetch(
      `${config.AUTOGEN2_URL}/api/public_sessions`,
      this.fetchParams('POST'),
    ).catch((e) => console.error('Error creating new public session', e))
  }

  die() {
    console.log('stopping autogen')
    this.messages = []
    this.state = 'off'
    this.error = null
    this?.onChange()
  }
}

export default function useAutogen({
  model,
  onNewContent,
  refreshModel = () => {},
  shareSession,
  mode = 'automl',
  workflow = 'default',
}) {
  const { token } = useAuth()
  // eslint-disable-next-line
  const [update, setUpdate] = useState(0)
  const [online, setOnline] = useState(true)
  const [state, setState] = useState(
    () =>
      (window.currentChatState = new AutogenState({
        model,
        token,
        onChange: () => {
          setUpdate((u) => u + 1)
        },
        shareSession,
        appMode: mode,
        workflow,
      })),
  )
  useEffect(() => {
    const iv = setInterval(() => {
      fetch(config.API_URL)
        .then((r) => {
          if (r?.status !== 200) setOnline(false)
          else setOnline(true)
        })
        .catch((e) => setOnline(false))
    }, 30000)
    return () => clearInterval(iv)
  }, [])

  useEffect(() => {
    if (onNewContent && state)
      state.onNewContent = () => {
        if (state.state !== 'off') onNewContent()
      }
  }, [onNewContent, state])

  useEffect(() => {
    state.start()
    return () => {
      state.die()
    }
  }, [state])

  return {
    online,
    autogenState: state,
    messages: state.messages ?? [],
    defaultPrompt: state.defaultPrompt,
    state: state.state,
    error: state.error,
    currentLogs: state.currentLogs,
    sessions: state.sessions,
    currentSession: state.currentSession,
    finishedNewContent: state.finishedNewContent,
    clearError: () => {
      state.clearError()
    },
    sendMessage: async (
      message,
      regenerate = false,
      replace_msg_id = null,
      options = {},
    ) => {
      await state.sendMessage(message, regenerate, replace_msg_id, options)
    },
    clearMessages: async () => {
      await state.clearMessages()
    },
    forceUpdate: () => {
      state.onChange()
    },
    updatePrompt: (prompt) => {
      state.defaultPrompt = prompt
      onNewContent()
    },
    changeSession: async (session) => {
      state.currentSession = session
      state.die()
      const newChat = (window.currentChatState = new AutogenState({
        model,
        token,
        onChange: () => {
          setUpdate((u) => u + 1)
        },
        shareSession,
        sessions: state.sessions,
        currentSession: state.currentSession,
        appMode: mode,
        workflow,
      }))
      setState(newChat)
    },
    newSession: async () => {
      state.state = 'working'
      state.onChange()
      const newSessions = await state.create_session()
      if (!Array.isArray(newSessions)) {
        state.addError('Could not create a new chat')
        return
      }
      state.sessions = newSessions
      state.currentSession = newSessions[0]
      state.die()
      const newChat = (window.currentChatState = new AutogenState({
        model,
        token,
        onChange: () => {
          setUpdate((u) => u + 1)
        },
        shareSession,
        sessions: state.sessions,
        currentSession: state.currentSession,
        workflow,
      }))
      setState(newChat)
    },
    deleteSession: async (sessionName) => {
      await state.deleteSession(sessionName)
      state.onChange()
    },
    setModelVisibility: async (shared) => {
      await state.setSessionPrivacy(state.currentSession.session_id, shared)
    },
    createNewPublicSession: async () => {
      return (await state.createNewPublicSession()).json()
    },
    showSkills: () => {
      state.showSkills()
      state.onChange()
    },
    createWelcome: () => state.createWelcome(),
    applyTransform: async (ignore = false) => {
      return await state.applyTransform(ignore)
    },
    setCustomWelcome: (welcome, overwrite) =>
      state.setCustomWelcome(welcome, overwrite),
    convertIntoApp: async (message) => await state.convertIntoApp(message),
  }
}

import React, { useState, useEffect, useMemo } from 'react'
import { Row, Col, Button, Modal, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { ResponsiveBar } from '@nivo/bar'
import { MdWarning } from 'react-icons/md'
import { BiVerticalCenter } from 'react-icons/bi'
import { SiStatista } from 'react-icons/si'
import { GoSingleSelect } from 'react-icons/go'

import Loading from '../loading/LoadingSmall'
import { useAuth } from '../../providers/AuthProvider'
import { fillEmptyValues } from '../../services/model'
import { awaitTaskCall } from '../../services/base'
import { NotificationManager } from 'react-notifications'
import AccuracyGauge from '../model-content/AccuracyGauge'
import ErrorNB from '../loading/ErrorNB'
import { useModels } from '../../providers/ModelProvider'
import HelpTooltip from '../model-content/HelpTooltip'
import { defaultFormat } from '../utils/formating'
import NextbrainSelect from '../model-content/NextbrainSelect'
import { useDebouncedCallback } from 'use-debounce'

function StatisticsVisualization({ metric, scores, method, constantValue }) {
  const { t } = useTranslation()
  scores = scores || {}

  if (method === 'mean')
    return (
      <Row className="fill-empty-mean-box">
        <Col className="text-center" xs={12}>
          <p>{t('Mean value for missing entries')}: </p>
          <p className="text-primary  h4">
            <BiVerticalCenter size={30} className="me-1" />
            {defaultFormat({ num: scores.mean_value, digits: 4 })}
          </p>
        </Col>
      </Row>
    )

  if (method === 'mode')
    return (
      <Row className="fill-empty-mean-box">
        <Col className="text-center" xs={12}>
          <p>{t('Mode value for missing entries')}: </p>
          <p className="text-primary  h4">
            <SiStatista size={30} className="me-1" />
            {defaultFormat({ num: scores.mode_value, digits: 4 })}
          </p>
        </Col>
      </Row>
    )

  if (method === 'median')
    return (
      <Row className="fill-empty-mean-box">
        <Col className="text-center" xs={12}>
          <p>{t('Median value for missing entries')}: </p>
          <p className="text-primary  h4">
            <SiStatista size={30} className="me-1" />
            {defaultFormat({ num: scores.median_value, digits: 4 })}
          </p>
        </Col>
      </Row>
    )

  if (method === 'constant')
    return (
      <Row className="fill-empty-mean-box">
        <Col className="text-center" xs={12}>
          <p>{t('Constant value for missing entries')}: </p>
          <p className="text-primary  h4">
            <GoSingleSelect size={30} className="me-1" />
            {constantValue}
          </p>
        </Col>
      </Row>
    )

  if ('distribution' in scores) {
    const data = Object.entries(scores.distribution)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 8)
      .map(([column, value]) => ({ column, value }))

    return (
      <Row>
        <Col
          xs={12}
          className="px-0"
          style={{ maxHeight: '220px', minHeight: '220px' }}
        >
          <ResponsiveBar
            data={data}
            indexBy={'column'}
            keys={['value']}
            colors={() => 'var(--nextbrain-tables-graph-bar-orange-color)'}
            margin={{ top: 10, right: 0, bottom: 75, left: 45 }}
            padding={0.3}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            label={(d) => `${defaultFormat({ num: d.value, digits: 2 })}%`}
            theme={{
              background: '#ffffff00',
              textColor: '#ffffff',
              fontSize: 11,
              axis: {
                domain: {
                  line: {
                    stroke: '#ffffff',
                    strokeWidth: 1,
                  },
                },
                legend: {
                  text: {
                    fontSize: 12,
                    fill: '#ffffff',
                  },
                },
                ticks: {
                  line: {
                    stroke: '#ffffff',
                    strokeWidth: 1,
                  },
                  text: {
                    fontSize: 11,
                    fill: '#ffffff',
                  },
                },
              },
              grid: {
                line: {
                  stroke: '#ffffff',
                  strokeWidth: 1,
                },
              },
              legends: {
                title: {
                  text: {
                    fontSize: 11,
                    fill: '#ffffff',
                  },
                },
                text: {
                  fontSize: 11,
                  fill: '#ffffff',
                },
                ticks: {
                  line: {},
                  text: {
                    fontSize: 10,
                    fill: '#ffffff',
                  },
                },
              },
              annotations: {
                text: {
                  fontSize: 13,
                  fill: '#ffffff',
                  outlineWidth: 2,
                  outlineColor: '#ffffff',
                  outlineOpacity: 1,
                },
                link: {
                  stroke: '#ffffff',
                  strokeWidth: 1,
                  outlineWidth: 2,
                  outlineColor: '#ffffff',
                  outlineOpacity: 1,
                },
                outline: {
                  stroke: '#ffffff',
                  strokeWidth: 2,
                  outlineWidth: 2,
                  outlineColor: '#ffffff',
                  outlineOpacity: 1,
                },
                symbol: {
                  fill: '#000000',
                  outlineWidth: 2,
                  outlineColor: '#ffffff',
                  outlineOpacity: 1,
                },
              },
              tooltip: {
                container: {
                  background: '#ffffff',
                  color: '#333333',
                  fontSize: 12,
                },
                basic: {},
                chip: {},
                table: {},
                tableCell: {},
                tableCellValue: {},
              },
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              legend: t(
                'Distribution of values that will be used to fill empty entries',
              ),
              legendPosition: 'middle',
              legendOffset: 62,
              tickRotation: -22,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: t('Frequency'),
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="black"
            legends={[]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </Col>
      </Row>
    )
  }

  return (
    <AccuracyGauge
      testAcc={metric}
      tooltip={t('Generated data accuracy')}
      height={190}
      marginLeft={0}
      colorTest={metric > 40 ? '#3ec73e' : '#DA5B0C'}
      labelsTextColor={'white'}
      theme={{
        fontSize: 20,
      }}
    />
  )
}

const numericOptions = ['mean', 'median', 'mode', 'constant', 'ml']

const categoryOptions = ['mode', 'constant', 'ml']

export default function FillColumns({ model, column, onClose }) {
  const { token, signout } = useAuth()
  const { requestUpdate } = useModels()
  const [confirm, setConfirm] = useState(false)
  const [useSyntheticData, setUseSyntheticData] = useState(false)
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  useEffect(() => {
    return () => (window.fillWorking = false)
  }, [])

  const isCategorical =
    model?.dataset?.final_column_status?.[column] === 'Categorical'

  const [selectedOption, setSelectedOption] = useState(null)
  const [constantValue, setConstantValue] = useState(null)
  const options = useMemo(() => {
    let result = (isCategorical ? categoryOptions : numericOptions).map(
      (v) => ({
        label: t(`fill_method_label_${v}`),
        value: v,
      }),
    )
    if (isCategorical && model?.dataset?.statistics?.[column]?.nunique > 100)
      result = result.filter((v) => v.value !== 'ml')
    return result
    // eslint-disable-next-line
  }, [t, isCategorical])

  useEffect(() => {
    if (!selectedOption) setSelectedOption(options[0])
    // eslint-disable-next-line
  }, [options, isCategorical])

  useEffect(() => {
    queryClient.invalidateQueries(['fillColumns', model.id, column, true])
    if (window.fillWorking)
      queryClient.invalidateQueries([
        'fillColumns',
        model.id,
        column,
        false,
        false,
        null,
        null,
      ])
    // eslint-disable-next-line
  }, [])

  const { data, isLoading } = useQuery(
    [
      'fillColumns',
      model.id,
      column,
      selectedOption?.value === 'ml' ? useSyntheticData : false,
      confirm,
      selectedOption?.value,
      selectedOption?.value === 'constant' ? constantValue : null,
    ],
    async () => {
      if (
        window.fillWorking ||
        !selectedOption?.value ||
        (selectedOption.value === 'constant' && !constantValue)
      )
        return
      try {
        window.fillWorking = true
        const res = await awaitTaskCall(fillEmptyValues, 2000, 600000, {
          modelId: model?.id,
          column,
          useSynthetic: useSyntheticData,
          confirm,
          method: selectedOption?.value,
          constantValue:
            selectedOption?.value === 'constant' ? constantValue : null,
          token,
          signout,
        }).catch((e) => e)
        if (!res?.scores) {
          NotificationManager.warning(
            `${t('Sorry we can not fill empty values: ')} ${
              res?.error ? String(res.error) : ''
            }`,
            null,
            10000,
          )
          onClose()
        }
        if (confirm) {
          NotificationManager.info('Updated dateset')
          queryClient.invalidateQueries([
            'viewData-infinite',
            model?.id,
            'imported',
          ])
          queryClient.invalidateQueries(['model-summary', model.id, 'imported'])
          queryClient.invalidateQueries(['columnsDeleted', model?.id])
          requestUpdate(model)
          onClose()
        }
        return res
      } catch (e) {
      } finally {
        window.fillWorking = false
      }
    },
    { staleTime: Infinity },
  )

  const updateConstant = useDebouncedCallback((value) => {
    if (!value) return
    if (isCategorical) setConstantValue(value)
    else {
      const num = Number.parseFloat(value)
      if (!isNaN(num)) setConstantValue(num)
      else NotificationManager.warning('Please input a valid number')
    }
  }, 1500)

  const metric = (() => {
    const value = selectedOption?.value
    if (value === 'mean' || value === 'mode') return 100

    if (data?.scores) {
      const hmetrics = ['100 - Weighted MAPE']
      let metrics = []
      switch (model.dataset.final_column_status[column]) {
        case 'Integer':
        case 'Double':
          metrics = ['ExpVariance', 'F1 Weighted', 'Accuracy Binary']
          break
        case 'Datetime':
          metrics = ['Accuracy', '100 - Weighted MAPE']
          break
        case 'Categorical':
          metrics = ['F1 Weighted', 'Accuracy Multiclass']
          break
        default:
          metrics = ['Precision']
          break
      }
      return (
        metrics.reduce(
          (r, v) =>
            r === 0
              ? 0
              : r || data?.scores?.[v] * (hmetrics.includes(v) ? 1 : 100),
          null,
        ) ?? 0
      )
    }
    return 0
  })()

  const badAcc = metric < 65

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>{`${t(
        'Fill empty values for',
      )} ${column}`}</Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Loading message={t('Generating values')} />
        ) : (
          <>
            {!data?.scores &&
            selectedOption &&
            !(selectedOption?.value === 'constant' && !constantValue) ? (
              <ErrorNB
                size={12}
                errorMessage={
                  data?.error ? String(data.error) : 'Failed to generate values'
                }
              />
            ) : (
              <Row className="justify-content-center">
                <Col xs={12} className="px-4 pt-1">
                  <span className="smallp color-white">{t('Fill method')}</span>
                </Col>
                <Col xs="12" className="px-4 pt-1 mb-2">
                  <NextbrainSelect
                    value={selectedOption}
                    onChange={(value) => {
                      setConstantValue(null)
                      setSelectedOption(value)
                    }}
                    options={options}
                    hideSelectedOptions={false}
                    isClearable={true}
                    placeholder={'Selected a method to fill your empty values'}
                    type={'dark'}
                  />
                </Col>
                {selectedOption?.value === 'constant' && (
                  <Col xs="12" className="px-4 pt-1 mb-2">
                    <Form.Control
                      className="nb-input"
                      placeholder={t('Input your constant value')}
                      defaultValue={constantValue ?? ''}
                      onChange={(e) => updateConstant(e.target.value)}
                    />
                  </Col>
                )}
                {data?.scores && (
                  <Col xs={12} className="px-5">
                    <StatisticsVisualization
                      metric={metric}
                      scores={data?.scores}
                      method={selectedOption?.value}
                      constantValue={constantValue}
                    />
                  </Col>
                )}
                {selectedOption?.value === 'ml' && (
                  <>
                    <Col
                      xs={12}
                      className="text-center text-secondary mt-4 px-5"
                    >
                      <p>{t('Explain missing values 1', { column })}</p>
                      <p>{t('Explain missing values 2')}</p>
                      <p>{t('Explain missing values 3')}</p>
                    </Col>
                    <Col xs={12}>
                      <Form.Check
                        type="switch"
                        label={
                          <span>
                            {t('Use synthetic data to improve accuracy')}
                            <HelpTooltip
                              message={t('Tooltip generate synthetic')}
                              className="ms-1"
                            />
                            {Object.values(
                              model?.dataset?.final_column_status ?? {},
                            ).some((v) => v === 'Datetime') && (
                              <div className="small text-center d-flex align-items-center">
                                {t(
                                  'Datetime columns will not be used to generate synthetic data',
                                )}
                                <MdWarning
                                  className="ms-1"
                                  color={'var(--nextbrain-warning-color)'}
                                />
                              </div>
                            )}
                          </span>
                        }
                        className="form-switch-share ps-0 d-flex align-items-center"
                        style={{ marginLeft: '50px' }}
                        checked={useSyntheticData}
                        onChange={async (e) => {
                          setUseSyntheticData(e.target.checked)
                        }}
                      />
                    </Col>
                  </>
                )}
              </Row>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="px-0">
        {((!isLoading && data?.scores) ||
          (!isLoading && selectedOption?.value === 'constant')) && (
          <Row
            className="justify-content-center mx-0"
            style={{
              borderTop: '1px solid var(--nextbrain-secondary-border-color)',
            }}
          >
            {badAcc ? (
              <Col className="text-center mt-2" xs={12}>
                {t(
                  'The accuracy of the generated sample is unsatisfactory and will add noise to the dataset rather than improving it',
                )}
                .
              </Col>
            ) : (
              <Col className="text-center mt-2" xs={12}>
                {t('Fill the missing with the generated data?')}
              </Col>
            )}
            <Col className="d-flex justify-content-center mt-3 " xs={12}>
              <Button
                className="py-3 me-4 px-4 original empty-secondary"
                variant="danger"
                onClick={onClose}
              >
                {t('Cancel')}
              </Button>
              <Button
                className="py-3 me-4 px-4"
                disabled={badAcc}
                onClick={() => setConfirm(true)}
              >
                {t('Confirm')}
              </Button>
            </Col>
          </Row>
        )}
      </Modal.Footer>
    </Modal>
  )
}

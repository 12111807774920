import React, { useState, useEffect, useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import { FaArrowLeft } from 'react-icons/fa'

import {
  formatedProblemType,
  isBinaryModel,
  isForecastingModel,
  isMMMModel,
  isMulticlassModel,
  isRegressionModel,
} from '../../util/models'

import {
  ConfigDataDistribution,
  WidgetDataDistribution,
} from './Widgets/DataDistribution'
import { ConfigPerformance, WidgetPerformance } from './Widgets/Performance'
import { ConfigStorytelling, WidgetStorytelling } from './Widgets/Storytelling'
import {
  ConfigModelAccuracy,
  WidgetModelAccuracy,
} from './Widgets/ModelAccuracy'
import {
  ConfigPredictedActual,
  WidgetPredictedActual,
} from './Widgets/PredictedActual'
import {
  ConfigColumnImportance,
  WidgetColumnImportance,
} from './Widgets/ColumnImportance'
import {
  ConfigColumnDataDistribution,
  WidgetColumnDataDistribution,
} from './Widgets/ColumnDataDistribution'
import {
  ConfigConfusionMatrix,
  WidgetConfusionMatrix,
} from './Widgets/ConfusionMatrix'
import {
  ConfigImportantFeaturesAffectTarget,
  WidgetImportantFeaturesAffectTarget,
} from './Widgets/ImportantFeaturesAffectTarget'
import {
  ConfigPrediction,
  ConfigPredictionForecast,
  WidgetPrediction,
} from './Widgets/Prediction'
import {
  ConfigAlgorithmsUsed,
  WidgetAlgorithmsUsed,
} from './Widgets/AlgorithmsUsed'
import { ConfigHistory, WidgetHistory } from './Widgets/History'
import {
  ConfigAdvancedStatistics,
  WidgetAdvancedStatistics,
} from './Widgets/AdvancedStatistics'
import { ConfigPPS, WidgetPPS } from './Widgets/PPS'
import {
  ConfigPearsonMatrix,
  WidgetPearsonMatrix,
} from './Widgets/PearsonMatrix'
import {
  ConfigVariableCorrelation,
  WidgetVariableCorrelation,
} from './Widgets/VariableCorrelation'
import { ConfigDTW, WidgetDTW } from './Widgets/DecisionTreeWidget'
import {
  ConfigModelPrediction,
  WidgetModelPrediction,
} from './Widgets/ModelPrediction'
import {
  ConfigForecastingHorizon,
  WidgetForecastingHorizon,
} from './Widgets/ForecastingHorizon'
import { WidgetDataTrend, ConfigDataTrend } from './Widgets/DataTrend'
import { WidgetYearlyTrend, ConfigYearlyTrend } from './Widgets/YearlyTrend'
import { WidgetWeeklyTrend, ConfigWeeklyTrend } from './Widgets/WeeklyTrend'
import {
  WidgetMediaContributionBars,
  ConfigMediaContributionBars,
} from './Widgets/MediaContributionBarWidget'
import {
  WidgetMediaContributionLines,
  ConfigMediaContributionLines,
} from './Widgets/MediaContributionLinesWidget'
import {
  WidgetMMMSeasonalData,
  ConfigMMMSeasonalData,
} from './Widgets/MMMSeasonalData'
import {
  ConfigMMMSaturationAll,
  WidgetMMMSaturationAll,
} from './Widgets/MMMSaturationAll'
import {
  ConfigMMMSaturationChannel,
  WidgetMMMSaturationChannel,
} from './Widgets/MMMSaturationChannel'
import './WidgetReport.css'
import {
  ConfigPredictionChart,
  WidgetPredictionChart,
} from './Widgets/PredictionChartWidget'
import {
  ConfigDynamicSpend,
  WidgetDynamicSpend,
} from './Widgets/DynamicSpendWidget'
import {
  ConfigLagAndCarryover,
  WidgetLagAndCarryover,
} from './Widgets/LagAndCarryoverWidget'
import { ConfigInfluence, WidgetInfluence } from './Widgets/InfluenceWidget'
import { ConfigBestSpend, WidgetBestSpend } from './Widgets/BestSpendWidget'
import {
  ConfigShapleyValues,
  WidgetShapleyValues,
} from './Widgets/ShapleyValuesWidget'
import { ConfigLIDAGraph, WidgetLIDAGraph } from './Widgets/LIDAGraph'
import { ConfigKeyFeatures, WidgetKeyFeatures } from './Widgets/KeyFeatures'
import { ConfigRocAUCurve, WidgetRocAUCurve } from './Widgets/RocAUCurve'
import { useTranslation } from 'react-i18next'

//Changing the Ids will destroy saved reports
const widgets = [
  {
    id: 1,
    name: 'Data distribution',
    icon: '/icons/foundation_graph-pie.svg',
    config: ConfigDataDistribution,
    widget: WidgetDataDistribution,
    help: `data-distribution-8fu8c3`,
  },
  {
    id: 2,
    name: 'Performance',
    //icon: '/icons/Performance-24.svg',
    icon: '/icons/Performance.svg',
    config: ConfigPerformance,
    widget: WidgetPerformance,
    help: `overfitting-vs-underfitting-r68vxo`,
  },
  {
    id: 3,
    name: 'Global accuracy',
    icon: '/icons/Globalaccuracy-23.svg',
    config: ConfigModelAccuracy,
    widget: WidgetModelAccuracy,
    help: `global-accuracy-1yzxobx`,
  },
  {
    id: 4,
    name: 'Effect of features on target',
    icon: '/icons/Feature_influence_target.svg',
    config: ConfigImportantFeaturesAffectTarget,
    widget: WidgetImportantFeaturesAffectTarget,
    help: `feature-importance-1xrb1ah`,
  },
  {
    id: 5,
    name: 'Column Importance',
    icon: '/icons/Columns_importance.svg',
    config: ConfigColumnImportance,
    widget: WidgetColumnImportance,
    help: `column-importance-4vp1ng`,
  },
  {
    id: 6,
    name: 'Predicted VS Actual',
    icon: '/icons/Predicted_vs_actual.svg',
    config: ConfigPredictedActual,
    widget: WidgetPredictedActual,
    help: `predicted-vs-actual-1rf223b`,
  },
  {
    id: 7,
    name: 'Predict',
    icon: '/icons/Predict.svg',
    config: ConfigPrediction,
    widget: WidgetPrediction,
    help: `predict-1gcy3pa`,
  },
  {
    id: 8,
    name: 'Column distribution',
    icon: '/icons/codicon_graph.svg',
    config: ConfigColumnDataDistribution,
    widget: WidgetColumnDataDistribution,
    help: `column-distribution-u8j75`,
  },
  {
    id: 9,
    name: 'Algorithms used',
    icon: '/icons/Algoritmsused-06.svg',
    config: ConfigAlgorithmsUsed,
    widget: WidgetAlgorithmsUsed,
    help: `algorithms-used-ycpmgq`,
  },
  {
    id: 10,
    name: 'Storytelling',
    icon: '/icons/Storyteling-05.svg',
    config: ConfigStorytelling,
    widget: WidgetStorytelling,
    help: `storytelling-1g45a5s`,
  },
  {
    id: 11,
    name: 'Confusion Matrix',
    icon: '/icons/Confusion-matrix.svg',
    config: ConfigConfusionMatrix,
    widget: WidgetConfusionMatrix,
    help: `confusion-matrix-k2monl`,
  },
  {
    id: 12,
    name: 'History',
    icon: '/icons/History.svg',
    config: ConfigHistory,
    widget: WidgetHistory,
    help: `history-r68vxo`,
  },
  {
    id: 13,
    name: 'Advanced Statistics',
    icon: '/icons/Advanced_statistics.svg',
    config: ConfigAdvancedStatistics,
    widget: WidgetAdvancedStatistics,
    help: `advanced-statistics-1skca2c`,
  },
  {
    id: 14,
    name: 'Correlation',
    icon: '/icons/Correlation.svg',
    config: ConfigVariableCorrelation,
    widget: WidgetVariableCorrelation,
    help: `variable-correlation-1ecbd24`,
  },
  {
    id: 15,
    name: 'Predictive Power Score',
    icon: '/icons/Predictive_Power_Score.svg',
    config: ConfigPPS,
    widget: WidgetPPS,
    help: `predictive-power-score-1az82wg`,
  },
  {
    id: 16,
    name: 'Correlation Matrix',
    icon: '/icons/Correlation_matrix.svg',
    config: ConfigPearsonMatrix,
    widget: WidgetPearsonMatrix,
    help: `pearson-matrix-1bue39`,
  },
  {
    id: 17,
    name: 'Visual explainability',
    icon: '/icons/Explainability-14.svg',
    config: ConfigDTW,
    widget: WidgetDTW,
    help: `visual-explainability-1hozz8l`,
  },
  {
    id: 18,
    name: 'Forecast',
    icon: '/icons/Forecast.svg',
    config: ConfigPredictionForecast,
    widget: WidgetPrediction,
    help: `forecast-prediction-50wo7x/`,
  },
  {
    id: 19,
    name: 'Model Prediction',
    icon: '/icons/ModelPrediction.svg',
    config: ConfigModelPrediction,
    widget: WidgetModelPrediction,
    help: `model-prediction-1rs9kfm`,
  },
  {
    id: 20,
    name: 'Forecasting Accuracy',
    icon: '/icons/ForecastingAccuracy.svg',
    config: ConfigForecastingHorizon,
    widget: WidgetForecastingHorizon,
    help: `forecast-accuracy-nq92ps`,
  },
  {
    id: 21,
    name: 'Data Trend',
    icon: '/icons/DataTrend.svg',
    config: ConfigDataTrend,
    widget: WidgetDataTrend,
    help: `forecast-data-trends-oburq5`,
  },

  {
    id: 22,
    name: 'Weekly Trend',
    icon: '/icons/Weeklytrend.svg',
    config: ConfigWeeklyTrend,
    widget: WidgetWeeklyTrend,
    help: `weekly-data-trend-104oy6s`,
  },
  {
    id: 23,
    name: 'Yearly Trend',
    icon: '/icons/YearlyTrend.svg',
    config: ConfigYearlyTrend,
    widget: WidgetYearlyTrend,
    help: `forecast-yearly-data-trends-12wej34`,
  },
  {
    id: 24,
    name: 'Media contribution',
    icon: '/icons/Weeklytrend.svg',
    config: ConfigMediaContributionBars,
    widget: WidgetMediaContributionBars,
    help: `media-contribution-1gxazob`,
  },
  {
    id: 25,
    name: 'Seasonal Data',
    icon: '/icons/season-mmm.svg',
    config: ConfigMMMSeasonalData,
    widget: WidgetMMMSeasonalData,
    help: `seasonal-trend-z5u2s8`,
  },
  {
    id: 26,
    name: 'Saturation Curves',
    icon: '/icons/Channel_influence.svg',
    config: ConfigMMMSaturationChannel,
    widget: WidgetMMMSaturationChannel,
    help: `saturation-per-channel-14n1437`,
  },
  {
    id: 27,
    name: 'Saturation Line',
    icon: '/icons/season-mmm.svg',
    config: ConfigMMMSaturationAll,
    widget: WidgetMMMSaturationAll,
    help: `saturation-752pwh`,
  },
  {
    id: 29,
    name: 'Media contribution per week',
    icon: '/icons/Double_line.svg',
    config: ConfigMediaContributionLines,
    widget: WidgetMediaContributionLines,
    help: `media-contribution-lines`,
  },
  {
    id: 30,
    name: 'Response Model accuracy',
    icon: '/icons/Channel_influence.svg',
    config: ConfigPredictionChart,
    widget: WidgetPredictionChart,
    help: `prediction-chart`,
  },
  {
    id: 31,
    name: 'Ad to consumer response',
    icon: '/icons/dynamic-spend.svg',
    config: ConfigDynamicSpend,
    widget: WidgetDynamicSpend,
    help: `dynamic-spend`,
  },
  {
    id: 32,
    name: 'Lag and Carryover',
    icon: '/icons/lag-carryover.svg',
    config: ConfigLagAndCarryover,
    widget: WidgetLagAndCarryover,
    help: `lag-carryover`,
  },
  {
    id: 33,
    name: 'Underlying effects',
    icon: '/icons/underlying-effects.svg',
    config: ConfigInfluence,
    widget: WidgetInfluence,
    help: `influence`,
  },
  {
    id: 34,
    name: 'Shape effect',
    icon: '/icons/shape-effect.svg',
    config: ConfigBestSpend,
    widget: WidgetBestSpend,
    help: `best-spend`,
  },
  {
    id: 35,
    name: 'Column Importance analysis',
    icon: '/icons/var-importance.svg',
    config: ConfigShapleyValues,
    widget: WidgetShapleyValues,
    help: `shapley-values`,
  },
  {
    id: 36,
    name: 'AI Generated graphs',
    icon: '/icons/AIGraphs.svg',
    config: ConfigLIDAGraph,
    widget: WidgetLIDAGraph,
    help: `lida-graphs`,
  },
  {
    id: 37,
    name: 'Key features',
    icon: '/icons/keyFeatures.svg',
    config: ConfigKeyFeatures,
    widget: WidgetKeyFeatures,
    help: `lida-graphs`,
  },
  {
    id: 38,
    name: 'ROC curve (AUC)',
    icon: '/icons/rocauc.svg',
    config: ConfigRocAUCurve,
    widget: WidgetRocAUCurve,
    help: ``,
  },
]

export const widgetsMap = widgets.reduce((dict, i) => {
  dict[i.name] = i
  dict[i.id] = i
  return dict
}, {})

const getWidgetsForModel = (model, llmDisabled = false) => {
  const baseWidgets = [
    widgetsMap['Global accuracy'],
    widgetsMap['Storytelling'],
    widgetsMap['History'],
    widgetsMap['Advanced Statistics'],
  ]

  const specificWidgets = []
  const mmmWidgets = []

  if (model) {
    if (isMMMModel(model)) {
      // mmmWidgets.push(widgetsMap['Column distribution'])
      mmmWidgets.push(widgetsMap['Correlation'])
      // mmmWidgets.push(widgetsMap['Predict'])
      // mmmWidgets.push(widgetsMap['Seasonal Data'])
      // mmmWidgets.push(widgetsMap['Saturation Curves'])
      // mmmWidgets.push(widgetsMap['Saturation Line'])
      mmmWidgets.push(widgetsMap['Media contribution'])
      mmmWidgets.push(widgetsMap['Media contribution per week'])
      mmmWidgets.push(widgetsMap['Lag and Carryover'])
      mmmWidgets.push(widgetsMap['Underlying effects'])
      mmmWidgets.push(widgetsMap['Shape effect'])
      // Not ready yet
      // mmmWidgets.push(widgetsMap['Response Model accuracy'])
      mmmWidgets.push(widgetsMap['Ad to consumer response'])
    } else {
      specificWidgets.push(widgetsMap['AI Generated graphs'])
    }

    if (
      isMulticlassModel(model) ||
      isBinaryModel(model) ||
      isRegressionModel(model)
    ) {
      specificWidgets.push(widgetsMap['Performance'])
      specificWidgets.push(widgetsMap['Column Importance'])
      specificWidgets.push(widgetsMap['Effect of features on target'])
      specificWidgets.push(widgetsMap['Visual explainability'])
      specificWidgets.push(widgetsMap['Correlation'])
      specificWidgets.push(widgetsMap['Key features'])
    }
    const problem = formatedProblemType(model)
    if (
      problem === 'Classification (binary)' ||
      problem === 'Classification (multiclass)'
    ) {
      specificWidgets.push(widgetsMap['Confusion Matrix'])
      specificWidgets.push(widgetsMap['ROC curve (AUC)'])
    }
    if (
      model.status === 'trained' &&
      !['time_series_regression'].includes(model.problem_type) &&
      model?.details?.individual_feature_importance
    ) {
      specificWidgets.push(widgetsMap['Column Importance analysis'])
    }
    if (isForecastingModel(model)) {
      specificWidgets.push(widgetsMap['Predict'])
      specificWidgets.push(widgetsMap['Forecast'])
      specificWidgets.push(widgetsMap['Model Prediction'])
      specificWidgets.push(widgetsMap['Forecasting Accuracy'])
      // specificWidgets.push(widgetsMap['Data Trend'])
      // specificWidgets.push(widgetsMap['Yearly Trend'])
      // specificWidgets.push(widgetsMap['Weekly Trend'])
    }
    if (isRegressionModel(model) && model?.true_vs_predict) {
      specificWidgets.push(widgetsMap['Predicted VS Actual'])
    }
    if (model.mljar_description) {
      specificWidgets.push(widgetsMap['Algorithms used'])
    }
    if (model.pps && !isForecastingModel(model)) {
      specificWidgets.push(widgetsMap['Predictive Power Score'])
    }
    if (model.pearson && !isForecastingModel(model)) {
      specificWidgets.push(widgetsMap['Correlation Matrix'])
    }
  }

  return [...baseWidgets, ...specificWidgets, ...mmmWidgets]
}

export default function MenuCreateWidget({
  model,
  config,
  id,
  llmDisabled = false,
  onFinish = () => {},
  ...props
}) {
  const { t } = useTranslation()
  const [stage, setStage] = useState(false)

  const CreateWidgets = useMemo(() => {
    return getWidgetsForModel(model, llmDisabled)
      .filter((w) => w)
      .map((w) => (
        <Col onClick={() => setStage(w)} md={4} key={w?.name} xs={12} id={id}>
          <Row className="widget-box-select flex-column my-1 mx-1">
            <Col xs={'auto'}>
              <div
                className="icon-menu-widget"
                style={{
                  maskImage: `url(${w?.icon})`,
                  WebkitMaskImage: `url(${w?.icon})`,
                }}
              ></div>
            </Col>
            <Col align={'center'} xs={'auto'}>
              {t(w?.name)}
            </Col>
          </Row>
        </Col>
      ))
    // eslint-disable-next-line
  }, [model, llmDisabled, t])

  useEffect(
    () => {
      if (config && !stage) {
        setStage(widgetsMap[config.config.id])
      }
    },
    // eslint-disable-next-line
    [],
  )

  const Config = stage?.config
  return (
    <Row {...props}>
      <Col
        align="center"
        className="menu-new-header h4 position-relative"
        xs={12}
      >
        {t(stage ? stage.name : 'Add a new widget')}
        <div
          onClick={() => {
            setStage(false)
            if (config) onFinish(null)
          }}
          className={`nav-back-menu-widget ${stage ? '' : 'd-none'}`}
        >
          <FaArrowLeft size={30} />
        </div>
      </Col>
      <Col className={`menu-widgets ${stage ? 'inactive' : ''}`} xs={12}>
        <Row className="mx-2">{CreateWidgets}</Row>
      </Col>
      <Col
        className={`menu-config-widgets ${!stage ? 'inactive' : ''}`}
        xs={12}
      >
        <Row className="mx-2">
          {stage && (
            <Config
              onFinish={(c) => {
                if (!c) {
                  setStage(null)
                  if (config) onFinish(null)
                } else
                  onFinish(
                    stage.widget,
                    { ...c, id: stage.id, name: stage.name },
                    config,
                    stage.help,
                  )
              }}
              model={model}
              config={config?.config ?? {}}
            />
          )}
        </Row>
      </Col>
    </Row>
  )
}

import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
} from 'react-bootstrap'
import { MdOutlineInsertChartOutlined } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../providers/AuthProvider'
import {
  modelIsImporting,
  modelValidForCorrelationVisualizations,
} from '../../util/models'
import { CustomVarCorrealtion } from './CustomVarCorrelation'
import CrashFallback from '../crash-fallback/CrashFallback'
import CorrelatinMatrixViewdata from '../view-data/CorrelationMatrixViewdata'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Loading from '../loading/LoadingSmall'
import HelpTooltip from './HelpTooltip'
import AutogenBot from '../bot/AutogenBot'
import { useModels } from '../../providers/ModelProvider'
import { getModelRepository } from '../../services/model'
import { useQuery } from 'react-query'
import { getDocuments, getTags } from '../../services/document'

export default function InsightsAutoml({
  model,
  getHead,
  dataId,
  onChange,
  setTitle,
  customOrder,
  updateModel = () => {},
  rowsPerPage = 10,
  titlePrefix = 'Sample',
  exportButton = false,
  columnToType = {},
  maxHeight = '500px',
  onColumnTypeChanged = (column, newType) =>
    console.log('Change column', column, 'to the type', newType),
  showStatistics = true,
  errorMessage = 'Failed to retrieve data',
  hasEditMode = false,
  ...props
}) {
  const { token, signout } = useAuth()
  const { t } = useTranslation()
  let { llmDisabled } = useAuth()
  const [viewStatistics, setViewStatistics] = useState(false)
  const { requestUpdate } = useModels()

  useEffect(() => {
    if (setTitle) setTitle(`${t('Insights')} | ${t('Nextbrain')}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isImporting = model?.status !== 'error' && modelIsImporting(model)
  const showCorrelation =
    model && showStatistics && modelValidForCorrelationVisualizations(model)

  const { data: repositoryId } = useQuery(
    ['modelAssistant-repo-insights', model?.id],
    async () => {
      const repo = await getModelRepository({
        modelId: model?.id,
        token,
        signout,
      })
      return repo?.id
    },
    { staleTime: Infinity },
  )

  const { data: _tags } = useQuery(
    ['repository-tags', repositoryId],
    async () => {
      if (!repositoryId) return null
      const tags = await getTags({
        repositoryId,
        token,
        signout,
      })
      if (!Array.isArray(tags)) return []
      tags.sort((a, b) => a.localeCompare(b))
      return tags
    },
    { staleTime: Infinity },
  )

  const { data: documents } = useQuery(
    ['documents', repositoryId, token],
    async () => {
      if (!repositoryId) return null
      return await getDocuments({ repositoryId, token, signout })
    },
    { staleTime: Infinity },
  )

  const tags = []

  if (Array.isArray(documents)) {
    tags.push({
      name: 'All documents',
      type: 'document',
    })
    tags.push(
      ...documents.map((d) => ({ name: d.name, type: 'document', id: d.id })),
    )

    if (Array.isArray(_tags))
      tags.push(..._tags.map((t) => ({ name: t, type: 'tag' })))
  }

  return (
    <div
      {...props}
      className={`view-data-component ${
        hasEditMode ? 'with-edit-mode' : 'no-edit-mode'
      }`}
    >
      {model?.id && showStatistics && !llmDisabled && (
        <>
          <Row
            className={`w-100 ${
              isImporting ? 'disabled-blur' : ''
            } position-relative mt-4`}
          >
            {isImporting && (
              <Col
                className="not-blur position-absolute d-flex align-items-center justify-content-center h-100"
                xs={12}
              >
                <Loading message={'Importing'} />
              </Col>
            )}
            <Tabs>
              <Row className={`alt-tabsdiv chatbot-explore-data d-none`}>
                <Col xs={12}>
                  <TabList className="ps-0">
                    <Tab>
                      <span className="ms-3 h6 navigate-explore-header">
                        {t('Explore')}
                      </span>
                      <HelpTooltip
                        className="ms-1 help-select-icon"
                        message={t('Explore your dataset with the assistant')}
                        helpLink={'chat-interaction-2tt9io'}
                      />
                    </Tab>
                  </TabList>
                </Col>
              </Row>
              <TabPanel>
                {model?.dataset?.status === 'imported' && (
                  <Row className="w-100 pl-3">
                    <Col className="position-relative" xs={12}>
                      <AutogenBot
                        model={model}
                        extraControls={
                          showCorrelation ? (
                            <OverlayTrigger
                              rootClose={true}
                              trigger={['hover', 'focus']}
                              placement="auto"
                              delay={{ show: 200, hide: 0 }}
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  {t('View correlation matrix')}
                                </Tooltip>
                              )}
                            >
                              <Button
                                className="linkshare link original"
                                onClick={() => setViewStatistics(true)}
                              >
                                <MdOutlineInsertChartOutlined
                                  className="ms-2 icon-btn"
                                  size={34}
                                />
                              </Button>
                            </OverlayTrigger>
                          ) : null
                        }
                        resizeRelative={true}
                        refreshModel={() => {
                          requestUpdate(model)
                        }}
                        tags={tags}
                      />
                    </Col>
                  </Row>
                )}
              </TabPanel>
            </Tabs>
          </Row>
        </>
      )}
      {showCorrelation && (
        <Modal
          size={'xl'}
          show={viewStatistics}
          onHide={() => setViewStatistics(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {model?.dataset.cols < 500 && (
              <CorrelatinMatrixViewdata
                model={model}
                updateModel={updateModel}
              />
            )}
            <Row className="mt-5">
              <CrashFallback
                style={{ minHeight: '0px' }}
                onCrash={() => {}}
                t={t}
                message={t(' ')}
              >
                <CustomVarCorrealtion model={model} />
              </CrashFallback>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

import {
  Row,
  Col,
  Button,
  Image,
  Accordion,
  Modal,
  OverlayTrigger,
  Tooltip,
  Form,
} from 'react-bootstrap'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import TextareaAutosize from 'react-textarea-autosize'
import { useSearchParams } from 'react-router-dom'
import Lightbox from 'react-image-lightbox'
import {
  FaCode,
  FaFileDownload,
  FaFilePdf,
  FaInfoCircle,
  FaSpinner,
} from 'react-icons/fa'
import { FaChevronRight, FaPencil } from 'react-icons/fa6'
import { TbPrompt } from 'react-icons/tb'
import { GrStatusUnknown } from 'react-icons/gr'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import CodeMirror from '@uiw/react-codemirror'
import { python } from '@codemirror/lang-python'
import { solarizedDark } from '@uiw/codemirror-theme-solarized'
import { VscOutput } from 'react-icons/vsc'

import { useAuth } from '../../providers/AuthProvider'
import {
  addRecipeParameters,
  executeCustomApp,
  getRecipeById,
  getRecipeExecution,
  getRecipeExecutionFile,
  updateRecipeById,
} from '../../services/recipe'
import Loading from '../loading/LoadingSmall'
import { awaitTaskCallCallback } from '../../services/base'
import NextbrainSelect from '../model-content/NextbrainSelect'
import { BouncyLoader } from '../utils/ui'
import { InlineEdit } from '../inline-edit/InlineEdit'
import {
  MdEmail,
  MdExtension,
  MdOutlineDescription,
  MdPlayCircle,
} from 'react-icons/md'
import SingleAccordion from '../single-accordion/SingleAccordion'
import MarkdownView from 'react-showdown'
import { ResponsiveLine } from '@nivo/line'
import { useModels } from '../../providers/ModelProvider'

const SCOPE_OPTIONS = ['model', 'workspace', 'user', 'company']

function isImageFile(filename) {
  const imageExtensions = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'tiff',
    'webp',
    'svg',
    'ico',
  ]
  const extension = filename.split('.').pop().toLowerCase()
  return imageExtensions.includes(extension)
}

function download(file, data) {
  const link = document.createElement('a')
  if (typeof data === 'string')
    link.setAttribute(
      'href',
      `data:application/octet-stream;base64,${btoa(
        unescape(encodeURIComponent(data)),
      )}`,
    )
  else {
    const url = URL.createObjectURL(data)
    link.setAttribute('href', url)
    setTimeout(() => {
      URL.revokeObjectURL(url)
    }, 5000)
  }
  link.setAttribute('download', `${file}`)
  document.body.appendChild(link)
  link.click()
}

function ImageItem({ filename, data, processed }) {
  const [show, setShow] = useState(false)

  return (
    <Row className="file-fragment-autogen position-relative">
      <Col xs={12}>
        {show && (
          <Lightbox mainSrc={processed} onCloseRequest={() => setShow(false)} />
        )}
        <Image
          className="botimg d-block"
          style={{
            height: '120px',
            width: '200px',
            objectFit: 'contain',
          }}
          src={processed}
          onClick={(e) => {
            e.stopPropagation()
            setShow(true)
          }}
        />
      </Col>
      <div
        className="icon-btn position-absolute"
        style={{
          bottom: 0,
          left: 'calc(100% - 40px)',
          zIndex: 1,
        }}
        onClick={(e) => {
          e.stopPropagation()
          download(filename, data)
        }}
      >
        <Button className="original p-1">
          <FaFileDownload size={20} />
        </Button>
      </div>
    </Row>
  )
}

function FileItem({ filename, data }) {
  return (
    <Row
      className="file-fragment-autogen flex-column mx-1 position-relative overflow-hidden justify-content-center"
      title={filename}
      style={{ minHeight: '150px' }}
      onClick={() => {
        download(filename, data)
      }}
    >
      <Col className="text-center smallp" xs={12}>
        <div
          className="d-inline-block text-truncate w-100 color-white"
          style={{ marginTop: 4 }}
        >
          {filename}
        </div>
      </Col>
      <Col className="dflex-center mt-2" xs={12}>
        <GrStatusUnknown size={45} />
      </Col>
    </Row>
  )
}

function ExecutionFiles({ executionResult }) {
  const { token, signout } = useAuth()
  const { data: imageData, isLoading: imageLoading } = useQuery(
    [
      'image-data-execution',
      executionResult?.recipe_id,
      executionResult?.timestamp,
    ],
    async () => {
      if (!executionResult?.files?.length) return []
      const files = await Promise.all(
        executionResult.files.map(
          async (filename) =>
            await getRecipeExecutionFile({
              recipeId: executionResult.recipe_id,
              timestamp: executionResult.timestamp,
              filename,
              token,
              signout,
            })
              .then(async (r) => {
                if (!r.ok)
                  return {
                    filename,
                    data: null,
                  }
                return {
                  filename,
                  data: await r.blob(),
                }
              })
              .catch((e) => ({
                filename,
                data: null,
              })),
        ),
      )
      return files
    },
    { staleTime: Infinity },
  )
  const [processedFiles, setProcessedFiles] = useState(null)

  useEffect(() => {
    if (Array.isArray(imageData)) {
      const processed = imageData.map(({ filename, data }) => {
        return {
          filename,
          data,
          processed:
            isImageFile(filename) && data ? URL.createObjectURL(data) : null,
        }
      })
      setProcessedFiles(processed)
      return () => {
        processed.forEach((p) => {
          if (p.processed) URL.revokeObjectURL(p.processed)
        })
      }
    }
  }, [imageData])

  if (!executionResult?.files?.length) return null
  return (
    <Row className="my-3">
      {imageLoading || !Array.isArray(processedFiles) ? (
        <Loading />
      ) : (
        <>
          {processedFiles.map(({ filename, data, processed }) => (
            <Col
              xl={4}
              md={6}
              sm={12}
              key={filename}
              className="d-flex justify-content-center mb-3"
            >
              {processed ? (
                <ImageItem
                  filename={filename}
                  data={data}
                  processed={processed}
                />
              ) : (
                <FileItem filename={filename} data={data} />
              )}
            </Col>
          ))}
        </>
      )}
    </Row>
  )
}

export function EditModalRecipeStatus({ model, onSave = () => {} }) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { token, signout } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const recipeId = searchParams.get('appId')
  const nameRef = useRef()

  const [tested, setTested] = useState({})
  const options = useMemo(() => {
    return SCOPE_OPTIONS.map((scope) => ({
      value: scope,
      label: t(`Scope_${scope}`),
    }))
  }, [t])

  const { data: recipe, isLoading } = useQuery(
    ['recipe', recipeId],
    async () => {
      if (!recipeId) return null
      const recipe = await getRecipeById({
        recipeId,
        modelId: model?.id,
        token,
        signout,
      })
      if (!recipe) throw new Error('Recipe not found')
      return recipe
    },
    { staleTime: Infinity },
  )

  const [accordionStatus, setAccordionStatus] = useState({
    description: true,
  })
  const [renderAccordionItems, setRenderAccordionItems] = useState({
    description: true,
  })
  const triggerRender = (i) => {
    setTimeout(() => {
      setRenderAccordionItems((r) => ({ ...r, [i]: true }))
    }, 100)
  }

  const edit = useRef({})
  const [confirm, setConfirm] = useState(false)

  useEffect(() => {
    if (confirm) {
      const update = { ...edit.current, status: 'configured' }
      updateRecipeById({
        modelId: model?.id,
        recipeId,
        update,
        token,
        signout,
      })
        .then((r) => {
          if (!r?.recipe_id) {
            NotificationManager.warning(t('Recipe could not be saved'))
          } else {
            NotificationManager.success(t('Recipe saved successfully'))
            queryClient.invalidateQueries(['recipe', recipeId])
            queryClient.invalidateQueries(['custom-apps', model?.id, token])
            onSave()
          }
        })
        .catch((e) =>
          NotificationManager.warning(t('Recipe could not be saved')),
        )
        .finally(() => setConfirm(false))
    }
    // eslint-disable-next-line
  }, [confirm])

  const [progress, setProgress] = useState({
    cpuUsage: [{ x: 0.5, y: 0 }],
    memoryUsage: [{ x: 0.5, y: 0 }],
    time_elapsed: 0,
  })
  const loadingMessage = useRef(null)

  useEffect(() => {
    if (recipe?.status === 'generating-data') {
      const start = Date.now()
      const messages = [
        [0, 'Generating description (code interpretation)'],
        [6000, 'Generating title'],
        [11000, 'Processing code to create app'],
        [16000, 'Detecting dependencies'],
      ]
      const iv = setInterval(() => {
        const elapsed = Date.now() - start
        const currentMessage = messages.find((m) => m[0] >= elapsed)
        let m = loadingMessage.current
        if (currentMessage) m = currentMessage[1]
        else m = 'Saving app'
        if (m !== loadingMessage.current) {
          loadingMessage.current = m
        }
      }, 3000)
      return () => {
        clearInterval(iv)
        loadingMessage.current = null
        queryClient.invalidateQueries(['recipe', recipeId])
      }
    }
    // eslint-disable-next-line
  }, [recipe?.status])

  useEffect(() => {
    if (tested?.doTest && recipe && !tested?.testing) {
      loadingMessage.current = null
      setTested({ doTest: false, testing: true })

      setProgress({
        cpuUsage: [{ x: 0.5, y: 0 }],
        memoryUsage: [{ x: 0.5, y: 0 }],
        time_elapsed: 0,
      })
      awaitTaskCallCallback(
        executeCustomApp,
        2500,
        null,
        (d) => {
          const NUM_POINTS = 20
          const update = {}
          if (d?.data?.cpu_usage || d?.data?.cpu_usage === 0)
            update.cpu = {
              x: Number.parseInt(d.data.time_elapsed),
              y: d.data.cpu_usage * 100,
            }
          if (d?.data?.memory_usage || d?.data?.memory_usage === 0)
            update.memory = {
              x: Number.parseInt(d.data.time_elapsed),
              y: d.data.memory_usage * 100,
            }

          if (Object.keys(update).length)
            setProgress((p) => {
              if (update.cpu) {
                /*if (p.cpuUsage.length < NUM_POINTS)
                  p.cpuUsage.push(
                    ...new Array(NUM_POINTS - p.cpuUsage.length)
                      .fill(null)
                      .map((_, i) => ({
                        x: update.time_elapsed,
                        y: i === 0 ? 0 : null,
                      })),
                  )*/
                if (p.cpuUsage.length >= NUM_POINTS) p.cpuUsage.splice(0, 1)
                p.cpuUsage.push(update.cpu)
              }
              if (update.memory) {
                /*if (p.memoryUsage.length < NUM_POINTS)
                  p.memoryUsage.push(
                    ...new Array(NUM_POINTS - p.memoryUsage.length)
                      .fill(null)
                      .map((_, i) => ({
                        x: update.time_elapsed,
                        y: i === 0 ? 0 : null,
                      })),
                  )*/
                if (p.memoryUsage.length >= NUM_POINTS)
                  p.memoryUsage.splice(0, 1)
                p.memoryUsage.push(update.memory)
              }
              return { ...p }
            })
        },
        {
          modelId: model?.id,
          test: true,
          token,
          signout,
          recipeId,
        },
      )
        .then((r) => {
          if (r?.is_error) {
            NotificationManager.warning(t('App could not be executed'))
            setTested((t) => ({ ...t, testing: false, success: false }))
          } else {
            loadingMessage.current = 'Finishing'
            setTested((t) => ({ ...t, testing: false, success: true }))
            NotificationManager.success(
              t(
                'App executed successfully, you can now save it and execute it from the app page',
              ),
            )
          }
        })
        .catch((e) => {
          setTested((t) => ({ ...t, testing: false, success: false }))
          NotificationManager.warning(t('Error executing app'))
        })
    }
    // eslint-disable-next-line
  }, [tested])

  useEffect(() => {
    if (recipe?.status === 'generating-data') {
      const interval = setInterval(() => {
        queryClient.invalidateQueries(['recipe', recipeId])
      }, 5000)
      return () => clearInterval(interval)
    } else if (recipe?.status === 'pending-user-configuration') {
      const interval = setInterval(() => {
        queryClient.invalidateQueries(['custom-apps', model?.id, token])
      }, 5000)
      return () => clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [recipe])

  if (!recipeId) return null

  return (
    <Modal
      show={true}
      onHide={() => {
        searchParams.delete('appId')
        setSearchParams(searchParams)
      }}
      size="xl"
    >
      <Modal.Header closeButton className="mb-0">
        <h5
          className="d-flex align-items-center input-grow-full mb-0"
          style={{
            minWidth: 'min(70vw, 500px)',
            maxWidth: 'min(70vw, 500px)',
            minHeight: '40px',
            maxHeight: '40px',
          }}
        >
          {recipe && recipe?.name && (
            <>
              <InlineEdit
                onEdit={(value) => {
                  edit.current.name = value
                }}
                key={edit.current.name ?? recipe?.name}
                text={edit?.current?.name ?? recipe?.name}
                className="d-inline-block text-truncate "
                style={{ maxWidth: '100%' }}
                ref={nameRef}
              />
              <FaPencil
                className="icon-btn ms-1"
                style={{ marginTop: '-5px' }}
                onClick={() => nameRef.current.click()}
                size={15}
              />
            </>
          )}
        </h5>
      </Modal.Header>
      <Modal.Body className="pt-0">
        {isLoading ||
        recipe.status === 'generating-data' ||
        tested?.testing ||
        tested?.doTest ||
        confirm ? (
          <Row>
            {tested?.testing && (
              <Col xs={12} className="mt-2">
                <ExecuteProgress {...progress} />
              </Col>
            )}
            <Col
              xs={12}
              className="mb-5 d-flex align-items-center justify-content-center"
              style={{ minHeight: '20vh' }}
            >
              <Loading
                message={
                  tested?.testing || tested?.doTest
                    ? 'Testing App'
                    : loadingMessage?.current || 'Loading'
                }
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className="d-flex align-items-center" xs={'auto'}>
              <label
                htmlFor={`recipe-scope-${recipeId}`}
                className="nb-label d-flex align-items-center color-white"
              >
                <MdExtension size={20} className="me-1" />
                {t('Scope')} / {t('Visibility')}
              </label>
            </Col>
            <Col
              className="d-flex align-items-center"
              xs={'auto'}
              style={{ minWidth: '350px' }}
            >
              <NextbrainSelect
                defaultValue={
                  options.find(
                    (o) => o.value === (edit?.current?.scope ?? recipe?.scope),
                  ) ?? options[0]
                }
                onChange={(value) => (edit.current.scope = value?.value)}
                className="min-w-full"
                options={options}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                allowSelectAll={true}
              />
            </Col>
            <Col className="mt-2" xs={12}>
              <label
                htmlFor={`recipe-description-${recipeId}`}
                className="d-flex align-items-center color-white cursor-pointer"
                onClick={() => {
                  if (!accordionStatus?.description)
                    setRenderAccordionItems((r) => ({
                      ...r,
                      description: false,
                    }))
                  setAccordionStatus((a) => ({
                    ...a,
                    description: !accordionStatus?.description,
                  }))
                  triggerRender('description')
                }}
              >
                <MdOutlineDescription size={20} className="me-1" />
                {t('Description')}
                <FaChevronRight
                  className="ms-2"
                  style={{
                    transform: `rotate(${
                      accordionStatus?.description ? '90' : '0'
                    }deg)`,
                    transitionDuration: '0.3s',
                  }}
                />
              </label>
            </Col>
            <Col className="mb-2" xs={12}>
              <SingleAccordion
                header={false}
                forceStatus={!!accordionStatus?.description}
                className="single-accordion-cookies w-100"
                defaultStyles=""
                bodyProps={{
                  style: { fontSize: '14px' },
                }}
              >
                {renderAccordionItems?.description && (
                  <TextareaAutosize
                    id={`recipe-description-${recipeId}`}
                    style={{ minWidth: '100%' }}
                    className="nb-input w-full p-2 mt-2"
                    onChange={(e) =>
                      (edit.current.description = e.target.value)
                    }
                    defaultValue={
                      edit?.current?.description ?? recipe?.description
                    }
                    maxRows={10}
                  />
                )}
              </SingleAccordion>
            </Col>
            <Col className="my-2" xs={12}>
              <label
                htmlFor={`recipe-prompt-${recipeId}`}
                className="d-flex align-items-center color-white cursor-pointer"
                onClick={() => {
                  setRenderAccordionItems((r) => ({ ...r, prompt: false }))
                  setAccordionStatus((a) => ({
                    ...a,
                    prompt: !accordionStatus?.prompt,
                  }))
                  triggerRender('prompt')
                }}
              >
                <TbPrompt size={20} className="me-1" />
                {t('Prompt')}
                <FaChevronRight
                  className="ms-2"
                  style={{
                    transform: `rotate(${
                      accordionStatus?.prompt ? '90' : '0'
                    }deg)`,
                    transitionDuration: '0.3s',
                  }}
                />
              </label>
            </Col>
            <Col className="mb-2" xs={12}>
              <SingleAccordion
                header={false}
                forceStatus={!!accordionStatus?.prompt}
                className="single-accordion-cookies w-100"
                defaultStyles=""
                bodyProps={{
                  style: { fontSize: '14px' },
                }}
              >
                {renderAccordionItems?.prompt && (
                  <TextareaAutosize
                    id={`recipe-prompt-${recipeId}`}
                    style={{ minWidth: '100%' }}
                    className="nb-input w-full p-2"
                    onChange={(e) => (edit.current.prompt = e.target.value)}
                    defaultValue={edit?.current?.prompt ?? recipe?.prompt}
                    maxRows={10}
                  />
                )}
              </SingleAccordion>
            </Col>
            <Col className="mb-2" xs={12}>
              <label
                htmlFor={`recipe-script-${recipeId}`}
                className="d-flex align-items-center color-white cursor-pointer"
                onClick={() => {
                  setAccordionStatus((a) => ({
                    ...a,
                    script: !accordionStatus?.script,
                  }))
                  triggerRender('script')
                }}
              >
                <FaCode size={20} className="me-1" />
                {t('Code')}
                <FaChevronRight
                  className="ms-2"
                  style={{
                    transform: `rotate(${
                      accordionStatus?.script ? '90' : '0'
                    }deg)`,
                    transitionDuration: '0.3s',
                  }}
                />
              </label>
            </Col>
            <Col className="mb-2" xs={12}>
              <SingleAccordion
                header={false}
                forceStatus={!!accordionStatus?.script}
                className="single-accordion-cookies w-100"
                defaultStyles=""
                bodyProps={{
                  style: { fontSize: '14px' },
                }}
              >
                <CodeMirror
                  value={recipe.script}
                  readOnly
                  editable={false}
                  extensions={[python({})].filter((e) => e)}
                  onChange={() => {}}
                  theme={solarizedDark}
                  indentWithTab
                  maxHeight="400px"
                />
              </SingleAccordion>
            </Col>
            <Col xs={12} className="mt-4 d-inline-flex justify-content-center">
              <Button
                className="me-3 px-4 py-2 original empty-secondary"
                onClick={() => setTested({ doTest: true })}
                disabled={tested?.testing || tested?.doTest || confirm}
              >
                {t('Test')}
              </Button>
              <Button
                className="ms-3 px-4 py-2 "
                disabled={
                  (!tested?.success && recipe.status !== 'configured') ||
                  confirm
                }
                onClick={() => setConfirm(true)}
              >
                {t('Save')}
              </Button>
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  )
}

function ExecuteProgress({ cpuUsage, memoryUsage }) {
  const maxCpu =
    Math.min(
      100,
      Number.parseInt(
        Math.max(...cpuUsage.map((c) => c.y).map((v) => v || 0)) / 10,
      ) *
        10 +
        10,
    ) || 100
  const maxMemory =
    Math.min(
      100,
      Number.parseInt(
        Math.max(...memoryUsage.map((c) => c.y).map((v) => v || 0)) / 10,
      ) *
        10 +
        10,
    ) || 100
  const maxY = Math.max(maxCpu, maxMemory)
  return (
    <Row className="mx-5">
      <Col xs={12} className="mt-2">
        <h5 className="text-center">
          Real-time CPU and RAM usage for this App
        </h5>
      </Col>
      <Col xs={12} style={{ minHeight: '300px', maxHeight: '300px' }}>
        <ResponsiveLine
          data={[
            {
              id: 'RAM usage',
              data: memoryUsage,
              color: 'var(--nextbrain-warning-color)',
            },
            {
              id: 'CPU usage',
              data: cpuUsage,
              color: 'var(--nextbrain-tables-graph-bar-color)',
            },
          ]}
          colors={(d) => d.color}
          margin={{ top: 20, right: 150, bottom: 50, left: 60 }}
          xScale={{
            type: 'point',
          }}
          yScale={{
            type: 'linear',
            min: 0,
            max: maxY,
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            legendOffset: 40,
            legend: 'Secs',
            legendPosition: 'middle',
          }}
          axisTop={null}
          axisRight={null}
          enableGridX={false}
          enableGridY={false}
          enablePoints={true}
          enablePointLabel={true}
          pointSize={10}
          enableSlices={'x'}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Usage (%)',
            legendPosition: 'middle',
            legendOffset: -50,
          }}
          theme={{
            textColor: 'white',
            fontSize: 14,
            axis: {
              ticks: {
                text: {
                  fill: '#F0EFFF88',
                },
              },
              domain: {
                line: {
                  stroke: '#777777',
                  strokeWidth: 1,
                },
              },
            },
          }}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          useMesh={true}
          animate={false}
        />
      </Col>
    </Row>
  )
}

function AddParameters({ recipe }) {
  const addRef = useRef()
  const { token, signout } = useAuth()

  const [waitingRecipe, setWaitingRecipe] = useState(null)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (waitingRecipe) {
      const i = setInterval(() => {
        queryClient.invalidateQueries(['recipe', recipe?.recipe_id])
        console.log(waitingRecipe?.parameters, recipe?.parameters)
        if (
          JSON.stringify(waitingRecipe?.parameters) !==
          JSON.stringify(recipe?.parameters)
        )
          setWaitingRecipe(null)
      }, 5000)
      return () => clearInterval(i)
    }
    // eslint-disable-next-line
  }, [waitingRecipe, recipe])

  return (
    <Row className="my-3">
      <Col xs={12}>
        <Form.Control
          style={{ maxWidth: '600px' }}
          ref={addRef}
          className="nb-input"
          placeholder="Prompt to add new parameters"
        />
      </Col>
      <Col className="mt-2" xs={12}>
        <Button
          onClick={() => {
            const value = addRef.current.value
            if (value) {
              addRef.current.value = ''
              addRecipeParameters({
                recipeId: recipe?.recipe_id,
                prompt: value,
                token,
                signout,
              })
                .then((r) => {
                  if (!r?.ok)
                    NotificationManager.warning('Parameter could not be added')
                  else setWaitingRecipe(JSON.parse(JSON.stringify(recipe)))
                })
                .catch(() =>
                  NotificationManager.warning('Parameter could not be added'),
                )
            }
          }}
        >
          Add
        </Button>
        {waitingRecipe && <FaSpinner className="rotating-2 ms-2" />}
      </Col>
    </Row>
  )
}

function ExecuteParametersFormItem({
  name,
  config,
  isRequired,
  formParameters,
  ...props
}) {
  return (
    <Row>
      <Col xs={12}>
        <span>{name}</span>
        {isRequired && (
          <span
            className="color-white"
            style={{ marginTop: '-5px', fontSize: '20px' }}
          >
            *
          </span>
        )}
      </Col>
      <Col xs={12} className="smallpp mb-1 color-white">
        {config?.description}
      </Col>
      <Col xs={12}>
        {config.type === 'boolean' ? (
          <div style={{ maxWidth: '60px' }}>
            <Form.Check
              type="switch"
              className="form-switch-share"
              defaultChecked={!!formParameters?.[name]}
              onChange={(e) => (formParameters[name] = e.target.checked)}
              {...props}
            />
          </div>
        ) : config.type === 'array' ? (
          <NextbrainSelect
            creatable
            isMulti
            onChange={(value) => (formParameters[name] = value)}
            placeholder={'Type and add values with enter'}
            type="thin"
            defaultValue={formParameters?.[name] ?? []}
            {...props}
          />
        ) : (
          <Form.Control
            className="nb-input w-full"
            onChange={(e) => (formParameters[name] = e.target.checked)}
            defaultValue={formParameters?.[name] ?? ''}
            {...props}
          />
        )}
      </Col>
    </Row>
  )
}

function ExecuteParameters({ recipe, formParameters }) {
  if (
    !recipe?.parameters?.properties ||
    Object.keys(recipe?.parameters?.properties ?? {}).length === 0
  )
    return null

  const required = recipe?.parameters?.required ?? []

  return (
    <Row>
      {required?.length && (
        <Col xs={12}>
          <hr />
          <h5>Required parameters</h5>
        </Col>
      )}
      {required.map((name, i) => (
        <Col xl={6} md={12} key={name} className="mb-2">
          <ExecuteParametersFormItem
            name={name}
            config={recipe?.parameters?.properties?.[name] ?? {}}
            isRequired={true}
            formParameters={formParameters}
            {...(i ? {} : { autoFocus: true })}
          />
        </Col>
      ))}
      {required?.length && (
        <Col xs={12}>
          <hr />
          <h5>Optional parameters</h5>
        </Col>
      )}
      {Object.keys(recipe?.parameters?.properties)
        .filter((k) => !required.includes(k))
        .map((name) => (
          <Col xl={6} md={12} key={name} className="mb-2">
            <ExecuteParametersFormItem
              name={name}
              formParameters={formParameters}
              config={recipe?.parameters?.properties?.[name] ?? {}}
              isRequired={false}
            />
          </Col>
        ))}
    </Row>
  )
}

export function ExecuteModalRecipeStatus({ model }) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const recipeId = searchParams.get('execute_appId')
  const executionResultId = searchParams.get('execution_id')
  const { requestUpdate } = useModels()

  const { data: executionResult } = useQuery(
    ['recipe-execute-result', recipeId, executionResultId],
    async () => {
      if (!recipeId || !executionResultId) return null
      const executions = await getRecipeExecution({
        recipeId,
        timestamp: executionResultId,
        token,
        signout,
      })
      if (Array.isArray(executions) && executions.length) return executions[0]
      return null
    },
    { staleTime: Infinity },
  )

  const { data: recipe, isLoading } = useQuery(
    ['recipe', recipeId],
    async () => {
      if (!recipeId) return null
      const recipe = await getRecipeById({
        recipeId,
        modelId: model?.id,
        token,
        signout,
      })
      if (!recipe) throw new Error('Recipe not found')
      return recipe
    },
    { staleTime: Infinity },
  )

  const [execute, setExecute] = useState(false)
  const [progress, setProgress] = useState({
    cpuUsage: [{ x: 0.5, y: 0 }],
    memoryUsage: [{ x: 0.5, y: 0 }],
    time_elapsed: 0,
  })

  useEffect(() => {
    if (execute) {
      window.working = true
      setProgress({
        cpuUsage: [{ x: 0.5, y: 0 }],
        memoryUsage: [{ x: 0.5, y: 0 }],
        time_elapsed: 0,
      })
      awaitTaskCallCallback(
        executeCustomApp,
        2500,
        null,
        (d) => {
          const NUM_POINTS = 20
          const update = {}
          if (d?.data?.cpu_usage || d?.data?.cpu_usage === 0)
            update.cpu = {
              x: Number.parseInt(d.data.time_elapsed),
              y: d.data.cpu_usage * 100,
            }
          if (d?.data?.memory_usage || d?.data?.memory_usage === 0)
            update.memory = {
              x: Number.parseInt(d.data.time_elapsed),
              y: d.data.memory_usage * 100,
            }

          if (Object.keys(update).length)
            setProgress((p) => {
              if (update.cpu) {
                /*if (p.cpuUsage.length < NUM_POINTS)
                  p.cpuUsage.push(
                    ...new Array(NUM_POINTS - p.cpuUsage.length)
                      .fill(null)
                      .map((_, i) => ({
                        x: update.time_elapsed,
                        y: i === 0 ? 0 : null,
                      })),
                  )*/
                if (p.cpuUsage.length >= NUM_POINTS) p.cpuUsage.splice(0, 1)
                p.cpuUsage.push(update.cpu)
              }
              if (update.memory) {
                /*if (p.memoryUsage.length < NUM_POINTS)
                  p.memoryUsage.push(
                    ...new Array(NUM_POINTS - p.memoryUsage.length)
                      .fill(null)
                      .map((_, i) => ({
                        x: update.time_elapsed,
                        y: i === 0 ? 0 : null,
                      })),
                  )*/
                if (p.memoryUsage.length >= NUM_POINTS)
                  p.memoryUsage.splice(0, 1)
                p.memoryUsage.push(update.memory)
              }
              return { ...p }
            })
        },
        {
          modelId: model?.id,
          token,
          signout,
          recipeId,
        },
      )
        .then((r) => {
          if (r?.is_error) {
            NotificationManager.warning('App could not be executed')
          } else {
            NotificationManager.success('App executed successfully')
            queryClient.setQueryData(
              ['recipe-execute-result', recipeId, r?.timestamp],
              r,
            )
            searchParams.set('execution_id', r?.timestamp)
            setSearchParams(searchParams)
            if (recipe?.transform_data) {
              queryClient.invalidateQueries([
                'model-summary',
                model?.id,
                'imported',
              ])
              queryClient.invalidateQueries([
                'viewData-infinite',
                model?.id,
                'imported',
              ])
              queryClient.invalidateQueries(['columnsDeleted', model?.id])
              requestUpdate(model?.id)
            }
          }
        })
        .catch(() => {
          NotificationManager.warning('App could not be executed')
        })
        .finally(() => {
          setExecute(false)
        })
    }
    // eslint-disable-next-line
  }, [execute])

  const [formParameters, setFormParameters] = useState({})

  if (!recipeId) return null

  return (
    <Modal
      show={true}
      onHide={() => {
        searchParams.delete('execute_appId')
        searchParams.delete('execution_id')
        setSearchParams(searchParams)
      }}
      size="xl"
    >
      <Modal.Header closeButton className="mb-0">
        <h5
          className="d-flex align-items-center input-grow-full mb-0"
          style={{
            minWidth: 'calc(100% - 30px)',
            maxWidth: 'calc(100% - 30px)',
            minHeight: '40px',
            maxHeight: '40px',
          }}
        >
          <Row className="min-w-full">
            <Col
              xs={12}
              style={{
                maxWidth: 'calc(100% - 100px)',
                minWidth: 'calc(100% - 100px)',
              }}
            >
              {recipe?.name}
              {executionResult && recipe?.description && (
                <OverlayTrigger
                  rootClose={true}
                  trigger={['hover', 'focus']}
                  placement="auto"
                  delay={{ show: 200, hide: 0 }}
                  overlay={(props) => (
                    <Tooltip
                      {...props}
                      className={`context-menu-column-type big-menu pe-none ${
                        props?.className ?? ''
                      }`}
                    >
                      {recipe?.description}
                    </Tooltip>
                  )}
                >
                  <span>
                    <FaInfoCircle className="ms-2" />
                  </span>
                </OverlayTrigger>
              )}
            </Col>
            <Col
              xs={12}
              style={{ maxWidth: '100px', minWidth: '100px' }}
              className="d-inline-flex justify-content-end pe-0"
            >
              {executionResult && (
                <>
                  <span
                    className="icon-btn"
                    title={'Send by email'}
                    onClick={() => {
                      NotificationManager.warning(
                        'This feature is not available yet',
                      )
                    }}
                  >
                    <MdEmail size={30} />
                  </span>
                  <span
                    className="ms-3 icon-btn"
                    title={'Export to pdf'}
                    onClick={() => {
                      NotificationManager.warning(
                        'This feature is not available yet',
                      )
                    }}
                  >
                    <FaFilePdf size={25} />
                  </span>
                </>
              )}
            </Col>
          </Row>
        </h5>
      </Modal.Header>
      <Modal.Body className="pt-0">
        {isLoading || recipe.status === 'generating-data' || execute ? (
          <Row>
            {recipe?.description && !executionResult && (
              <>
                <Col
                  xs={12}
                  style={{ maxWidth: '120px' }}
                  className="text-center d-flex align-items-center flex-column justify-content-center color-white smallp"
                >
                  <FaInfoCircle size={20} className="mb-1" />
                  {t('App description')}
                </Col>
                <Col
                  xs={12}
                  style={{ maxWidth: 'calc(100% - 120px)' }}
                  className="py-3"
                >
                  <p className="mb-0">{recipe?.description}</p>
                </Col>
              </>
            )}
            {execute && (
              <Col xs={12} className="mt-2">
                <ExecuteProgress {...progress} />
              </Col>
            )}
            <Col
              xs={12}
              className="mb-5 d-flex align-items-center justify-content-center"
              style={{ minHeight: '20vh' }}
            >
              <Loading
                message={execute ? 'Executing the App. Please wait' : 'Loading'}
              />
            </Col>
          </Row>
        ) : (
          <>
            <Col xs={12}>
              <Row>
                {recipe?.description && !executionResult && (
                  <>
                    <Col
                      xs={12}
                      style={{ maxWidth: '120px' }}
                      className="text-center d-flex align-items-center flex-column justify-content-center color-white smallp"
                    >
                      <FaInfoCircle size={20} className="mb-1" />
                      {t('App description')}
                    </Col>
                    <Col
                      xs={12}
                      style={{ maxWidth: 'calc(100% - 120px)' }}
                      className="py-3"
                    >
                      <p className="mb-0">{recipe?.description}</p>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
            {false &&
              (process.env.REACT_APP_MODE || process.env.NODE_ENV) ===
                `development` && (
                <Col xs={12}>
                  <AddParameters recipe={recipe} />
                </Col>
              )}
            <ExecuteParameters
              recipe={recipe}
              formParameters={formParameters}
            />
            {executionResult?.interpretation && (
              <Col className="mt-2" xs={12}>
                <Row>
                  <Col
                    xs={12}
                    style={{ maxWidth: '120px' }}
                    className="text-center d-flex align-items-center flex-column justify-content-center color-white smallp"
                  >
                    <VscOutput size={20} className="mb-1" />
                    {t('Response')}
                  </Col>
                  <Col
                    xs={12}
                    style={{ maxWidth: 'calc(100% - 120px)' }}
                    className="py-3"
                  >
                    <MarkdownView
                      markdown={executionResult?.interpretation}
                      options={{ tables: true, emoji: true }}
                      className="markdown-content"
                    />
                  </Col>
                </Row>
              </Col>
            )}
            {executionResult?.stdout && (
              <Col className="mt-2" xs={12}>
                <Accordion
                  style={{ maxWidth: 'calc(100% - 20px)' }}
                  className={`mt-2 animated-arrow  accordion-message-code overflow-auto`}
                >
                  <Accordion.Item className="p-0" eventKey="0">
                    <Accordion.Header className="py-0 d-flex align-items-start accordion-header-code">
                      <ul className="ps-2 mb-0">
                        <li className="thought-bot-header-code">
                          <span className="color-white">Execution output</span>
                        </li>
                      </ul>
                    </Accordion.Header>
                    <Accordion.Body className="p-0 code-body">
                      <div className="px-3 pb-3">
                        <MarkdownView
                          markdown={executionResult?.stdout}
                          options={{ tables: true, emoji: true }}
                          className="markdown-content p-3 max-w-100 overflow-auto"
                        />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            )}
            {executionResult && (
              <Col className="mt-2" xs={12}>
                <ExecutionFiles executionResult={executionResult} />
              </Col>
            )}
            <Col xs={12} className="mt-4 d-inline-flex justify-content-center">
              <Button
                className="ms-3 px-4 py-2 d-flex align-items-center original empty-main"
                disabled={execute}
                onClick={() => {
                  searchParams.delete('execution_id')
                  setSearchParams(searchParams)
                  setExecute(true)
                }}
              >
                {execute ? (
                  <BouncyLoader className="my-2" />
                ) : (
                  <div className="d-inline-flex align-items-center">
                    <MdPlayCircle
                      className="me-1"
                      size={25}
                      color="var(--nextbrain-tables-graph-bar-color)"
                    />
                    {t(executionResult ? 'Re-execute' : 'Execute')}
                  </div>
                )}
              </Button>
            </Col>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

import { useState, useEffect, Fragment } from 'react'
import {
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Tooltip,
  Modal,
  Button,
  Form,
} from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { GoDotFill } from 'react-icons/go'

import {
  ApplyQuickFixDataQualityRules,
  getHead,
  getModelDataQualityRules,
  getModelFlow,
  setColumnType,
  updateModelDataQualityRules,
} from '../../services/model'
import ViewDataTable from '../view-data/view-data'
import { useAuth } from '../../providers/AuthProvider'
import { modelChangeDate } from '../../util/models'
import { useModels } from '../../providers/ModelProvider'
import { defaultFormat } from '../utils/formating'
import {
  FaBolt,
  FaChevronDown,
  FaChevronRight,
  FaInfoCircle,
  FaPercent,
} from 'react-icons/fa'
import SingleAccordion from '../single-accordion/SingleAccordion'
import NBInput from '../form/NBInput'
import { enforceValidation } from '../../util/validation'
import NextbrainSelect from './NextbrainSelect'
import Loading from '../loading/LoadingSmall'
import { NotificationManager } from 'react-notifications'
import { BouncyLoader } from '../utils/ui'

function NullsThreshold({ config, setConfig }) {
  const { t } = useTranslation()
  return (
    <Row>
      <Col xs={12}>{t('Empty value tolerance per column')}</Col>
      <Col xs={12}>
        <small className="color-white">{t('(in percetange 0% - 100%)')}</small>
      </Col>
      <Col xs={12} style={{ maxWidth: '150px' }} className="position-relative">
        <NBInput
          validation={true}
          type="text"
          name={`NullsThreshold-form-limit-per-column`}
          aria-label="Number"
          onKeyPress={enforceValidation({
            numeric: true,
            clamp: { min: 0, max: 100 },
          })}
          onChange={(e) => {
            if (config) config.percent = e.target.value / 100
            else
              setConfig({
                ...(config ?? {}),
                percent: e.target.value / 100,
              })
          }}
          soft={true}
          className={`w-full no-arrows force mt-2 pe-5`}
          style={{ minHeight: 45 }}
          placeholder={t('%')}
          defaultValue={Number.parseInt((config?.percent ?? 0) * 100)}
        />
        <FaPercent
          style={{
            position: 'absolute',
            right: '30px',
            top: 'calc(50% - 8px)',
          }}
          size={20}
        />
      </Col>
    </Row>
  )
}

const numericalOptions = [
  {
    label: 'Percentile',
    value: 'percentile',
    parameters: [
      {
        name: 'lowPercent',
        label: 'Low percentile (0-10)',
        type: 'number',
        id: 'low_percent',
        defaultValue: 0.1,
        min: 0,
        max: 10,
      },
      {
        name: 'highPercent',
        label: 'High percentile (90-100)',
        type: 'number',
        id: 'high_percent',
        defaultValue: 0.9,
        min: 90,
        max: 100,
      },
    ],
  },
]

const categoricalOptions = [
  {
    label: 'Threshold',
    value: 'threshold',
    parameters: [
      {
        name: 'thresshold',
        id: 'percent',
        min: 0,
        max: 10,
        defaultValue: 0.1,
        label:
          'Categories that fail to make up to this percentage of the sample will be removed',
        type: 'number',
      },
    ],
  },
]

function FormItem({ config, ...props }) {
  const { t } = useTranslation()
  const _props = {
    type: 'text',
    soft: true,
    ...props,
  }
  const Component = config.type === 'number' ? NBInput : Fragment

  if (config.type === 'number' && (config.min || config.max)) {
    const clamp = {}
    if (!Number.isNaN(config.min)) clamp.min = config.min
    if (!Number.isNaN(config.max)) clamp.max = config.max
    _props.onKeyPress = enforceValidation({ numeric: true, clamp })
  }
  return (
    <Row>
      {config.label && (
        <Col className="mt-2 color-white" xs={12}>
          <small>{t(config.label)}</small>
        </Col>
      )}
      <Col className="position-relative enforced-validation-container" xs={12}>
        <div style={{ maxWidth: '300px' }}>
          <Component {..._props} />
        </div>
      </Col>
    </Row>
  )
}

function OutlierNumerical({ config, setConfig }) {
  const { t } = useTranslation()
  const option = numericalOptions.find((i) => i.value === config.value)

  return (
    <Row>
      <Col className="mb-1" xs={12}>
        {t('Numerical outliers detection')}
      </Col>
      <Col
        xs={12}
        className="position-relative mt-2 d-inline-flex align-items-center"
      >
        {t('Method')}:
        <span style={{ maxWidth: '300px' }} className="ms-2">
          <NextbrainSelect
            value={option}
            onChange={(value) =>
              setConfig({
                value: value.value,
              })
            }
            hideSelectedOptions={false}
            isClearable={false}
            placeholder={t('Select method')}
            type={'dark'}
            options={numericalOptions}
          />
        </span>
      </Col>
      <Col xs={12} className="position-relative mt-1">
        <Row className="ms-2">
          {option?.parameters?.map((param) => (
            <Col xs={6} key={param.id}>
              <FormItem
                config={param}
                defaultValue={Number.parseInt(
                  (config?.data?.[param.id] ?? param?.defaultValue ?? 0) * 100,
                )}
                onChange={(e) => {
                  config.data = config?.data ?? {}
                  config.data[param.id] = e.target.value / 100
                }}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

function RuleConfig({ config, rule }) {
  const { t } = useTranslation()
  switch (rule) {
    case 'cell_is_null':
      return null
    case 'nulls_threshold':
      return (
        <Row className="align-items-center">
          <Col
            className="d-flex align-items-center justify-content-center pe-0"
            xs={6}
          >
            {t('Limit')}:
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center ps-0"
            xs={6}
          >
            {(config?.percent ?? 0) * 100}%
          </Col>
        </Row>
      )
    case 'outlier_numerical':
      return (
        <Row className="align-items-center">
          <Col
            className="d-flex align-items-center justify-content-center pe-0"
            xs={6}
          >
            {t('Method')}:
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center ps-0"
            xs={6}
          >
            {t(config?.value) ?? 'None'}
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center pe-0 mt-2"
            xs={6}
          >
            {t('Low percentile')}:
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center ps-0 mt-2"
            xs={6}
          >
            {Number.isNaN(config?.data?.low_percent)
              ? t('Not set')
              : `${Number.parseInt(config.data.low_percent * 100)}%`}
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center pe-0 mt-2"
            xs={6}
          >
            {t('High percentile')}:
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center ps-0 mt-2"
            xs={6}
          >
            {Number.isNaN(config?.data?.high_percent)
              ? 'Not set'
              : `${Number.parseInt(config.data.high_percent * 100)}%`}
          </Col>
        </Row>
      )
    case 'outlier_categorical':
      return (
        <Row className="align-items-center">
          <Col
            className="d-flex align-items-center justify-content-center pe-0"
            xs={6}
          >
            {t('Method')}:
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center ps-0"
            xs={6}
          >
            {config?.value ?? 'None'}
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center pe-0 mt-2"
            xs={6}
          >
            {t('Threshold')}:
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center ps-0 mt-2"
            xs={6}
          >
            {Number.isNaN(config?.data?.percent)
              ? t('Not set')
              : `${Number.parseInt(config.data.percent * 100)}%`}
          </Col>
        </Row>
      )
    case 'categorical_inconsistent_data_type':
      return (
        <Row className="align-items-center">
          <Col
            className="d-flex align-items-center justify-content-center pe-0"
            xs={6}
          >
            {t('Threshold')}:
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center ps-0"
            xs={6}
          >
            {Number.isNaN(config?.threshold)
              ? '60%'
              : `${Number.parseInt(config.threshold * 100)}%`}
          </Col>
        </Row>
      )
    default:
      return null
  }
}

function OutlierCategorical({ config, setConfig }) {
  const { t } = useTranslation()
  const option = categoricalOptions.find((i) => i.value === config.value)

  return (
    <Row>
      <Col className="mb-1" xs={12}>
        {t('Categorical outliers detection')}
      </Col>
      <Col
        xs={12}
        className="position-relative mt-2 d-inline-flex align-items-center"
      >
        {t('Method')}:
        <span style={{ maxWidth: '300px' }} className="ms-2">
          <NextbrainSelect
            value={option}
            onChange={(value) =>
              setConfig({
                value: value.value,
              })
            }
            hideSelectedOptions={false}
            isClearable={false}
            placeholder={t('Select method')}
            type={'dark'}
            options={categoricalOptions}
          />
        </span>
      </Col>
      <Col xs={12} className="position-relative mt-1">
        <Row className="ms-2">
          {option?.parameters?.map((param) => (
            <FormItem
              key={param.id}
              config={param}
              defaultValue={Number.parseInt(
                (config?.data?.[param.id] ?? param?.defaultValue ?? 0) * 100,
              )}
              onChange={(e) => {
                config.data = config?.data ?? {}
                config.data[param.id] = e.target.value / 100
              }}
            />
          ))}
        </Row>
      </Col>
    </Row>
  )
}

function CategoricalInconsistent({ config, setConfig }) {
  const { t } = useTranslation()

  return (
    <Row>
      <Col className="mb-1" xs={12}>
        {t('Tolerance percentage of numeric data in categorical columns')}
      </Col>
      <Col xs={12}>
        <small className="color-white">{t('(in percetange 0% - 100%)')}</small>
      </Col>
      <Col xs={12} style={{ maxWidth: '150px' }} className="position-relative">
        <NBInput
          validation={true}
          type="text"
          name={`NumericInconsistent-form-limit-per-column`}
          aria-label="Number"
          onKeyPress={enforceValidation({
            numeric: true,
            clamp: { min: 0, max: 100 },
          })}
          onChange={(e) => {
            if (config) config.threshold = e.target.value / 100
            else
              setConfig({
                ...(config ?? {}),
                threshold: e.target.value / 100,
              })
          }}
          soft={true}
          className={`w-full no-arrows force mt-2 pe-5`}
          style={{ minHeight: 45 }}
          placeholder={t('%')}
          defaultValue={Number.parseInt((config?.threshold ?? 0) * 100)}
        />
        <FaPercent
          style={{
            position: 'absolute',
            right: '30px',
            top: 'calc(50% - 8px)',
          }}
          size={20}
        />
      </Col>
    </Row>
  )
}

const RULES_WITH_FORM = {
  nulls_threshold: NullsThreshold,
  outlier_numerical: OutlierNumerical,
  outlier_categorical: OutlierCategorical,
  categorical_inconsistent_data_type: CategoricalInconsistent,
}

const RULES_WITH_QUICKFIX = new Set(['nulls_threshold'])

function RulesConfig({ model, rules, close }) {
  const queryClient = useQueryClient()
  const { token, signout } = useAuth()
  const { t } = useTranslation()

  const [localQualityRules, setLocalQualityRules] = useState(() => {
    return JSON.parse(JSON.stringify(rules))
  })
  const [open, setIsOpen] = useState(
    Object.keys(rules).map((r) => ({
      r: false,
    })),
  )

  const translateRuleToConfigKey = {
    nulls_threshold: 'nulls',
    cell_is_null: 'cell_null',
    outlier_numerical: ['outliers', 'numerical_method'],
    outlier_categorical: ['outliers', 'categorical_method'],
    duplicated_rows: 'duplicated_rows',
    categorical_inconsistent_data_type: 'categorical_inconsistent_data_type',
  }

  function RuleAccordion({
    rule,
    rules_quality,
    isOpen,
    setIsOpen,
    localQualityRules,
    setLocalQualityRules,
  }) {
    const hasForm = !!RULES_WITH_FORM[rule]
    const FormItem = RULES_WITH_FORM[rule]
    let config = null
    switch (rule) {
      case 'cell_is_null':
        config = localQualityRules?.cell_null
        break
      case 'nulls_threshold':
        config = localQualityRules?.nulls
        break
      case 'outlier_numerical':
        config = localQualityRules?.outliers?.numerical_method
        break
      case 'outlier_categorical':
        config = localQualityRules?.outliers?.categorical_method
        break
      default:
        config = localQualityRules?.[translateRuleToConfigKey[rule]]
        break
    }
    if (!rules) return <Loading />

    return (
      <Row
        className={`mx-2 mt-1 ${isOpen ? 'rule-box-open' : ''}`}
        onClick={() => setIsOpen((o) => !o)}
      >
        <Col
          xs={12}
          className={`position-relative py-3 rule-box ${isOpen ? 'open' : ''}`}
          style={{
            ...(hasForm ? { cursor: 'pointer' } : {}),
            ...(hasForm && isOpen
              ? {
                  borderBottom: 'none',
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }
              : { borderRadius: 10 }),
          }}
        >
          <Row
            key={rule}
            className={`d-flex flex-nowrap ${
              hasForm ? 'cursor-pointer user-select-none' : ''
            }`}
          >
            <Col xs={4} className="text-center position-relative">
              <Form.Check
                className="p-0"
                checked={!!config && !config?.disabled}
                onChange={(e) => {
                  if (!config) config = {}
                  config.disabled = !e.target.checked
                  if (Array.isArray(translateRuleToConfigKey[rule])) {
                    let item = localQualityRules
                    for (const k of translateRuleToConfigKey[rule].slice(0, -1))
                      item = { ...(item?.[k] ?? {}) }
                    item[translateRuleToConfigKey[rule].slice(-1)[0]] = config
                    setLocalQualityRules({ ...localQualityRules })
                  } else
                    setLocalQualityRules({
                      ...localQualityRules,
                      [translateRuleToConfigKey[rule]]: config,
                    })
                }}
                onClick={(e) => e.stopPropagation()}
                style={{
                  position: 'absolute',
                  left: '20px',
                  marginTop: '0px',
                }}
              />
              <span className="d-inline-flex items-center ms-5">{t(rule)}</span>
            </Col>
            <Col xs={4} className="text-center" style={{ fontSize: '14px' }}>
              <RuleConfig config={config} rule={rule} />
            </Col>
            <Col xs={4} className="text-center">
              <span>
                <GoDotFill
                  size={24}
                  className="me-1 mb-1"
                  style={{
                    display: 'inline',
                    color: `${
                      rules_quality[rule] > 0.8
                        ? '#0F9D58'
                        : rules_quality[rule] > 0.5
                        ? '#FFC107'
                        : '#DC3545'
                    }`,
                  }}
                />
                {defaultFormat({
                  num: rules_quality[rule] * 100,
                  digits: 0,
                })}
                %
                {RULES_WITH_QUICKFIX?.has(rule) &&
                  rules_quality?.[rule] < 1 && (
                    <OverlayTrigger
                      rootClose={true}
                      trigger={['hover', 'focus']}
                      placement={'auto'}
                      delay={{ show: 100, hide: 200 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          <span className="">
                            {t('Solve this issue with a quick-fix')}
                          </span>
                        </Tooltip>
                      )}
                    >
                      <span>
                        <FaBolt
                          className="hover-yellow ms-1"
                          size={25}
                          onClick={(e) => {
                            e.stopPropagation()
                            ApplyQuickFixDataQualityRules({
                              modelId: model.id,
                              token,
                              signout,
                            }).then((r) => {
                              if (r?.ok) {
                                queryClient.invalidateQueries([
                                  'model-data-quality-rules',
                                  model.id,
                                ])
                                setTimeout(
                                  () =>
                                    queryClient.invalidateQueries([
                                      'model',
                                      model.id,
                                    ]),
                                  3000,
                                )
                                setTimeout(
                                  () =>
                                    queryClient.invalidateQueries([
                                      'model-data-quality-rules',
                                      model.id,
                                    ]),
                                  1000,
                                )
                                setTimeout(
                                  () =>
                                    queryClient.invalidateQueries([
                                      'model-data-quality-rules',
                                      model.id,
                                    ]),
                                  3000,
                                )
                                close()
                              } else {
                                NotificationManager.error(
                                  t('Failed to apply quick-fix'),
                                )
                              }
                            })
                          }}
                        />
                      </span>
                    </OverlayTrigger>
                  )}
              </span>
            </Col>
          </Row>
          {hasForm && (
            <FaChevronRight
              size={20}
              className="icon-btn"
              style={{
                position: 'absolute',
                right: '15px',
                top: 'calc(50% - 10px)',
                transform: `rotate(${isOpen ? '90deg' : '0deg'})`,
                transitionDuration: '0.3s',
              }}
            />
          )}
        </Col>
        {hasForm && (
          <Col className="px-0" xs={12} onClick={(e) => e.stopPropagation()}>
            <SingleAccordion
              defaultState={false}
              forceStatus={isOpen}
              headerProps={{ className: 'd-none' }}
              title={null}
              className="w-100 nb-accordion special"
              defaultStyles="p-2"
            >
              <FormItem
                config={config ?? {}}
                setConfig={(newConfig) => {
                  if (!newConfig) newConfig = {}
                  const key = translateRuleToConfigKey[rule]
                  if (Array.isArray(key)) {
                    let item = localQualityRules
                    for (const k of key.slice(0, -1)) item = item?.[k] ?? {}
                    item[key.slice(-1)[0]] = newConfig
                    setLocalQualityRules({
                      ...localQualityRules,
                      [key]: { ...localQualityRules[key] },
                    })
                  } else
                    setLocalQualityRules({
                      ...localQualityRules,
                      [key]: newConfig,
                    })
                }}
              />
            </SingleAccordion>
          </Col>
        )}
      </Row>
    )
  }

  let { global, ...rules_quality } = model?.dataset?.data_quality ?? {}

  if (rules_quality === undefined) {
    return (
      <Row className="d-flex flex-nowrap">
        <Col xs={12} className="text-center">
          <span>{t('No data quality rules')}</span>
        </Col>
      </Row>
    )
  }

  const quality = Object.keys(rules_quality).map((rule) => (
    <RuleAccordion
      key={rule}
      rule={rule}
      rules_quality={rules_quality}
      localQualityRules={localQualityRules}
      setLocalQualityRules={setLocalQualityRules}
      isOpen={open[rule]}
      setIsOpen={() => {
        setIsOpen({ ...open, [rule]: !open[rule] })
      }}
    />
  ))

  return (
    <>
      <Modal.Body>
        <Row
          className={`d-flex flex-nowrap mb-2 mx-2 ${
            Object.keys(rules_quality ?? {}).length ? '' : 'd-none'
          }`}
        >
          <Col xs={4} className="text-center">
            <strong>{t('Rule')}</strong>
          </Col>
          <Col xs={4} className="text-center">
            <strong>{t('Settings')}</strong>
          </Col>
          <Col xs={4} className="text-center">
            <strong>{t('% Followed')}</strong>
          </Col>
        </Row>
        {quality}
      </Modal.Body>
      <Modal.Footer>
        <Row style={{ minWidth: '100%' }}>
          <Col xs={12} className="justify-content-between d-flex">
            <Button
              className="original empty-secondary"
              onClick={() => {
                updateModelDataQualityRules({
                  modelId: model.id,
                  rules: null,
                  token,
                  signout,
                }).then((r) => {
                  if (r?.ok) {
                    queryClient.invalidateQueries([
                      'model-data-quality-rules',
                      model.id,
                    ])
                    setTimeout(
                      () =>
                        queryClient.invalidateQueries([
                          'model-data-quality-rules',
                          model.id,
                        ]),
                      1000,
                    )
                    setTimeout(
                      () => queryClient.invalidateQueries(['model', model.id]),
                      3000,
                    )
                    setTimeout(
                      () =>
                        queryClient.invalidateQueries([
                          'model-data-quality-rules',
                          model.id,
                        ]),
                      3000,
                    )
                    close()
                  } else
                    NotificationManager.error(
                      t('Failed to reset data quality rules'),
                      t('Error'),
                    )
                })
              }}
            >
              {t('Reset default')}
            </Button>
            <Button
              disabled={
                rules_quality === undefined ||
                Object.keys(rules_quality).length === 0
              }
              onClick={() => {
                updateModelDataQualityRules({
                  modelId: model.id,
                  rules: localQualityRules,
                  token,
                  signout,
                }).then((r) => {
                  if (r?.ok) {
                    queryClient.invalidateQueries([
                      'model-data-quality-rules',
                      model.id,
                    ])
                    setTimeout(
                      () => queryClient.invalidateQueries(['model', model.id]),
                      3000,
                    )
                    setTimeout(
                      () =>
                        queryClient.invalidateQueries([
                          'model-data-quality-rules',
                          model.id,
                        ]),
                      1000,
                    )
                    setTimeout(
                      () =>
                        queryClient.invalidateQueries([
                          'model-data-quality-rules',
                          model.id,
                        ]),
                      3000,
                    )
                    close()
                  } else
                    NotificationManager.error(
                      t('Failed to update data quality rules'),
                    )
                })
              }}
              className="px-4"
            >
              {t('Save changes')}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </>
  )
}

export default function ExploreData({ ...props }) {
  const { token, signout } = useAuth()
  const queryClient = useQueryClient()
  const {
    activeModel,
    workspaceId,
    updateModel,
    requestUpdate,
    isChangingDataActiveModel,
    setIsChangingDataActiveModel,
  } = useModels()
  const { t } = useTranslation()

  const { data: rules } = useQuery(
    ['model-data-quality-rules', activeModel?.id],
    async () => {
      if (activeModel?.id) {
        const rules = await getModelDataQualityRules({
          modelId: activeModel.id,
          token,
          signout,
        })
        window.rulez = rules
        return rules
      }
    },
    { staleTime: Infinity },
  )
  const [waitingForWorkFinished, setWaitingForWorkFinished] = useState(false)
  useEffect(() => {
    if (!rules?.working && waitingForWorkFinished) {
      setWaitingForWorkFinished(false)
      requestUpdate(activeModel?.id)
      queryClient.invalidateQueries([
        'model-summary',
        activeModel?.id,
        activeModel?.dataset?.status,
      ])
      queryClient.invalidateQueries([
        'viewData-infinite',
        activeModel?.id,
        activeModel?.dataset?.status,
      ])
    }
    // eslint-disable-next-line
  }, [waitingForWorkFinished, rules?.working])

  useEffect(() => {
    if (rules?.working) {
      setWaitingForWorkFinished(true)
      const iv = setInterval(
        () =>
          queryClient.invalidateQueries([
            'model-data-quality-rules',
            activeModel?.id,
          ]),
        5000,
      )
      return () => clearInterval(iv)
    }
    // eslint-disable-next-line
  }, [rules])

  const updated = modelChangeDate(activeModel)
  const updatedStr = `${updated.getDate()} ${updated.toLocaleString('default', {
    month: 'short',
  })} ${updated.getFullYear()}`

  const generateColumnToType = (model) => {
    const finalColumnStatus = model?.dataset?.final_column_status
    if (!finalColumnStatus) return {}
    return finalColumnStatus
  }

  const { data: flow } = useQuery(
    [`model-flow-${activeModel?.id}`],
    async () => {
      if (!activeModel || activeModel?.id === 'new') return {}
      return await getModelFlow({
        modelId: activeModel.id,
        token,
        signout,
      })
    },
    { staleTime: Infinity },
  )
  const [showDataQuality, setShowDataQuality] = useState(false)

  useEffect(() => {
    if (activeModel && !activeModel?.dataset?.final_column_status) {
      const timeout = setTimeout(() => requestUpdate(activeModel?.id), 2500)
      return () => clearTimeout(timeout)
    }
    // eslint-disable-next-line
  }, [activeModel])

  const invalidColumns = Object.values(
    activeModel?.dataset?.final_column_status ?? {},
  ).filter((v) => v === 'Text' || v === 'ID').length

  let additionHeaders =
    activeModel.dataset && activeModel.dataset !== undefined ? (
      <Col xs="auto" style={{ maxWidth: 'calc(100% - 200px)' }}>
        <Row className="d-flex flex-nowrap">
          <Col xs={'auto'}>
            <Row className="ms-1 text-center">
              <strong>{t('Updated')}</strong>
            </Row>
            <Row className="ms-1 text-center">
              <span>{updatedStr}</span>
            </Row>
          </Col>
          <Col className="ms-md-2 ms-1 ms-lg-4" xs={'auto'}>
            <Row className="text-center">
              <strong>{t('Columns')}</strong>
            </Row>
            {activeModel.dataset.cols !== undefined ? (
              <Row className="text-center">
                <span>{activeModel.dataset.cols}</span>
              </Row>
            ) : (
              <Row className="text-center">
                <span>N/A</span>
              </Row>
            )}
          </Col>
          <Col className="ms-md-2 ms-1 ms-lg-4" xs={'auto'}>
            <Row className="text-center">
              <strong>{t('Rows')}</strong>
            </Row>
            {activeModel.dataset.rows !== undefined ? (
              <Row className="text-center">
                <OverlayTrigger
                  rootClose={true}
                  trigger={['hover', 'focus']}
                  placement={'auto'}
                  delay={{ show: 100, hide: 200 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      <span className="">{activeModel.dataset.rows} rows</span>
                    </Tooltip>
                  )}
                >
                  <span>
                    {defaultFormat({ num: activeModel.dataset.rows })}
                  </span>
                </OverlayTrigger>
              </Row>
            ) : (
              <Row className="text-center">
                <span>N/A</span>
              </Row>
            )}
          </Col>
          <Col
            className={`ms-md-2 ms-1 ms-lg-4 quality-btn ${
              rules?.working || isChangingDataActiveModel
                ? 'opacity-50 pe-none'
                : ''
            }`}
            xs={'auto'}
            onClick={() =>
              rules?.working || isChangingDataActiveModel
                ? () => {}
                : setShowDataQuality(true)
            }
          >
            <>
              <Row className="text-center">
                <strong>
                  {t('Data Quality')}
                  <FaChevronDown size={20} className="ms-1" />
                </strong>
              </Row>
              {activeModel?.dataset?.data_quality !== undefined &&
              activeModel?.dataset?.data_quality !== null ? (
                <Row className="text-center">
                  {rules?.working || isChangingDataActiveModel ? (
                    <Col xs={12} className="py-1">
                      <BouncyLoader />
                    </Col>
                  ) : (
                    <span>
                      <GoDotFill
                        size={24}
                        className="me-1 mb-1"
                        style={{
                          display: 'inline',
                          color: `${
                            activeModel?.dataset?.data_quality?.global > 0.9
                              ? '#0F9D58'
                              : activeModel?.dataset?.data_quality?.global > 0.5
                              ? '#FFC107'
                              : '#DC3545'
                          }`,
                        }}
                      />
                      {defaultFormat({
                        num: activeModel?.dataset?.data_quality?.global * 100,
                        digits: 0,
                      })}
                      %
                      <OverlayTrigger
                        rootClose={true}
                        trigger={['hover', 'focus']}
                        placement="auto"
                        delay={{ show: 100, hide: 100 }}
                        overlay={(props) => (
                          <Popover
                            {...props}
                            style={{
                              ...props.style,
                              minWidth: '480px',
                              pointerEvents: 'none',
                            }}
                          >
                            <Row className="py-3 px-2">
                              <Col xs={12} className="mb-4">
                                <Row className="px-4 pt-2 center color-white">
                                  <Col style={{ fontSize: '14px' }} xs={12}>
                                    {t(
                                      'This value is calculated based on data quality rules, by dividing the number of cells without data quality issues by the total number of cells (rows x columns).',
                                    )}
                                  </Col>
                                  <Col className="mt-2" xs={12}>
                                    {t(
                                      'Text or ID columns are excluded from this calculation.',
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                className="d-inline-flex flex-nowrap align-items-center"
                                xs={12}
                              >
                                <>
                                  <span className="inline-block ms-4 text-center">
                                    <div
                                      style={{
                                        maxWidth: '300px',
                                        minWidth: '300px',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      <Row>
                                        <Col
                                          xs={12}
                                          style={{
                                            borderBottom: '1px solid white',
                                          }}
                                        >
                                          {Math.round(
                                            activeModel?.dataset?.data_quality
                                              ?.global *
                                              activeModel?.dataset?.rows *
                                              (activeModel?.dataset?.cols -
                                                invalidColumns),
                                          )}{' '}
                                          {t(
                                            'cells without any data quality issues',
                                          )}
                                        </Col>
                                        <Col xs={12}>
                                          {Math.round(
                                            activeModel?.dataset?.rows *
                                              (activeModel?.dataset?.cols -
                                                invalidColumns),
                                          )}{' '}
                                          {t('Total cells')}
                                        </Col>
                                      </Row>
                                    </div>
                                  </span>
                                  <span className="mx-4">{' = '}</span>
                                  <span>
                                    <GoDotFill
                                      size={24}
                                      className="me-1 mb-1"
                                      style={{
                                        display: 'inline',
                                        color: `${
                                          activeModel?.dataset?.data_quality
                                            ?.global > 0.8
                                            ? '#0F9D58'
                                            : activeModel?.dataset?.data_quality
                                                ?.global > 0.5
                                            ? '#FFC107'
                                            : '#DC3545'
                                        }`,
                                      }}
                                    />
                                    {defaultFormat({
                                      num:
                                        activeModel?.dataset?.data_quality
                                          ?.global * 100,
                                      digits: 0,
                                    })}
                                    %
                                  </span>
                                </>
                              </Col>
                            </Row>
                          </Popover>
                        )}
                        style={{
                          ...props.style,
                          minWidth: 'max(25vw, 300px)',
                        }}
                      >
                        <span
                          className="ms-4"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <FaInfoCircle />
                        </span>
                      </OverlayTrigger>
                    </span>
                  )}
                </Row>
              ) : (
                <Row className="text-center">
                  <span>N/A</span>
                </Row>
              )}
            </>
          </Col>
          <Col xs={'auto'}>
            <Row className="text-center">
              <strong>{t('Id')}</strong>
            </Row>
            <Row className="text-center">
              <span>{activeModel.id}</span>
            </Row>
          </Col>
        </Row>
      </Col>
    ) : null

  if (additionHeaders) {
    additionHeaders = (
      <>
        <OverlayTrigger
          rootClose={true}
          trigger={['hover', 'focus']}
          placement={'auto'}
          delay={{ show: 100, hide: 200 }}
          overlay={(props) => (
            <Popover {...props} style={{ ...props.style, minWidth: '450px' }}>
              <div className="p-4 dflex-center">{additionHeaders}</div>
            </Popover>
          )}
        >
          <Row className="align-items-center justify-content-between d-flex d-sm-none">
            {additionHeaders}
          </Row>
        </OverlayTrigger>
        <Row className="align-items-center justify-content-between d-sm-flex d-none">
          {additionHeaders}
        </Row>
      </>
    )
  }

  return (
    <div {...props}>
      <div className="mt-2 position-relative">
        <ViewDataTable
          model={activeModel}
          setIsChangingDataActiveModel={setIsChangingDataActiveModel}
          updateModel={updateModel}
          getHead={getHead}
          dataId={activeModel.id}
          onChange={activeModel}
          columnToType={generateColumnToType(activeModel)}
          onColumnTypeChanged={(column, newType, dateFormat) => {
            console.log(
              'On column type changed',
              column,
              '(new type is',
              newType,
              ')',
            )
            setIsChangingDataActiveModel(true)
            setColumnType(
              activeModel.id,
              column,
              newType,
              dateFormat,
              token,
              signout,
            ).then((response) => {
              activeModel.dataset.final_column_status[column] = newType
              activeModel.dirty = true
              updateModel(activeModel.id, activeModel)
              queryClient.invalidateQueries([
                'model-data-quality-rules',
                activeModel?.id,
              ])
              queryClient.invalidateQueries([
                'viewData-infinite',
                activeModel?.id,
                activeModel?.dataset?.status,
              ])
              setIsChangingDataActiveModel(false)
            })
          }}
          hasEditMode={true}
          rowsPerPage={20000}
          linkFlow={
            Array.isArray(flow?.objects) &&
            (flow.objects.length > 1 ||
              flow.objects.some((o) => o?.metadata?.data?.advancedFlow)) &&
            Object.keys(flow?.objects?.[0]?.metadata ?? {}).length > 0
              ? `/dataflow/${workspaceId}?model-id=${activeModel.id}`
              : null
          }
          additionHeaders={additionHeaders}
        />
        <Modal
          show={showDataQuality}
          onHide={() => setShowDataQuality(false)}
          size="xl"
          centered
        >
          <Modal.Header closeButton onHide={() => setShowDataQuality(false)}>
            <Modal.Title className="h4">
              <Row>
                <Col xs={12}>
                  <span>
                    <Trans i18nKey="Data Quality"></Trans>
                  </span>
                  {activeModel?.dataset?.data_quality?.global && (
                    <span>
                      {' '}
                      (
                      <GoDotFill
                        size={24}
                        className="me-1 mb-1"
                        style={{
                          display: 'inline',
                          color: `${
                            activeModel?.dataset?.data_quality?.global > 0.9
                              ? '#0F9D58'
                              : activeModel?.dataset?.data_quality?.global > 0.5
                              ? '#FFC107'
                              : '#DC3545'
                          }`,
                        }}
                      />
                      {defaultFormat({
                        num: activeModel?.dataset?.data_quality?.global * 100,
                        digits: 0,
                      })}
                      % )
                    </span>
                  )}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>
          {showDataQuality && rules && (
            <RulesConfig
              model={activeModel}
              rules={rules}
              close={() => {
                setWaitingForWorkFinished(true)
                setShowDataQuality(false)
              }}
            />
          )}
        </Modal>
      </div>
    </div>
  )
}

import { Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import CodeMirror from '@uiw/react-codemirror'
import { json } from '@codemirror/lang-json'
import { solarizedDark } from '@uiw/codemirror-theme-solarized'
import { useAuth } from '../../providers/AuthProvider'
import { useEffect, useMemo, useRef, useState } from 'react'
import { MdEmail } from 'react-icons/md'
import { FaChevronRight, FaRegCopy } from 'react-icons/fa'
import { NotificationManager } from 'react-notifications'
import SingleAccordion from '../single-accordion/SingleAccordion'

const body = `{
"prompt": "Your question here",
"destination_email": "<optional; defaults to sender's email>",
"delete_files": false,
"repository_id": "<optional; ID of the repository for attached files>",
"document_name": "<optional; name for the response document attachment>"
}`

export default function EmailRepository({ repository }) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const lastElementRef = useRef()

  const [showImportantNotes, setShowImportantNotes] = useState(false)
  useEffect(() => {
    if (showImportantNotes) {
      setTimeout(() => {
        lastElementRef.current.scrollIntoView({ behavior: 'smooth' })
      }, 300)
    }
  }, [showImportantNotes])

  const adjustedBody = useMemo(() => {
    let adjusted = body
    if (user?.email)
      adjusted = adjusted.replace(
        "<optional; defaults to sender's email>",
        user.email,
      )
    return adjusted
  }, [user])

  return (
    <Row
    // style={{ fontSize: '0.8rem' }}
    >
      <Col xs={12}>
        You can query your knowledge base (RAG) by sending an email to{' '}
        <strong>upload@nextbrain.ai</strong> containing your question (prompt).
        Optionally, you can attach documents to the email.
      </Col>
      <Col className="mt-3" xs={12}>
        <h5>
          <strong>Email Guidelines</strong>
          <hr />
        </h5>
      </Col>
      <Col xs={12}>
        <ul>
          <li>
            Subject Line: Begin with <strong>RAG</strong> followed by your
            custom subject.
          </li>
          <li className="mt-2">
            Email Body: Choose one of the following formats:
            <br />
            <ol>
              <li>
                <strong>Prompt Only</strong>: Write your question directly in
                the email body. Ensure no additional text or signatures are
                included.
              </li>
              <li>
                <strong>Prompt with Additional Configurations</strong>: Include
                your prompt and settings in JSON format:
              </li>
            </ol>
          </li>
        </ul>
      </Col>
      <Col xs={12} className="p-2">
        <CodeMirror
          value={adjustedBody}
          extensions={[json({})]}
          theme={solarizedDark}
          readOnly
          indentWithTab
        />
      </Col>
      <Col
        className="mt-3 cursor-pointer"
        xs={12}
        onClick={() => setShowImportantNotes(!showImportantNotes)}
      >
        <h5>
          <strong>
            {t('Important notes')}
            <FaChevronRight
              className="ms-2"
              style={{
                transform: `rotate(${showImportantNotes ? 90 : 0}deg)`,
                transitionDuration: '0.3s',
              }}
            />
          </strong>
        </h5>
      </Col>
      <SingleAccordion
        header={false}
        forceStatus={showImportantNotes}
        className="w-100  nb-accordion"
        defaultStyles=""
        bodyProps={{ style: { padding: 0 } }}
      >
        <Col xs={12}>
          <ul>
            <li className="mt-2">
              Attachments and Repository ID:
              <br />
              <ul>
                <li className="mt-1">
                  If you specify a <strong>repository_id</strong>, any attached
                  files will be uploaded to that repository. Attaching files is
                  optional in this case.
                </li>
                <li className="mt-1">
                  If you do not specify a <strong>repository_id</strong>, you
                  must attach at least one file. A new repository will be
                  created based on your email's subject.
                </li>
              </ul>
            </li>
          </ul>
        </Col>
        <Col xs={12}>
          <ul>
            <li>
              Deleting Files:
              <br />
              <ul>
                <li className="mt-1">
                  Set <strong>delete_files</strong> to true if you want the
                  system to delete the attached files after processing.
                </li>
              </ul>
            </li>
          </ul>
        </Col>
        <Col xs={12}>
          <ul>
            <li>
              Response Delivery:
              <ul>
                <li className="mt-1">
                  By default, the response will be sent to the sender's email
                  address.
                </li>
                <li className="mt-1">
                  To receive the response at a different email, use the{' '}
                  <strong>destination_email</strong> parameter.
                </li>
              </ul>
            </li>
          </ul>
        </Col>
        <Col xs={12}>
          <ul>
            <li>
              Response Format:
              <ul>
                <li className="mt-1">
                  By default, the response will be sent to the sender's email
                  address.
                </li>
              </ul>
            </li>
          </ul>
        </Col>
        <Col xs={12} ref={lastElementRef}>
          <ul>
            <li>
              Response Format:
              <ul>
                <li className="mt-1">
                  Specify a document_name to receive the response as an attached
                  document with that name.
                </li>
                <li className="mt-1">
                  Otherwise, the response will be included in the body of the
                  email.
                </li>
              </ul>
            </li>
          </ul>
        </Col>
      </SingleAccordion>

      <Col xs={12} className="dflex-center mt-2">
        <Button
          className="original empty-secondary me-1"
          style={{ maxWidth: '160px', minWidth: '160px' }}
          onClick={() => {
            if (navigator?.clipboard?.writeText) {
              navigator.clipboard.writeText(adjustedBody)
              NotificationManager.info('Configuration copied to clipboard')
            } else NotificationManager.error('Clipboard not supported')
          }}
        >
          <Row>
            <Col xs="3" className="dflex-center pe-0">
              <FaRegCopy size={30} />
            </Col>
            <Col xs="9" className="text-start">
              {t('Copy configuration')}
            </Col>
          </Row>
        </Button>
        <Button
          className="ms-1 min-h-full"
          style={{ maxWidth: '160px', minWidth: '160px' }}
          onClick={() => {
            const body = `{\n  "prompt": "Your question here",\n  "destination_email": "<can be omitted>",\n  "delete_files": false,\n  "repository_id": "${repository.id}"\n}`
            const mailtoLink = `mailto:upload@nextbrain.ai?subject=${encodeURIComponent(
              '[RAG] Your subject',
            )}&body=${encodeURIComponent(body)}`
            window.open(mailtoLink, '_blank')
          }}
        >
          <Row>
            <Col xs="3" className="dflex-center pe-0">
              <MdEmail size={30} />
            </Col>
            <Col xs="9" className="text-start">
              {t('Send email')}
            </Col>
          </Row>
        </Button>
      </Col>
    </Row>
  )
}

import React, { useEffect, useState, useMemo } from 'react'
import { Row, Col, Form } from 'react-bootstrap'

import { useAuth } from '../../providers/AuthProvider'
import { getRecentModels } from '../../services/model'
import { useDebouncedCallback } from 'use-debounce'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next'
import NextbrainSelect from '../model-content/NextbrainSelect'
import { useQuery } from 'react-query'
import { getAllWorkSpace } from '../../services/project'
import ItemModel from '../model-landing/ItemModel'

import './ModelTopView.css'
import { useNavigate } from 'react-router-dom'
import { useNav } from '../../providers/NavProvider'

export default function ModelTopView({
  setTitle = () => {},
  small = false,
  ...props
}) {
  const { t } = useTranslation()

  const [problemFilterOptions, modelStatusOptions, projectTypeOptions] =
    useMemo(() => {
      const problemFilterOptions = [
        { label: t('Classification models'), value: 'multiclass' },
        { label: t('Regression models'), value: 'regression' },
        { label: t('Forecast models'), value: 'time_series_regression' },
      ]

      const modelStatusOptions = [
        { label: t('Created'), value: 'created' },
        { label: t('Training'), value: 'training' },
        { label: t('Trained'), value: 'trained' },
        { label: t('Error'), value: 'error' },
      ]

      const projectTypeOptions = [
        { label: t('Owned'), value: 'owned' },
        { label: t('Shared'), value: 'shared' },
        { label: t('Favorited'), value: 'favorited' },
      ]
      return [problemFilterOptions, modelStatusOptions, projectTypeOptions]
    }, [t])
  const { token, signout } = useAuth()
  const PAGE_SIZE = small
    ? window.outerWidth < 640
      ? 2
      : 6
    : Math.max(2, Math.floor(window.outerWidth / 300)) *
      Math.floor(window.outerHeight / 250)
  const [search, setSearch] = useState('')
  const [problemType, setProblemType] = useState(null)
  const { isLoading: workspacesIsLoading, data: workspaces } = useQuery(
    `workspace-${token}`,
    async () => {
      return await getAllWorkSpace({ only_projects: true, token, signout })
    },
    { staleTime: 30 * 1000 },
  )
  const { mode } = useNav()
  const workspacesNames = useMemo(() => {
    if (workspacesIsLoading) return []
    return (Array.isArray(workspaces) ? workspaces : []).map((w) => ({
      label: w.name,
      value: w.name,
    }))
  }, [workspacesIsLoading, workspaces])
  const [selectedWorkspace, setSelectedWorkspace] = useState(null)
  const [modelStatus, setModelStatus] = useState(null)
  const [projectType, setProjectType] = useState(null)

  const [models, setModels] = useState([])
  const [pages, setPages] = useState({ currentPage: 0, lastPage: 0 })
  const [searching, setSearching] = useState(false)
  const navigate = useNavigate()

  const message =
    searching || models.length
      ? 'Searching'
      : search.search
      ? 'No results'
      : 'Empty search'

  const updateSearch = useDebouncedCallback((str) => {
    setSearch(str)
  }, 1000)

  const deleteTopModel = (model) => {
    setModels((models) => models.filter((m) => m.id !== model))
  }

  const doSearch = (clearPage = true) => {
    if (searching) return
    setSearching(true)
    getRecentModels({
      limit: PAGE_SIZE,
      page: clearPage ? 0 : pages.currentPage,
      name_like: search,
      problem_type: problemType?.value,
      project_name: selectedWorkspace ? selectedWorkspace : null,
      model_status: modelStatus?.value,
      project_type: projectType?.value,
      token,
      signout,
    })
      .then((m) => {
        pages.currentPage = m?.actualPage ?? 0
        pages.lastPage = m?.lastPage ?? -1
        setModels(m?.models ?? [])
      })
      .finally(() => setSearching(false))
  }

  useEffect(
    () => {
      doSearch()
    },
    // eslint-disable-next-line
    [search, problemType, selectedWorkspace, modelStatus, projectType],
  )

  useEffect(
    () => {
      doSearch(false)
    },
    // eslint-disable-next-line
    [pages],
  )

  useEffect(
    () => {
      setTitle(`${t('Nextbrain')} | Search models`)
    },
    // eslint-disable-next-line
    [],
  )

  const createUri = (model) =>
    `/model/${model.project_id}?wp-name=${encodeURIComponent(
      model.project_name,
    )}&model-id=${model.id}`

  const inputs = small
    ? {
        xl: 4,
        md: 6,
        xs: 12,
      }
    : {
        xl: 2,
        md: 3,
        xs: 4,
      }

  const modelSpacing = small
    ? {
        xxl: 4,
        lg: 6,
        md: 6,
        sm: 12,
        xs: 12,
      }
    : {
        xxl: 2,
        lg: 3,
        md: 4,
        sm: 6,
        xs: 12,
      }

  return (
    <>
      {!small && (
        <Row className="mx-0">
          <Col className="h3 mt-3" xs={12}>
            <strong>
              <div className="models-icon icon-nav d-inline-block"></div>{' '}
              {t('Models')}{' '}
            </strong>
          </Col>
        </Row>
      )}

      <Row
        {...props}
        className={`mx-0 model-top-view-container ${props.className ?? ''}`}
      >
        <Col xs={12} className="mt-1 d-flex ">
          <Row className="w-100">
            <Col xs={12} className="mb-2">
              <Row>
                <Col className="mt-1 mt-md-0" {...inputs}>
                  <Form.Control
                    style={{
                      borderRadius: 8,
                      paddingLeft: '50px',
                    }}
                    className="nb-input py-2"
                    placeholder={t('Search a model...')}
                    autoFocus
                    onChange={(e) => {
                      updateSearch(e.target.value)
                    }}
                  />
                  <i
                    style={{ float: 'left' }}
                    className="fas fa-search icon"
                  ></i>
                </Col>
                <Col className="mt-1 mt-md-0" {...inputs}>
                  <NextbrainSelect
                    value={
                      selectedWorkspace
                        ? { label: selectedWorkspace, value: selectedWorkspace }
                        : null
                    }
                    onChange={(value) => setSelectedWorkspace(value?.value)}
                    options={workspacesNames}
                    hideSelectedOptions={false}
                    isClearable={true}
                    placeholder={t('All workspaces')}
                    type={'dark'}
                  />
                </Col>
                <Col className="mt-1 mt-md-0" {...inputs}>
                  <NextbrainSelect
                    value={problemType}
                    onChange={(value) => setProblemType(value)}
                    options={problemFilterOptions}
                    hideSelectedOptions={false}
                    isClearable={true}
                    type={'dark'}
                    placeholder={t('Any problem type')}
                  />
                </Col>
                <Col className="mt-1 mt-md-0" {...inputs}>
                  <NextbrainSelect
                    value={modelStatus}
                    onChange={(value) => setModelStatus(value)}
                    options={modelStatusOptions}
                    hideSelectedOptions={false}
                    isClearable={true}
                    type={'dark'}
                    placeholder={t('Any model status')}
                  />
                </Col>
                <Col className="mt-1 mt-md-0" {...inputs}>
                  <NextbrainSelect
                    value={projectType}
                    onChange={(value) => setProjectType(value)}
                    options={projectTypeOptions}
                    hideSelectedOptions={false}
                    isClearable={true}
                    type={'dark'}
                    placeholder={t('All project types')}
                  />
                </Col>
              </Row>
            </Col>
            {models?.length ? (
              <>
                {models?.map((t) => (
                  <Col key={t.id} {...modelSpacing}>
                    <ItemModel
                      className="topmodels-modelcard light-card py-2 mx-1 px-3 my-2"
                      model={t}
                      projectName={t.project_name}
                      projectID={t.project_id}
                      onDelete={deleteTopModel}
                      fullyLoaded={false}
                      onMouseUp={(e) => {
                        //Inline edit doesn't behave well with <Link>, workaround
                        if (e.button === 2) return
                        const newWindow =
                          e.ctrlKey || e.shiftKey || e.button === 1
                        if (newWindow) window.open(createUri(t), '_blank')
                        else navigate(createUri(t))
                      }}
                      mode={mode}
                    />
                  </Col>
                ))}
                <Row className="justify-content-end mt-2">
                  <Col xs={'auto'}>
                    <ReactPaginate
                      className="paginate-top-view"
                      breakLabel="..."
                      nextLabel={t('next')}
                      forcePage={pages.currentPage ?? 0}
                      onPageChange={({ selected }) => {
                        setPages((s) => ({ ...s, currentPage: selected }))
                      }}
                      pageRangeDisplayed={3}
                      pageCount={Math.max(0, pages.lastPage)}
                      previousLabel={t('previous')}
                      renderOnZeroPageCount={null}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <Col className="loading-top-models-container" xs={12}>
                <div>{message}</div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  )
}

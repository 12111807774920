import React, { useState, useRef, useEffect } from 'react'
import * as d3 from 'd3'
import { Row, Col, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import './DecisionTree.css'
import { getTree } from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'
import { NotificationManager } from 'react-notifications'
import { categories as categoryColor } from '../../util/aethetics'
import { defaultFormat, round } from '../utils/formating'
import { useSampleFlow } from './sampleflow'
import { useDebouncedCallback } from 'use-debounce'
import Loading from '../loading/LoadingSmall'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'

const MARGIN_TOP_GRAPH = 100
const BOXWIDTH = 141
const BOXHEIGHT = 70
const BOX_X_OFFSET = -70
const BOX_Y_OFFSET = -95

function parseCategories(string, ratio = 1, validCategories) {
  let res = {}
  if (Array.isArray(string)) {
    res = string.reduce((dict, v) => {
      if (v.count) dict[v.value] = Math.ceil(v.count * ratio)
      return dict
    }, {})
  } else
    res = string
      .split(',')
      .map((s) => s.split('of').map((e) => e.trim()))
      .reduce((dict, v) => {
        const num = Math.ceil(Number.parseInt(v[0]) * ratio)
        if (v) dict[v[1]] = num
        return dict
      }, {})

  if (validCategories) {
    const onlyValidCatogories = {}
    Object.keys(res).forEach((k) => {
      if (validCategories.has(k)) onlyValidCatogories[k] = res[k]
      else {
        onlyValidCatogories['other'] = onlyValidCatogories['other'] ?? 0
        onlyValidCatogories['other'] += res[k]
      }
    })
    res = onlyValidCatogories
  }

  return res
}

function adjustPredicate(node) {
  const keys = Object.keys(node.calculatedPred)
  keys.sort((a, b) => node.calculatedPred[b] - node.calculatedPred[a])

  if (keys.length > 8) {
    const newRes = { other: 0 }
    keys.slice(0, 8).forEach((k) => (newRes[k] = node.calculatedPred[k]))
    keys.slice(8).forEach((k) => (newRes['other'] += node.calculatedPred[k]))
    node.calculatedPred = newRes
  }
}

function depth(node) {
  if (node.children) {
    return 1 + node.children.reduce((m, n) => Math.max(m, depth(n)), 0)
  }
  return 1
}

function getTextWidth(text, fontSize = '13', fontFace = 'monospace') {
  var a = document.createElement('canvas')
  var b = a.getContext('2d')
  b.font = fontSize + 'px ' + fontFace
  return b.measureText(text).width
}
// eslint-disable-next-line
function generateTooltip(text, x, y, fontSize = 12) {
  const tooltip = d3.create('svg:g').attr('class', 'tooltip-decision-tree')

  tooltip
    .append('rect')
    .attr('class', 'tooltip-container')
    .attr('width', getTextWidth(text, fontSize) + 20)
    .attr('height', 30)
    .attr('x', x - 10)
    .attr('y', y)
  tooltip
    .append('text')
    .attr('x', x)
    .attr('y', y + 20)
    .text(text)

  return tooltip
}

function collapse(node, recurse = true) {
  if (node.children) {
    node._children = node.children
    node.children = null
    if (recurse) node._children.forEach((n) => collapse(n, recurse))
  } else {
    node.children = node._children
    node._children = null
  }
}

//Draw the bars with% at the bottom
function drawBarGraph(treeData, model, nodeEnter) {
  nodeEnter.append((d) => {
    const rect = d3
      .create('svg:g')
      .attr('class', `node-top-tooltip node-top-tooltip-${d.id}`)
      .attr('width', BOXWIDTH)
      .attr('height', 20)
      .attr('x', BOX_X_OFFSET)
      .attr('y', BOX_Y_OFFSET + 150)

    const categories = d.calculatedPred
    const total = Object.entries(categories).reduce((ac, e) => ac + e[1], 0)

    const max = Object.keys(categories).reduce((ac, k) => {
      const perc = categories[k] / total
      const text = `${Math.round(100 * perc)}%`
      const tw_pluspadding = Math.max(perc * 137, getTextWidth(text) + 6)
      return Math.max(ac, tw_pluspadding, getTextWidth(k))
    }, 0)

    d.data.width = max + 10

    const left = BOX_X_OFFSET + BOXWIDTH + 5
    const top = BOX_Y_OFFSET + 15

    rect
      .append('rect')
      .attr('class', 'bg-tooltip-tree')
      .attr('x', left - 5)
      .attr('y', top - 20)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', () => max + 10)
      .attr('height', Object.keys(categories).length * 40 + 10)

    Object.keys(categories).forEach((k, i) => {
      const perc = categories[k] / total
      const text = `${Math.round(100 * perc)}%`

      const tw_pluspadding = Math.max(perc * 137, getTextWidth(text) + 6)

      rect
        .append('svg:rect')
        .attr('class', 'bar-category')
        .attr('width', tw_pluspadding)
        .attr('height', 20)
        .attr('x', left)
        .attr('y', top + i * 40)
        .attr('rx', 3)
        .attr('ry', 3)
        .style('fill', treeData.categoryColors[k])

      rect
        .append('svg:text')
        .attr('class', 'stroke-text')
        .attr('x', left)
        .attr('y', top - 5 + i * 40)
        .attr('text-anchor', 'start')
        .text(`${k}`)

      rect
        .append('svg:text')
        .attr('class', 'category-text')
        .attr('x', left + 5)
        .attr('y', top + 13 + i * 40)
        .attr('text-anchor', 'start')
        .text(text)
    })
    return rect.node()
  })
}

function drawBallsPlaceholder(nodeEnter) {
  nodeEnter.append((d) => {
    const rt = d3
      .create('svg:g')
      .style('transform', `translate(${-1 * d.x}px,${-1 * d.y}px)`)
    d.requests().forEach((r) => {
      rt.append('svg:circle')
        .attr('class', 'placeholder-circle')
        .attr('cx', r.x)
        .attr('cy', r.y)
        .attr('r', 3)
        .style('fill', r.color)
    })
    return rt.node()
  })
}

function drawText(nodes, nodeEnter, model, format) {
  nodeEnter.append((d) => {
    const res = d3
      .create('svg:g')
      .attr('class', 'tooltip-trigger')
      .attr('width', 141)
      .attr('height', 70)
      .attr('x', -70)
      .attr('y', 55)

    //Not a leaf
    if (d.data.pred) {
      d.data.question.forEach((l, i) => {
        res
          .append('text')
          .attr('dy', '.15em')
          .attr('class', 'stroke-text')
          .attr('x', BOX_X_OFFSET + (BOXWIDTH - getTextWidth(l)) / 2)
          .attr('y', 25 + i * 20)
          .attr('title', l)
          .attr('text-anchor', 'start')
          .text(l)
      })
      const base = d.data?.question?.length ?? 0
      if (d.data.data) {
        Object.keys(d.data.data).forEach((k, i) => {
          const message = format
            ? format(k, d.data.data[k])
            : `${k} = ${defaultFormat({ num: d.data.data[k] })}`
          res
            .append('text')
            .attr('dy', '.15em')
            .attr('class', 'stroke-text')
            .attr('x', BOX_X_OFFSET + (BOXWIDTH - getTextWidth(message)) / 2)
            .attr('y', 25 + (i + base) * 20)
            .attr('title', message)
            .attr('text-anchor', 'start')
            .text(message)
        })
      }

      const percStr = `${Math.round(
        (100 * d.data.size) / nodes[0].data.size,
      )}% (${round(d.data.size)} rows)`
      res
        .append('text')
        .attr('class', 'subtext')
        .attr('x', BOX_X_OFFSET + (BOXWIDTH - getTextWidth(percStr, 12)) / 2)
        .attr('y', BOX_Y_OFFSET + 15)
        .attr('text-anchor', 'start')
        .text(percStr)
    }
    //A leaf
    else {
      const percStr = `${Math.round(
        (100 * d.data.size) / nodes[0].data.size,
      )}% (${round(d.data.size)} rows)`
      res
        .append('text')
        .attr('class', 'subtext')
        .attr('x', BOX_X_OFFSET + (BOXWIDTH - getTextWidth(percStr, 12)) / 2)
        .attr('y', BOX_Y_OFFSET + 15)
        .attr('text-anchor', 'start')
        .text(percStr)
    }
    return res.node()
  })
}

function draw(src, treeData, model, setTargetNode, format, baseHeight = 200) {
  treeData.container.attr('height', baseHeight * depth(treeData.nodes))
  const tree = treeData.tree(treeData.nodes)
  const nodes = tree.descendants()

  const links = nodes.slice(1)
  nodes.forEach(function (d) {
    d.y = d.depth * 180
  })

  const node = treeData.svg.selectAll('g.node').data(nodes, function (d) {
    return d.id
  })

  const node2 = treeData.tooltipContainer
    .selectAll('g.node-tooltip-entry')
    .data(nodes, function (d) {
      return d.id
    })

  const nodeEnter = node
    .enter()
    .append('g')
    .lower()
    .attr('class', 'node tooltip-trigger')
    .attr('transform', function (d) {
      return 'translate(' + (src.x0 ?? '0') + ',' + (src.y0 ?? 0) + ')'
    })
    .on('click', (event, n) => {
      setTargetNode({ node: n })
    })
    .on('mouseleave', (e, d) => {
      d3.selectAll(`g.node-top-tooltip-${d.id}`).attr(
        'class',
        `node-top-tooltip node-top-tooltip-${d.id} `,
      )
    })
    .on('mouseenter', (e, d) => {
      d3.selectAll(`g.node-top-tooltip-${d.id}`).attr(
        'class',
        `node-top-tooltip node-top-tooltip-${d.id} visible`,
      )
    })

  const nodeEnter2 = node2
    .enter()
    .append('g')
    .lower()
    .attr('class', 'node-tooltip-entry')
    .attr('transform', function (d) {
      return (
        'translate(' +
        -65 +
        ',' +
        (!d.depth ? treeData.tooltipBaseY ?? 0 : d.depth * 180) +
        ')'
      )
    })

  nodeEnter //Rect container
    .append('rect')
    .attr(
      'class',
      (d) =>
        `${d.children || d._children ? 'with-children' : 'no-children'} ${
          d._children ? 'expandable' : ''
        }`,
    )
    .attr('width', BOXWIDTH)
    .attr('height', BOXHEIGHT)
    .attr('x', BOX_X_OFFSET)
    .attr('y', BOX_Y_OFFSET)
    .attr('rx', 6)
    .attr('ry', 6)

  nodeEnter
    .append('circle')
    .attr('class', (d) =>
      d.children || d._children ? `node node-circle` : 'node-empty',
    )
    .attr('r', 1e-6)
    .style('fill', function (d) {
      return d._children ? 'lightsteelblue' : '#fff'
    })

  nodeEnter
    .append('text')
    .attr(
      'class',
      (d) =>
        `stroke-text expand-tooltip  ${
          d.children || d._children ? '' : 'd-none'
        }`,
    )
    .attr('x', -6)
    .attr('y', 7)
    .text((d) => (d.children ? '-' : '+'))

  //Draw the tail in the rectangles pointing to the node
  nodeEnter
    .append('polygon')
    .attr('class', (d) => (d.children || d._children ? 'bocadillo' : 'leaf'))
    .attr(
      'points',
      `${BOX_X_OFFSET + BOXWIDTH / 2 + 25},${BOX_Y_OFFSET + BOXHEIGHT - 1} ` +
        `${BOX_X_OFFSET + BOXWIDTH / 2 + 5},${BOX_Y_OFFSET + BOXHEIGHT - 1} ` +
        `${BOX_X_OFFSET + BOXWIDTH / 2},${BOX_Y_OFFSET + BOXHEIGHT + 20}`,
    )
  nodeEnter
    .append('polygon')
    .attr('class', (d) => (d.children || d._children ? 'boc-border' : 'leaf'))
    .attr(
      'points',
      `${BOX_X_OFFSET + BOXWIDTH / 2 + 25},${BOX_Y_OFFSET + BOXHEIGHT - 2} ` +
        `${BOX_X_OFFSET + BOXWIDTH / 2 + 5},${BOX_Y_OFFSET + BOXHEIGHT - 2} ` +
        `${BOX_X_OFFSET + BOXWIDTH / 2 + 5},${BOX_Y_OFFSET + BOXHEIGHT} ` +
        `${BOX_X_OFFSET + BOXWIDTH / 2 + 25},${BOX_Y_OFFSET + BOXHEIGHT} `,
    )

  //Draw typography
  drawText(nodes, nodeEnter, model, format)
  //Perc % graph legacy
  //drawBarGraph(treeData, model, nodeEnter);
  drawBallsPlaceholder(nodeEnter)
  drawBarGraph(treeData, model, nodeEnter2)

  //Transition stuff to make updates visually appealing
  const nodeUpdate = nodeEnter.merge(node)
  nodeUpdate
    .transition()
    .duration(500)
    .attr('transform', function (d) {
      return 'translate(' + d.x + ',' + d.y + ')'
    })
    .select('rect')
    .attr(
      'class',
      (d) =>
        `${d.children || d._children ? 'with-children' : 'no-children'} ${
          d._children ? 'expandable' : ''
        }`,
    )

  nodeUpdate
    .select('circle.node')
    .attr('r', 10)
    .style('fill', function (d) {
      return d._children ? 'lightsteelblue' : '#fff'
    })
    .attr('cursor', 'pointer')

  nodeUpdate.select('text.expand-tooltip').text((d) => (d.children ? '-' : '+'))

  const nodeUpdate2 = nodeEnter2.merge(node2)
  nodeUpdate2
    .transition()
    .duration(500)
    .attr('transform', function (d) {
      if (d.x < treeData.width / 2) return 'translate(' + d.x + ',' + d.y + ')'
      else
        return 'translate(' + (d.x - d.data.width - BOXWIDTH) + ',' + d.y + ')'
    })

  //Hide stuff
  const nodeExit = node
    .exit()
    .transition()
    .duration(500)
    .attr('transform', function (d) {
      return 'translate(' + src.x + ',' + src.y + ')'
    })
    .remove()

  nodeExit.select('circle').attr('r', 1e-6)
  nodeExit.select('text').style('fill-opacity', 1e-6)

  //Draw links
  var link = treeData.svg.selectAll('path.link').data(links, function (d) {
    return d.id
  })

  var linkEnter = link
    .enter()
    .insert('path', 'g')
    .attr('class', (d) => {
      return `link ${d.data.side === 'left' ? 'no-path' : 'yes-path'}`
    })
    .attr('d', function (d) {
      const o = { x: src.x, y: src.y }
      return diagonal(o, o)
    })
    .style(
      'stroke-width',
      (d) => `${1 + (6 * d.data.size) / d.parent.data.size}px`,
    )

  var linkUpdate = linkEnter.merge(link)

  linkUpdate
    .transition()
    .duration(500)
    .attr('d', function (d) {
      return diagonal(d, d.parent)
    })

  link
    .exit()
    .transition()
    .duration(500)
    .attr('d', function (d) {
      var o = { x: src.x, y: src.y }
      return diagonal(o, o)
    })
    .remove()

  nodes.forEach(function (d) {
    d.x0 = d.x
    d.y0 = d.y
  })
  treeData.activateBranch(nodes.sort((a, b) => b.depth - a.depth))
  const removed = d3.selectAll('g.nodeflow-container').remove()
  treeData.svg.append(() => removed.node())
  const removedTooltip = d3.selectAll('g.top-tooltip-container').remove()
  treeData.svg.append(() => removedTooltip.node())

  function diagonal(s, d) {
    const path = `M ${s.x} ${s.y - (s.children || s._children ? 0 : 50)}
          C  ${(s.x + d.x) / 2} ${s.y},
          ${(s.x + d.x) / 2} ${d.y},
            ${d.x} ${d.y}`

    return path
  }
}

function createYesNoLegend(
  svgContainer,
  containerWidth,
  labelPredicate = 'Rows',
) {
  const diag = (s, d) => {
    return `M ${s.x} ${s.y}
    C  ${(s.x + d.x) / 2} ${s.y},
    ${(s.x + d.x) / 2} ${d.y},
      ${d.x} ${d.y}`
  }
  const baseX = containerWidth - 161
  const baseY = 0
  const boxWidth = labelPredicate.length * 4 + 44
  const boxHeight = 30
  const tailNo = { x: baseX - 25, y: baseY + boxHeight + 35 }
  const tailYes = { x: baseX + boxWidth + 25, y: baseY + boxHeight + 35 }
  svgContainer.selectAll('.yes-no-legend').remove()

  svgContainer
    .append('path', 'g')
    .attr('class', 'link no-path yes-no-legend')
    .attr('d', diag({ x: baseX + boxWidth / 2, y: baseY + boxHeight }, tailNo))
    .style('stroke-width', 3)
  svgContainer
    .append('path', 'g')
    .attr('class', 'link yes-path yes-no-legend')
    .attr('d', diag({ x: baseX + boxWidth / 2, y: baseY + boxHeight }, tailYes))
    .style('stroke-width', 3)

  svgContainer
    .append('rect')
    .attr('width', boxWidth)
    .attr('height', boxHeight)
    .style('fill', 'var(--nextbrain-secondary-color)')
    .attr('class', 'tooltip-with-children yes-no-legend')
    .attr('rx', 4)
    .attr('ry', 4)
    .attr('stroke-width', 5)
    .attr('x', baseX)
    .attr('y', baseY)

  svgContainer
    .append('text')
    .attr('class', 'legend-yes-no-decision-tree stroke-text yes-no-legend')
    .attr('x', baseX + (boxWidth - getTextWidth(labelPredicate)) / 2)
    .attr('y', baseY + boxHeight / 2)
    .attr('dy', '.15em')
    .text(labelPredicate)

  svgContainer
    .append('text')
    .attr('class', 'legend-interrogant-decision-tree stroke-text yes-no-legend')
    .attr('x', baseX + (boxWidth - getTextWidth('?', 25)) / 2)
    .attr('y', baseY + boxHeight + 20)
    .attr('dy', '.15em')
    .text('?')

  svgContainer
    .append('text')
    .attr('class', 'legend-yes-no-decision-tree stroke-text yes-no-legend')
    .attr('x', tailNo.x - 20)
    .attr('y', tailNo.y)
    .attr('dy', '.15em')
    .text('No')

  svgContainer
    .append('text')
    .attr('class', 'legend-yes-no-decision-tree stroke-text yes-no-legend')
    .attr('x', tailYes.x + 5)
    .attr('y', tailYes.y)
    .attr('dy', '.15em')
    .text('Yes')
}

export default function DecisionTree({
  model,
  tree = null,
  margin = { top: 20, left: 20, right: 20, bottom: 20 },
  expandFirst = true,
  interpretationFirst = false,
  optionalTree = false,
  disabledOnError = false,
  valuesTitle = null,
  labelPredicate = 'Rows',
  colorScheme = 'color10',
  format = null,
  baseHeight = 180,
  ...props
}) {
  const { token, signout } = useAuth()
  const [treeData, setTreeData] = useState({})
  const [interpretation, setInterpretation] = useState({})
  const [targetNode, setTargetNode] = useState(null)
  const { t } = useTranslation()
  // eslint-disable-next-line
  const [sampleNode, addNodes, activateBranch] = useSampleFlow()
  const [showTree, setShowTree] = useState(!optionalTree)
  const [containerWidth, setContainerWidth] = useState(10)
  const [error, setError] = useState(false)
  const canvasRef = useRef()
  const widthCallback = useDebouncedCallback(() => {
    setContainerWidth(Math.max(canvasRef?.current?.offsetWidth ?? 0, 10))
  }, 200)

  const setTree = (d) => {
    if (!d || d.detail) {
      if (disabledOnError) {
        setError(true)
        return
      }
      NotificationManager.error('Failed to retrieve tree data')
      return
    }
    setInterpretation(d.interpretation)
    const containerWidth = Math.max(canvasRef.current?.offsetWidth ?? 0, 10)
    const tree = d3
      .tree()
      .size([
        containerWidth - margin.left - margin.right,
        200 * depth(d) - MARGIN_TOP_GRAPH,
      ])
      .separation(function separation(a, b) {
        return a.parent === b.parent ? 1 : 1
      })

    const nodes = d3
      .hierarchy(d, (d) => d.children)
      .sort((a, b) => (a.data.side === 'left' ? -1 : 1))

    nodes.calculatedPred = parseCategories(
      nodes.data.pred ?? nodes.data.name,
      1,
    )
    adjustPredicate(nodes)
    const validCategories = new Set(Object.keys(nodes.calculatedPred))

    const ratio =
      90 /
      Object.entries(nodes.calculatedPred).reduce((ac, [k, v]) => ac + v, 0)

    const maxLineLen = 25
    nodes.descendants().forEach((n) => {
      const pred = parseCategories(
        n.data.pred ?? n.data.name,
        ratio,
        validCategories,
      )
      if (!Array.isArray(n.data.name)) {
        const nameBase = (n.data?.name ?? '').split(' ')
        const lines = []
        const trail = nameBase.reduce((s, l) => {
          if (l.length > maxLineLen) {
            if (s) {
              lines.push(s)
              lines.push(l.substr(0, maxLineLen - 3) + '..')
              return ''
            }
          }
          const cat = s ? `${s} ${l}` : l
          if (cat.length > maxLineLen) {
            lines.push(s)
            return l
          }
          return cat
        }, '')
        if (trail) lines.push(trail)
        const nonEmptyLines = lines.filter((l) => l)
        n.data.question = nonEmptyLines
      }
      n.calculatedPred = pred
    })

    //Need to assign a color to each category/range beforehand
    const categories = []
    nodes.descendants().forEach((n, i) => {
      n.id = i + 1
      if (n.data.pred) categories.push(...Object.keys(n.calculatedPred))
    })

    const categoryColors = [...new Set(categories)].reduce((dict, k, i) => {
      dict[k] =
        categoryColor[colorScheme][i % categoryColor[colorScheme].length]
      return dict
    }, {})

    //Rounding errors may leave more than 90 children, clip them
    const difference = Object.keys(nodes.calculatedPred).reduce(
      (ac, k) => ac + nodes.calculatedPred[k],
      0,
    )
    if (difference > 90) {
      const rm = Object.keys(nodes.calculatedPred).sort(
        (a, b) => nodes.calculatedPred[b] - nodes.calculatedPred[a],
      )[0]
      nodes.calculatedPred[rm] -= Math.min(
        nodes.calculatedPred[rm],
        difference - 90,
      )
    }

    const clipChildren = (node) => {
      //There's some rounding errors when we limit the nodes to 90 so some clipping is required
      if (node.children) {
        Object.keys(node.calculatedPred).forEach((k) => {
          const parentHas = node.calculatedPred[k]
          let childrenHave = node.children.reduce((ac, c) => {
            return ac + (c.calculatedPred[k] ?? 0)
          }, 0)
          const cp1 = node.children[0].calculatedPred
          const cp2 = node.children[1].calculatedPred
          if (parentHas < childrenHave) {
            let diff = childrenHave - parentHas
            if (cp1[k]) {
              const rm = Math.floor(Math.min(cp1[k], diff / 2))
              cp1[k] -= rm
              diff -= rm
            }
            if (cp2[k]) {
              const rm = Math.floor(Math.min(cp2[k], diff))
              cp2[k] -= rm
              diff -= rm
            }
            if (cp1[k]) {
              const rm = Math.floor(Math.min(cp1[k], diff))
              cp1[k] -= rm
              diff -= rm
            }
          }
        })
        node.children.forEach((c) => {
          Object.keys(c.calculatedPred).forEach((k) => {
            if (!node.calculatedPred[k] || !c.calculatedPred[k]) {
              delete c.calculatedPred[k]
            }
          })
        })
        node.children.forEach(clipChildren)
      }
    }
    clipChildren(nodes)

    nodes.descendants().forEach((n) => {
      n.requests = (() => {
        const pred = n.calculatedPred
        const req = Object.keys(pred)
          .map((k) =>
            new Array(pred[k]).fill({
              category: k,
              color: categoryColors[k],
            }),
          )
          .flat()
        return () => {
          return req.map((ni, i) => ({
            ...ni,
            index: i, //Dynamically calculate where the placeholder should be since it repositions each time the tree expands
            x: BOX_X_OFFSET + 10 + (i % 18) * 7 + n.x,
            y: BOX_Y_OFFSET + 65 + Math.floor(i / 18) * -10 + n.y,
          }))
        }
      })()
    })

    const setParents = (n, parents = []) => {
      n.parentIds = parents
      if (n.children) n.children.forEach((c) => setParents(c, [...parents, n]))
    }
    setParents(nodes)

    $(canvasRef.current).find('svg').remove()
    const svgContainer = d3
      .select(canvasRef.current)
      .append('svg')
      .attr('class', 'svg-container-tree')
      .attr('width', Math.max(canvasRef.current.offsetWidth, 10))
      .attr('height', baseHeight * depth(d))

    //Addition container not affected by transformations for the forcegraph
    const nodeFlowContainer = svgContainer
      .append('svg:g')
      .attr('class', 'nodeflow-container')

    //Create the dynamic elements to distribute
    const nodeElements = addNodes({
      newNodes: nodes.requests(),
      onCreate: (n) => {
        nodeFlowContainer.append(() => n.element.node())
      },
    })

    nodes.dynamicNodes = nodeElements

    //May look expensive, it's limited to 90 nodes at most so it shouldn't go crazy in da complexity domain
    const distributeNodes = (node) => {
      if (!node.children)
        //Leafs don't give nodes away
        return

      const availableNodes = node.dynamicNodes.reduce((dict, node) => {
        dict[node.category] = dict[node.category] ?? []
        dict[node.category].push(node)
        return dict
      }, {})

      node.children.forEach((child) => {
        const childDynamicNodes = []
        const childRequests = child.requests() //How many of each category the child needs?
        childRequests.forEach((req) => {
          if (availableNodes?.[req.category]?.length)
            childDynamicNodes.push(availableNodes[req.category].pop())
          else console.log('A node is missing a sample from his parent')
        })
        child.dynamicNodes = childDynamicNodes
        distributeNodes(child) //And here he goes doing the same for his children and the children of his children, sharing is loving
      })
    }
    distributeNodes(nodes)

    //A legend on top left and right is needed to guide the user on the meaning of nodes
    createYesNoLegend(svgContainer, containerWidth, labelPredicate)
    svgContainer
      .append('text')
      .attr('class', 'legend-decision-tree')
      .attr('x', 0)
      .attr('y', 15)
      .attr('dy', '.15em')
      .text(valuesTitle ?? `Possible values of ${model?.target ?? 'Cluster'}:`)
    Object.keys(categoryColors).forEach((k, i) => {
      svgContainer
        .append('circle')
        .attr('r', 3)
        .style('fill', categoryColors[k])
        .attr('cx', 5)
        .attr('cy', 35 + i * 25)

      svgContainer
        .append('text')
        .attr('class', 'legend-decision-tree stroke-text')
        .attr('x', 15)
        .attr('y', i * 25 + 37)
        .attr('dy', '.15em')
        .text(`${k}`)
    })

    collapse(nodes) //Collapse initially, collapses all recursively
    if (expandFirst) {
      collapse(nodes) //Display the first level, doesn't expand recursively
    }

    let nodeContainer = svgContainer
      .append('g')
      .style('transform', `translateY(${MARGIN_TOP_GRAPH}px)`)

    setTreeData((t) => {
      return {
        tree: tree,
        link: d3
          .linkRadial()
          .angle((d) => d.x)
          .radius((d) => d.y),
        container: svgContainer,
        flowContainer: nodeFlowContainer,
        activateBranch: activateBranch,
        categoryColors: categoryColors,
        tooltipBaseY: Object.keys(categoryColors).length * 30 + 25,
        svg: nodeContainer,
        tooltipContainer: nodeContainer
          .append('g')
          .attr('class', 'top-tooltip-container'),
        nodes: nodes,
        width: Math.max(canvasRef?.current?.offsetWidth, 10),
      }
    })
  }

  useEffect(() => {
    if (model && canvasRef.current) {
      getTree({ modelId: model.id, token, signout }).then(setTree)
    } else if (tree && canvasRef.current) {
      setTree(tree)
    }
    // eslint-disable-next-line
  }, [model, tree])

  useEffect(() => {
    window.addEventListener('resize', widthCallback)
    return () => window.removeEventListener('resize', widthCallback)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    treeData.width = containerWidth
    if (treeData.tree) {
      treeData.container.attr('width', containerWidth)
      createYesNoLegend(treeData.container, containerWidth)
      treeData.tree = d3
        .tree()
        .size([
          containerWidth - margin.left - margin.right,
          baseHeight * depth(treeData.nodes) - MARGIN_TOP_GRAPH,
        ])
        .separation(function separation(a, b) {
          return a.parent === b.parent ? 1 : 1
        })
      draw(treeData.nodes, treeData, model, setTargetNode, format, baseHeight)
    }
    // eslint-disable-next-line
  }, [containerWidth])

  useEffect(() => {
    if (treeData.nodes) {
      draw(treeData.nodes, treeData, model, setTargetNode, format, baseHeight)
    }
    // eslint-disable-next-line
  }, [treeData])

  useEffect(() => {
    if (targetNode) {
      collapse(targetNode.node, false)
      draw(targetNode.node, treeData, model, setTargetNode, format, baseHeight)
    }
    // eslint-disable-next-line
  }, [targetNode])

  const interpretationInstance = typeof interpretation === 'object' &&
    Object.keys(interpretation).length > 0 && (
      <div
        className={`${
          interpretationFirst ? 'pb-2 mb-1' : 'py-2 my-1 mt-2 '
        } decision-tree-container-tree-interpretation`}
      >
        <h5 className="text-center">
          {model
            ? t('Rules that define "{{target}}" values', {
                target: model?.target ?? 'Cluster',
              })
            : t('Main characteristics that define each cluster')}
        </h5>
        <Row className="justify-content-around">
          {Object.keys(interpretation)
            .sort()
            .map((k, i) => (
              <TreeInterpretation
                key={k}
                k={k}
                interpretation={interpretation[k]}
                categories={treeData?.categoryColors}
              />
            ))}
        </Row>
      </div>
    )

  // <div>{JSON.stringify(interpretation, null, 2)}</div>
  return error ? (
    <Row>
      <Col className="h4 p-2 text-center" xs={12}>
        {t('Could not generate a valid decision tree for this model')}
      </Col>
    </Row>
  ) : (
    <div className={`${props.className ?? ''}`}>
      {interpretationFirst && interpretationInstance}
      {optionalTree && (
        <div style={{ width: 'auto' }}>
          <Form.Check
            type="switch"
            label={t('Show advanced exploration features')}
            className="form-switch-share d-flex"
            onChange={async (e) => {
              setShowTree(e.target.checked)
              setTimeout(widthCallback, 200)
            }}
          />
        </div>
      )}
      <div
        ref={canvasRef}
        {...props}
        className={`decision-tree-model ${showTree ? '' : 'd-none'}`}
      >
        {!treeData?.tree && <Loading />}
      </div>
      {!interpretationFirst && interpretationInstance}
    </div>
  )
}

function BuildInterpretationContent({
  interpretation,
  isOr = false,
  t,
  color,
}) {
  if (!interpretation) return <></>

  // Legacy
  if (typeof interpretation === 'string' || interpretation instanceof String) {
    return interpretation?.split(', ')?.map((i) => {
      i = i.replace('When', '')
      return <li key={i}>{i}</li>
    })
  }

  const buildInterpretationFromArray = (data) => {
    return data?.map((i) => <li key={i}>{i}</li>)
  }

  const buildInterpretationFromObject = (data, t) => {
    const extraStyles = color ? { '--joined-bullet-line-color': color } : {}
    return (
      <>
        <Row className="justify-content-between mt-1">
          <Col
            className="pe-0 position-relative"
            style={{ maxWidth: 'calc(100% - 90px)' }}
          >
            <ul
              className="joined-bullets"
              style={{ paddingLeft: '15px', ...extraStyles }}
            >
              {data?.main?.map((i) => (
                <li className="small display-block w-100" key={i}>
                  {i}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        {BuildInterpretationContent({
          interpretation: data?.or,
          isOr: true,
          t,
          color: color,
        })}
      </>
    )
  }

  return (
    <Row>
      <Col xs={12} className="text-center">
        {isOr ? (
          <strong
            className={`${color ? '' : 'text-muted'} mt-1`}
            style={color ? { color } : {}}
          >
            +
          </strong>
        ) : (
          <></>
        )}
      </Col>
      <Col xs={12}>
        {Array.isArray(interpretation)
          ? buildInterpretationFromArray(interpretation)
          : buildInterpretationFromObject(interpretation, t)}
      </Col>
    </Row>
  )
}

function sumInterpretation(interpretation) {
  if (!interpretation) return 0

  return (
    (interpretation?.metadata?.absolute_percentage ?? 1) +
    sumInterpretation(interpretation?.or)
  )
}

function TreeInterpretation({ k, interpretation, categories }) {
  const { t } = useTranslation()
  const total = round(Math.min(sumInterpretation(interpretation), 1) * 100, 2)

  return (
    <OverlayTrigger
      rootClose={true}
      trigger={['hover', 'focus']}
      placement={'auto'}
      delay={{ show: 100, hide: 200 }}
      overlay={(props) => (
        <Tooltip {...props}>
          <span className="">
            {t(
              `These are the characteristics that define {{total}}% of this group`,
              { total: total },
            )}
          </span>
        </Tooltip>
      )}
    >
      <Col
        className="mt-1"
        style={{ minWidth: 'min(280px, 100%)', maxWidth: 'min(280px, 100%)' }}
      >
        <Row className="visual-explainability-interpretation h-100">
          <Col className="text-center px-0" xs={12}>
            <span
              style={{ backgroundColor: categories?.[k] ?? 'red' }}
              className="d-inline-block detail-interpretation"
            ></span>
            {k.startsWith('"target is') ? 'Target is' : ''}
            <strong>{k}</strong>
          </Col>
          <Col xs={12} className="px-0">
            {BuildInterpretationContent({
              interpretation,
              t,
              color: categories?.[k],
            })}
          </Col>
        </Row>
      </Col>
    </OverlayTrigger>
  )
}

export function expandNode(containerRef, levels = 2, retry = 1000) {
  const svg = d3.select(containerRef.current).select('svg.svg-container-tree')
  const node = svg.selectAll('rect.with-children.expandable')
  if (!node.size() && retry) {
    return setTimeout(() => {
      //Might still be loading
      expandNode(containerRef, levels, 0)
    }, retry)
  } else {
    let i = 1
    node.each(function () {
      var parentElement = d3.select(this.parentNode)
      const target = parentElement.node()
      setTimeout(() => {
        target.dispatchEvent(new Event('click'))
      }, 300 * i++)
    })
    if (levels > 1)
      setTimeout(() => expandNode(containerRef, levels - 1, 0), 1000)
  }
}

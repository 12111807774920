import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import '../homepage/homepage.css'
import AutogenBot from '../bot/AutogenBot'
import { useAuth } from '../../providers/AuthProvider'
import { getDocuments, getRepositories, getTags } from '../../services/document'
import { BsArrowLeft } from 'react-icons/bs'
import BreadcrumbNav from '../header/BreadcrumbNav'

export default function KnowledgeBaseChat() {
  const { token, signout } = useAuth()
  const { repositoryId } = useParams()

  const { data: repository } = useQuery(
    ['document-repository', repositoryId, token],
    async () => {
      const repos = await getRepositories({ repositoryId, token, signout })
      return repos?.[0]
    },
    { staleTime: Infinity },
  )

  const { data: _tags } = useQuery(
    ['repository-tags', repositoryId],
    async () => {
      const tags = await getTags({
        repositoryId,
        token,
        signout,
      })
      if (!Array.isArray(tags)) return []
      tags.sort((a, b) => a.localeCompare(b))
      return tags
    },
    { staleTime: Infinity },
  )

  const { data: documents } = useQuery(
    ['documents', repositoryId, token],
    async () => {
      return await getDocuments({ repositoryId, token, signout })
    },
    { staleTime: Infinity },
  )

  const tags = []

  if (Array.isArray(documents)) {
    tags.push({
      name: 'All documents',
      type: 'document',
    })
    tags.push(
      ...documents.map((d) => ({ name: d.name, type: 'document', id: d.id })),
    )

    if (Array.isArray(_tags))
      tags.push(..._tags.map((t) => ({ name: t, type: 'tag' })))
  }

  return (
    <>
      <BreadcrumbNav
        repositoryId={repository?.id}
        repositoryName={repository?.name}
        chat={true}
      />
      <div className="mx-4 overflow-hidden">
        <Row>
          <Col className="my-3" xs={12}>
            <Link to={`/knowledge-base/${repositoryId}`}>
              <span
                className="breadcrumbs-nav-report p-2 looks-like-a-2"
                style={{
                  fontWeight: 'bold',
                  border: '1px solid var(--nextbrain-main-color)',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
              >
                <BsArrowLeft size={25} />
              </span>
            </Link>
          </Col>
        </Row>
        <Row className="w-100">
          <Col className="position-relative " xs={12}>
            <AutogenBot
              model={{
                id: `repository_${repositoryId}`,
              }}
              refreshModel={() => {}}
              workflow="rag"
              resizeRelative={true}
              tags={tags}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

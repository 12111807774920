import React, { useEffect, useState, useMemo, useRef } from 'react'
import {
  Button,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  FaLock,
  FaLockOpen,
  FaArrowRight,
  FaFileDownload,
} from 'react-icons/fa'
import { GrPowerReset } from 'react-icons/gr'
import { MdOutlineCodeOff } from 'react-icons/md'
import { NotificationManager } from 'react-notifications'

import ModelNotFound from '../model/model-not-found'
import LoadingModel from '../model/loading-model'
import {
  generateCounterfactuals,
  getModelById,
  predictModel,
} from '../../services/model'
import { useAuth } from '../../providers/AuthProvider'
import NextbrainSelect from '../model-content/NextbrainSelect'

import { useNav } from '../../providers/NavProvider'
import { adjustLNSQ } from '../../util/other'
import { defaultFormat, round } from '../utils/formating'
import Loading from '../loading/LoadingSmall'
import Slider from '../slider/Slider'
import CrashFallback from '../crash-fallback/CrashFallback'
import ErrorNB from '../loading/ErrorNB'
import { useDebouncedCallback } from 'use-debounce'
import { GridTable, DownloadPosition } from '../grid-table/GridTable'
import ShareModelContextMenu from './ShareModelContextMenu'

function Lock({ name, locked, setLocked }) {
  const { t } = useTranslation()
  return (
    setLocked && (
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement={'auto'}
        delay={{ show: 100, hide: 200 }}
        overlay={(props) => (
          <Tooltip {...props}>
            <span className="">
              {t(
                'Locked values will not change when exploring inputs for the desired outcome',
              )}
            </span>
          </Tooltip>
        )}
      >
        <div className="lock-unlock-column">
          {locked ? (
            <FaLock
              className="text-warning"
              onClick={() => setLocked((l) => ({ ...l, [name]: false }))}
            />
          ) : (
            <FaLockOpen
              onClick={() => setLocked((l) => ({ ...l, [name]: true }))}
            />
          )}
        </div>
      </OverlayTrigger>
    )
  )
}

function InputSlider({
  name,
  inputProps = {},
  sliderProps = {},
  locked,
  setLocked,
  ...props
}) {
  const inputRef = useRef()

  return (
    <div
      className="input-slider dynamic-predict-input-slider position-relative"
      {...props}
    >
      <Lock name={name} locked={locked} setLocked={setLocked} />
      <Form.Control ref={inputRef} {...inputProps} />
      <Slider
        updateLeft={false}
        updateRight={false}
        onChange={(e) => {
          inputRef.current.value = e
          inputProps?.onChange({
            target: {
              value: `${e}`,
              name,
            },
          })
        }}
        {...sliderProps}
      />
    </div>
  )
}

function InputSliderDouble({
  name,
  inputProps = {},
  sliderProps = {},
  locked,
  setLocked,
  defaultLeft,
  defaultRight,
  ...props
}) {
  const inputRef = useRef()
  const inputRef2 = useRef()
  const { t } = useTranslation()

  const update = (left) => {
    let a = inputRef.current.value
    let b = inputRef2.current.value
    const [min, max] = [Math.min(a, b), Math.max(a, b)]
    inputRef.current.value = min
    inputRef2.current.value = max
    inputProps?.onChange({
      target: {
        value: [min, max],
        name,
      },
    })
  }
  return (
    <div
      className="input-slider dynamic-predict-input-slider position-relative"
      {...props}
    >
      <Lock name={name} locked={locked} setLocked={setLocked} />
      <Row>
        <Col xs={6}>
          <div>{t('From')}</div>
          <Form.Control
            ref={inputRef}
            {...inputProps}
            placeholder={defaultLeft}
            onChange={update}
          />
          <Slider
            updateLeft={false}
            updateRight={false}
            onChange={(e) => {
              inputRef.current.value = e
              update(true)
            }}
            {...sliderProps}
            value={sliderProps?.value?.[0] ?? sliderProps?.min}
            min={sliderProps?.min}
            max={/*sliderProps?.value?.[1] ??*/ sliderProps?.max}
            ceiling={sliderProps?.value?.[1] ?? sliderProps?.max}
          />
        </Col>
        <Col xs={6}>
          <div className="d-flex">{t('To')}</div>
          <Form.Control
            ref={inputRef2}
            {...inputProps}
            placeholder={defaultRight}
            onChange={() => update()}
          />
          <Slider
            updateLeft={false}
            updateRight={false}
            onChange={(e) => {
              inputRef2.current.value = e
              update()
            }}
            {...sliderProps}
            value={sliderProps?.value?.[1] ?? sliderProps?.max}
            min={/*sliderProps?.value?.[0] ??*/ sliderProps?.min}
            max={sliderProps?.max}
            ceiling={sliderProps?.max}
          />
        </Col>
      </Row>
    </div>
  )
}

function InputSliderCategory({
  name,
  selectProps = {},
  sliderProps = {},
  locked,
  setLocked,
  skipSingle,
  ...props
}) {
  const [value, setValue] = useState(selectProps?.defaultValue)

  useEffect(() => {
    selectProps?.onChange(value)
    // eslint-disable-next-line
  }, [value])

  const single = skipSingle && selectProps?.options?.length === 1
  return (
    <>
      <div
        className="base-input position-relative"
        style={{ maxHeight: '31px', ...(single ? {} : props?.style ?? {}) }}
      >
        <Lock name={name} locked={locked} setLocked={setLocked} />
        {single ? (
          selectProps?.defaultValue?.value
        ) : (
          <NextbrainSelect
            {...selectProps}
            onChange={(value) => setValue(value)}
            value={value}
          />
        )}
      </div>
      {!single && (
        <div className="input-slider dynamic-predict-input-slider">
          <Slider
            updateLeft={false}
            updateRight={false}
            onChange={(e) =>
              setValue(selectProps.options[e] ?? selectProps.options[0])
            }
            min={0}
            max={
              selectProps?.options?.length
                ? selectProps?.options?.length - 1
                : 1
            }
            ceiling={
              selectProps?.options?.length ? selectProps?.options?.length : 1
            }
            {...sliderProps}
          />
        </div>
      )}
    </>
  )
}

function minMax(model, column, margin = 0.3) {
  if (
    model.dataset.final_column_status[column] === 'Categorical' ||
    model.dataset.final_column_status[column] === 'Text'
  )
    return {
      min: model.dataset.statistics[column].min,
      max: model.dataset.statistics[column].max,
    }

  const isDate = model.dataset.final_column_status[column] === 'Datetime'
  const min = isDate
    ? new Date(model.dataset.statistics[column].min).getTime()
    : model.dataset.statistics[column].min
  const max = isDate
    ? new Date(model.dataset.statistics[column].max).getTime()
    : model.dataset.statistics[column].max
  const dif = Math.abs(max - min)
  const res = {
    min: Math.floor(min - dif * margin),
    max: Math.ceil(max + dif * margin),
  }

  return res
}

function formatTarget(value, t) {
  if (!value) return ''
  return Array.isArray(value)
    ? `${t('from')} ${defaultFormat({ num: value[0] })} ${t(
        'to',
      )} ${defaultFormat({ num: value[1] })}`
    : defaultFormat({ num: value })
}

function Padding({ model, column, double }) {
  const isCategorical =
    model.dataset.final_column_status[column] === 'Categorical' ||
    model.dataset.final_column_status[column] === 'Text'
  if (isCategorical) {
    if (model.dataset.statistics[column].nunique > 2)
      return <div className="pad-cat" style={{ minHeight: '7px' }}></div>
    return <></>
  }
  return (
    <Row
      className="pe-none user-select-none d-flex "
      style={{ visibility: 'hidden', paddingTop: '9px' }}
    >
      <Col xs={'auto'}>F</Col>
    </Row>
  )
}

function ModelFields({ model }) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const title = searchParams.get('title')
  let { signout, token, user } = useAuth()
  const resultRef = useRef()
  const width = resultRef?.current?.getBoundingClientRect()?.width - 33
  const [otherFieldsVisible, setOtherFieldsVisible] = useState(false)
  const [beenActive, setBeenActive] = useState(false)

  const getDefaultValue = (column, currentPrediction) => {
    if (model?.target === column && model?.problem_type === 'regression')
      return [
        model.dataset.statistics[column].min,
        model.dataset.statistics[column].max,
      ]

    switch (model.dataset.final_column_status[column]) {
      case 'Integer':
        return model.dataset.statistics[column].mode
      case 'Double':
      case 'Float':
        return round(model.dataset.statistics[column].mode, 4)
      case 'Categorical':
      case 'Text':
        return model?.dataset?.categorical_to_unique?.[column]?.find(
          (v) => v !== currentPrediction,
        )
      case 'Datetime':
        let now = new Date()
        let tzOffset = now.getTimezoneOffset() * 60 * 1000
        return new Date(now.getTime() - tzOffset).toISOString().split('.')[0]
      default:
        return 0
    }
  }

  const [validColumns, otherColumns] = useMemo(() => {
    const importances = model?.details?.feature_importance?.reduce(
      (dict, feature) => {
        const [col] = adjustLNSQ(feature.feature, feature.importance)
        dict[col] = feature.importance
        return dict
      },
      {},
    )

    const items = (
      model &&
      model.status === 'trained' &&
      model.dataset &&
      model.dataset.columns_order
        ? model.dataset.columns_order.filter((column) => {
            const type = model.dataset.final_column_status[column]
            if (type === 'Text' || type === 'ID') return false
            return (
              column in model.columns_active &&
              column in model.dataset.statistics &&
              column !== model.target
            )
          })
        : []
    )
      .map((k) => ({
        k,
        importance: importances?.[k] ?? 0,
      }))
      .sort((a, b) => b.importance - a.importance)
      .reduce(
        (acc, item) => {
          if (acc.acum < 0.9 || acc.elements.length < 6) {
            acc.acum += item.importance
            acc.elements.push(item.k)
          } else acc.other.push(item.k)
          return acc
        },
        { acum: 0, elements: [], other: [] },
      )

    return [
      items.elements.slice(0, 8),
      [...items.elements.slice(8), ...items.other],
    ]
  }, [model])

  const [locked, setLocked] = useState(() =>
    validColumns.reduce((acc, column) => {
      acc[column] = false
      return acc
    }, {}),
  )

  const getDefaultFormState = () => {
    let initialFormState = {}
    for (let column of validColumns)
      initialFormState[column] = {
        value: getDefaultValue(column),
        isDefault: true,
      }
    for (let column of otherColumns)
      initialFormState[column] = {
        value: getDefaultValue(column),
        isDefault: true,
      }
    initialFormState[model?.target] = {
      value: getDefaultValue(model?.target),
      isDefault: true,
    }
    return initialFormState
  }

  const [formState, setFormState] = useState(() => getDefaultFormState())
  const [currentFocus, setCurrentFocus] = useState(validColumns[0])
  const [queryParams, setQueryParams] = useState(null)
  const [queryParamsPredict, setQueryParamsPredict] = useState(null)

  const [baseState] = useState(() => ({
    otherFieldsVisible,
    beenActive,
    formState: JSON.parse(JSON.stringify(formState)),
  }))

  const reset = () => {
    setOtherFieldsVisible(baseState.otherFieldsVisible)
    setBeenActive(baseState.beenActive)
    setFormState(JSON.parse(JSON.stringify(baseState.formState)))
  }

  const updateQuery = useDebouncedCallback((formState, locked) => {
    setQueryParams(JSON.parse(JSON.stringify({ formState, locked })))
  }, 1000)

  const updateQueryPredict = useDebouncedCallback((formState) => {
    setQueryParamsPredict(
      Object.entries(formState)
        .filter(([k]) => k !== model.target)
        .reduce(
          (a, [k, v]) => {
            a.header.push(k)
            a.rows[0].push(v.value)
            return a
          },
          { header: [], rows: [[]] },
        ),
    )
  }, 500)

  useEffect(
    () => formState && updateQueryPredict(formState),
    // eslint-disable-next-line
    [formState],
  )

  const { data: currentPrediction, isLoading: currentPredictionIsLoading } =
    useQuery(
      ['prediction-what-if-tool', model?.id, queryParamsPredict],
      async () => {
        if (!model?.id || !queryParamsPredict) return null
        const res = await predictModel(
          model.id,
          queryParamsPredict,
          token,
          signout,
        )
        if (res?.status) {
          NotificationManager.error(t('Failed to predict'))
        } else return `${defaultFormat({ num: res?.predictions?.[0]?.[0] })}`
      },
      { staleTime: Infinity },
    )

  useEffect(
    () => updateQuery(formState, locked),
    // eslint-disable-next-line
    [formState, locked, currentPredictionIsLoading],
  )

  const { data: predictData, isLoading: isPredictDataLoading } = useQuery(
    [
      'what-if-tool',
      model?.id,
      queryParams,
      currentPrediction,
      currentPredictionIsLoading,
    ],
    async () => {
      if (
        !model?.id ||
        !queryParams ||
        !currentPrediction ||
        currentPredictionIsLoading
      )
        return null
      const cf = await generateCounterfactuals({
        modelId: model.id,
        prediction: Object.entries(queryParams.formState)
          .filter(([k, v]) => !Array.isArray(v?.value))
          .reduce((a, [k, v]) => {
            a[k] = v?.value
            return a
          }, {}),
        notChangeableColumns: Object.keys(queryParams.locked)
          .filter((k) => locked[k])
          .concat(model?.problem_type === 'regression' ? [] : [model?.target]),
        predictionRange:
          model?.problem_type === 'regression'
            ? [
                queryParams.formState[model?.target].value[0],
                queryParams.formState[model?.target].value[1],
              ]
            : [],
        desiredClass:
          model?.problem_type === 'regression'
            ? 0
            : queryParams.formState[model?.target].value,
        token,
        signout,
      })
      let baseTable = null
      if (cf?.table) {
        const colMap = cf.table.columns.reduce((acc, col, i) => {
          acc[col] = i
          return acc
        }, {})
        baseTable = {
          header: [...cf.table.columns],
          rows: JSON.parse(JSON.stringify(cf.table.data)),
        }
        const count = {}
        if (Array.isArray(cf?.counterfactuals))
          cf.counterfactuals.forEach((c, i) =>
            c.forEach((c) => {
              if (colMap[c?.feature] !== null) {
                baseTable.rows[i][colMap[c?.feature]] = `${defaultFormat({
                  num: c.original_value,
                })}->${defaultFormat({ num: c.value })}`
                count[c?.feature] = count[c?.feature] ?? 0
                count[c?.feature]++
                cf.table.data[i][colMap[c?.feature]] = (
                  <div className="d-inline-flex flex-nowrap align-items-center">
                    <span>{defaultFormat({ num: c.original_value })}</span>
                    <FaArrowRight className="mx-2" />
                    <strong>{defaultFormat({ num: c.value })}</strong>
                  </div>
                )
              }
            }),
          )

        Object.keys(count).forEach((k) => {
          count[k] = (100 * count[k]) / cf.table.data.length
        })
        cf.change_counts = count

        const index = cf.table.columns.findIndex((c) => c === model.target)
        if (index !== -1)
          cf.table.data.forEach((c, i) => {
            if (c[index] !== cf.base_prediction) {
              c[index] = (
                <div className="d-inline-flex flex-nowrap align-items-center">
                  <span>{currentPrediction}</span>
                  <FaArrowRight className="mx-2" />
                  <strong>{c[index]}</strong>
                </div>
              )
            }
          })
        cf.table.data = cf.table.data.map((r) =>
          r.map((v) => defaultFormat({ num: v })),
        )
      }
      setBeenActive(true)
      cf.baseTable = baseTable
      return cf
    },
    { staleTime: Infinity },
  )

  const renderPredictField = (
    column,
    locked,
    setLocked,
    double,
    currentPrediction,
    skipSingle,
    props = {},
  ) => {
    const handleChange = (event) => {
      setFormState((prevState) => {
        if (event.target.value.length === 0)
          prevState[event.target.name] = {
            value: parseFloat(getDefaultValue(column)),
            isDefault: true,
          }
        else
          prevState[event.target.name] = {
            value: parseFloat(event.target.value),
            isDefault: false,
          }
        return { ...prevState }
      })
    }

    const handleCategoricalChange = (event) => {
      if (currentPrediction) setQueryParams(null)

      setFormState((prevState) => {
        prevState[column] = {
          value: event.value,
          isDefault: false,
        }
        return { ...prevState }
      })
    }

    const handleDateChange = (event) => {
      setFormState((prevState) => {
        if (isNaN(new Date(event.target.value)))
          prevState[event.target.name] = {
            value: getDefaultValue(column),
            isDefault: true,
          }
        else
          prevState[event.target.name] = {
            value: new Date(event.target.value),
            isDefault: false,
          }
        return { ...prevState }
      })
    }

    const handleDoubleChange = (event) => {
      setFormState((prevState) => {
        prevState[column] = {
          value: event.target.value,
          isDefault: false,
        }
        return { ...prevState }
      })
    }

    switch (model.dataset.final_column_status[column]) {
      case 'Integer':
        return double ? (
          <InputSliderDouble
            model={model}
            name={column}
            inputProps={{
              name: column,
              type: 'number',
              'aria-label': 'Number',
              onKeyPress: (event) => {
                // Allow minus only at first position
                if (event.key === '-' && event.target.value.length === 0) {
                  return
                }
                // Allow only integers
                if (isNaN(event.key)) {
                  event.preventDefault()
                  event.stopPropagation()
                }
              },
              className:
                'no-arrows big-form nb-input-soft force mt-0 py-0 base-input',
              onChange: handleDoubleChange,
            }}
            onFocus={() => setCurrentFocus(column)}
            sliderProps={{
              ...minMax(model, column),
              ceiling: model.dataset.statistics[column].max,
              value: formState[column]?.value,
              onMouseDown: () => {
                setCurrentFocus(column)
              },
            }}
            locked={locked}
            setLocked={setLocked}
            defaultLeft={getDefaultValue(column)?.[0]}
            defaultRight={getDefaultValue(column)?.[1]}
          />
        ) : (
          <InputSlider
            model={model}
            name={column}
            inputProps={{
              name: column,
              type: 'number',
              placeholder: defaultFormat({
                num: getDefaultValue(column),
                digits: column < 1000 ? 0 : 2,
              }),
              'aria-label': 'Number',
              onKeyPress: (event) => {
                // Allow minus only at first position
                if (event.key === '-' && event.target.value.length === 0) {
                  return
                }
                // Allow only integers
                if (isNaN(event.key)) {
                  event.preventDefault()
                  event.stopPropagation()
                }
              },
              className:
                'no-arrows big-form nb-input-soft force mt-0 py-0 base-input',
              onChange: handleChange,
            }}
            onFocus={() => setCurrentFocus(column)}
            sliderProps={{
              ...minMax(model, column),
              ceiling: model.dataset.statistics[column].max,
              value: formState[column]?.value,
              onMouseDown: () => {
                setCurrentFocus(column)
              },
            }}
            locked={locked}
            setLocked={setLocked}
          />
        )
      case 'Double':
      case 'Float':
        return double ? (
          <InputSliderDouble
            model={model}
            name={column}
            inputProps={{
              name: column,
              type: 'number',
              'aria-label': 'Number',
              onKeyPress: (event) => {
                // Allow minus only at first position
                if (event.key === '-' && event.target.value.length === 0) {
                  return
                }
                // Allow only integers
                if (isNaN(event.key)) {
                  event.preventDefault()
                  event.stopPropagation()
                }
              },
              className:
                'no-arrows big-form nb-input-soft force mt-0 py-0 base-input',
              onChange: handleDoubleChange,
            }}
            onFocus={() => setCurrentFocus(column)}
            sliderProps={{
              ...minMax(model, column),
              ceiling: model.dataset.statistics[column].max,
              value: formState[column]?.value,
              onMouseDown: () => {
                setCurrentFocus(column)
              },
            }}
            locked={locked}
            setLocked={setLocked}
            defaultLeft={getDefaultValue(column)?.[0]}
            defaultRight={getDefaultValue(column)?.[1]}
          />
        ) : (
          <InputSlider
            name={column}
            inputProps={{
              name: column,
              type: 'number',
              placeholder: defaultFormat({
                num: getDefaultValue(column),
                digits: 2,
              }),
              'aria-label': 'Number',
              className:
                'no-arrows big-form nb-input-soft force mt-0 py-0 base-input',
              onChange: handleChange,
            }}
            onFocus={() => setCurrentFocus(column)}
            sliderProps={{
              ...minMax(model, column),
              ceiling: model.dataset.statistics[column].max,
              value: formState[column].value,
              onMouseDown: () => {
                setCurrentFocus(column)
              },
            }}
            locked={locked}
            setLocked={setLocked}
          />
        )
      case 'Text':
      case 'Categorical': {
        const options = [
          ...(model.dataset.categorical_to_unique?.[column] ?? []),
        ]
          .sort()
          .map((option) => ({
            value: option,
            label: option,
          }))
          .filter((e) => e.value !== currentPrediction)
        return (
          <InputSliderCategory
            selectProps={{
              className: 'basic-single mt-0',
              type: 'thin',
              classNamePrefix: 'select',
              options,
              defaultValue: {
                value: getDefaultValue(column, currentPrediction),
                label: getDefaultValue(column, currentPrediction),
              },
              onChange: handleCategoricalChange,
              name: column,
              onFocus: () => setCurrentFocus(column),
            }}
            sliderProps={{
              onMouseDown: () => {
                setCurrentFocus(column)
              },
              value: options.findIndex(
                (e) => e.value === formState[column]?.value,
              ),
            }}
            name={column}
            locked={locked}
            setLocked={setLocked}
            skipSingle={skipSingle}
            {...props}
          />
        )
      }
      case 'Datetime':
        let now = new Date()
        let tzOffset = now.getTimezoneOffset() * 60 * 1000
        now = new Date(now.getTime() - tzOffset).toISOString().split('.')[0]
        return (
          <div
            className="input-slider dynamic-predict-input-slider position-relative"
            {...props}
          >
            <Lock name={column} locked={locked} setLocked={setLocked} />
            <Form.Control
              name={column}
              type="datetime-local"
              placeholder="Datetime"
              aria-label="Number"
              className="no-arrows nb-input-soft force big-form mt-0"
              onChange={handleDateChange}
              defaultValue={now}
              onFocus={() => setCurrentFocus(column)}
              {...props}
            />
          </div>
        )
      default:
        return <p className="my-2">WIP: Invalid type for now</p>
    }
  }

  return (
    <>
      <Container className="px-0">
        {title && (
          <Row className="mb-4">
            <Col xs={12} className="h3 ps-0 position-relative">
              {title}
              <OverlayTrigger
                rootClose={true}
                trigger={['hover', 'focus']}
                placement="left"
                delay={{ show: 100, hide: 100 }}
                overlay={(props) => (
                  <Tooltip
                    {...props}
                    className={`context-menu-column-type big-menu ${
                      props?.className ?? ''
                    }`}
                  >
                    {t('Reset counterfactuals')}
                  </Tooltip>
                )}
              >
                <div
                  style={{
                    position: 'absolute',
                    width: '30px',
                    top: '0px',
                    right: '40px',
                  }}
                >
                  <GrPowerReset
                    className="icon-btn"
                    size={30}
                    style={{
                      filter: 'invert(1)',
                    }}
                    onClick={reset}
                  />
                </div>
              </OverlayTrigger>
              {user && (
                <ShareModelContextMenu
                  model={model}
                  shareTooltip={t('Share counterfactuals')}
                  name={t('Counterfactuals')}
                />
              )}
            </Col>
          </Row>
        )}
        <Row className="px-0">
          <Col
            className="h4 mt-0 mb-4 color-white ps-0 position-relative"
            xs={12}
          >
            {t('Input values')}{' '}
            {model?.dataset?.name ? (
              <span>
                {t('for the counterfactual analysis tool {{modelName}} model', {
                  modelName: model.dataset.name,
                })}
              </span>
            ) : (
              <></>
            )}
            {user && !title && (
              <>
                <OverlayTrigger
                  rootClose={true}
                  trigger={['hover', 'focus']}
                  placement="left"
                  delay={{ show: 100, hide: 100 }}
                  overlay={(props) => (
                    <Tooltip
                      {...props}
                      className={`context-menu-column-type big-menu ${
                        props?.className ?? ''
                      }`}
                    >
                      {t('Reset counterfactuals')}
                    </Tooltip>
                  )}
                >
                  <div
                    style={{
                      position: 'absolute',
                      width: '30px',
                      top: '0px',
                      right: '40px',
                    }}
                  >
                    <GrPowerReset
                      className="icon-btn"
                      size={30}
                      style={{}}
                      onClick={reset}
                    />
                  </div>
                </OverlayTrigger>
                <ShareModelContextMenu
                  model={model}
                  shareTooltip={t('Share counterfactuals')}
                  name={t('Counterfactuals')}
                />
              </>
            )}
          </Col>
          <Col className="predict-form-inputs" xs={12}>
            <Row className="mx-5">
              {validColumns.length ? (
                validColumns.map((column) => (
                  <Col key={column} xl={4} md={6} sm={12}>
                    <Row className="mb-1 mx-2 flex-column flex-nowrap justify-content-between min-h-full">
                      <Col md={12}>
                        <span
                          className={`predict-input-head ${
                            column === currentFocus
                              ? 'active-predict-input-head'
                              : ''
                          }`}
                        >
                          {column}
                        </span>
                      </Col>
                      <Col className="predict-input" md={12}>
                        {renderPredictField(column, locked[column], setLocked)}
                      </Col>
                    </Row>
                  </Col>
                ))
              ) : (
                <></>
              )}
              {otherColumns.length && otherFieldsVisible ? (
                otherColumns.map((column) => (
                  <Col key={column} xl={4} md={6} sm={12}>
                    <Row className="mb-1 mx-2 flex-column flex-nowrap justify-content-between min-h-full">
                      <Col md={12}>
                        <span
                          className={`predict-input-head ${
                            column === currentFocus
                              ? 'active-predict-input-head'
                              : ''
                          }`}
                        >
                          {column}
                        </span>
                      </Col>
                      <Col className="predict-input" md={12}>
                        {renderPredictField(column, locked[column], setLocked)}
                      </Col>
                    </Row>
                  </Col>
                ))
              ) : (
                <></>
              )}
              {otherColumns.length ? (
                <Col xl={4} md={6} sm={12}>
                  <div
                    className="text-center"
                    style={{ marginTop: 30, marginBottom: 10 }}
                  >
                    <Button
                      className="linkshare link not-float"
                      onClick={() => setOtherFieldsVisible((v) => !v)}
                    >
                      {otherFieldsVisible
                        ? t('Hide less relevant fields')
                        : t('Show more fields')}
                    </Button>
                  </div>
                  <Row style={{ marginBottom: '20px', fontSize: '20px' }}>
                    <Col
                      xs={9}
                      className="offset-3 icon-btn"
                      onClick={() => {
                        const items = {}
                        validColumns?.forEach((c) => (items[c] = true))
                        otherColumns.length &&
                          otherFieldsVisible &&
                          otherColumns?.forEach((c) => (items[c] = true))
                        setLocked((i) => items)
                      }}
                    >
                      <FaLock /> {t('Lock all values')}
                    </Col>
                    <Col
                      xs={9}
                      className="offset-3 mt-3 icon-btn"
                      onClick={() => {
                        const items = {}
                        validColumns?.forEach((c) => (items[c] = false))
                        otherColumns.length &&
                          otherFieldsVisible &&
                          otherColumns?.forEach((c) => (items[c] = false))
                        setLocked((i) => items)
                      }}
                    >
                      <FaLockOpen /> {t('Unlock all values')}
                    </Col>
                  </Row>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Col>
          <div
            style={{
              minHeight: beenActive ? '650px' : '0px',
            }}
          >
            {currentPredictionIsLoading ? (
              <Loading className="mt-5" />
            ) : (
              currentPrediction && (
                <Col className="h4 my-5 color-white ps-0 text-center" xs={12}>
                  <Row className="justify-content-center">
                    <Col
                      xs={12}
                      md={6}
                      style={{
                        border: '1px solid var(--nextbrain-white)',
                        borderRadius: 20,
                      }}
                      className="py-3"
                    >
                      {t('Prediction for your current input values')} <br />
                      <span
                        style={{
                          color: 'var(--nextbrain-datatypes-categorical)',
                        }}
                      >
                        {model?.target}
                      </span>{' '}
                      ={' '}
                      <div className="whatif-current-prediction">
                        {currentPrediction}
                      </div>
                    </Col>
                  </Row>
                </Col>
              )
            )}
            {currentPrediction && (
              <Col
                className="predict-form-results px-0 mt-3"
                xs={12}
                ref={resultRef}
              >
                <Row>
                  <Col
                    className="h4 color-white mt-3 d-flex align-items-start"
                    style={{ verticalAlign: 'top' }}
                    xs={12}
                  >
                    <span
                      style={{
                        lineHeight: '100%',
                        verticalAlign: 'super',
                      }}
                    >
                      <Padding
                        model={model}
                        column={model?.target}
                        double={model?.problem_type === 'regression'}
                      />
                      {t('How can I get')}{' '}
                      <span
                        style={{
                          color: 'var(--nextbrain-datatypes-categorical)',
                        }}
                      >
                        {model.target}
                      </span>{' '}
                      {model?.problem_type === 'regression'
                        ? t('in the range') + ':'
                        : t('to equal')}
                    </span>
                    <div
                      className="predict-input mx-3"
                      style={{
                        display: 'inline-block',
                      }}
                    >
                      {renderPredictField(
                        model?.target,
                        null,
                        null,
                        model?.problem_type === 'regression',
                        currentPrediction,
                        true,
                        {
                          style: { minWidth: '350px' },
                        },
                      )}
                    </div>
                    <span
                      style={{
                        lineHeight: '100%',
                        verticalAlign: 'super',
                      }}
                    >
                      <Padding
                        model={model}
                        column={model?.target}
                        double={model?.problem_type === 'regression'}
                      />
                      ?
                    </span>
                  </Col>
                  {isPredictDataLoading ? (
                    <Loading className="mt-5" />
                  ) : predictData?.table_insight ? (
                    <Row>
                      <Col className="h5 text-center mt-5" xs={12}>
                        {predictData?.table_insight}
                        <br />
                        <MdOutlineCodeOff
                          className="mt-2"
                          size={40}
                          color={'var(--nextbrain-warning-color)'}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <CrashFallback
                      style={{ minHeight: '0px' }}
                      message={<ErrorNB style={{ minWidth: '300px' }} />}
                      t={t}
                      key={currentFocus}
                    >
                      <Col xs={12}>
                        <p>
                          {t(
                            'This table shows you all the possibilities for obtaining',
                          )}{' '}
                          <span
                            style={{
                              color: 'var(--nextbrain-datatypes-categorical)',
                            }}
                          >
                            {model.target}
                          </span>{' '}
                          {model?.problem_type === 'regression' ? ' ' : '= '}
                          {formatTarget(
                            formState[model.target]?.value,
                            t,
                          )}.{' '}
                          {t(
                            'You will find a list of the minimal adjustments required, one possibility per row.',
                          )}
                        </p>
                      </Col>
                      {predictData?.table && (
                        <>
                          <Col
                            className="d-flex justify-content-end my-1"
                            xs={12}
                          >
                            <OverlayTrigger
                              rootClose={true}
                              trigger={['hover', 'focus']}
                              placement={'auto'}
                              delay={{ show: 100, hide: 200 }}
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  <span className="">{t('Save result')}</span>
                                </Tooltip>
                              )}
                            >
                              <span>
                                <FaFileDownload
                                  className="icon-btn"
                                  size={30}
                                  onClick={() => {
                                    const csvContent =
                                      'data:text/csv;charset=utf-8,' +
                                      predictData?.baseTable?.header.join(',') +
                                      '\n' +
                                      predictData?.baseTable?.rows
                                        .map((x) => x.join(','))
                                        .join('\n')
                                    const encodedUri = encodeURI(csvContent)
                                    const link = document.createElement('a')
                                    link.setAttribute('href', encodedUri)
                                    link.setAttribute(
                                      'download',
                                      `What_if_result_${
                                        model?.id
                                      }_${Date.now()}.csv`,
                                    )
                                    document.body.appendChild(link)
                                    link.click()
                                    link.remove()
                                  }}
                                />
                              </span>
                            </OverlayTrigger>
                          </Col>
                          <Col xs={12} className="mb-4">
                            <GridTable
                              rows={predictData?.table.data}
                              header={[predictData?.table?.columns]}
                              download={DownloadPosition.INDEX}
                              downloadFilename={`Whatiftool_result_${model?.dataset?.name}`}
                              downloadProcessCells={(cell) =>
                                String(cell).replaceAll(',', '.') ?? cell
                              }
                              cellElement={(str) => (
                                <span className="single-line">{str}</span>
                              )}
                              headerElement={(str) => {
                                const oclass = str === model?.target
                                const isLocked = locked[str]
                                return (
                                  <strong
                                    className={`single-line`}
                                    style={{
                                      color: oclass
                                        ? 'var(--nextbrain-datatypes-categorical)'
                                        : isLocked
                                        ? '#FFC107'
                                        : 'inherit',
                                    }}
                                  >
                                    {str}
                                    {predictData?.change_counts?.[str] && (
                                      <>
                                        <br />
                                        <span
                                          className="color-white"
                                          style={{
                                            fontSize: '13px',
                                            fontWeight: 'initial',
                                          }}
                                        >
                                          {' ('}
                                          {round(
                                            predictData?.change_counts?.[str],
                                            2,
                                          )}
                                          % {t('Changed')}
                                          {')'}
                                        </span>
                                      </>
                                    )}
                                  </strong>
                                )
                              }}
                              index={(i, v) => (
                                <div
                                  className={`grid-table-cell index-cell ${
                                    i ? '' : 'first'
                                  }`}
                                >
                                  <div className="d-flex flex-column ">
                                    {i ? i : ''}
                                  </div>
                                </div>
                              )}
                              className={`w-100 table-view-data not-z-index`}
                              defaultColumnWidth={(str) =>
                                Math.max(
                                  200,
                                  width / predictData?.table?.columns?.length,
                                )
                              }
                              pagerLast={true}
                              modelId={model?.id}
                              customDownload={() => {
                                const csvContent =
                                  'data:text/csv;charset=utf-8,' +
                                  predictData?.table?.columns.join(',') +
                                  '\n' +
                                  predictData?.table.data
                                    .map((x) => x.join(','))
                                    .join('\n')
                                const encodedUri = encodeURI(csvContent)
                                const link = document.createElement('a')
                                link.setAttribute('href', encodedUri)
                                link.setAttribute(
                                  'download',
                                  `What_if_result_${
                                    model?.id
                                  }_${Date.now()}.csv`,
                                )
                                document.body.appendChild(link)
                                link.click()
                                link.remove()
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </CrashFallback>
                  )}
                </Row>
              </Col>
            )}
          </div>
        </Row>
      </Container>
    </>
  )
}

export default function WhatIfTool({
  setTitle,
  defaultModel = null,
  hideNav = true,
}) {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  let { signout, token } = useAuth()
  const [model, setModel] = useState(defaultModel)
  const param = useParams()
  const { setShowNav } = useNav()

  useEffect(() => {
    if (hideNav && window.self !== window.top) {
      setShowNav(false)
      return () => setShowNav(true)
    }
    // eslint-disable-next-line
  }, [])

  const { isLoading, data } = useQuery(
    `model-${param.id}`,
    async () => defaultModel ?? (await getModelById(param.id, token, signout)),
    { staleTime: Infinity },
  )

  useEffect(() => {
    if (isLoading) return
    setModel(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (!model) {
      setTitle(
        searchParams?.get('title') ||
          `${t(' Counterfactual analysis')} | ${t('NextBrain')}`,
      )
      return
    }
    setTitle(
      searchParams?.get('title') ||
        `${t(' Counterfactual analysis')} ${model.dataset.name} | ${t(
          'NextBrain',
        )}`,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model])

  if (isLoading)
    return <LoadingModel shortMsg={t('Loading Counterfactual analysis')} />

  if (!model) return <ModelNotFound />

  return (
    <>
      <Row className={`mb-4 header-app px-0`}>
        <Col md={12} className="d-flex align-items-center px-4">
          {model && model.status === 'trained'
            ? model.dataset.name + ' | '
            : ''}{' '}
          {t('Counterfactual analysis')}
        </Col>
      </Row>
      <Container className="">
        <ModelFields model={model} />
      </Container>
    </>
  )
}

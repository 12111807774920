import React, { forwardRef, useState, useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import {
  TopFieldsGraphLine,
  TopFieldsGraphColumns,
} from './TopFieldsExplorationGraphs'
import { isBinaryModel, isMulticlassModel } from '../../util/models'
import HelpTooltip from './HelpTooltip'
import { abbrNum } from '../utils/ui'
import NextbrainSelect from '../model-content/NextbrainSelect'
import './TopFieldsExploration.css'
import { defaultFormat } from '../utils/formating'

function remove_prefix(str) {
  if (!str) return ''
  return str.replace(/^(ln_)||(sq_)/, '')
}

const mockupData = {
  yes: {
    'Company age': {
      importance: 42,
      effects: {
        '0-3': 0.098,
        '3-9': 0.018,
        '9-20': -0.0198,
        '20+': -0.0398,
      },
    },
    Industry: {
      importance: 29,
      effects: {
        '0-3': 0.018,
        '3-9': 0.008,
        '9-20': -0.098,
        '20+': -0.0118,
      },
    },
    AnnualRevenue: {
      importance: 20,
      effects: {
        '0-3': 0.018,
        '3-9': 0.008,
        '9-20': -0.098,
        '20+': -0.0118,
      },
    },
    Employees: {
      importance: 9,
      effects: {
        '0-3': 0.008,
        '3-9': 0.001,
        '9-20': -0.0198,
        '20+': -0.018,
      },
    },
  },
  no: {
    'Company age': {
      importance: 42,
      effects: {
        '0-3': 0.098,
        '3-9': 0.018,
        '9-20': -0.0198,
        '20+': -0.0398,
      },
    },
    Industry: {
      importance: 29,
      effects: {
        '0-3': 0.018,
        '3-9': 0.008,
        '9-20': -0.098,
        '20+': -0.0118,
      },
    },
    AnnualRevenue: {
      importance: 20,
      effects: {
        '0-3': 0.018,
        '3-9': 0.008,
        '9-20': -0.098,
        '20+': -0.0118,
      },
    },
    Employees: {
      importance: 9,
      effects: {
        '0-3': 0.008,
        '3-9': 0.001,
        '9-20': -0.0198,
        '20+': -0.018,
      },
    },
  },
}

const getLower = (x) =>
  x ? parseFloat(x.substring(x.indexOf('(') + 1, x.indexOf(','))) : 0
const getUpper = (x) =>
  x ? parseFloat(x.substring(x.indexOf(' ') + 1, x.indexOf(']'))) : 0

function TargetSelector({
  model,
  columnToExplore,
  targetValue,
  setTargetValue,
}) {
  const individual_feature_importance =
    model?.details?.individual_feature_importance ?? mockupData
  const allSet = useMemo(
    (ifp) => {
      const res = {}
      const resNeg = {}
      Object.entries(individual_feature_importance).forEach(([k, v]) => {
        v.forEach((d) => {
          res[d.column] = res[d.column] ?? {}
          resNeg[d.column] = resNeg[d.column] ?? {}
          d.effects.forEach((e) => {
            if (e.individual_importance === 'nan') return
            res[d.column][e.range] = res[d.column][e.range] ?? 0
            resNeg[d.column][e.range] = resNeg[d.column][e.range] ?? 0

            if (e.individual_importance < 0)
              resNeg[d.column][e.range] += e.individual_importance
            else res[d.column][e.range] += e.individual_importance
          })
        })
      })

      return [...new Set([...Object.keys(res), ...Object.keys(resNeg)])].reduce(
        (d, v) => {
          d[v] = Object.keys(res[v]).map((k) => ({
            range: k,
            individual_importance: res[v]?.[k] ?? 0,
            negative_individual_importance: resNeg[v]?.[k] ?? 0,
          }))
          return d
        },
        {},
      )
    },
    [individual_feature_importance],
  )

  const isClassification = isBinaryModel(model) || isMulticlassModel(model)
  const keys = Object.keys(individual_feature_importance)
  if (!isClassification) {
    keys.sort((a, b) => getLower(a) - getLower(b))
  }

  let maxIndividualImportance = -Infinity
  let minNegativeIndividualImportance = Infinity

  for (let key in allSet) {
    let arr = allSet[key]
    for (let i = 0; i < arr.length; i++) {
      let importance = arr[i]['individual_importance']
      let negativeImportance = arr[i]['negative_individual_importance']
      if (importance > maxIndividualImportance) {
        maxIndividualImportance = importance
      }
      if (negativeImportance < minNegativeIndividualImportance) {
        minNegativeIndividualImportance = negativeImportance
      }
    }
  }

  const options = useMemo(() => {
    const isClassification = isBinaryModel(model) || isMulticlassModel(model)
    const keys = Object.keys(individual_feature_importance)
    if (!isClassification) {
      keys.sort((a, b) => getLower(a) - getLower(b))
      return keys.map((k) => {
        let label = k.replace('(', '').replace(']', '').split(',')
        label = `From ${defaultFormat({ num: label[0] })} to ${defaultFormat({
          num: label[1],
        })}`
        return {
          label,
          value: k,
        }
      })
    }
    const type = model?.dataset?.statistics?.[model?.target]?.logical_type
    const format =
      type === 'Double' || type === 'Integer'
        ? (x) => defaultFormat({ num: x })
        : (x) => x
    return keys.map((k) => ({ label: format(k), value: k }))
    // eslint-disable-next-line
  }, [model])

  return (
    <Row id="showcaseTarget">
      <Col className="mt-1" xs={12}>
        <NextbrainSelect
          value={options?.find((o) => o?.value === targetValue)}
          onChange={(value) => setTargetValue(value?.value)}
          options={options ?? []}
          hideSelectedOptions={false}
          isClearable={false}
          type={'train'}
        />
      </Col>
    </Row>
  )
}

const ShapleyValuesWithTargetSelector = forwardRef(
  function ShapleyValuesWithTargetSelector(
    {
      model,
      columnToExplore,
      layout,
      setExpand,
      targetValue,
      setTargetValue,
      ...props
    },
    ref,
  ) {
    const { t } = useTranslation()

    const individual_feature_importance =
      model?.details?.individual_feature_importance ?? mockupData
    const allSet = useMemo(
      (ifp) => {
        const res = {}
        const resNeg = {}
        Object.entries(individual_feature_importance).forEach(([k, v]) => {
          v.forEach((d) => {
            res[d.column] = res[d.column] ?? {}
            resNeg[d.column] = resNeg[d.column] ?? {}
            d.effects.forEach((e) => {
              if (e.individual_importance === 'nan') return
              res[d.column][e.range] = res[d.column][e.range] ?? 0
              resNeg[d.column][e.range] = resNeg[d.column][e.range] ?? 0

              if (e.individual_importance < 0)
                resNeg[d.column][e.range] += e.individual_importance
              else res[d.column][e.range] += e.individual_importance
            })
          })
        })

        return [
          ...new Set([...Object.keys(res), ...Object.keys(resNeg)]),
        ].reduce((d, v) => {
          d[v] = Object.keys(res[v]).map((k) => ({
            range: k,
            individual_importance: res[v]?.[k] ?? 0,
            negative_individual_importance: resNeg[v]?.[k] ?? 0,
          }))
          return d
        }, {})
      },
      [individual_feature_importance],
    )

    const isClassification = isBinaryModel(model) || isMulticlassModel(model)
    let maxIndividualImportance = -Infinity
    let minNegativeIndividualImportance = Infinity

    for (let key in allSet) {
      let arr = allSet[key]
      for (let i = 0; i < arr.length; i++) {
        let importance = arr[i]['individual_importance']
        let negativeImportance = arr[i]['negative_individual_importance']
        if (importance > maxIndividualImportance) {
          maxIndividualImportance = importance
        }
        if (negativeImportance < minNegativeIndividualImportance) {
          minNegativeIndividualImportance = negativeImportance
        }
      }
    }

    const isCategoricalVariable = () => {
      if (targetValue && columnToExplore) {
        const type =
          model?.dataset?.final_column_status?.[
            individual_feature_importance[targetValue][columnToExplore]?.column
          ]
        return type === 'Categorical' || type === 'Text'
      }
      return false
    }

    const columnToExploreName =
      targetValue && columnToExplore
        ? remove_prefix(
            individual_feature_importance[targetValue][columnToExplore].column,
          )
        : ''
    const effects =
      individual_feature_importance?.[targetValue]?.[columnToExplore]?.effects

    return (
      <div ref={ref}>
        {targetValue && columnToExplore ? (
          <div
            onClick={() => {
              setExpand((e) => !e)
            }}
          >
            <Row
              style={{ position: 'relative', zIndex: 3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <Col className="px-3 pb-2 mt-2" xs={12}>
                <Row className="justify-content-center">
                  <Col
                    xs={'auto'}
                    className="d-inline-block text-truncate nowrap"
                    style={{ maxWidth: '50%', lineHeight: '50px' }}
                    title={`${t('Displaying influence when')} ${
                      model?.target
                    } ${t('value is')}`}
                  >
                    {`${t('Displaying influence when')} `}
                    <strong>{model?.target}</strong> {`${t('value is')}`}
                  </Col>
                  <Col xs={6} style={{ maxWidth: '550px' }}>
                    <TargetSelector
                      model={model}
                      columnToExplore={columnToExplore}
                      targetValue={targetValue}
                      setTargetValue={setTargetValue}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {!isClassification && (
              <Row className="justify-content-center">
                <Col lg={8} className="text-center mt-4">
                  {t('Influence that ')}
                  <strong>{columnToExploreName}</strong>
                  {t(' values have on the ')}
                  <strong>{model.target}</strong>
                  {t(' values within the range ')}
                  <strong>
                    {abbrNum(getLower(targetValue)) +
                      t(' to ') +
                      abbrNum(getUpper(targetValue))}
                  </strong>
                </Col>
              </Row>
            )}
            <Row style={{ height: 600 }}>
              {effects ? (
                <ShapleyValues
                  model={model}
                  effects={effects}
                  columnToExplore={columnToExploreName}
                  targetValue={targetValue}
                  isCategorical={isCategoricalVariable()}
                  maxY={maxIndividualImportance}
                  minY={minNegativeIndividualImportance}
                />
              ) : (
                <Row>
                  <Col xs={12}>{t('No valid columns')}</Col>
                </Row>
              )}
            </Row>
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  },
)

export function ShapleyValues({
  model,
  effects,
  columnToExplore,
  targetValue,
  isCategorical,
  maxY,
  minY,
  isWidget = false,
}) {
  return isCategorical ? (
    <TopFieldsGraphColumns
      effects={effects}
      model={model}
      category={columnToExplore}
      variable={targetValue}
      isWidget={isWidget}
    />
  ) : (
    <TopFieldsGraphLine
      effects={effects}
      maximumY={maxY}
      minimumY={minY}
      model={model}
      category={columnToExplore}
      variable={targetValue}
      isWidget={isWidget}
    />
  )
}

export default function TopFieldExploration({ model, ...props }) {
  const { t } = useTranslation()
  const [expand, setExpand] = useState(false)

  const individual_feature_importance =
    model?.details?.individual_feature_importance ?? mockupData
  const isClassification = isBinaryModel(model) || isMulticlassModel(model)
  const keys = Object.keys(individual_feature_importance)
  if (!isClassification) {
    keys.sort((a, b) => getLower(a) - getLower(b))
  }
  const [targetValue, setTargetValue] = useState(keys[0])

  const defaultTargetCategory = Object.keys(individual_feature_importance)[0]
  const sumImportance = defaultTargetCategory
    ? Object.keys(individual_feature_importance[defaultTargetCategory] ?? {})
        .map(
          (k) =>
            individual_feature_importance[defaultTargetCategory][k].importance,
        )
        .reduce((ac, i) => ac + i, 0)
    : 100

  const validVariables = defaultTargetCategory
    ? Object.keys(individual_feature_importance[defaultTargetCategory])
        .sort(
          (a, b) =>
            individual_feature_importance[defaultTargetCategory][b].importance -
            individual_feature_importance[defaultTargetCategory][a].importance,
        )
        .filter(
          (e) =>
            model?.dataset?.final_column_status[
              remove_prefix(
                individual_feature_importance[defaultTargetCategory][e].column,
              )
            ] !== undefined &&
            individual_feature_importance[defaultTargetCategory][e].importance /
              sumImportance >
              0.01,
        )
        .slice(0, 7)
    : []

  const [columnToExplore, setColumnToExplore] = useState(validVariables[0])
  const layout = columnToExplore ? (expand ? [1, 11] : [4, 8]) : [9, 3]

  return (
    <Row
      {...props}
      className={`top-field-exploration ${props.className ?? ''}`}
    >
      <Col className="exploration-header py-3" xs={12}>
        <Row>
          <Col className="h4 bold" xs={12}>
            <strong>{t('Column importance')}</strong>
            <HelpTooltip
              className="ms-1 help-select-icon"
              message={'column importance'}
              helpLink={'column-effect-importance-18tlnuh'}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row>
          <Col
            className="animated-layout mb-2 pe-0"
            style={{ zIndex: 2 }}
            xs={layout[0]}
          >
            <Row className="top-fields-exploration-variable-container align-items-center px-2">
              {defaultTargetCategory ? (
                validVariables.map((k, i) => (
                  <Col
                    className={`top-fields-exploration-variable p-3 ${
                      i ? 'mt-2' : ''
                    } ${k === columnToExplore ? 'selected' : ''}`}
                    key={k}
                    xs={12}
                    onClick={() => {
                      setColumnToExplore(k)
                      setExpand(false)
                    }}
                  >
                    <Row>
                      <Col
                        align="left"
                        className="d-inline-block text-truncate p-0 ms-2"
                        xs={12}
                      >
                        {remove_prefix(
                          individual_feature_importance[defaultTargetCategory][
                            k
                          ].column,
                        )}{' '}
                        <span className="effect-perc">
                          {
                            individual_feature_importance[
                              defaultTargetCategory
                            ][k].importance
                          }
                          %
                        </span>
                      </Col>
                      <Col
                        className="position-relative  d-inline-block text-truncate p-0 ms-2"
                        xs={11}
                      >
                        <div className="content-bar-bg position-absolute"></div>
                        <div
                          className="content-bar position-relative"
                          style={{
                            maxWidth: `${individual_feature_importance[defaultTargetCategory][k].importance}%`,
                          }}
                        ></div>
                      </Col>
                    </Row>
                  </Col>
                ))
              ) : (
                <Col
                  className="d-flex align-items-center justify-content-center mt-2"
                  style={{ minHeight: '100%' }}
                >
                  {t('No valid columns')}
                </Col>
              )}
            </Row>
          </Col>

          <Col
            className={`animated-layout top-fields-effect-container ps-0`}
            xs={layout[1]}
          >
            <ShapleyValuesWithTargetSelector
              isInView={true}
              model={model}
              columnToExplore={columnToExplore}
              layout={layout}
              setExpand={setExpand}
              targetValue={targetValue}
              setTargetValue={setTargetValue}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

import React, { useEffect, useState } from 'react'
import BlankHeader from './component/header/BlankHeader'
import ModelLanding from './component/model-landing/model-landing'
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import { Container, Row, Col } from 'react-bootstrap'
import { Row as GRow, Col as GCol } from 'react-grid-system'
import Home from './component/homepage/homepage'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom'

import { TourProvider } from '@reactour/tour'
import { NotificationContainer } from 'react-notifications'
import Insights from './component/insights/insights'
import Login from './component/login-form/LoginForm'
import Recover from './component/recover-form/RecoverForm'
import ResetPassword from './component/recover-form/ResetPassword'
import Questionnaire from './component/questionnaire-form/QuestionnaireForm'
import Guides from './component/guides/Guides'
import WidgetReport from './component/widget-report/WidgetReport'
import { ModelSummaryEngagement } from './component/model-content/ModelSummaryEngagement'
import withClearCache from './ClearCache'
import AdminDashboard from './component/admin-dashboard/AdminDashboard'
import UserManager from './component/user-manager/UserManager'
import UserActivity from './component/user-activity/UserActivity'
import Server from './component/server/Server'
import AiTools from './component/AiTools/AiTools'
import UserDetails from './component/user-details/UserDetails'
import ViewDataShareView from './component/view-data/ViewDataShareView'
import { getHead } from './services/model'
import { Insights as InsightsV2 } from './component/insights/insights-v2'
import ModelTopView from './component/model/ModelTopView'
import { RequireAuth, useAuth } from './providers/AuthProvider'
import { WebAppPredict } from './component/predict-form/predict-form'
import {
  modelLandingTourSteps,
  ModelLandingBadge,
} from './component/assistant/Tour'
import './App.css'
import Payment from './component/pricing/Payment'
import PlansInfo from './component/pricing/PlansInfo'
import DeleteAccount from './component/profile/DeleteAccount'
import Account from './component/profile/Account'
import SetInfoAccount from './component/profile/SetInfoAccount'
import Plan from './component/plan/Plan'
import CrashFallback from './component/crash-fallback/CrashFallback'
import { useTranslation } from 'react-i18next'
import NavColumn from './component/header/NavColumn'
import TrialHeader from './component/header/TrialHeader'
import SearchModal from './component/search-modal/SearchModal'
import { useNav } from './providers/NavProvider'
import Validate from './component/recover-form/ValidateAccountForm'
import RegisterPostSetup from './component/register-form/RegisterPostSetup'
import { sendReport } from './services/report'
import UnderMaintenance from './component/under-maintenance/UnderMaintenance'
import { getFlag } from './services/user'
import { useQuery } from 'react-query'
import MaintenanceWarning from './component/header/MaintenanceWarning'
import AdminManager from './component/admin-manager/AdminManager'
import SelectProduct from './component/homepage/SelectProduct'
import BreadcrumbNav from './component/header/BreadcrumbNav'
import SpreadsheetShare from './component/predict-form/SpreadsheetShare'
import DynamicPredictShare from './component/predict-form/DynamicPredictShare'
import DatasourceFlowView from './component/model-content/datasource-flow/DatasourceFlowView'
import ShareAutogenChat from './component/bot/ShareAutogenChat'
// import PaidFeature from './component/paid-feature/PaidFeature'
import CookieBanner from './component/cookie/CookieBanner'
import WhatIfTool from './component/predict-form/WhatIfTool'
import MultivariableForecast from './component/predict-form/MultivariableForecast'
import KnowledgeBase from './component/knowledge-base/KnowledgeBase'
import Documents from './component/knowledge-base/Documents'
import KnowledgeBaseChat from './component/knowledge-base/KnowledgeBaseChat'
import CausalInference from './component/predict-form/CausalInference'
import Integrations from './component/integrations/Integrations'

//Make navigate object available globally
//To elements under router components
function NavigationItem() {
  const navigate = useNavigate()
  window.navigateTo = navigate

  return <></>
}

function LoginWrapper({ setTitle }) {
  return (
    <ThemeProvider>
      <div className="App" id="backlogin">
        <Container>
          <Row>
            <Col md={12}>
              <Login setTitle={setTitle} />
            </Col>
          </Row>
        </Container>
      </div>
    </ThemeProvider>
  )
}

function RecoverWrapper({ setTitle }) {
  return (
    <ThemeProvider>
      <div className="App" id="backlogin">
        <Container>
          <Row>
            <Col md={12}>
              <Recover setTitle={setTitle} />
            </Col>
          </Row>
        </Container>
      </div>
    </ThemeProvider>
  )
}

function RegisterWrapper({ setTitle }) {
  return (
    <ThemeProvider>
      <NotificationContainer />
      <div className="App" id="backlogin">
        <Container>
          <Row>
            <Col md={12}>
              <Login setTitle={setTitle} register={true} />
            </Col>
          </Row>
        </Container>
      </div>
    </ThemeProvider>
  )
}

function QuestionnaireWrapper({ setTitle }) {
  return (
    <ThemeProvider>
      <div className="App" id="backregister">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Questionnaire setTitle={setTitle} />
            </Col>
          </Row>
        </Container>
      </div>
    </ThemeProvider>
  )
}

function ResetPasswordWrapper({ setTitle }) {
  return (
    <ThemeProvider>
      <div className="App" id="backlogin">
        <Container>
          <Row>
            <Col md={12}>
              <ResetPassword setTitle={setTitle} />
            </Col>
          </Row>
        </Container>
      </div>
    </ThemeProvider>
  )
}

function ValidateWrapper({ setTitle, ...props }) {
  return (
    <ThemeProvider>
      <div className="App" id="backlogin">
        <Container>
          <Row>
            <Col md={12}>
              <NotificationContainer />
              <Validate setTitle={setTitle} {...props} />
            </Col>
          </Row>
        </Container>
      </div>
    </ThemeProvider>
  )
}

function PaymentWrapper({ setTitle }) {
  return (
    <ThemeProvider>
      <div className="App">
        <Container>
          <Row>
            <Col md={12}>
              <Payment setTitle={setTitle} />
            </Col>
          </Row>
        </Container>
      </div>
    </ThemeProvider>
  )
}

function PlansInfoWrapper({ setTitle }) {
  return (
    <ThemeProvider>
      <div className="App">
        <BlankHeader />
        <Container>
          <Row>
            <Col md={12}>
              <PlansInfo setTitle={setTitle} />
            </Col>
          </Row>
        </Container>
      </div>
    </ThemeProvider>
  )
}

const DISABLED_PLAN = process.env?.['REACT_APP_DISABLEPLANS'] === '1'
const DISABLED_ACADEMY = process.env?.['REACT_APP_DISABLEACADEMY'] === '1'
const DISABLED_ADMIN = process.env?.['REACT_APP_DISABLEADMIN'] === '1'

const DISABLED_ADMINMANAGER =
  process.env?.['REACT_APP_DISABLEADMINMANAGER'] === '1'

function _App() {
  const { t } = useTranslation()
  const [topRecentModels, setTopRecentModels] = useState([])
  const {
    validlogin,
    user,
    signinExternal,
    requiresValidation,
    setRequiresValidation,
  } = useAuth()
  const [title, setTitle] = useState(t('NextBrain'))
  const [search, setSearch] = useState(false)
  const { showNav, mode } = useNav()

  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title
  }, [title])

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.shiftKey && e.keyCode === 70) setSearch((s) => !s)
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [])

  const { data: isMantenance, refetch } = useQuery(
    ['app-wide-is-maintenance'],
    async () => {
      const maintenance = await getFlag('maintenance')
      return Number.parseInt(maintenance)
    },
    { staleTime: Infinity },
  )

  useEffect(() => {
    if (isMantenance === 1 && (!user || !user.is_admin)) {
      const interval = setInterval(refetch, 30000)
      return () => clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [refetch, isMantenance])

  useEffect(() => {
    if (
      (process.env.REACT_APP_MODE || process.env.NODE_ENV) !== `development` &&
      window.location.protocol !== 'https:' &&
      window.location.port === '80'
    ) {
      window.location.replace(
        `https:${window.location.href.substring(
          window.location.protocol.length,
        )}`,
      )
    }
  }, [])

  if (isMantenance === 1 && (!user || !user?.is_admin))
    return <UnderMaintenance />

  if (requiresValidation)
    return (
      <ValidateWrapper
        setTitle={setTitle}
        reset={() => setRequiresValidation(false)}
        success={signinExternal}
        {...requiresValidation}
      />
    )

  if (!validlogin()) {
    return (
      <Router>
        <NavigationItem />
        <NotificationContainer />
        <Routes>
          <Route path="*" element={<LoginWrapper setTitle={setTitle} />} />
          <Route path="/login" element={<LoginWrapper setTitle={setTitle} />} />
          <Route
            path="/register"
            element={<RegisterWrapper setTitle={setTitle} />}
          />
          <Route
            path="/recover"
            element={<RecoverWrapper setTitle={setTitle} />}
          />
          <Route
            path="/questionnaire"
            element={<QuestionnaireWrapper setTitle={setTitle} />}
          />
          <Route
            path="/insights-v2/:id"
            element={<Insights setTitle={setTitle} />}
          />
          <Route
            path="/insights/:id"
            element={<InsightsV2 setTitle={setTitle} />}
          />
          <Route
            path="/predict/:id"
            element={<WebAppPredict setTitle={setTitle} />}
          />
          <Route
            path="/predict-dynamic/:id"
            element={<DynamicPredictShare setTitle={setTitle} />}
          />
          <Route
            path="/what-if-tool/:id"
            element={<WhatIfTool setTitle={setTitle} />}
          />
          <Route
            path="/causal-inference/:id"
            element={<CausalInference setTitle={setTitle} />}
          />
          <Route
            path="/predict-spreadsheet/:id"
            element={<SpreadsheetShare setTitle={setTitle} />}
          />
          <Route
            path="/model/widget/:id"
            element={<WidgetReport hideNav={true} setTitle={setTitle} />}
          />
          <Route
            path="/view-data/:id"
            element={
              <ViewDataShareView setTitle={setTitle} getHead={getHead} />
            }
          />
          <Route
            path="/reset-password"
            element={
              <ResetPasswordWrapper setTitle={setTitle} getHead={getHead} />
            }
          />
          <Route
            path="/pricing"
            element={<PlansInfoWrapper setTitle={setTitle} getHead={getHead} />}
          />
          {!DISABLED_ADMINMANAGER && (
            <Route
              path="/admin-manager-config"
              element={
                <AdminManager
                  style={{ minHeight: '100vh', position: 'relative' }}
                />
              }
            />
          )}
          <Route path="/chat/:sessionId" element={<ShareAutogenChat />} />
        </Routes>
      </Router>
    )
  }

  const requestFinishSetup =
    !user?.configured_name && user.login_type === 'nextbrain'
  if (
    !requestFinishSetup &&
    (user.country === '' || user.introduction === '')
  ) {
    return (
      <ThemeProvider>
        <NotificationContainer />
        <Router>
          <NavigationItem />
          <RequireAuth>
            <Routes>
              <Route path="*" element={<SetInfoAccount />} />
            </Routes>
          </RequireAuth>
        </Router>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider>
      <NotificationContainer />
      <RegisterPostSetup show={requestFinishSetup} />
      <Router>
        <RequireAuth>
          <NavigationItem />
          <Row
            className={`px-0 mx-0 app-mode-${mode || 'automl'}`}
            style={{
              maxWidth: '100vw',
              minWidth: '100vw',
            }}
          >
            <Col className={`nav-col ${showNav ? '' : 'd-none'}`}>
              <NavColumn onSearch={() => setSearch(true)} setTitle={setTitle} />
            </Col>
            <Col className={`content-col ${showNav ? '' : 'expand'} px-0`}>
              <TrialHeader />
              {isMantenance === 1 && <MaintenanceWarning />}
              <Routes>
                <Route
                  path="*"
                  element={
                    <SelectProduct>
                      {mode && <BreadcrumbNav />}
                      <Home
                        setTitle={setTitle}
                        topRecentModels={topRecentModels}
                        setTopRecentModels={setTopRecentModels}
                      />
                    </SelectProduct>
                  }
                />
                <Route
                  path="/payment/:id"
                  element={
                    <PaymentWrapper setTitle={setTitle} getHead={getHead} />
                  }
                />
                <Route
                  path="/model"
                  element={
                    <ModelTopView setTitle={setTitle} className="px-2" />
                  }
                />
                <Route
                  path="/model/:id"
                  element={
                    <TourProvider
                      components={{ Content: ModelLandingBadge }}
                      steps={modelLandingTourSteps}
                    >
                      <ModelLanding setTitle={setTitle} />
                    </TourProvider>
                  }
                />
                <Route
                  path="/view-data/:id"
                  element={
                    <ViewDataShareView setTitle={setTitle} getHead={getHead} />
                  }
                />
                <Route
                  path="/view-data/external/:id"
                  element={
                    <ViewDataShareView setTitle={setTitle} getHead={getHead} />
                  }
                />
                <Route
                  path="/predict/:id"
                  element={<WebAppPredict setTitle={setTitle} />}
                />
                <Route
                  path="/predict-dynamic/:id"
                  element={<DynamicPredictShare setTitle={setTitle} />}
                />
                <Route
                  path="/what-if-tool/:id"
                  element={<WhatIfTool setTitle={setTitle} />}
                />
                <Route
                  path="/causal-inference/:id"
                  element={<CausalInference setTitle={setTitle} />}
                />
                <Route
                  path="/multiforecast/:id"
                  element={<MultivariableForecast setTitle={setTitle} />}
                />
                <Route
                  path="/predict-spreadsheet/:id"
                  element={<SpreadsheetShare setTitle={setTitle} />}
                />
                <Route
                  path="/insights-v2/:id"
                  element={<Insights setTitle={setTitle} />}
                />
                <Route
                  path="/insights/:id"
                  element={<InsightsV2 setTitle={setTitle} />}
                />
                <Route
                  path="/model/widget/:id"
                  element={<WidgetReport setTitle={setTitle} />}
                />
                {!DISABLED_ADMIN && (
                  <>
                    <Route path="/dashboard" element={<AdminDashboard />} />
                    <Route path="/users" element={<UserManager />} />
                    <Route path="/activity" element={<UserActivity />} />
                    <Route path="/server" element={<Server />} />
                    <Route path="/tools" element={<AiTools />} />
                    <Route path="/user" element={<UserDetails />} />
                  </>
                )}
                <Route
                  path="/model/external/summary/:id"
                  element={
                    <GRow className="justify-content-center">
                      <GCol xs={11}>
                        <GRow>
                          <ModelSummaryEngagement hideNav={true} />
                        </GRow>
                      </GCol>
                    </GRow>
                  }
                />
                {!DISABLED_ACADEMY && (
                  <Route
                    path="/guides"
                    element={<Guides setTitle={setTitle} />}
                  />
                )}
                <Route path="/profile/delete" element={<DeleteAccount />} />
                <Route
                  path="/account"
                  element={<Account setTitle={setTitle} />}
                />
                {!DISABLED_PLAN && (
                  <Route path="/plan" element={<Plan setTitle={setTitle} />} />
                )}
                <Route
                  path="/reset-password"
                  element={
                    <ResetPasswordWrapper
                      setTitle={setTitle}
                      getHead={getHead}
                    />
                  }
                />
                {!DISABLED_PLAN && <Route path="/pricing" element={<Plan />} />}
                {!DISABLED_ADMINMANAGER && (
                  <Route
                    path="/admin-manager-config"
                    element={<AdminManager className="h-100" />}
                  />
                )}
                <Route
                  path="/dataflow/:workspaceId"
                  element={<DatasourceFlowView setTitle={setTitle} />}
                />
                <Route path="/chat/:sessionId" element={<ShareAutogenChat />} />
                <Route path="/knowledge-base" element={<KnowledgeBase />} />
                <Route
                  path="/knowledge-base/:repositoryId"
                  element={<Documents />}
                />
                <Route
                  path="/knowledge-base/chat/:repositoryId"
                  element={<KnowledgeBaseChat />}
                />
                <Route path="/integrations" element={<Integrations />} />
              </Routes>
            </Col>
          </Row>
          <CrashFallback message={<></>} t={t}>
            <SearchModal show={search} handleClose={() => setSearch(false)} />
          </CrashFallback>
        </RequireAuth>
      </Router>
    </ThemeProvider>
  )
}

const ClearCacheComponent = withClearCache(_App)

function App() {
  const { user, token, signout } = useAuth()
  const { t } = useTranslation()
  return (
    <CrashFallback
      t={t}
      onError={(error, componentStack) => {
        if (
          (process.env.REACT_APP_MODE || process.env.NODE_ENV) === `development`
        )
          return
        const report = {
          userId: user?.id,
          error: {
            message: error?.message,
            stack: componentStack.componentStack,
          },
          location: window.location.pathname + window.location.search,
          source: 'app',
          time: new Date().toISOString(),
        }
        const modelId = report.location.match(/model-id=[a-f0-9]+/)
        if (Array.isArray(modelId) && modelId.length) {
          report.modelId = modelId[0].split('=')[1]
        }

        sendReport({ message: 'Unexpected crash', report, token, signout })
      }}
    >
      <ClearCacheComponent />
      <CookieBanner />
    </CrashFallback>
  )
}

export default App

import { components, default as ReactSelect } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useTranslation } from 'react-i18next'
import { dataTypeCategories } from '../../util/aethetics'
import AsyncSelect from 'react-select/async'
import { useNav } from '../../providers/NavProvider'

export const categoryToColor = {
  Categorical: dataTypeCategories.categorical,
  Double: dataTypeCategories.float,
  Integer: dataTypeCategories.integer,
  Datetime: dataTypeCategories.date,
  Text: dataTypeCategories.text,
  ID: dataTypeCategories.id,
}

const trainStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: 15,
    boxShadow: 'none',
    backgroundColor: 'none',
  }),
  container: (provided, a) => {
    let borderColor = 'var(--nextbrain-automl-color)'
    if (a?.className?.includes('react-select-nb-mmm'))
      borderColor = 'var(--nextbrain-mmm-color)'
    else if (a?.className?.includes('react-select-nb-inventory_management'))
      borderColor = 'var(--nextbrain-inventory-color)'

    return {
      ...provided,
      border: `1px solid ${borderColor}`,
      borderRadius: 15,
    }
  },
  singleValue: (provided, a) => {
    let color = 'var(--nextbrain-automl-color)'
    if (a?.selectProps?.className?.includes('react-select-nb-mmm'))
      color = 'var(--nextbrain-mmm-color)'
    else if (
      a?.selectProps?.className?.includes(
        'react-select-nb-inventory_management',
      )
    )
      color = 'var(--nextbrain-inventory-color)'
    return {
      ...provided,
      backgroundColor: 'var(--nextbrain-body)',
      color: color,
    }
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isFocused
        ? 'var(--nextbrain-background)'
        : 'var(--nextbrain-body)',
      color: categoryToColor[state.data.type] ?? 'var(--nextbrain-white-font)',
      //border: '1px solid var(--nextbrain-secondary-border-color)',
      cursor: 'pointer',
    }
  },
  menuList: (provided) => ({
    ...provided,
    backgroundColor: 'var(--nextbrain-body)',
    border: '1px solid var(--nextbrain-secondary-border-color)',
    color: 'var(--nextbrain-white-font)',
    padding: '0px',
  }),
  menu: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    svg: { fill: 'var(--nextbrain-white-font)' },
    backgroundColor: 'var(--nextbrain-body)',
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'var(--nextbrain-white-font)',
    width: '2px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: 'var(--nextbrain-body)',
    paddingTop: '5px',
    paddingBottom: '5px',
    cursor: 'pointer',
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
  }),
  multiValue: (provided, s) => {
    const values = s.getValue()
    let color = 'var(--nextbrain-secondary-color)'
    if (s?.selectProps?.className?.includes('react-select-nb-mmm')) {
      color = 'var(--nextbrain-mmm-color-alt2)'
    }
    if (values.length > s.selectProps.formatOptionLabel.maxValues) {
      if (s.data.label !== values[0].label)
        return {
          display: 'none',
        }
      else
        return {
          pointerEvents: 'none',
          marginLeft: '-5000px',
        }
    }
    return {
      ...provided,
      backgroundColor: color,
    }
  },
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--nextbrain-white-font)',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--nextbrain-white-font)',
  }),
}

const thinStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: 15,
    boxShadow: 'none',
    backgroundColor: 'none',
    minHeight: '0px',
  }),
  container: (provided, a) => {
    let borderColor = 'var(--nextbrain-automl-color)'
    if (a?.className?.includes('react-select-nb-mmm'))
      borderColor = 'var(--nextbrain-mmm-color)'
    else if (a?.className?.includes('react-select-nb-inventory_management'))
      borderColor = 'var(--nextbrain-inventory-color)'

    return {
      ...provided,
      border: `1px solid ${borderColor}`,
      borderRadius: 15,
    }
  },
  singleValue: (provided, a) => {
    let color = 'var(--nextbrain-automl-color)'
    if (a?.selectProps?.className?.includes('react-select-nb-mmm'))
      color = 'var(--nextbrain-mmm-color)'
    else if (
      a?.selectProps?.className?.includes(
        'react-select-nb-inventory_management',
      )
    )
      color = 'var(--nextbrain-inventory-color)'
    return {
      ...provided,
      backgroundColor: 'var(--nextbrain-body)',
      padding: 0,
      color: color,
    }
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isFocused
        ? 'var(--nextbrain-background)'
        : 'var(--nextbrain-body)',
      color: categoryToColor[state.data.type] ?? 'var(--nextbrain-white-font)',
      //border: '1px solid var(--nextbrain-secondary-border-color)',
      cursor: 'pointer',
    }
  },
  menuList: (provided) => ({
    ...provided,
    backgroundColor: 'var(--nextbrain-body)',
    border: '1px solid var(--nextbrain-secondary-border-color)',
    color: 'var(--nextbrain-white-font)',
    padding: '0px',
  }),
  menu: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    svg: { fill: 'var(--nextbrain-white-font)' },
    backgroundColor: 'var(--nextbrain-body)',
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'var(--nextbrain-white-font)',
    width: '2px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: 'var(--nextbrain-body)',
    padding: '0px 4px',
    cursor: 'pointer',
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
  }),
  multiValue: (provided, s) => {
    const values = s.getValue()
    let color = 'var(--nextbrain-secondary-color)'
    if (s?.selectProps?.className?.includes('react-select-nb-mmm')) {
      color = 'var(--nextbrain-mmm-color-alt2)'
    }
    if (values.length > s.selectProps.formatOptionLabel.maxValues) {
      if (s.data.label !== values[0].label)
        return {
          display: 'none',
        }
      else
        return {
          pointerEvents: 'none',
          marginLeft: '-5000px',
        }
    }
    return {
      ...provided,
      backgroundColor: color,
    }
  },
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--nextbrain-white-font)',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--nextbrain-white-font)',
    margin: '0',
  }),
}

const emptyStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: 15,
    boxShadow: 'none',
    backgroundColor: 'none',
    minHeight: '20px',
  }),
  container: (provided, a) => {
    return {
      ...provided,
      border: `1px solid #00000000`,
      borderRadius: 15,
    }
  },
  singleValue: (provided, a) => {
    return {
      ...provided,
      backgroundColor: '#00000000',
      color: 'var(--nextbrain-white-font)',
      fontWeight: 'bold',
    }
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isFocused
        ? 'var(--nextbrain-background)'
        : 'var(--nextbrain-body)',
      color: categoryToColor[state.data.type] ?? 'var(--nextbrain-white-font)',
      //border: '1px solid var(--nextbrain-secondary-border-color)',
      cursor: 'pointer',
    }
  },
  menuList: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
    color: 'var(--nextbrain-white-font)',
    padding: '0px',
    border: '1px solid var(--nextbrain-secondary-border-color)',
  }),
  menu: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    svg: { fill: 'var(--nextbrain-white-font)' },
    backgroundColor: '#00000000',
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    padding: '0px',
    maxWidth: '0px',
    transform: 'translateX(-18px)',
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
    width: '0px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
    paddingTop: '0px',
    paddingBottom: '0px',
    cursor: 'pointer',
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    fontSize: '13px',
    padding: '0px',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--nextbrain-white-font)',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--nextbrain-white-font)',
  }),
}

const suggestionStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: 15,
    boxShadow: 'none',
    backgroundColor: 'none',
  }),
  container: (provided, a) => {
    return {
      ...provided,
      border: `1px solid var(--nextbrain-secondary-border-color)`,
      borderBottomLeftRadius: '0.25em',
      borderBottomRightRadius: '0.25em',
    }
  },
  singleValue: (provided, a) => {
    return {
      ...provided,
      backgroundColor: '#00000000',
      color: 'var(--nextbrain-white-font)',
      fontWeight: 'bold',
    }
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isFocused
        ? 'var(--nextbrain-background)'
        : 'var(--nextbrain-body)',
      color: categoryToColor[state.data.type] ?? 'var(--nextbrain-white-font)',
      //border: '1px solid var(--nextbrain-secondary-border-color)',
      cursor: 'pointer',
    }
  },
  menuList: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
    color: 'var(--nextbrain-white-font)',
    padding: '0px',
  }),
  menu: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    svg: { fill: 'var(--nextbrain-white-font)' },
    backgroundColor: '#00000000',
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
    width: '2px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
    paddingTop: '5px',
    paddingBottom: '5px',
    cursor: 'pointer',
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#00000000',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--nextbrain-white-font)',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--nextbrain-white-font)',
  }),
}

const dateTypesStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: '5px',
    boxShadow: 'none',
  }),
  container: (provided) => ({
    ...provided,
    border: '1px solid var(--nextbrain-secondary-border-color)',
  }),
  singleValue: (provided) => ({
    ...provided,
    backgroundColor: 'var(--nextbrain-secondary-color)',
    color: 'var(--nextbrain-white-font)',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? 'var(--nextbrain-secondary-color)'
      : 'var(--nextbrain-secondary-color)',
    color: 'var(--nextbrain-white-font)',
    border: '1px solid var(--nextbrain-secondary-border-color)',
    cursor: 'pointer',
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: 'var(--nextbrain-secondary-color)',
    color: 'var(--nextbrain-white-font)',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    svg: { fill: 'var(--nextbrain-white-font)' },
    backgroundColor: 'var(--nextbrain-secondary-color)',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'var(--nextbrain-white-font)',
    width: '2px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: 'var(--nextbrain-secondary-color)',
    paddingTop: '5px',
    paddingBottom: '5px',
    cursor: 'pointer',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--nextbrain-white-font)',
  }),
}

export default function NextbrainSelect({
  type = 'train',
  creatable = false,
  async = false,
  maxSelected = 5,
  ...props
}) {
  if (creatable && async) {
    console.warn('Using both creatable and async react select is not supported')
  }
  const { t } = useTranslation()
  const { mode } = useNav()
  const Component = async
    ? AsyncSelect
    : creatable
    ? CreatableSelect
    : ReactSelect
  switch (type) {
    case 'train':
    case 'dark': {
      const formatLabel = (a, b) => {
        if (
          Array.isArray(b?.selectValue) &&
          b.selectValue.length > maxSelected
        ) {
          return (
            <div
              className="status-select-indicator"
              data-selected-num={
                b?.inputValue
                  ? `🔍 ${b?.inputValue}`
                  : `${b.selectValue.length}${
                      props?.options?.length
                        ? ` / ${props?.options?.length}`
                        : ''
                    } ${t('selected')}`
              }
            ></div>
          )
        }
        return a.label
      }
      formatLabel.maxValues = maxSelected
      return (
        <Component
          styles={trainStyles}
          {...props}
          className={`${props?.className ?? ''} react-select-nb-${mode}`}
          formatOptionLabel={formatLabel}
        />
      )
    }
    case 'thin': {
      const formatLabel = (a, b) => {
        if (
          Array.isArray(b?.selectValue) &&
          b.selectValue.length > maxSelected
        ) {
          return (
            <div
              className="status-select-indicator"
              data-selected-num={
                b?.inputValue
                  ? `🔍 ${b?.inputValue}`
                  : `${b.selectValue.length}${
                      props?.options?.length
                        ? ` / ${props?.options?.length}`
                        : ''
                    } selected`
              }
            ></div>
          )
        }
        return a.label
      }
      formatLabel.maxValues = maxSelected
      return (
        <Component
          styles={thinStyles}
          {...props}
          className={`${
            props?.className ?? ''
          } base-input react-select-nb-${mode}`}
          formatOptionLabel={formatLabel}
        />
      )
    }
    case 'empty':
      return <Component styles={emptyStyles} {...props} />
    // return <Component styles={darkStyles} {...props} />
    case 'dateTypes':
      return <Component styles={dateTypesStyles} {...props} />
    case 'suggestions':
      return <Component styles={suggestionStyles} {...props} />
    default:
      throw new Error(`${t('Unknown select type')} ${type}`)
  }
}

export const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label
          style={{
            color:
              (!props.isSelected ? categoryToColor[props.data.type] : null) ??
              'inherit',
          }}
        >
          {props.label}
        </label>
      </components.Option>
    </div>
  )
}

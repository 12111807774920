import React, { useState, useRef, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useAuth } from '../../providers/AuthProvider'
import { createRepository, getRepositories } from '../../services/document'
import { Col, Row, Modal, Form, Button, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { TiPlus } from 'react-icons/ti'
import { NotificationManager } from 'react-notifications'
import Repository from './Repository'
import '../homepage/homepage.css'
import { useNavigate } from 'react-router-dom'
import useStoreState from '../../hooks/UseStoreState'
import IntroScreen from '../homepage/IntroScreen'
import ShareRepository from './ShareRepository'
import BreadcrumbNav from '../header/BreadcrumbNav'

export default function KnowledgeBase() {
  const [userHelp, setUserHelp] = useStoreState({ key: 'userHelp' })
  const queryClient = useQueryClient()
  const { token, signout } = useAuth()
  const { t } = useTranslation()
  const [menu, setMenu] = useState(null)
  const [share, setShare] = useState(null)
  const menuRef = useRef()
  const navigate = useNavigate()

  const { data: repositories, isLoading: isLoadingRepositories } = useQuery(
    ['document-repositories', token],
    async () => {
      const repos = await getRepositories({ token, signout })
      repos.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      )
      return repos
    },
  )

  useEffect(() => {
    if (repositories?.some((r) => r.loading)) {
      const iv = setInterval(() => {
        queryClient.invalidateQueries(['document-repositories', token])
      }, 4000)
      return () => clearInterval(iv)
    }
    // eslint-disable-next-line
  }, [repositories])

  const createRepo = () => {
    if (!menuRef.current.value)
      NotificationManager.error('Repository name is required')
    else {
      createRepository({
        name: menuRef.current.value,
        token,
        signout,
      }).then((r) => {
        if (r?.repository_id) {
          NotificationManager.success('Repository created')
          queryClient.invalidateQueries(['document-repositories', token])
          setMenu(null)
        } else NotificationManager.error('Error creating repository')
      })
    }
  }

  return (
    <>
      {share && (
        <ShareRepository repository={share} onHide={() => setShare(null)} />
      )}
      <Modal size={'lg'} show={!userHelp.rag}>
        <IntroScreen
          message={'Knowledge base introduction'}
          video={'https://www.youtube.com/embed/D1IkipzX3dk'}
          onFinish={() => {
            setUserHelp({ ...userHelp, rag: true })
          }}
        />
      </Modal>
      <BreadcrumbNav />
      <Container>
        <Row className="px-4 mt-4">
          {menu}
          <Col
            xs={12}
            className="mb-2 d-flex justify-content-center"
            onClick={() => {}}
          >
            <Row
              className="project-new-template p-2 w-auto  justify-content-center"
              onClick={() =>
                setMenu(
                  <Modal show={true} onHide={() => setMenu(null)}>
                    <Modal.Header closeButton>
                      <span className="h3">{t('New repository')}</span>
                    </Modal.Header>
                    <Modal.Body>
                      <Row>
                        <Col xs={12}>
                          <Form.Control
                            type="text"
                            autoFocus
                            className="nb-input-soft"
                            placeholder={t('Repository name')}
                            ref={menuRef}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                createRepo()
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Row className="w-100">
                        <Col
                          className="d-inline-flex justify-content-end"
                          xs={12}
                        >
                          <Button
                            className="me-3 original empty-secondary"
                            variant="secondary"
                            onClick={() => setMenu(null)}
                          >
                            {t('Cancel')}
                          </Button>
                          <Button onClick={createRepo}>{t('Create')}</Button>
                        </Col>
                      </Row>
                    </Modal.Footer>
                  </Modal>,
                )
              }
            >
              <Col className="col-auto d-flex align-items-center">
                <TiPlus className="me-1" size={25} />
                {t('Create a new repository')}
              </Col>
            </Row>
          </Col>
          <Col className="h4 mx-2 mt-2 mb-3" xs={12}>
            {t('Repositories')}
          </Col>
          <Col xs={12}>
            <Row className="">
              {isLoadingRepositories ? (
                <Col xs={12} className="text-center loading-tooltip">
                  {t('Loading repositories')}
                </Col>
              ) : (
                (Array.isArray(repositories) ? repositories : [])?.map(
                  (repository, i) => (
                    <Col xl={4} md={6} xs={12} key={repository.id}>
                      <Repository
                        className="py-3 ps-3 mx-1 mt-3 mb-2 project-card d-flex flex-column justify-content-between"
                        repository={repository}
                        onClick={() =>
                          navigate(`/knowledge-base/${repository.id}`)
                        }
                        onDelete={() => {
                          repositories.splice(i, 1)
                          queryClient.setQueriesData(
                            ['document-repositories', token],
                            [...repositories],
                          )
                        }}
                        onShare={() => {
                          document.querySelector('body')?.click()
                          setShare(repository)
                        }}
                      />
                    </Col>
                  ),
                )
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

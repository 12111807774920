import { useState, useRef } from 'react'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaSortAlphaDown, FaSortAlphaUpAlt, FaFill } from 'react-icons/fa'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import ViewDataResume from './ViewDataResume'
import ModalChangeType from './ModalChangeType'
import { useMemo } from 'react'
import CustomModal from '../modal/modal'
import { BouncyLoader } from '../utils/ui'
import RoleDisable from '../utils/RoleDisable'

export default function ColumnAndType({
  column,
  summary,
  onFill,
  width,
  columnToType,
  columnDescription,
  sort,
  header,
  rows,
  setRows,
  originalRulesRows,
  setRulesRows,
  setSort,
  onColumnTypeChanged,
  sampleData,
  model,
  deleteColumn = null,
  readOnly = false,
  setIsChangingDataActiveModel = () => {},
}) {
  const { t } = useTranslation()
  const [deleting, setDeleting] = useState(false)
  const [visibleMenu, setVisibleMenu] = useState(false)
  const deleteRef = useRef()
  const columnType =
    columnToType[column] === 'Double' ? 'Decimal' : columnToType[column]
  const inType = column in columnToType
  const nullCount = model?.dataset?.statistics?.[column]?.nan_count ?? 0
  const realType = model?.dataset?.final_column_status?.[column]
  const validType = realType !== 'Text' && realType !== 'ID'

  const resume = useMemo(
    () => (
      <ViewDataResume
        summary={summary}
        width={width}
        model={model}
        column={column}
      />
    ),
    // eslint-disable-next-line
    [summary, width, columnType],
  )

  if (inType) {
    return (
      <Row className="flex-column column-type-control justify-content-between p-2 pb-0">
        <Col
          className={`d-flex flex-row flex-nowrap justify justify-content-between`}
          xs={'auto'}
          onClick={() => {
            const index = header[0].findIndex((e) => e === column)

            const sortedData = rows
              .map((row, i) => ({ row, rules: originalRulesRows[i] }))
              .sort((a, b) => {
                if (sort) {
                  return a.row[index] < b.row[index] ? 1 : -1
                } else {
                  return a.row[index] > b.row[index] ? 1 : -1
                }
              })

            const newRows = sortedData.map((item) => item.row)
            const newRulesRows = sortedData.map((item) => item.rules)

            setRows([...newRows])
            setRulesRows([...newRulesRows])
            setSort((s) => !s)
          }}
        >
          <div
            className={`d-inline-block text-truncate header-line ${
              sort ? 'descending' : 'ascending'
            }`}
          >
            {columnDescription ? (
              <OverlayTrigger
                rootClose={true}
                trigger={['hover', 'focus']}
                placement={'auto'}
                delay={{ show: 100, hide: 100 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    <span>{columnDescription}</span>
                  </Tooltip>
                )}
              >
                <span>{column}</span>
              </OverlayTrigger>
            ) : (
              <span>{column}</span>
            )}
            <FaSortAlphaDown className="mx-1 sort-desc" />
            <FaSortAlphaUpAlt className="mx-1 sort-asc" />
          </div>
          <div>
            <OverlayTrigger
              rootClose={true}
              trigger={'click'}
              placement={'top'}
              onToggle={(e) => setVisibleMenu(e)}
              delay={{ show: 100, hide: 100 }}
              overlay={(props) => (
                <Tooltip
                  {...props}
                  className={`${
                    props?.className ?? ''
                  } context-menu-column-type`}
                >
                  <Row
                    className="my-2 px-4"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <RoleDisable className="w-100 d-inline-block px-0">
                      <Col
                        className="linkshare link white-link d-flex flex-nowrap align-items-center"
                        xs={12}
                        onClick={() => {
                          deleteRef.current.click()
                          document.querySelector('body')?.click()
                        }}
                      >
                        {t('Delete column')} <MdDelete size={17} />
                      </Col>
                    </RoleDisable>
                    <RoleDisable
                      className="w-100 d-inline-block px-0"
                      opacity={100}
                    >
                      <Col
                        className={`mt-2 linkshare link white-link d-flex flex-nowrap  align-items-center ${
                          nullCount && !readOnly && validType ? '' : 'disabled'
                        }`}
                        xs={12}
                        onClick={() => {
                          if (!nullCount) return
                          onFill()
                          document.querySelector('body')?.click()
                        }}
                      >
                        {t('Fill empty values')} <FaFill size={14} />
                      </Col>
                    </RoleDisable>
                  </Row>
                </Tooltip>
              )}
            >
              <span
                onClick={(e) => {
                  e.stopPropagation()
                  document.querySelector('body')?.click()
                }}
                style={{ float: 'right' }}
              >
                <BsThreeDotsVertical
                  className={`icon-btn context-menu ${
                    visibleMenu ? 'force-show' : ''
                  }`}
                  size={20}
                />
              </span>
            </OverlayTrigger>
          </div>
          {deleteColumn && (
            <div
              className="delete-column-table"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              <CustomModal
                className="no-bg"
                title={
                  <Col xs={12}>
                    <span>
                      {t('Delete')} <strong>{column}</strong>
                    </span>
                  </Col>
                }
                btn={
                  <div
                    className="delete-column-trigger-container"
                    ref={deleteRef}
                  >
                    {deleting ? <BouncyLoader /> : <MdDelete size={30} />}
                  </div>
                }
                confirmText={t('Delete')}
                confirmProps={{
                  className: 'original',
                  variant: 'warning',
                }}
                showVariant={''}
                confirmCallback={async () => {
                  setDeleting(true)
                  setIsChangingDataActiveModel(true)
                  await deleteColumn(column)
                  setDeleting(false)
                  setIsChangingDataActiveModel(false)
                }}
              >
                <p>
                  {t('Confirm deleting. Once done it can not be restored.')}
                </p>
              </CustomModal>
            </div>
          )}
        </Col>
        <Col xs={'auto'}></Col>
        <ModalChangeType
          model={model}
          column={column}
          multipleDates={
            (model?.dataset?.datetime_options?.[column] ?? [])?.length > 1
          }
          columnType={columnType}
          onColumnTypeChanged={onColumnTypeChanged}
          deleteColumn={deleteColumn}
          readOnly={readOnly}
        />
        <Col className="px-0" xs={12}>
          {resume}
        </Col>
        <Col className="d-inline-block text-truncate null-value-info" xs={12}>
          {readOnly ? (
            <>
              {nullCount && model?.dataset?.rows ? (
                <span>
                  {nullCount} {t('empty values')}, (
                  {Math.round((100 * nullCount) / model?.dataset?.rows)}%)
                </span>
              ) : (
                t('no empty values')
              )}
            </>
          ) : (
            <>
              {nullCount && model?.dataset?.rows ? (
                validType ? (
                  <OverlayTrigger
                    rootClose={true}
                    trigger={['hover', 'focus']}
                    placement={'top'}
                    delay={{ show: 100, hide: 100 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        <span>{t('Fill empty values')}</span>
                      </Tooltip>
                    )}
                  >
                    <span
                      className="link white-on-hover text-underline"
                      onClick={() => {
                        if (!nullCount) return
                        onFill()
                        document.querySelector('body')?.click()
                      }}
                    >
                      {nullCount} {t('empty values')}, (
                      {Math.round((100 * nullCount) / model?.dataset?.rows)}%)
                    </span>
                  </OverlayTrigger>
                ) : (
                  <span className="">
                    {nullCount} {t('empty values')}, (
                    {Math.round((100 * nullCount) / model?.dataset?.rows)}%)
                  </span>
                )
              ) : (
                t('no empty values')
              )}
            </>
          )}
        </Col>
      </Row>
    )
  }
  return column
}

import React, { useMemo } from 'react'
import { Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import NextbrainSelect, { Option } from '../NextbrainSelect'
import './TrainFlow.css'
import HelpTooltip from '../HelpTooltip'

function SwitchIncludeExclude({ active, ...props }) {
  return (
    <Form.Check
      type="switch"
      className={`ps-0 mb-0 input-columns-ignore ${
        active ? 'input-columns-ignore-active' : ''
      }`}
      {...props}
    />
  )
}

export default function ColumnsToIgnore({
  columnsIgnored,
  columnsToIgnore,
  setColumnsIdnored,
  targetConfig,
  mmmTimeColumn,
  extraFeatures,
  columnsEnabled,
  setColumnsEnabled,
}) {
  const { t } = useTranslation()

  const extraFeaturesSet = useMemo(() => {
    return new Set(extraFeatures.map((feature) => feature?.value))
  }, [extraFeatures])

  const currentOptions = columnsToIgnore.filter(
    (c) =>
      c.value !== targetConfig?.target &&
      c.value !== mmmTimeColumn?.value &&
      !extraFeaturesSet.has(c.value),
  )

  let updateColumnsIgnored = (value) => setColumnsIdnored(value)
  let currentValue = columnsIgnored.filter(
    (c) =>
      c.value !== targetConfig?.target &&
      c.value !== mmmTimeColumn?.value &&
      !extraFeaturesSet.has(c.value),
  )
  if (columnsEnabled) {
    const current = new Set(currentValue.map((v) => v.value))
    currentValue = currentOptions.filter((c) => !current.has(c.value))
    updateColumnsIgnored = (value) => {
      const current = new Set(value.map((v) => v.value))
      setColumnsIdnored(currentOptions.filter((c) => !current.has(c.value)))
    }
  }

  return (
    <>
      <Col
        className="d-flex justify-content-between align-items-center"
        xs={12}
      >
        <div className="d-inline-block">
          <span>{t('Columns to')} </span>
          <span className="d-inline-block" style={{ minWidth: '120px' }}>
            <SwitchIncludeExclude
              active={columnsEnabled}
              checked={!columnsEnabled}
              onChange={async (e) => {
                setColumnsEnabled(!e.target.checked)
              }}
            />
          </span>
          <HelpTooltip
            className="help-select-icon"
            message={'column to ignore'}
            helpLink={'columns-to-ignore-14wj24d'}
          />
        </div>
      </Col>
      <Col xs={12}>
        <NextbrainSelect
          value={currentValue}
          onChange={updateColumnsIgnored}
          className="mt-2"
          options={currentOptions}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
          }}
          allowSelectAll={true}
        />
      </Col>
    </>
  )
}

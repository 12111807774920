import React, { useCallback } from 'react'
import CodeMirror from '@uiw/react-codemirror'
import { sql } from '@codemirror/lang-sql'
import { json } from '@codemirror/lang-json'
import { dracula } from '@uiw/codemirror-theme-dracula'

export function SqlEditor({ sqlValue, setSqlValue }) {
  const onChange = useCallback((value, viewUpdate) => {
    setSqlValue(value)
    // eslint-disable-next-line
  }, [])

  return (
    <CodeMirror
      value={sqlValue}
      height="200px"
      extensions={[sql({})]}
      onChange={onChange}
      theme={dracula}
      indentWithTab
    />
  )
}

export function JSONEditor({ value, setValue, height = '300px', ...props }) {
  const onChange = useCallback((v, viewUpdate) => {
    setValue(v)
    // eslint-disable-next-line
  }, [])
  return (
    <CodeMirror
      value={value}
      height={height}
      extensions={[json({})]}
      onChange={onChange}
      theme={dracula}
      indentWithTab
      {...props}
    />
  )
}

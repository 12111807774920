import { useState } from 'react'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import CustomModal from '../modal/modal'
import { categoryToColorDataTypes as categoryToColor } from '../../util/aethetics'
import DataTypeIcon from '../data-types/DataTypeIcon'
import NextbrainSelect from '../model-content/NextbrainSelect'
import { CiWarning } from 'react-icons/ci'

export default function ModalChangeType({
  column,
  model,
  columnType,
  onColumnTypeChanged,
  multipleDates = false,
  deleteColumn = null,
  readOnly = false,
}) {
  const { t } = useTranslation()
  const [selectedValue, setSelectedValue] = useState()
  const dateFormats = (model?.dataset?.datetime_options?.[column] ?? []).map(
    (d) => ({ label: d, value: d }),
  )
  const currentFormat = model?.dataset?.datetime_formats?.[column]
  const [selectedFormat, setSelectedFormat] = useState(
    currentFormat ? { label: currentFormat, value: currentFormat } : null,
  )

  const btn = (
    <div className="columnd-and-type mt-2 position-relative d-inline-flex flex-nowrap">
      <span
        align="left"
        className="ms-2 badge-datatype position-relative"
        style={{
          color: categoryToColor[columnType],
          backgroundColor: `${categoryToColor[columnType]}22`,
        }}
      >
        <div className="d-flex align-items-center">
          <span>{t(columnType)}</span>

          <DataTypeIcon
            className="ms-2 mt-1"
            size={19}
            color={categoryToColor[columnType]}
            type={columnType}
          />
        </div>
        {!readOnly && (
          <div
            style={{ backgroundColor: categoryToColor[columnType] }}
            className="chevron-badge"
          ></div>
        )}
      </span>
      {!readOnly && multipleDates && columnType === 'Datetime' && (
        <OverlayTrigger
          rootClose={true}
          trigger={['hover', 'focus']}
          placement="auto"
          delay={{ show: 200, hide: 1000 }}
          overlay={(props) => (
            <Tooltip {...props}>{t('Multiple date formats available')}</Tooltip>
          )}
        >
          <span>
            <CiWarning color="white" size={24} className="ms-2" />
          </span>
        </OverlayTrigger>
      )}
    </div>
  )

  if (readOnly) return btn

  return (
    <CustomModal
      className="no-bg cursor-pointer"
      as={Row}
      title={
        <Col xs={12}>
          <span>
            <Trans i18nKey="Edit column type">
              Edit <strong>{column}</strong> column type
            </Trans>
          </span>
        </Col>
      }
      btn={btn}
      confirmText="Save"
      confirmProps={{
        className: 'original',
        variant: 'success',
      }}
      showVariant={''}
      confirmCallback={() => {
        //setSampleData();
        if (!selectedValue && currentFormat !== selectedFormat?.value) {
          onColumnTypeChanged(column, columnType, selectedFormat?.value)
        }
        if (selectedValue)
          onColumnTypeChanged(column, selectedValue, selectedFormat?.value)
      }}
    >
      <p>
        {t(
          'It is necessary that we correctly identify the data type for each column.',
        )}
      </p>
      <div className="small w-100">
        <strong>{t('We have the following options:')} </strong>
        <ul>
          <li>
            <strong>{t('Decimal')}</strong> (e.g., 0.12 or 23.56) or{' '}
            <strong>{t('Integer')}</strong> (eg. 4 or 3456). <br />
            {t('Explain integer')}
          </li>
          <li>
            <strong>{t('Categorical')}</strong> {t('Explain categorical')}
          </li>
          <li>
            <strong>{t('Text')}</strong> {t('Explain text')}
          </li>
          <li>
            <strong>{t('Datetime')}</strong> {t('Explain datetime')}
          </li>
        </ul>
        <span className="text-secondary">
          {t('The same apply to the other columns.')}
        </span>
      </div>
      <NextbrainSelect
        type="dateTypes"
        className="basic-single mt-2 nb-input"
        classNamePrefix="select"
        isSearchable={true}
        name="Column type"
        defaultValue={{ label: t(columnType), value: columnType }}
        onChange={(v) => {
          setSelectedValue(v.value)
        }}
        options={[
          { label: t('Integer'), value: 'Integer' },
          { label: t('Decimal'), value: 'Double' },
          { label: t('Datetime'), value: 'Datetime' },
          { label: t('Categorical'), value: 'Categorical' },
          { label: t('Text'), value: 'Text' },
          { label: t('ID'), value: 'ID' },
        ]}
      />
      {multipleDates && (selectedValue ?? columnType) === 'Datetime' && (
        <NextbrainSelect
          type="dateTypes"
          className="basic-single mt-2 nb-input"
          classNamePrefix="select"
          isSearchable={true}
          placeholder={t('Datetime format')}
          value={selectedFormat}
          onChange={(v) => {
            setSelectedFormat(v)
          }}
          options={dateFormats}
        />
      )}
    </CustomModal>
  )
}

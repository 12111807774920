import React, { useState, useRef, useEffect } from 'react'
import { Col, Row, Form, Button } from 'react-bootstrap'
import { useQuery, useQueryClient } from 'react-query'
import { useAuth } from '../../providers/AuthProvider'
import { getRepositories } from '../../services/document'
import { getCustomApps } from '../../services/recipe'
import { useTranslation } from 'react-i18next'
import NextbrainSelect from '../model-content/NextbrainSelect'
import Loading from '../loading/LoadingSmall'
import { FaPlug, FaPlus, FaTrash } from 'react-icons/fa'
import { FaShop } from 'react-icons/fa6'
import { GiReturnArrow } from 'react-icons/gi'
import { NotificationManager } from 'react-notifications'
import {
  createIntegration,
  updateIntegration,
} from '../../services/integration'
import { MarketplaceModel } from '../recipe/Marketplace'

function ApiForm({ callbackConfig, onChange }) {
  const { t } = useTranslation()
  const urlRef = useRef()
  const [method, setMethod] = useState(callbackConfig?.method ?? 'POST')
  const [headers, setHeaders] = useState(() => {
    if (callbackConfig?.headers) {
      return Object.entries(callbackConfig.headers).reduce((acc, [k, v]) => {
        acc.push([k, v])
        return acc
      }, [])
    }

    return [['', '']]
  })

  const update = () => {
    const config = {
      url: urlRef.current.value,
      method,
      headers: headers.reduce((acc, [k, v]) => {
        if (k) acc[k] = v
        return acc
      }, {}),
    }
    onChange(config, !!(config.url && config.method))
  }

  useEffect(() => {
    update()
    // eslint-disable-next-line
  }, [headers])

  return (
    <Row className="mt-2 p-2 py-4 ms-1 api-form-integration">
      <Col xs={12}>{t('URL*')}</Col>
      <Col className="mt-1" xs={12}>
        <Form.Control
          className="nb-input-soft"
          placeholder="https://example.com/callback"
          ref={urlRef}
          defaultValue={callbackConfig?.url ?? ''}
          required
          onBlur={() => {
            update()
          }}
        />
      </Col>
      <Col className="mt-2" xs={12}>
        {t('Method')}
      </Col>
      <Col className="mt-1" xs={12}>
        <NextbrainSelect
          type={'thin'}
          value={{ value: method, label: method }}
          className="basic-single"
          classNamePrefix="select"
          isClearable={false}
          onChange={(v) => setMethod(v.value)}
          options={[
            { value: 'POST', label: 'POST' },
            { value: 'GET', label: 'GET' },
          ]}
        />
      </Col>
      <Col className="mt-3" xs={12}>
        {t('Headers')}
      </Col>
      <Col xs={12}>
        <Row className="pe-4 my-1">
          <Col xs={6}>{t('Header name')}</Col>
          <Col xs={6}>{t('Header value')}</Col>
        </Row>
        {headers.map((header, i) => (
          <Row className="pe-4 position-relative" key={`${header?.[0]}_${i}`}>
            {headers?.length > 1 && (
              <FaTrash
                size={20}
                className="icon-btn position-absolute px-0"
                style={{ right: 5, top: 'calc(50% - 6px)', maxWidth: '20px' }}
                onClick={() => {
                  const filterHeaders = headers.filter((_, idx) => idx !== i)
                  setHeaders(filterHeaders)
                }}
              />
            )}
            <Col xs={6}>
              <Form.Control
                className="nb-input-soft"
                defaultValue={header[0]}
                onChange={(e) => {
                  header[0] = e.target.value
                }}
                onBlur={update}
              />
            </Col>
            <Col xs={6}>
              <Form.Control
                className="nb-input-soft"
                defaultValue={header[1]}
                onChange={(e) => {
                  header[1] = e.target.value
                }}
                onBlur={update}
              />
            </Col>
          </Row>
        ))}
        <Row className="pe-4 mt-3">
          <Col className="dflex-center" xs={12}>
            <Button
              onClick={() => {
                const filterHeaders = headers.filter(([k, v]) => k || v)
                filterHeaders.push(['', ''])
                setHeaders(filterHeaders)
              }}
            >
              <FaPlus size={30} />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default function ConfigIntegration({ mode, integration, onFinish }) {
  if (integration) mode = integration?.pipeline?.[0]?.type ?? 'app'

  const { token, signout } = useAuth()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const CALLBACK_OPTIONS = [
    { value: 'api', label: t('Api callback'), form: ApiForm },
  ]

  const [addApp, setAddApp] = useState(false)

  const [pipeline, setPipeline] = useState(null)
  const [callback, setCallback] = useState(CALLBACK_OPTIONS[0])
  const [formState, setFormState] = useState(
    integration?.callbacks?.[0] ?? null,
  )

  const { data: repositories } = useQuery(
    ['document-repositories', token],
    async () => {
      const repos = await getRepositories({ token, signout })
      repos.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      )
      return repos
    },
  )

  const { data: customApps } = useQuery(
    ['custom-apps', null, token],
    async () => {
      const apps = await getCustomApps({ token, signout })
      if (Array.isArray(apps)) return apps.reverse()
      return []
    },
    { staleTime: Infinity },
  )

  useEffect(() => {
    if (!pipeline && customApps && integration?.pipeline?.[0]?.type === 'app') {
      const app = customApps.find(
        (app) => app.recipe_id === integration.pipeline[0].id,
      )
      setPipeline(app ? { value: app.recipe_id, label: app.name } : null)
    }
    if (
      !pipeline &&
      repositories &&
      integration?.pipeline?.[0]?.type === 'rag'
    ) {
      const repo = repositories.find(
        (repo) => repo.id === integration.pipeline[0].id,
      )
      setPipeline({ value: repo.id, label: repo.name })
    }
    // eslint-disable-next-line
  }, [integration, customApps, repositories])

  const CallbackForm = callback.form

  if (!Array.isArray(customApps) || !Array.isArray(repositories))
    return (
      <Row>
        <Col className="py-5" xs={12}>
          <Loading />
        </Col>
      </Row>
    )

  return (
    <Row>
      <Col xl={6} md={12}>
        <Row>
          <Col className="mb-2" xs={12}>
            <h5>
              <FaPlug className="me-2 color-white" />
              {t('Pipeline')}
            </h5>
          </Col>
          <Col xs={12}>
            <Row className="ms-2">
              {mode === 'app' ? (
                <Col xs={12}>
                  <Row>
                    <Col xs={12}>{t('Choose an App')}</Col>
                    <Col xs={12}>
                      <div style={{ maxWidth: '350px' }}>
                        <NextbrainSelect
                          value={pipeline}
                          type={'dark'}
                          className="basic-single mt-2"
                          classNamePrefix="select"
                          isClearable={false}
                          onChange={(v) => setPipeline(v)}
                          options={customApps.map((app) => ({
                            value: app.recipe_id,
                            label: app.name,
                          }))}
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div
                        style={{ maxWidth: '350px' }}
                        className="py-2 dflex-center"
                      >
                        <Button
                          className="d-inline-flex align-items-center  original empty-secondary"
                          onClick={() => setAddApp(true)}
                        >
                          <FaShop className="me-2" />
                          {t('Add app from marketplace')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col xs={12}>
                  <Row>
                    <Col xs={12}>{t('Choose a Repository')}</Col>
                    <Col xs={12}>
                      <div style={{ maxWidth: '350px' }}>
                        <NextbrainSelect
                          value={pipeline}
                          type={'dark'}
                          className="basic-single mt-2"
                          classNamePrefix="select"
                          isClearable={false}
                          onChange={(v) => setPipeline(v)}
                          options={repositories.map((repo) => ({
                            value: repo.id,
                            label: repo.name,
                          }))}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col className="mt-xl-0 mt-md-5" xl={6} md={12}>
        <Row>
          <Col className="mb-2" xs={12}>
            <h5>
              <GiReturnArrow className="me-2 color-white" />
              {t('Callback')}
            </h5>
          </Col>
          <Col xs={12}>
            <Row className="ms-2">
              <Col xs={12}>{t('Choose a Callback type')}</Col>
              <Col xs={12}>
                <div style={{ maxWidth: '350px' }}>
                  <NextbrainSelect
                    value={callback}
                    type={'dark'}
                    className="basic-single mt-2"
                    classNamePrefix="select"
                    isClearable={false}
                    onChange={(v) => setCallback(v)}
                    options={CALLBACK_OPTIONS}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <CallbackForm
                  callbackConfig={integration?.callbacks?.[0]}
                  onChange={(value, valid) => {
                    if (valid) setFormState(value)
                    else setFormState(null)
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col className="d-flex justify-content-end mt-3" xs={12}>
        <Button
          disabled={!pipeline || !formState}
          onClick={(e) => {
            const tgt = e.currentTarget
            tgt.disabled = true
            if (integration) {
              updateIntegration({
                integrationId: integration.integration_id,
                data: {
                  pipeline: [
                    {
                      id: pipeline.value,
                      type: mode,
                    },
                  ],
                  callbacks: [formState],
                },
                token,
                signout,
              })
                .then((r) => {
                  if (r?.integration_id) {
                    NotificationManager.success(t('Integration updated'))
                    queryClient.invalidateQueries(['integrations', token])
                    onFinish()
                  } else
                    NotificationManager.error(
                      t('Failed to updated integration'),
                    )
                })
                .finally(() => {
                  tgt.disabled = false
                })
            } else {
              createIntegration({
                data: {
                  name: '',
                  scope: 'user',
                  pipeline: [
                    {
                      id: pipeline.value,
                    },
                  ],
                  type: mode,
                  callbacks: [formState],
                },
                token,
                signout,
              })
                .then((r) => {
                  if (r?.integration_id) {
                    NotificationManager.success(t('Integration created'))
                    queryClient.invalidateQueries(['integrations', token])
                    onFinish()
                  } else
                    NotificationManager.error(t('Failed to create integration'))
                })
                .finally(() => {
                  tgt.disabled = false
                })
            }
          }}
        >
          {integration ? t('Save') : t('Create')}
        </Button>
      </Col>
      {addApp && (
        <MarketplaceModel
          onHide={() => setAddApp(false)}
          onInstall={(app) => {
            setPipeline({ value: app.recipe_id, label: app.name })
            setAddApp(false)
          }}
          onUninstall={(app) => {
            if (pipeline.value === app.recipe_id) {
              setPipeline(null)
            }
          }}
        />
      )}
    </Row>
  )
}

import React, { useState, useMemo } from 'react'
import { Row, Col, Container, OverlayTrigger, Popover } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { TiPlus } from 'react-icons/ti'
import { FaArrowDown, FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa'
import { FcMultipleInputs } from 'react-icons/fc'
import { useDebouncedCallback } from 'use-debounce'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'

import ItemModel from './ItemModel'
import { useModels } from '../../providers/ModelProvider'
import NBInput from '../form/NBInput'
import { BsArrowLeft } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import { useNav } from '../../providers/NavProvider'
import Connector from '../model-content/Connector'
import { datasourceConfig } from '../model-content/datasource-flow/config'
import RoleDisable from '../utils/RoleDisable'

const translateDatasource = {
  'Stored data': 'existingData',
  'Upload local file': 'localFile',
  'Google Sheets': 'google',
  Database: 'database',
  Mongo: 'Mongo',
  'General API': 'api',
}

function ModelNavCreateNewModel({ ...props }) {
  const { t } = useTranslation()
  const [activeContext, setActiveContext] = useState(false)
  const navigate = useNavigate()
  const { workspacename, workspaceId } = useModels()

  return (
    <Row className="px-4 py-3 position-relative">
      <ContextMenuTrigger
        id={`model-nav-create-new-model-context-menu`}
        mouseButton={0}
      >
        <Connector
          name={t('Simple Import')}
          description={t('Create a model from a single source')}
          icon=<FaArrowDown style={{ color: '#EC812F', fontSize: 30 }} />
          xl={12}
          md={12}
          xs={12}
        />
      </ContextMenuTrigger>
      <Connector
        className={`${
          activeContext ? `hide-advanced-flow-option` : ''
        } advanced-flow-option`}
        name={t('Advanced Dataflow')}
        description={t('Multiple datasources and transformations')}
        icon=<FcMultipleInputs style={{ color: '#0F9D58', fontSize: 35 }} />
        xl={12}
        md={12}
        xs={12}
        onClick={() =>
          navigate(
            `/model/${workspaceId}?wp-name=${workspacename}&model-id=new&connector=dataflow`,
          )
        }
      />
      <ContextMenu
        id={`model-nav-create-new-model-context-menu`}
        className="menu-context-dataflow config-forced-model-nav"
        onShow={() => setActiveContext(true)}
        onHide={() => setActiveContext(false)}
      >
        {Object.keys(datasourceConfig)
          .filter((k) => k !== 'Config' && k !== 'Public Datasets')
          .map((key, i) => (
            <MenuItem key={key}>
              <div
                className={`d-inline-block text-nowrap pt-1 h5 mx-3 ${
                  i ? '' : 'mt-2'
                }`}
                onMouseDown={() =>
                  navigate(
                    `/model/${workspaceId}?wp-name=${workspacename}&model-id=new&connector=${
                      translateDatasource?.[key] ?? key
                    }`,
                  )
                }
              >
                {datasourceConfig[key].iconSmall}
                {t(key)}
              </div>
            </MenuItem>
          ))}
      </ContextMenu>
    </Row>
  )
}

export default function ModelNav({
  projectId,
  setActiveModel,
  models,
  ...props
}) {
  const { updateModel, deleteModel, workspacename: projectName } = useModels()
  const { t } = useTranslation()
  const [filter, setFilter] = useState('')
  const [ordering, setOrdering] = useState(null)
  const { mode } = useNav()
  const updateSearch = useDebouncedCallback((e) => {
    setFilter(e)
  }, 500)

  const filteredModels = useMemo(() => {
    let localModels = [...models]
    switch (ordering) {
      case 'desc':
        localModels.sort((a, b) =>
          a?.dataset?.name?.toLowerCase() > b?.dataset?.name?.toLowerCase()
            ? -1
            : 1,
        )
        break
      case 'asc':
        localModels.sort((a, b) =>
          a?.dataset?.name?.toLowerCase() > b?.dataset?.name?.toLowerCase()
            ? 1
            : -1,
        )
        break
      default:
        break
    }

    return (localModels ?? []).filter((model) => {
      return model.dataset.name.toLowerCase().includes(filter.toLowerCase())
    })
  }, [filter, models, ordering])

  const createUri = (model) =>
    `/model/${projectId}?wp-name=${encodeURIComponent(projectName)}&model-id=${
      model.id
    }`

  const getModelType = (model) => {
    let type = 'automl'
    if (
      model.special_model_type === 'mmm' ||
      (model.status !== 'trained' && mode === 'mmm')
    )
      type = 'mmm'
    return type
  }

  const SortItem = ordering === 'desc' ? FaSortAlphaUp : FaSortAlphaDown

  return (
    <Container>
      <Row
        {...props}
        className={`model-top-view-container model-content ${
          props.className ?? ''
        }`}
      >
        <Col xs={12} className="mb-1 d-flex ">
          <Row className="w-100">
            <Col xs={12} className="mb-5">
              <Row
                className={`justify-content-between ${
                  props.className ?? ''
                } my-2`}
                style={{ minHeight: '50px', maxHeight: '50px' }}
              >
                <Col
                  className="d-flex align-items-center ps-0"
                  style={{ minWidth: '150px' }}
                  xl="auto"
                  sm={6}
                >
                  <Link to="/">
                    <span
                      className="breadcrumbs-nav-report p-2 looks-like-a-2"
                      style={{
                        fontWeight: 'bold',
                        border: '1px solid var(--nextbrain-main-color)',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        userSelect: 'none',
                      }}
                    >
                      <BsArrowLeft size={25} />
                    </span>
                  </Link>
                </Col>
                <Col
                  className="d-flex align-items-center justify-content-xl-center justify-content-end  px-0"
                  xl="auto"
                  sm={6}
                >
                  <RoleDisable className="px-0">
                    <OverlayTrigger
                      rootClose={true}
                      trigger={'click'}
                      placement="auto"
                      delay={{ show: 200, hide: 0 }}
                      overlay={(props) => (
                        <Popover
                          {...props}
                          className={`popover-nextbrain-body ${
                            props?.className ?? ''
                          }`}
                          style={{
                            ...props.style,
                            minWidth: 'max(25vw, 300px)',
                          }}
                        >
                          <div style={{ width: 'max(25vw, 300px)' }}>
                            <ModelNavCreateNewModel />
                          </div>
                        </Popover>
                      )}
                    >
                      <Row className="project-new-template p-2 w-auto  justify-content-end">
                        <Col className="col-auto d-flex align-items-center">
                          <TiPlus className="me-1" size={25} />
                          {t('Import data')}
                        </Col>
                      </Row>
                    </OverlayTrigger>
                  </RoleDisable>
                </Col>
                <Col
                  className="px-0 d-flex justify-content-sm-start justify-content-xl-end mt-xl-0 mt-sm-2 align-items-center"
                  style={{ minWidth: '150px', maxWidth: '150px' }}
                  xl={'auto'}
                  sm={12}
                >
                  <SortItem
                    size={22}
                    color={ordering ? 'white' : '#A8A8A8'}
                    className="icon-btn me-2"
                    onClick={() => {
                      switch (ordering) {
                        case 'asc':
                          setOrdering('desc')
                          break
                        case 'desc':
                          setOrdering(null)
                          break
                        default:
                          setOrdering('asc')
                      }
                    }}
                  />
                  <div
                    className="position-relative"
                    style={{ maxHeight: '42px' }}
                  >
                    <NBInput
                      borderRadius="8px"
                      style={{
                        borderRadius: 8,
                        paddingLeft: '50px',
                        minWidth: '300px',
                        maxWidth: '300px',
                      }}
                      className="py-2 expand-search-item"
                      placeholder={t('Search a model...')}
                      useWrapper={false}
                      onFocus={(e) => {
                        e.currentTarget.classList.add('focused-search')
                      }}
                      onBlur={(e) => {
                        e.currentTarget.classList.remove('focused-search')
                      }}
                      autoFocus
                      onChange={(e) => {
                        updateSearch(e.target.value)
                      }}
                    />
                    <i
                      style={{ float: 'left' }}
                      className="fas fa-search icon"
                    ></i>
                  </div>
                </Col>
              </Row>
            </Col>
            {
              <>
                {filteredModels.map((model) => (
                  /*<Col key={model.id} xl={3} lg={4} md={6} xs={12}>
                    <ItemModel
                      className="topmodels-modelcard light-card py-2 mx-1 px-3 mt-2 mb-4"
                      model={model}
                      projectName={projectName}
                      projectID={projectId}
                      navigateProject={false}
                      onUpdate={updateModel}
                      onDelete={deleteModel}
                      mode={mode}
                      onMouseUp={(e) => {
                        //Inline edit doesn't behave well with <Link>, workaround
                        if (e.button === 2) return
                        const newWindow =
                          e.ctrlKey || e.shiftKey || e.button === 1
                        if (newWindow) window.open(createUri(model), '_blank')
                        else setActiveModel(model)
                      }}
                    />
                  </Col>*/
                  <Col
                    xl={3}
                    lg={4}
                    md={6}
                    xs={12}
                    className="mb-4"
                    key={model.id}
                  >
                    <div
                      className={`model-background-container model-card-type-${getModelType(
                        model,
                      )}`}
                    >
                      <div
                        className={`model-radial-gradient model-card-type-${getModelType(
                          model,
                        )}`}
                      >
                        <ItemModel
                          className="mx-1 px-3 pt-1 mt-2 pb-3"
                          model={model}
                          projectName={projectName}
                          projectID={projectId}
                          navigateProject={false}
                          onUpdate={updateModel}
                          onDelete={deleteModel}
                          mode={mode}
                          onMouseUp={(e) => {
                            //Inline edit doesn't behave well with <Link>, workaround
                            if (e.button === 2) return
                            const newWindow =
                              e.ctrlKey || e.shiftKey || e.button === 1
                            if (newWindow)
                              window.open(createUri(model), '_blank')
                            else setActiveModel(model)
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              </>
            }
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

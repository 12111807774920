// Constants.js
const API_URL =
  process.env?.['REACT_APP_PROD_API'] || `https://api.nextbrain.ai`
const ADMIN_API_URL =
  process.env?.['REACT_APP_PROD_ADMIN_API'] || `https://adminapi.nextbrain.ai`
const AUTOGEN2_API_URL =
  process.env?.['REACT_APP_AUTOGEN2_API'] || `https://agent.nextbrain.ai`
const WAREHOUSE_URL =
  process.env?.['REACT_APP_WAREHOUSE_URL'] ||
  `https://warehouse.nextbrain.ai:8080`

const prod = {
  API_URL: API_URL,
  ADMIN_API_URL: ADMIN_API_URL,
  AUTOGEN2_URL: AUTOGEN2_API_URL,
  WAREHOUSE_URL: WAREHOUSE_URL,
  STRIPE_PK:
    'pk_live_51MtTM7FtT3tOjj6lHLY6BgiCkVV88PNtdpeyElOh7rQh1ZK9qPMTuxJF4LyDtrgcnGR3VklLSjHMSJp5VANXPq9L00qgviIOdS',
  STRIPLE_PLANS: {
    Freelance: {
      id: 'prod_NjzBpDueB55BwK',
      usd: {
        year: 'price_1Ng1EgFtT3tOjj6l8eRA1D8A',
        month: 'price_1Ng1DdFtT3tOjj6lCacO9Yit',
      },
      eur: {
        year: 'price_1Ng1EgFtT3tOjj6l8eRA1D8A',
        month: 'price_1Ng1DdFtT3tOjj6lCacO9Yit',
      },
    },
    'Small Business': {
      id: 'prod_NjzDOKGF3UQTaT',
      usd: {
        year: 'price_1Ng1PqFtT3tOjj6lht25r0Jg',
        month: 'price_1Ng1P0FtT3tOjj6lY6UREKdJ',
      },
      eur: {
        year: 'price_1Ng1PqFtT3tOjj6lht25r0Jg',
        month: 'price_1Ng1P0FtT3tOjj6lY6UREKdJ',
      },
    },
    Enterprise: {
      id: 'prod_NjzFW3954IRAtY',
      usd: {
        year: 'price_1Ng1gOFtT3tOjj6ltIdpBXbD',
        month: 'price_1Ng1fsFtT3tOjj6lme43jhXW',
      },
      eur: {
        year: 'price_1Ng1gOFtT3tOjj6ltIdpBXbD',
        month: 'price_1Ng1fsFtT3tOjj6lme43jhXW',
      },
    },
  },
}
const dev = {
  API_URL: `https://mldev.dataslayer.ai`,
  ADMIN_API_URL: `https://adminapi.nextbrain.ml`,
  AUTOGEN2_URL: 'https://chat.nextbrain.ml',
  WAREHOUSE_URL: 'https://warehouse.nextbrain.ml',
  // API_URL: `http://localhost:8888`,
  // ADMIN_API_URL: `http://localhost:8080`,
  // AUTOGEN2_URL: 'http://localhost:4321',
  // WAREHOUSE_URL: 'http://localhost:8080',

  STRIPE_PK:
    'pk_test_51MtTM7FtT3tOjj6l8QpJsCBwLJXjnrMKxi3GvzyZhVWvfN9FxscrxSk16ue9srQdhIg2mIrE4CFxW4OX5yBeJx6m00lKEtsJDx',
  STRIPLE_PLANS: {
    Freelance: {
      id: 'prod_Nen2P1c0lJNyUQ',
      usd: {
        year: 'price_1NgPxSFtT3tOjj6lmedZbG45',
        month: 'price_1Ng1IZFtT3tOjj6lHz8CSWhs',
      },
      eur: {
        year: 'price_1NgPxSFtT3tOjj6lmedZbG45',
        month: 'price_1Ng1IZFtT3tOjj6lHz8CSWhs',
      },
    },
    'Small Business': {
      id: 'prod_Nen3bFm2pW8785',
      usd: {
        year: 'price_1NgQ2RFtT3tOjj6lRQDSYSQG',
        month: 'price_1Ng1KxFtT3tOjj6lgJTMyTxs',
      },
      eur: {
        year: 'price_1NgQ2RFtT3tOjj6lRQDSYSQG',
        month: 'price_1Ng1KxFtT3tOjj6lgJTMyTxs',
      },
    },
    Enterprise: {
      id: 'prod_Nen3l59KARw9ce',
      usd: {
        year: 'price_1Ng1evFtT3tOjj6leotNUKhU',
        month: 'price_1Ng1eMFtT3tOjj6lZ6mzbPPA',
      },
      eur: {
        year: 'price_1Ng1evFtT3tOjj6leotNUKhU',
        month: 'price_1Ng1eMFtT3tOjj6lZ6mzbPPA',
      },
    },
  },
}

export const config =
  (process.env.REACT_APP_MODE || process.env.NODE_ENV) === `development`
    ? dev
    : prod
export const HELP_URL = 'https://help.nextbrain.ai'

import React from 'react'
import { Row, Col, OverlayTrigger, Popover } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import HelpTooltip from '../HelpTooltip'
import { statisticalSignificance } from '../../../services/model'
import { useAuth } from '../../../providers/AuthProvider'
import Loading from '../../loading/LoadingSmall'
import { defaultFormat, defaultFormatRange, round } from '../../utils/formating'
import { BiSpreadsheet } from 'react-icons/bi'
import { BsEyeglasses } from 'react-icons/bs'
import './significance.css'
import CrashFallback from '../../crash-fallback/CrashFallback'

const STYLE_FORMAT = { color: 'white' }

function Bar({
  percent,
  color = 'var(--nextbrain-tables-graph-bar-color)',
  children,
  ...props
}) {
  return (
    <Row>
      <Col
        className={`bar-distribution-container mx-2 ${props?.className ?? ''}`}
        xs={12}
      >
        <Row className="h-100">
          <Col
            xs={12}
            className="bar-distribution dflex-center px-0"
            style={{ maxWidth: `${percent}%`, backgroundColor: color }}
          >
            {round(percent, 0)}%
          </Col>
          {children}
        </Row>
      </Col>
    </Row>
  )
}

function Distribution({
  columnValue,
  target,
  targetValue,
  increment,
  total,
  group,
  column,
  value,
}) {
  const { t } = useTranslation()

  return (
    <Row className="px-4 py-2 position-relative w-100 ms-2">
      <Col className=" text-start h5 ps-0" style={{ fontSize: '18px' }} xs={12}>
        <Row>
          <Col className="px-0" style={{ maxWidth: '40px' }} xs={12}>
            <BiSpreadsheet className="me-2" size={40} />
          </Col>
          <Col
            style={{ maxWidth: 'calc(100% - 40px)', textWrap: 'balance' }}
            xs={12}
          >
            {t('Rows with')}
            {` ${column} `} {columnValue}
          </Col>
        </Row>
      </Col>
      <Col className="significance-card-distribution" xs={12}>
        <Row className="justify-content-between flex-column h-100">
          <Col xs={12}>
            <Row className="justify-content-center">
              <Col className="pb-2 dflex-center" xs={12}>
                {t('Whole dataset')}
              </Col>
              <Col xs={8}>
                <Bar percent={total * 100} />
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Row className="justify-content-center">
              <Col className="mt-3 py-2 dflex-center" xs={12}>
                {t('When {{target}} is {{targetValue}}', {
                  target,
                  targetValue,
                })}
              </Col>
              <Col xs={8}>
                <Bar
                  color={'var(--nextbrain-automl-color)'}
                  percent={group * 100}
                />
              </Col>
            </Row>
          </Col>
          <Col className="dflex-center" xs={12}>
            <Row className="justify-content-center">
              <Col xs={12}>{increment}</Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col className="mt-3 mb-1 py-2" xs={12}>
        <Row>
          <Col
            className="px-0 dflex-center"
            style={{ maxWidth: '50px', fontSize: '40px' }}
            xs={4}
          >
            ! <BsEyeglasses color="white" size={40} />
          </Col>
          <Col
            className="px-0 dflex-center"
            xs={6}
            style={{ maxWidth: 'calc(50% - 50px)' }}
          >
            <Row className="justify-content-start w-100">
              <Col className="mb-1" xs={10}>
                <Bar percent={Math.max(total * 100, 30)} />
              </Col>
              <Col xs={10}>
                <Bar
                  color={'var(--nextbrain-automl-color)'}
                  percent={Math.max(total * 100, 30)}
                />
              </Col>
            </Row>
          </Col>
          <Col className="ps-0 text-start" xs={6}>
            {t('If')}
            {` ${column} `} {columnValue}{' '}
            {t(
              'had no effect on predicting {{target}} we would expect a similar distribution in both groups',
              { target },
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default function StatisticalSignificance({
  modelId,
  span,
  showTitle = true,
  filter = null,
  wrapper = (children) => children,
}) {
  const { t } = useTranslation()
  const { token, signout } = useAuth()

  if (Array.isArray(filter)) filter = new Set(filter)

  const { data: significance, isLoading } = useQuery(
    ['statistical-significance', modelId],
    async () => {
      const data = await statisticalSignificance({
        modelId,
        token,
        signout,
      })

      const target_to_stats = data.target_value_to_column_statistics

      function scaleTo0_100(value, min_value, max_value) {
        return ((value - min_value) / (max_value - min_value)) * 100
      }

      function scaleToLog(value) {
        return Math.log(value + 1)
      }

      let minSignificance = Infinity
      let maxSignificance = -Infinity

      for (const key in target_to_stats) {
        for (const key2 in target_to_stats[key].statistics) {
          target_to_stats[key].statistics[key2].significant = scaleToLog(
            target_to_stats[key].statistics[key2].significant,
          )
        }
      }

      for (const key in target_to_stats) {
        for (const key2 in target_to_stats[key].statistics) {
          const significance = target_to_stats[key].statistics[key2].significant
          minSignificance = Math.min(minSignificance, significance)
          maxSignificance = Math.max(maxSignificance, significance)
        }
      }

      for (const key in target_to_stats) {
        for (const key2 in target_to_stats[key].statistics) {
          target_to_stats[key].statistics[key2].significant =
            100 -
            scaleTo0_100(
              target_to_stats[key].statistics[key2].significant,
              minSignificance,
              maxSignificance,
            )
        }
      }

      return data
    },
    { staleTime: Infinity },
  )

  const targetValues = Object.keys(
    significance?.target_value_to_column_statistics ?? {},
  )
    .filter(
      (value) =>
        (!filter || filter.has(value)) &&
        Object.keys(
          significance?.target_value_to_column_statistics?.[value]
            ?.statistics ?? {},
        )?.length > 0,
    )
    .sort((a, b) => Number.parseFloat(a) - Number.parseFloat(b))

  const targetValuesFormat = targetValues.reduce((acc, str) => {
    try {
      acc[str] = defaultFormatRange({ range: str, wrap: true })
    } catch (e) {}
    return acc
  }, {})

  const ensureColumnName = (name) => {
    if (name.startsWith('ln_') || name.startsWith('sq_')) return name.slice(3)
    return name
  }

  const parseNumericalMode = (interval, wrap = false) => {
    if (interval === undefined || interval === null) return <></>
    try {
      interval = interval.slice(1, -1)
    } catch (e) {
      return <></>
    }

    const [min, max] = interval.split(', ')
    return (
      <span>
        From{' '}
        <span>{defaultFormat({ num: min, wrap, style: STYLE_FORMAT })}</span> to{' '}
        <span>{defaultFormat({ num: max, wrap, style: STYLE_FORMAT })}</span>
      </span>
    )
  }

  const getIncrement = (stats, column) => {
    return round(
      stats[column].global_percent > stats[column].mode_percent
        ? stats[column].global_percent / stats[column].mode_percent
        : stats[column].mode_percent / stats[column].global_percent,
      1,
    )
  }

  const size = span ? { xs: span } : { xl: 3, lg: 4, md: 6 }

  if (!isLoading && targetValues.length === 0) {
    return null
  }

  return wrapper(
    <>
      {showTitle && (
        <Row>
          <Col className="exploration-header mt-3" xs={12}>
            <Row>
              <Col className="h4 bold" xs={12}>
                <strong>
                  {t('Key features for each group in the "{{target}}" column', {
                    target: significance?.target ?? 'target',
                  })}
                </strong>
                <HelpTooltip
                  className="ms-1 help-select-icon"
                  message={'statistical significance'}
                  helpLink={'column-effect-importance-18tlnuh'}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {isLoading ? (
        <Row className="mb-3">
          <Loading />
        </Row>
      ) : (
        <Row className="justify-content-center mb-3">
          {targetValues.map((value, i) => {
            const stats =
              significance.target_value_to_column_statistics[value].statistics

            return (
              <Col key={value} className="significance-card-parent" {...size}>
                <Row className="justify-content-center h-100">
                  <Col
                    xs={12}
                    className="significance-card text-center text-break py-2"
                  >
                    <Row className="mb-3" style={{ minHeight: '50px' }}>
                      <h5>
                        {t('When')} {significance.target} {t('is')}
                        <br />
                        <span className="enphasis">
                          {targetValuesFormat[value] ?? value}
                        </span>
                      </h5>
                    </Row>
                    <Row className="my-1">
                      <Col className="d-flex justify-content-end" xs={12}>
                        <div style={{ lineHeight: 0.8, paddingRight: 25 }}>
                          {t('Group')}
                          <br />
                          <span style={{ fontSize: 12 }}>vs</span>
                          <br />
                          {t('Total')}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <table className="significance-table w-100">
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(stats)
                              .sort(
                                (a, b) =>
                                  getIncrement(stats, b) -
                                  getIncrement(stats, a),
                              )
                              .map((column, i) => {
                                const ratio =
                                  stats[column].global_percent >
                                  stats[column].mode_percent
                                    ? stats[column].global_percent /
                                      stats[column].mode_percent
                                    : stats[column].mode_percent /
                                      stats[column].global_percent

                                const MIN_VALUE = 1
                                const MAX_VALUE = 3
                                const NUMBER_OF_COLORS = 5
                                const clamp = Math.min(
                                  MAX_VALUE,
                                  Math.max(MIN_VALUE, ratio),
                                )
                                let significanceNumber =
                                  NUMBER_OF_COLORS -
                                  Math.floor(
                                    ((clamp - MIN_VALUE) /
                                      (MAX_VALUE - MIN_VALUE)) *
                                      (NUMBER_OF_COLORS - 1),
                                  )

                                significanceNumber = Math.max(
                                  1,
                                  Math.min(
                                    NUMBER_OF_COLORS,
                                    significanceNumber,
                                  ),
                                )

                                return (
                                  <tr key={`${value}-${column}`}>
                                    <td
                                      className="text-left w-75"
                                      style={{ textWrap: 'balance' }}
                                    >
                                      <span className="enphasis">
                                        {ensureColumnName(column)}
                                      </span>
                                      :{' '}
                                      {stats[column].number ? (
                                        parseNumericalMode(
                                          stats[column].mode,
                                          true,
                                        )
                                      ) : (
                                        <span>{stats[column].mode}</span>
                                      )}
                                    </td>
                                    <td>
                                      <OverlayTrigger
                                        rootClose={true}
                                        trigger={['hover', 'focus']}
                                        placement={'auto'}
                                        delay={{ show: 100, hide: 200 }}
                                        overlay={(props) => (
                                          <Popover
                                            {...props}
                                            className={`no-bg-popover-body popover-statistical-significance ${
                                              props?.className ?? ''
                                            }`}
                                            style={{
                                              ...(props?.style ?? {}),
                                              minWidth: `650px`,
                                              pointerEvents: 'none',
                                            }}
                                          >
                                            <Distribution
                                              columnValue={
                                                stats[column].number ? (
                                                  parseNumericalMode(
                                                    stats[column].mode,
                                                  )
                                                ) : (
                                                  <>
                                                    <span className="enphasis">
                                                      {stats[column].mode}
                                                    </span>
                                                  </>
                                                )
                                              }
                                              target={significance.target}
                                              targetValue={value}
                                              column={column}
                                              value={stats[column].mode}
                                              total={
                                                stats[column].global_percent
                                              }
                                              group={stats[column].mode_percent}
                                              increment={
                                                <p className="success-enphasis dflex-center position-relative p-1 m-0 mt-2">
                                                  {t('An increment of')}{' '}
                                                  {getIncrement(stats, column)}x
                                                </p>
                                              }
                                            />
                                          </Popover>
                                        )}
                                      >
                                        <p
                                          className="success-enphasis dflex-center position-relative py-1 w-100"
                                          style={{
                                            backgroundColor: `var(--significance-g${significanceNumber})`,
                                            minWidth: '50px',
                                          }}
                                        >
                                          {round(ratio, 1)}x
                                        </p>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            )
          })}
          <CrashFallback message={<></>} t={t}>
            <Col xs={12}>
              {significance?.target && targetValues && (
                <div className="smallp text-center px-5 mx-5 my-3">
                  <strong className="me-1">{t('EXPLANATION')}:</strong>
                  {t(
                    'The graph represents how a target variable, named "{{target}}," is categorized into {{groups}} groups based on its value. For each group, the graph displays the most important features determined by the average value within that group. This visualization helps understand how different features influence "{{target}}", highlighting the key characteristics that differentiate one group from another in terms of "{{target}}" determinants.',
                    {
                      target: significance?.target,
                      groups: Object.keys(targetValues || {}).length,
                    },
                  )}
                </div>
              )}
            </Col>
          </CrashFallback>
        </Row>
      )}
    </>,
  )
}

import React, { useState, useEffect, useRef } from 'react'
import { NotificationManager } from 'react-notifications'
import { FaFileDownload } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useAuth } from '../../providers/AuthProvider'
import { downloadDataset } from '../../services/model'
import './GridTable.css'

export function GridCell({ children, overlay, ...props }) {
  if (!overlay || overlay === '')
    return (
      <div {...props} className={`grid-table-cell ${props.className ?? ''}`}>
        {children}
      </div>
    )

  return (
    <OverlayTrigger
      rootClose={true}
      trigger={['hover', 'focus']}
      placement="top"
      overlay={(props) => (
        <Tooltip {...props}>
          <span>{overlay}</span>
        </Tooltip>
      )}
    >
      <div {...props} className={`grid-table-cell ${props.className ?? ''}`}>
        {children}
      </div>
    </OverlayTrigger>
  )
}

function stringifyValue(value) {
  if (typeof value === 'boolean') return value.toString()
  return value
}

export function GridRow({
  row,
  widths,
  element,
  order,
  header = false,
  rowIndex = null,
  stylesByColumn = [],
  style = {},
  onResize = () => {},
}) {
  const [updateHeader, setUpdateHeader] = useState(0)
  const { t } = useTranslation()

  const generateElement = (i, value, width, position) => {
    const response = element(value, width, position)
    let content = response
    let reasons = []
    // Check if element return two values
    if (Array.isArray(response)) {
      content = response[0]
      reasons = response[1]
    }
    return (
      <GridCell
        key={i}
        style={{ ...stylesByColumn[i], ...style }}
        overlay={
          reasons.length ? `${t('Data quality')}: ` + reasons.join(', ') : ''
        }
      >
        <>
          {header ? (
            <div
              className="control-width-header"
              onMouseUp={() => header && setUpdateHeader(updateHeader + 1)}
              onMouseDown={(e) => {
                onResize(i, e.screenX)
              }}
            ></div>
          ) : (
            <></>
          )}
          {content}
        </>
      </GridCell>
    )
  }

  return order ? (
    <>
      {order.map((dex, i) => {
        return generateElement(i, stringifyValue(row[dex]), widths[dex], {
          column: dex,
          row: rowIndex,
        })
      })}
    </>
  ) : (
    <>
      {row.map((cell, i) => {
        return generateElement(i, stringifyValue(cell), widths[i], {
          column: i,
          row: rowIndex,
        })
      })}
    </>
  )
}

function Grouping({ name, index, span, offset, Generator, ...props }) {
  const style = {
    gridColumn: `span ${span}`,
  }

  return (
    <div
      style={style}
      {...props}
      className={`grouping index-${index} ${props.className ?? ''}`}
    >
      <Generator name={name} />
    </div>
  )
}

export const DownloadPosition = Object.freeze({
  INDEX: 'INDEX',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
})
// eslint-disable-next-line
function DownloadTable({
  customDownload,
  header,
  rows,
  name,
  process,
  modelId = null,
}) {
  const { t } = useTranslation()
  let { signout, freeUser, token } = useAuth()

  return (
    <span className="d-inline-block hide-on-import">
      <OverlayTrigger
        rootClose={true}
        trigger={['hover', 'focus']}
        placement="top"
        delay={{ show: 100, hide: 100 }}
        overlay={(props) => (
          <Tooltip {...props}>
            {modelId ? t('Download original data') : t('Download as CSV')}
          </Tooltip>
        )}
      >
        <span>
          <FaFileDownload
            onClick={() => {
              if (modelId) {
                const warnMsg = t(
                  'Upgrade your plan to get access to this feature',
                )
                if (freeUser) {
                  NotificationManager.warning(warnMsg)
                  return
                }
                if (customDownload) customDownload()
                else
                  downloadDataset({ modelId, token, signout })
                    .then((response) => response.blob())
                    .then((blob) => {
                      // Create blob link to download
                      const url = window.URL.createObjectURL(new Blob([blob]))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', name + '.csv')

                      // Append to html link element page
                      document.body.appendChild(link)

                      // Start download
                      link.click()

                      // Clean up and remove the link
                      link.parentNode.removeChild(link)
                    })
                return
              }
              const data = [header.flat(), ...rows]
              const csvContent =
                'data:text/csv;charset=utf-8,' +
                (process
                  ? data.map((x) => x.map((cell) => process(cell)))
                  : data
                )
                  .map((x) => x.join(','))
                  .join('\n')
              const encodedUri = encodeURI(csvContent)
              const link = document.createElement('a')
              link.setAttribute('href', encodedUri)
              link.setAttribute('download', `${name ?? 'table'}.csv`)
              document.body.appendChild(link)
              link.click()
            }}
            className="icon-btn"
          />
        </span>
      </OverlayTrigger>
    </span>
  )
}

export function GridTable({
  header = [],
  headerElement = (h) => h,
  rows = [],
  cellElement = (c, widths, position) => c,
  defaultColumnWidth = () => 200,
  rowsPerPage = 50,
  customOrder,
  index = null,
  headerElementProps,
  Pager = () => <></>,
  pagerLast = false,
  groupings = null, //Create headers for groups of columns {"name":span:int, "name2": span:int, ...}
  groupingElement = (name, index, span) => name,
  footer = null,
  stylesByColumn = [],
  emptyDatasetMessage = 'Empty dataset',
  placeholder = false,
  stableHeight = false,
  download = false,
  downloadFilename,
  downloadProcessCells,
  customDownload,
  scroll,
  children,
  modelId = null,
  datasetLength,
  ...props
}) {
  const gridTableRef = useRef()
  const colWidthRef = useRef(
    header?.[0]?.map((s, i) => defaultColumnWidth(s, i)) ?? [],
  )
  const columnWidth = colWidthRef.current

  const setColumnWidth = (c) => {
    colWidthRef.current = c
    if (gridTableRef.current) {
      gridTableRef.current.style['grid-template-columns'] = `${
        index ? '30px' : ''
      } ${colWidthRef.current.map((k) => `${isNaN(k) ? 200 : k}px`).join(' ')}`
    }
  }

  const resizeRef = useRef(null)
  const setResize = (r) => {
    resizeRef.current = r
    if (gridTableRef.current) {
      if (r) gridTableRef.current.classList.add('resize-table')
      else gridTableRef.current.classList.remove('resize-table')
    }
  }
  const [order, setOrder] = useState(null)
  const [page, setPage] = useState(0)

  const getRowIteration = () =>
    rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage)

  // eslint-disable-next-line
  const resizeColumn = (e) => {
    if (resizeRef.current) {
      columnWidth[resizeRef.current.col] = Math.max(
        10,
        resizeRef.current.width + e.screenX - resizeRef.current.posX,
      )
      setColumnWidth([...columnWidth])
    }
  }

  const calculateOrder = () => {
    if (!header || !header.length || !Array.isArray(customOrder)) return null

    const [base, indexToKey] = header[0].reduce(
      ([b, i2k], v, i) => {
        b[v] = i
        i2k[i] = v
        return [b, i2k]
      },
      [{}, {}],
    )

    customOrder.forEach((v, i) => {
      if (base[v] === undefined) {
        console.error(`invalid field ${v} for ordering`)
        return
      }

      if (!v || base[v] === i) return

      const prevKey = indexToKey[i]
      const prevDex = base[v]
      base[v] = i
      base[prevKey] = null
      indexToKey[i] = v
      indexToKey[prevDex] = null
    })

    const emptyIndexes = Object.keys(indexToKey)
      .filter((k) => !indexToKey[k])
      .sort((a, b) => a - b)

    return header[0].map((v, i) => base[v] ?? emptyIndexes.pop())
  }

  const pager = (
    <Pager
      maxPage={Math.ceil(rows.length / rowsPerPage)}
      onChange={(p) => setPage(p)}
      page={page}
    />
  )

  const width = columnWidth.reduce((a, r) => a + r, 0)

  const resizeCB = (col, pos) => {
    setResize({
      col: col,
      posX: pos,
      width: columnWidth[col],
    })
  }

  useEffect(
    () => {
      if (header.length !== columnWidth.length)
        setColumnWidth(
          header[0].map((k, i) => {
            const res = defaultColumnWidth(k, i)
            if (isNaN(res)) return 200
            return res
          }),
        )
      setOrder(calculateOrder())
    },
    // eslint-disable-next-line
    [rows, header, placeholder],
  )

  const groupingTail = groupings
    ? Math.max(
        0,
        columnWidth.length -
          Object.keys(groupings).reduce((a, r) => a + groupings[r], 0),
      )
    : 0

  const interation = getRowIteration()

  const showData = interation.length > 0
  const showMessage = placeholder || interation.length === 0

  const fillerRows = []
  if (stableHeight && interation.length < rowsPerPage) {
    for (let i = 0; i < rowsPerPage - interation.length; i++) {
      fillerRows.push(
        <GridRow
          key={`filler_${i}`}
          row={Array(header[0].length).fill('.')}
          order={order}
          widths={columnWidth}
          element={cellElement}
          rowIndex={i}
          onResize={resizeCB}
          stylesByColumn={stylesByColumn}
          style={{ visibility: 'hidden' }}
        />,
      )
    }
  }

  useEffect(() => {
    const clear = () => setResize(null)
    const move = resizeColumn
    const body = document.getElementsByTagName('body')[0]
    body.addEventListener('mouseup', clear)
    body.addEventListener('mousemove', move)
    return () => {
      body.removeEventListener('mouseup', clear)
      body.removeEventListener('mousemove', move)
    }
    // eslint-disable-next-line
  }, [resizeColumn])

  const Scroll = scroll
  let sampled = false

  if (datasetLength && rows?.length && datasetLength > rows?.length)
    sampled = true

  return (
    <>
      {pagerLast ? <></> : pager}
      {scroll ? <Scroll scrollElement={() => gridTableRef} /> : null}
      <div
        {...props}
        className={`
          grid-table
          ${props.className ?? ''} 
          ${stableHeight ? 'h-auto' : ''}
          ${sampled ? 'sampled-grid-table' : ''}
         `}
        ref={gridTableRef}
        style={{
          ...(props?.style ?? {}),
        }}
      >
        <div
          className={`grid-table-container`}
          style={{
            gridTemplateColumns: 'inherit',
            minWidth: `${width}px`,
          }}
        >
          {groupings && (
            <>
              <Grouping
                index={-1}
                name={''}
                span={1}
                Generator={groupingElement}
                className="index-cell"
              />
              {Object.keys(groupings).map((k, i) => (
                <Grouping
                  key={i}
                  index={i}
                  name={k}
                  span={groupings[k]}
                  offset={i ? 0 : 2}
                  Generator={groupingElement}
                />
              ))}
              {groupingTail ? (
                <div
                  className="grouping-tail"
                  style={{ gridColumn: `span ${groupingTail}` }}
                ></div>
              ) : (
                <></>
              )}
            </>
          )}
          {index ? (
            index(download === DownloadPosition.INDEX ? <></> : 0)
          ) : (
            <></>
          )}
          {header.map((r, i) => (
            <GridRow
              header={true}
              key={i}
              row={r}
              order={order}
              widths={columnWidth}
              element={headerElement}
              onResize={resizeCB}
            />
          ))}
        </div>

        {showData &&
          interation.map((r, i) => (
            <div
              className={`grid-table-container`}
              key={i}
              style={{
                gridTemplateColumns: 'inherit',
                minWidth: `${width}px`,
              }}
            >
              {index ? index(rowsPerPage * page + i + 1) : <></>}
              <GridRow
                key={i}
                row={r}
                order={order}
                widths={columnWidth}
                rowIndex={i}
                element={cellElement}
                onResize={resizeCB}
                stylesByColumn={stylesByColumn}
              />
            </div>
          ))}

        {fillerRows.length > 0 &&
          fillerRows.map((r, i) => (
            <div
              className={`grid-table-container`}
              key={i}
              style={{
                gridTemplateColumns: 'inherit',
                minWidth: `${width}px`,
              }}
            >
              {index ? index(i + interation.length + 1) : <></>}
              {r}
            </div>
          ))}

        {showMessage && (
          <div
            className="grid-table-no-data w-100"
            style={{
              gridColumn: `span ${width.length}`,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {emptyDatasetMessage}
          </div>
        )}
        {footer ? (
          <div
            className={`grid-table-container grid-table-footer`}
            style={{
              gridTemplateColumns: 'inherit',
              minWidth: `${width}px`,
            }}
          >
            {index ? index('') : <></>}
            <GridRow
              row={footer}
              order={order}
              widths={columnWidth}
              element={cellElement}
              onResize={resizeCB}
              stylesByColumn={stylesByColumn}
            />
          </div>
        ) : (
          <></>
        )}
        {children}
      </div>
      {pagerLast ? pager : <></>}
    </>
  )
}
